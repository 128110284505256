import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import HeightQuestion from 'components/organisms/Questions/General/HeightQuestion';

import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';

export default function YourHeight(props) {
  const classes = usePageStyles(props);
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();

  const radioButtonsHeight = [
    {
      label: t('onboarding.generalSection.step3.ft'),
      value: 1,
      checked:
        overmindState.calc_selectedHeightMetric == null ||
        overmindState.calc_selectedHeightMetric === 1,
    },
    {
      label: t('onboarding.generalSection.step3.cm'),
      value: 2,
      checked: overmindState.calc_selectedHeightMetric === 2,
    },
  ];

  const onHeightChange = (data) => {
    const nextEnabled = data.height >= 120 && data.height <= 230;
    actions.updateStateProp({
      key: 'calc_height',
      value: data.height,
      nextEnabled: nextEnabled,
    });
    actions.updateStateProp({
      key: 'calc_selectedHeightMetric',
      value: data.selectedHeightMetric,
      nextEnabled: nextEnabled,
    });
    actions.updateStateProp({
      key: 'calc_heightFt',
      value: data.heightFt,
      nextEnabled: nextEnabled,
    });
    actions.updateStateProp({
      key: 'calc_heightIn',
      value: data.heightIn,
      nextEnabled: nextEnabled,
    });
  };

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <HeightQuestion
        title={t('onboarding.generalSection.step3.height')}
        data={radioButtonsHeight}
        height={overmindState.calc_height}
        heightFt={overmindState.calc_heightFt}
        heightIn={overmindState.calc_heightIn}
        selectedHeightMetric={overmindState.calc_selectedHeightMetric}
        onChange={onHeightChange}
      />
    </GridContainer>
  );
}
