import React from 'react';
import MuiSlider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import { useSliderStyles } from './sliderStyles';

function ModifiedAirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

/**
 * Slider component based on https://material-ui.com/api/slider/
 * @param {} props All possible props can be found in docs https://material-ui.com/api/slider/
 */
export default function Slider(props) {
  const classes = useSliderStyles(props);

  return (
    <MuiSlider
      id={props.uid}
      {...props}
      step={props.step ? props.step : 25}
      defaultValue={
        props.marksValues
          ? props.marksValues[props.selectedIndex]
          : props.selectedIndex * 25
      }
      marks={props.marks ? props.marks : true}
      min={props.min ? props.min : 0}
      max={props.max ? props.max : 100}
      onChangeCommitted={(event, value) => {
        if (props.onValueChanged) {
          if (props.marksValues) {
            props.onValueChanged(props.marksValues.indexOf(value));
          } else {
            props.onValueChanged(value / 25);
          }
        }
      }}
      classes={classes}
      ThumbComponent={ModifiedAirbnbThumbComponent}
    />
  );
}

Slider.propTypes = {
  uid: PropTypes.string,
  onValueChanged: PropTypes.func,
  selectedIndex: PropTypes.number,
};
