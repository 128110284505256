import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { useOvermindState } from '../../../overmind';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useFooterStyles, useButtonStyles } from './footerStyles';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import { getCalculatorStepIndex } from 'components/helpers/onboardingHelper';

export default function Footer(props) {
  const classes = useFooterStyles(props);
  const buttonClasses = useButtonStyles(props);
  const overmindState = useOvermindState();

  const getStepProgress = () => {
    const step = overmindState.progressStep;
    if (step === getCalculatorStepIndex('age')) {
      return '1';
    } else if (step === getCalculatorStepIndex('height')) {
      return '2';
    } else if (step === getCalculatorStepIndex('weight')) {
      return '3';
    } else if (step === getCalculatorStepIndex('your_first_baby_page')) {
      return '4';
    } else if (step === getCalculatorStepIndex('first_baby_week')) {
      return '5';
    } else if (step === getCalculatorStepIndex('post_term_pregnancy_page')) {
      return '6';
    } else if (step === getCalculatorStepIndex('have_you_been_diagnosed')) {
      return '7';
    } else if (step === getCalculatorStepIndex('how_often_do_you_smoke')) {
      return '8';
    } else if (step === getCalculatorStepIndex('how_stressful_is_your_file')) {
      return '9';
    } else if (step === getCalculatorStepIndex('cycle_length')) {
      return '10';
    } else if (step === getCalculatorStepIndex('conception_experience')) {
      return '11';
    } else if (step === getCalculatorStepIndex('date_of_transfer')) {
      return '12';
    } else if (step === getCalculatorStepIndex('do_you_know_last_period')) {
      return '12';
    } else if (
      step === getCalculatorStepIndex('do_you_know_when_you_have_conceived')
    ) {
      return '12';
    } else if (step === getCalculatorStepIndex('capture_email')) {
      return '12';
    } else if (step === getCalculatorStepIndex('calculate_due_date')) {
      return '12';
    } else if (step === getCalculatorStepIndex('first_name')) {
      return '12';
    }
  };

  return (
    <>
      {!props.hideFooter && (
        <GridContainer
          direction="row"
          alignItems="center"
          classes={{ ...classes }}
        >
          {!props.centerRightButton && (
            <GridItem xs={4} style={{ paddingLeft: 17 }}>
              {props.showLeftButton && (
                <Button
                  onClick={() => {
                    props.onBackClick();
                  }}
                  className={buttonClasses.back}
                >
                  {props.leftButtonContent ? (
                    props.leftButtonContent
                  ) : (
                    <>
                      <NavigateBeforeIcon /> Back
                    </>
                  )}
                </Button>
              )}
            </GridItem>
          )}

          {!props.centerRightButton && (
            <GridItem xs={4} style={{ textAlign: 'center' }}>
              {props.showProgressInSteps && (
                <Typography variant="body1" style={{ fontSize: '14px' }}>
                  {getStepProgress()}/12
                </Typography>
              )}
            </GridItem>
          )}

          <GridItem
            xs={props.centerRightButton ? 12 : 4}
            style={{ paddingRight: props.centerRightButton ? 0 : 17 }}
          >
            {props.hideRightButton !== true && (
              <Box
                display="flex"
                justifyContent={props.centerRightButton ? 'center' : 'flex-end'}
              >
                <Button
                  disabled={!overmindState.onboardingForwardEnabled}
                  variant="contained"
                  onClick={() => props.onNextClick()}
                  className={buttonClasses.root}
                >
                  {props.rightButtonContent ? (
                    props.rightButtonContent
                  ) : (
                    <>
                      {props.text ? props.text : 'Next'} <NavigateNextIcon />
                    </>
                  )}
                </Button>
              </Box>
            )}
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}

Footer.propTypes = {
  hideFooter: PropTypes.bool,
  hideLeftButton: PropTypes.bool,
  hideRightButton: PropTypes.bool,
  leftButtonContent: PropTypes.element,
  rightButtonContent: PropTypes.element,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
};
