import { makeStyles } from '@material-ui/core';
import { APP_TEXT_HINT } from 'themes';

export const useRecipeDetailsStyles = makeStyles((theme) => ({
  dataCard: {
    width: '150px',
    height: '50px',
    padding: '8.4px 7.3px 8.1px 9.2px',
    borderRadius: '5px',
    backgroundImage: 'linear-gradient(91deg, #e8af44 0%, #e86e6e 100%)',
    textAlign: 'center',
    color: theme.palette.grey[50],
    fontSize: '25px',
    paddingTop: '30px',
  },
  healthBenefit: {
    padding: '1.5px 10px 2.5px 8px',
    borderRadius: '6.5px',
    border: 'solid 0.5px ' + APP_TEXT_HINT,
    marginRight: '4px',
    marginBottom: '4px',
    color: APP_TEXT_HINT,
    fontSize: '13px',
  },
  hint: {
    color: 'white',
    fontSize: '30px',
  },
  servingInfoText: {
    fontSize: '16px',
  },
  caption: {
    fontSize: '10px',
    color: '#342f38',
  },
  accordionTitle: {
    fontSize: '14px',
    color: '#342f38',
  },
  assessmentLabel: {
    color: APP_TEXT_HINT,
  },
}));
