import React from 'react';

import Box from 'components/atoms/Layout/Box';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import BaseInput from 'components/atoms/Inputs/TextInput/BaseInput';

export default function WeightQuestion(props) {
  return (
    <QuestionRefactored title={props.title} subtitle={props.subtitle}>
      <Box display="flex" justifyContent="center" alignContent="flex-start">
        <BaseInput
          id="weight-in-kg"
          inputProps={{
            style: { textAlign: 'center' },
            inputType: 'number',
          }}
          value={props.weight}
          color="primary"
          onChange={(value) => {
            props.onWeightChange && props.onWeightChange(value);
          }}
        />
        <RadioButtonGroup
          id="weight-radio-input"
          color="primary"
          items={props.data}
          rowNumber={1}
          onChange={(value) => {
            props.onMetricChange && props.onMetricChange(value);
          }}
          colorUnchecked={props.colorUnchecked}
        />
      </Box>
    </QuestionRefactored>
  );
}
