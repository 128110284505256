import React from 'react';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundExercise } from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'components/atoms/Layout/Box/Box';

export default function ExerciseIntro(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ContainerWithBackgroundImg
        image={backgroundExercise}
        maxWidth={'957px'}
        maxHeight={'700px'}
        height={'95vh'}
        width={'100vw'}
        marginTop={'0vh'}
      >
        <Box
          color="#ffffff"
          maxWidth="300px"
          position="relative"
          pr={{ xs: 5.5 }}
          pl={{ xs: 2.5 }}
          left={{ sm: '27%' }}
          top={{ sm: '20%', xs: '30%' }}
        >
          <Typography variant="h1" style={{ marginLeft: '5%' }}>
            {t('onboarding.exerciseSection.intro')}
          </Typography>
        </Box>
      </ContainerWithBackgroundImg>
    </React.Fragment>
  );
}
