import { makeStyles } from '@material-ui/core';

export const useCalendarItemStyles = makeStyles((theme) => ({
  circle: (props) => ({
    width: '44px',
    height: '44px',
    borderRadius: '50px',
    backgroundColor:
      props.selected && props.type === 'day'
        ? theme.palette.secondary.main + '40'
        : '',
    textAlign: 'center',
    lineHeight: '14px',
    cursor: 'pointer',
  }),
  text1: (props) => ({
    lineHeight: 'inherit',
    paddingTop: '8px',
    fontSize: '12px',
    color:
      props.type === 'day'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  }),
  text2: (props) => ({
    lineHeight: 'inherit',
    paddingBottom: '8px',
    fontSize: '12px',
    color:
      props.type === 'day'
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  }),
}));
