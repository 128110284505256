import React, { useState, useEffect } from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import WeightQuestionObsolete from 'components/organisms/Questions/General/WeightQuestionObsolete';
import Question from 'components/organisms/Inputs/Question/Question';

import { useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { lbsToKg } from 'components/helpers/converterHelper';

export default function MonitorWeightQuestion(props) {
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  const [weight, setWeight] = useState(null);
  const [weightIsInLbs, setWeightIsInLbs] = useState(
    overmindState.metaData.user_metadata.selectedWeightMetric === 1
      ? true
      : false
  );

  const radioButtonsWeight = [
    {
      label: t('onboarding.generalSection.step3.lbs'),
      value: 1,
      checked: weightIsInLbs,
    },
    {
      label: t('onboarding.generalSection.step3.kg'),
      value: 2,
      checked: !weightIsInLbs,
    },
  ];

  const onWeightChange = (data) => {
    setWeight(data.weight);
    setWeightIsInLbs(data.selectedWeightMetric === 1);
  };

  useEffect(() => {
    props.onChange && props.onChange(weightIsInLbs ? lbsToKg(weight) : weight);
  }, [weight]);

  useEffect(() => {
    setWeight(props.weight);
  }, [props.weight]);

  return (
    <Question title={props.title}>
      <Box px={2.5} mt={2}>
        <WeightQuestionObsolete
          title={t('home.monitorQuestions.weight.title')}
          data={radioButtonsWeight}
          weight={weight}
          selectedWeightMetric={
            overmindState.metaData.user_metadata.selectedWeightMetric
          }
          onChange={onWeightChange}
          colorUnchecked={'primary'}
        />
      </Box>
    </Question>
  );
}
