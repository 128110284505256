import {
  axiosGET,
  axiosPOST,
  axiosDELETE,
  axiosPUT,
  axiosPATCH,
  axiosAnonymousPOST,
  axiosAdHocGET,
} from 'api/axiosClient';

const URL_USER_PROFILE = `profile/`;
const URL_USER_PROFILE_METADATA = 'user/';
const URL_USER_GENOTYPE = 'profile/genotype/';
const URL_USER_NUTRITION = 'profile/nutrition/';
const URL_USER_PREGNANCY = 'profile/pregnancy/';
const URL_USER_ASSESSMENT = 'profile/assessments/rms/9moons/';
const URL_REPORT_INITIAL = 'profile/assessments/rms/9moons-initial/';
const URL_REPORT_NUTRIENTS = 'profile/assessments/rms/9moons-nutrients/';
const URL_WEEKLY_CHECKIN_WEIGHT = 'profile/logs/weight/';
const URL_WEEKLY_CHECKIN_STRESS_LEVEL = 'profile/logs/stress-level/';
const URL_WEEKLY_CHECKIN_SLEEP_QUALITY = 'profile/logs/sleep-quality-level/';
const URL_WEEKLY_CHECKIN_EXERCISE_LEVEL = 'profile/logs/exercise-level/';
const URL_WEEKLY_CHECKIN_NAUSEA = 'profile/logs/nausea-level/';
const URL_WEEKLY_CHECKIN_FATIGUE = 'profile/logs/fatigue-level/';
const URL_WEEKLY_CHECKIN_ACHING_BACK = 'profile/logs/aching-back-level/';
const URL_WEEKLY_CHECKIN_MOOD_SWINGS = 'profile/logs/mood-swings-level/';
const URL_WEEKLY_CHECKIN_PREGNANCY_COMPLICATIONS =
  'profile/logs/pregnancy-complications/';
const URL_WEIGHT_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/weight/';
const URL_STRESS_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/stress-level/';
const URL_SLEEP_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/sleep-quality-level/';
const URL_EXERCISE_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/exercise-level/';
const URL_NAUSEA_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/nausea-level/';
const URL_FATIGUE_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/fatigue-level/';
const URL_ACHING_BACK_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/aching-back-level/';
const URL_MOOD_SWING_CHECKIN_BY_PREGNANCY_WEEK =
  'profile/pregnancy/weekly-checkin/mood-swings-level/';
const URL_USER_MEAL_PLAN = 'profile/recommender/mealplan/';
const URL_USER_RECIPE_ACTIONS = 'profile/recommender/mealplan/recipe-actions/';
const URL_USER_RECIPE_FAVORITES =
  'profile/recommender/mealplan/recipes/favorites/';
const URL_USER_RECIPE_SAVED = 'profile/recommender/mealplan/recipes/saved/';
const URL_USER_RECIPE_LOGGED = 'profile/recommender/mealplan/recipes/logged/';
const URL_USER_REPORT = 'profile/reports/';
const URL_USER_SIMILAR_RECIPES =
  '/profile/recommender/mealplan/recipes/similar/';
const URL_USER_FAVORITE_RECIPES =
  '/profile/recommender/mealplan/recipes/favorites/';
const URL_USER_RECIPE_DETAILS =
  '/profile/recommender/mealplan/recipes/details/';
const URL_USER_SAVED_FOR_LATER_RECIPES =
  '/profile/recommender/mealplan/recipes/saved/';
const URL_USER_SHOPPING_LIST = '/profile/recommender/mealplan/shopping-list/';
const URL_USER_SHOPPING_LIST_ITEMS =
  '/profile/recommender/mealplan/shopping-list/items/ ';
const URL_SWAP_MEAL = '/profile/recommender/mealplan/swap-meals/ ';
const URL_SAVE_EMAIL = '/apps/9moons/mailchimp-lists/b9da8e87d4/subscribe/';
const URL_SAVE_ONBOARDED_EMAIL =
  '/apps/9moons/mailchimp-lists/1c8254acae/subscribe/';
const URL_ACTIVATE_SUBSCRIPTION = '/apps/9moons/subscriptions/activate/';
const URL_FETCH_ACTIVE_SUBSCRIPTION_STATUS = '/apps/9moons/subscriptions/';
const URL_PAYMENT_INTENT = '/apps/9moons/stripe/create-subscription/';

const { REACT_APP_AUTH0_DOMAIN } = process.env;

export const api = {
  getProfile: async () => {
    const response = await axiosGET(URL_USER_PROFILE);
    return response.data;
  },
  putProfile: async (data) => {
    const response = await axiosPUT(URL_USER_PROFILE, data);
    return response.data;
  },
  deleteProfile: async () => {
    const response = await axiosDELETE(URL_USER_PROFILE);
    return response.data;
  },
  getCurrentUserMetadata: async () => {
    const response = await axiosGET(URL_USER_PROFILE_METADATA);
    return response.data;
  },
  putCurrentUserMetadata: async (data) => {
    const response = await axiosPUT(URL_USER_PROFILE_METADATA, data);
    return response.data;
  },
  deleteCurrentUserMetadata: async () => {
    const response = await axiosDELETE(URL_USER_PROFILE_METADATA);
    return response.data;
  },
};

export const apiNutrition = {
  getNutritionPreferences: async () => {
    const response = await axiosGET(URL_USER_NUTRITION);
    return response.data;
  },
  putNutritionPreferences: async (data) => {
    const response = await axiosPUT(URL_USER_NUTRITION, data);
    return response.data;
  },
  patchNutritionPreferences: async (data) => {
    const response = await axiosPATCH(URL_USER_NUTRITION, data);
    return response.data;
  },
};

export const apiProfilePregnancy = {
  getProfilePregnancy: async () => {
    const response = await axiosGET(URL_USER_PREGNANCY);
    return response.data;
  },
  putProfilePregnancy: async (data) => {
    const response = await axiosPUT(URL_USER_PREGNANCY, data);
    return response.data;
  },
  patchProfilePregnancy: async (data) => {
    const response = await axiosPATCH(URL_USER_PREGNANCY, data);
    return response.data;
  },
  deleteProfilePregnancy: async () => {
    const response = await axiosDELETE(URL_USER_PREGNANCY);
    return response.data;
  },
};

export const apiGenotype = {
  getGenotype: async () => {
    const response = await axiosGET(URL_USER_GENOTYPE);
    return response.data;
  },
  postGenotype: async (data) => {
    const response = await axiosPOST(URL_USER_GENOTYPE, data);
    return response.data;
  },
  deleteGenotype: async () => {
    const response = await axiosDELETE(URL_USER_GENOTYPE);
    if (response) {
      return response.data;
    } else {
      return null;
    }
  },
};

export const apiAssessment = {
  getAssessment: async () => {
    const response = await axiosGET(URL_USER_ASSESSMENT);
    return response.data;
  },
  getMealPlan: async () => {
    const response = await axiosGET(URL_USER_MEAL_PLAN);
    return response.data;
  },
  getInitialReport: async () => {
    const response = await axiosGET(URL_REPORT_INITIAL);
    return response.data;
  },
  getNutrientsReport: async () => {
    const response = await axiosGET(URL_REPORT_NUTRIENTS);
    return response.data;
  },
};

export const apiWeeklyCheckin = {
  getWeeklyCheckinWeight: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_WEIGHT);
    return response.data;
  },
  postWeeklyCheckinWeight: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_WEIGHT, data);
    return response.data;
  },
  getWeeklyCheckinStressLevel: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_STRESS_LEVEL);
    return response.data;
  },
  postWeeklyCheckinStressLevel: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_STRESS_LEVEL, data);
    return response.data;
  },
  getWeeklyCheckinSleepQuality: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_SLEEP_QUALITY);
    return response.data;
  },
  postWeeklyCheckinSleepQuality: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_SLEEP_QUALITY, data);
    return response.data;
  },
  getWeeklyCheckinExerciseLevel: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_EXERCISE_LEVEL);
    return response.data;
  },
  postWeeklyCheckinExerciseLevel: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_EXERCISE_LEVEL, data);
    return response.data;
  },
  getWeeklyCheckinNausea: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_NAUSEA);
    return response.data;
  },
  postWeeklyCheckinNausea: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_NAUSEA, data);
    return response.data;
  },
  getWeeklyCheckinFatigue: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_FATIGUE);
    return response.data;
  },
  postWeeklyCheckinFatigue: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_FATIGUE, data);
    return response.data;
  },
  getWeeklyCheckinAchingBack: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_ACHING_BACK);
    return response.data;
  },
  postWeeklyCheckinAchingBack: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_ACHING_BACK, data);
    return response.data;
  },
  getWeeklyCheckinMoodSwings: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_MOOD_SWINGS);
    return response.data;
  },
  postWeeklyCheckinMoodSwings: async (data) => {
    const response = await axiosPOST(URL_WEEKLY_CHECKIN_MOOD_SWINGS, data);
    return response.data;
  },
  getWeeklyCheckinPregnancyComplications: async () => {
    const response = await axiosGET(URL_WEEKLY_CHECKIN_PREGNANCY_COMPLICATIONS);
    return response.data;
  },
  postWeeklyCheckinPregnancyComplications: async (data) => {
    const response = await axiosPOST(
      URL_WEEKLY_CHECKIN_PREGNANCY_COMPLICATIONS,
      data
    );
    return response.data;
  },
};

export const apiRecipeActions = {
  getRecipeActions: async () => {
    const response = await axiosGET(URL_USER_RECIPE_ACTIONS);
    return response.data;
  },
  postRecipeAction: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_ACTIONS, data);
    return response.data;
  },
  removeRecipeAction: async (data) => {
    const urlExtension = data.recipe_id + '/' + data.action + '/';
    const response = await axiosDELETE(URL_USER_RECIPE_ACTIONS + urlExtension);
    return response.data;
  },
  getSimilarRecipes: async (recipeId, servingSize) => {
    const response = await axiosGET(
      `${URL_USER_SIMILAR_RECIPES}${recipeId}/${servingSize}/`
    );
    return response.data;
  },
  getFavoriteRecipes: async () => {
    const response = await axiosGET(URL_USER_FAVORITE_RECIPES);
    return response.data;
  },
  getSavedForLaterRecipes: async () => {
    const response = await axiosGET(URL_USER_SAVED_FOR_LATER_RECIPES);
    return response.data;
  },
  getRecipeDetails: async (recipeId) => {
    const response = await axiosGET(`${URL_USER_RECIPE_DETAILS}${recipeId}/`);
    return response.data;
  },
  postShoppingList: async (data) => {
    const response = await axiosPOST(URL_USER_SHOPPING_LIST, data);
    return response.data;
  },
  getShoppingList: async (type) => {
    let viewType = type ? `?view_type=${type}` : '';

    const response = await axiosGET(`${URL_USER_SHOPPING_LIST}${viewType}`);
    return response.data;
  },
  postShoppingListItem: async (data) => {
    const response = await axiosPOST(URL_USER_SHOPPING_LIST_ITEMS, data);
    return response.data;
  },
  deleteShoppingListItems: async () => {
    const response = await axiosDELETE(URL_USER_SHOPPING_LIST_ITEMS);
    return response.data;
  },
};

export const apiRecipeFavorites = {
  getRecipeFavorites: async () => {
    const response = await axiosGET(URL_USER_RECIPE_FAVORITES);
    return response.data;
  },
  postRecipeFavorites: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_FAVORITES, data);
    return response.data;
  },
};

export const apiRecipeSaved = {
  getRecipeSaved: async () => {
    const response = await axiosGET(URL_USER_RECIPE_SAVED);
    return response.data;
  },
  postRecipeSaved: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_SAVED, data);
    return response.data;
  },
};

export const apiSwap = {
  postSwap: async (data) => {
    const response = await axiosPOST(URL_SWAP_MEAL, data);
    return response.data;
  },
};

export const apiSaveEmail = {
  postEmail: async (data) => {
    const response = await axiosAnonymousPOST(URL_SAVE_EMAIL, data);
    return response.data;
  },
  postOnboardedEmail: async (data) => {
    const response = await axiosAnonymousPOST(URL_SAVE_ONBOARDED_EMAIL, data);
    return response.data;
  },
};
export const apiRecipeLogged = {
  getRecipeLogged: async () => {
    const response = await axiosGET(URL_USER_RECIPE_LOGGED);
    return response.data;
  },
  postRecipeLogged: async (data) => {
    const response = await axiosPOST(URL_USER_RECIPE_LOGGED, data);
    return response.data;
  },
};

export const apiReport = {
  getReport: async () => {
    const response = await axiosGET(URL_USER_REPORT);
    return response.data;
  },
  generateReport: async (data) => {
    const response = await axiosPOST(URL_USER_REPORT, data);
    return response.data;
  },
};

export const apiWeeklyCheckinsByPregnancyWeek = {
  getWeightCheckins: async () => {
    const response = await axiosGET(URL_WEIGHT_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getActivityCheckins: async () => {
    const response = await axiosGET(URL_EXERCISE_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getSleepCheckins: async () => {
    const response = await axiosGET(URL_SLEEP_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getStressCheckins: async () => {
    const response = await axiosGET(URL_STRESS_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getNauseaCheckins: async () => {
    const response = await axiosGET(URL_NAUSEA_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getFatigueCheckins: async () => {
    const response = await axiosGET(URL_FATIGUE_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getAchingBackCheckins: async () => {
    const response = await axiosGET(URL_ACHING_BACK_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
  getMoodSwingsCheckins: async () => {
    const response = await axiosGET(URL_MOOD_SWING_CHECKIN_BY_PREGNANCY_WEEK);
    return response.data;
  },
};

export const apiActivateSubscription = {
  updateSubscription: async (data) => {
    const response = await axiosPUT(URL_ACTIVATE_SUBSCRIPTION, data);
    return response.data;
  },
};

export const apiFetchActiveSubscriptionStatus = {
  fetchActiveSubscriptionStatus: async (data) => {
    const response = await axiosGET(URL_FETCH_ACTIVE_SUBSCRIPTION_STATUS);
    return response.data;
  },
};

export const apiPayment = {
  createPaymentIntent: async (data) => {
    const response = await axiosPOST(URL_PAYMENT_INTENT, data);
    return response.data;
  },
};

export const apiAuthentication = {
  checkAuthorizationCode: async (data) => {
    const response = await axiosAnonymousPOST(
      `https://${REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      data
    );
    return response.data;
  },
  getUserInfo: async () => {
    const response = await axiosGET(
      `https://${REACT_APP_AUTH0_DOMAIN}/userinfo`
    );
    return response.data;
  },
};
