import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useOvermindState } from 'overmind/index';

import Box from 'components/atoms/Layout/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Switch from 'components/atoms/Inputs/Switch/Switch';
import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';

import CalendarWeek from 'components/organisms/Calendar/CalendarWeek';
import DailyPlan from './DailyPlan/DailyPlan';
import WeeklyPlan from './WeeklyPlan/WeeklyPlan';
import RecipeDetails from './RecipeDetails/RecipeDetails';

import { ToggleDayCircle, ToggleWeekCircle } from 'assets/iconsList';

import { useActions } from 'overmind/index';
import {
  getIndexOfCurrentDayInWeek,
  getDate,
} from 'components/helpers/dateHelper';

import { useHistory, useLocation } from 'react-router-dom';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_MEAL_PLAN, ROUTE_PROFILE } from 'routes';
import { getRecipe, getMeal } from 'components/helpers/planHelper';
import MealPlanProblemsDialog from 'components/organisms/ModalWindow/MealPlanProblemsDialog';
import { useTranslation } from 'react-i18next';
import PaymentDialogHome from 'components/organisms/PaymentDialog/PaymentDialogHome';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

/**
 * It is not clear how will assessment screens look like and how can we structure the "pages" so that there is one
 * component with header and footer and content inside. Content will be daily plan or weekly plan or recipe details
 * or needs assessment.
 * Because this isn't clear, this component only handles "views": daily plan, recipe details, weekly plan.
 */
export default function MealPlan() {
  const DAILY_MODE = 'daily';
  const WEEKLY_MODE = 'weekly';
  const DETAILS_MODE = 'recipeDetails';

  const overmindState = useOvermindState();
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [allRecipesInSelectedDate, setAllRecipesInSelectedDate] = useState([]);
  const [displayMode, setDisplayMode] = useState(DAILY_MODE);
  const [hideSelectAction, setHideSelectAction] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showMealPlanDialog, setShowMealPlanDialog] = useState(false);
  const actions = useActions();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (location.pathname === ROUTE_MEAL_PLAN) {
      setDisplayMode(DAILY_MODE);
    }
  }, [location]);

  useEffect(() => {
    if (overmindState.isMealPlanLoaded === false) {
      actions.fetchMealPlan();
    }
  }, [overmindState.isMealPlanLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.errors.errorGettingMealPlan &&
      overmindState.errors.errorGettingMealPlan !== ''
    ) {
      setShowMealPlanDialog(true);
    } else {
      setShowMealPlanDialog(false);
    }
  }, [overmindState.errors.errorGettingMealPlan, actions]);

  useEffect(() => {
    if (overmindState.isNutrientsReportLoaded === false) {
      actions.fetchNutrientsReport();
    }
  }, [overmindState.isNutrientsReportLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === false &&
      overmindState.isMealPlanLoaded === true
    ) {
      actions.fetchRecipeActions();
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  useEffect(() => {
    if (displayMode === DAILY_MODE && selectedDate != null) {
      fetchSimilarRecipesForAllMeals(selectedDate);
    }
  }, [displayMode, overmindState.mealPlan, selectedDate]);

  useEffect(() => {
    if (!overmindState.isProfilePregnancyFetched) {
      actions.fetchProfilePregnancy();
    } else {
      setSelectedDate(
        getIndexOfCurrentDayInWeek(
          getDate(overmindState.profilePregnancy.pregnancy_week_start)
        )
      );
    }
  }, [actions, overmindState.isProfilePregnancyFetched]);

  useEffect(() => {
    if (overmindState.profilePregnancy.pregnancy_week_start != null) {
      setSelectedDate(
        getIndexOfCurrentDayInWeek(
          getDate(overmindState.profilePregnancy.pregnancy_week_start)
        )
      );
    }
  }, [overmindState.profilePregnancy.pregnancy_week_start]);

  const fetchSimilarRecipesForAllMeals = (day) => {
    fetchSimilarRecipesForMeal('breakfast', day);
    fetchSimilarRecipesForMeal('lunch', day);
    fetchSimilarRecipesForMeal('dinner', day);
    fetchSimilarRecipesForMeal('snack', day);
  };

  const fetchSimilarRecipesForMeal = (mealType, day) => {
    const recipe = getRecipe(overmindState.mealPlan, mealType, day, true);
    const meal = getMeal(overmindState.mealPlan, mealType, day);
    if (recipe && meal && overmindState.similarRecipes[recipe.id] == null) {
      actions.fetchSimilarRecipe({
        recipeId: recipe.id,
        servingSize: meal.numOfServings,
      });
    }
  };

  const handleRecipeSelected = (recipeId, mealType, selectedDay) => {
    trackEvent('Recipe Details', 'Clicked', { location: 'Planner' });
    setHideSelectAction(true);
    if (selectedDay != null && selectedDay !== selectedDate) {
      setSelectedDate(selectedDay);
    }
    const date = Object.keys(overmindState.mealPlan)[
      selectedDay !== undefined ? selectedDay : selectedDate
    ];
    setAllRecipesInSelectedDate(overmindState.mealPlan[date].meals);

    setSelectedRecipe(
      overmindState.mealPlan[date].meals.filter(
        (m) => m.meal === mealType && m.recipe.id === recipeId
      )[0]
    );
    actions.fetchRecipeDetails(recipeId).then(() => {
      setDisplayMode(DETAILS_MODE);
    });
  };

  const handleSimilarRecipeSelected = (recipeId, mainRecipeId, mealType) => {
    setHideSelectAction(false);
    if (selectedDate == null) {
      return;
    }
    const date = Object.keys(overmindState.mealPlan)[selectedDate];
    const similarRecipe = overmindState.similarRecipes[mainRecipeId].find(
      (recipe) => recipe.id === recipeId
    );
    const selectedSimilarRecipe = {
      recipe: similarRecipe,
      calories: null,
      id: similarRecipe.id,
      meal: mealType,
      numOfServings: 1,
    };
    let meals = overmindState.mealPlan[date].meals;
    if (mealType === 'breakfast') {
      setAllRecipesInSelectedDate([
        selectedSimilarRecipe,
        meals[1],
        meals[2],
        meals[3],
      ]);
    } else if (mealType === 'lunch') {
      setAllRecipesInSelectedDate([
        meals[0],
        selectedSimilarRecipe,
        meals[2],
        meals[3],
      ]);
    } else if (mealType === 'snack') {
      setAllRecipesInSelectedDate([
        meals[0],
        meals[1],
        meals[2],
        selectedSimilarRecipe,
      ]);
    } else if (mealType === 'dinner') {
      setAllRecipesInSelectedDate([
        meals[0],
        meals[1],
        selectedSimilarRecipe,
        meals[3],
      ]);
    }
    setSelectedRecipe(selectedSimilarRecipe);
    setDisplayMode(DETAILS_MODE);
  };

  const handleDateClick = (day, dayIndex) => {
    if (displayMode === WEEKLY_MODE) {
      setDisplayMode(DAILY_MODE);
      actions.updateIsWeeklyPlan(false);
    }

    setSelectedDate(dayIndex);
    fetchSimilarRecipesForAllMeals(dayIndex);
  };

  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setDisplayMode(DAILY_MODE);
      actions.updateIsWeeklyPlan(false);
    } else {
      setDisplayMode(WEEKLY_MODE);
      actions.updateIsWeeklyPlan(true);
    }

    trackEvent('MealPlanner Type', 'Switched', {
      newType: e.target.checked ? 'Daily' : 'Weekly',
    });
  };

  const renderDailyOrWeeklyTitle = () => {
    let title =
      displayMode === DAILY_MODE
        ? 'Daily Meal Plan'
        : displayMode === WEEKLY_MODE
        ? 'Weekly Meal Plan'
        : '';

    return (
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{
          marginBottom: '40px',
          paddingLeft: window.innerWidth < 800 ? '23px' : 0,
          paddingRight: '23px',
          width: '100%',
          maxWidth: '1200px',
        }}
      >
        <GridItem>
          <Typography variant="h3" style={{ textTransform: 'uppercase' }}>
            {title}
          </Typography>
        </GridItem>
        {overmindState.isMealPlanLoaded && (
          <GridItem>
            <Switch
              onChange={handleSwitchChange}
              checked={displayMode === DAILY_MODE}
              icon={
                <ToggleDayCircle
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              }
              checkedIcon={
                <ToggleWeekCircle
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              }
            />
          </GridItem>
        )}
      </GridContainer>
    );
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title="My Food"
        goBack={() =>
          setDisplayMode(overmindState.isWeeklyPlan ? WEEKLY_MODE : DAILY_MODE)
        }
        showBack={displayMode === DETAILS_MODE}
        whiteText={displayMode === DETAILS_MODE}
      />
      <Box
        style={{
          marginTop:
            displayMode === DAILY_MODE || displayMode === WEEKLY_MODE ? 50 : 0,
        }}
      >
        {(displayMode === DAILY_MODE || displayMode === WEEKLY_MODE) &&
          selectedDate != null &&
          overmindState.isProfilePregnancyFetched && (
            <>
              <Box style={{ marginBottom: '20px', textAlign: 'center' }}>
                <CalendarWeek
                  dateIndex={selectedDate}
                  type={displayMode === WEEKLY_MODE ? 'week' : 'day'}
                  onClick={handleDateClick}
                  extraText={
                    displayMode === WEEKLY_MODE
                      ? overmindState.profilePregnancy.pregnancy_week
                      : overmindState.profilePregnancy.pregnancy_day
                  }
                />
              </Box>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem style={{ width: '100%', maxWidth: '1200px' }}>
                  {renderDailyOrWeeklyTitle()}
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
                style={{
                  marginBottom: '20px',
                  paddingLeft: '23px',
                  paddingRight: '23px',
                }}
              >
                <GridItem style={{ width: '100%', maxWidth: '1200px' }}>
                  {displayMode === DAILY_MODE && (
                    <DailyPlan
                      dateIndex={selectedDate}
                      onRecipeSelected={(recipeId, mealType) =>
                        handleRecipeSelected(recipeId, mealType)
                      }
                      onSimilarRecipeSelected={(
                        recipeId,
                        mainRecipeId,
                        mealType
                      ) =>
                        handleSimilarRecipeSelected(
                          recipeId,
                          mainRecipeId,
                          mealType
                        )
                      }
                    />
                  )}

                  {displayMode === WEEKLY_MODE && selectedDate != null && (
                    <WeeklyPlan
                      dateIndex={selectedDate}
                      onRecipeSelected={(recipeId, mealType, selectedDay) =>
                        handleRecipeSelected(recipeId, mealType, selectedDay)
                      }
                    />
                  )}
                </GridItem>
              </GridContainer>
            </>
          )}

        <GridContainer
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            marginBottom: '40px',
          }}
        >
          <GridItem style={{ width: '100%', maxWidth: '1200px' }}>
            {displayMode === DETAILS_MODE &&
              selectedRecipe &&
              overmindState.isNutrientsReportLoaded &&
              selectedDate != null && (
                <RecipeDetails
                  r={selectedRecipe}
                  recipe={selectedRecipe.recipe}
                  meal={selectedRecipe.meal}
                  numberOfServings={selectedRecipe.numOfServings}
                  changeRecipe={(recipeId, mealType, selectedDay) =>
                    handleRecipeSelected(recipeId, mealType, selectedDay)
                  }
                  dailyRecipes={allRecipesInSelectedDate}
                  dateIndex={selectedDate}
                  parentComponent="meal_plan"
                  hideSelect={hideSelectAction}
                  onSwap={() => {
                    setDisplayMode(DAILY_MODE);
                  }}
                />
              )}
          </GridItem>
        </GridContainer>
        <PaymentDialogHome
          openDialog={paymentDialogOpened}
          onCloseDialog={() => setPaymentDialogOpened(false)}
        />
        <FooterMenu
          selectedValue={1}
          onSelected={(val) => {
            history.push(handleNavigation(val));
          }}
          menuOptions={getMenuOptions(assessmentTheme, 1)}
        />
      </Box>
      <MealPlanProblemsDialog
        openDialog={showMealPlanDialog}
        title={t('mealPlan.cantGenerateMessage')}
        confirmText={t('common.answerOk')}
        onClose={() => setShowMealPlanDialog(false)}
        onConfirm={() => history.push(ROUTE_PROFILE)}
      />
    </ThemeProvider>
  );
}
MealPlan.whyDidYouRender = true;
