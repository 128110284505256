import React from 'react';
import PropTypes from 'prop-types';

export default function FilledCircleIcon(props) {
  const { color } = props;
  return (
    <div
      style={{
        background: props.color,
        width: props.width ? props.width : '60px',
        height: props.height ? props.height : '60px',
        borderRadius: '50%',
      }}
    ></div>
  );
}

FilledCircleIcon.propTypes = {
  color: PropTypes.string,
};
