import { makeStyles } from '@material-ui/core';

export const useSignInStyles = makeStyles((theme) => ({
  title: (prop) => ({
    fontFamily: 'Parisine-Plus-Std-Clair-Bold',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '31px',
    color: '#000000',
    paddingTop: '30px',
    marginBottom: '30px',
    marginTop: -95,
  }),
  subtitle: (prop) => ({
    fontFamily: 'MuseoSans',
    fontSize: '16zpx',
    fontWeight: '500',
    lineHeight: '1.13',
    color: '#fff',
    // width: '220px',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
  titleForSignIn: () => ({
    fontFamily: 'Parisine-Plus-Std-Clair-Bold',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '31px',
    color: '#000000',
    paddingTop: '14px',
    marginBottom: '44px',
  }),
  titleForSignUp: () => ({
    fontFamily: 'Parisine Plus Std Clair',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '31px',
    color: '#000000',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
  subtitleForSignUp: () => ({
    fontFamily: 'MuseoSans',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '1.13',
    color: '#fff',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
  }),
  otherText: () => ({
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '25px',
    color: '#000000',
    textAlign: 'center',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    userSelect: 'none',
    paddingBottom: '10px',
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  }),
  inputText: () => ({
    display: 'flex',
    height: '46px',
    width: '271px',
    backgroundColor: '#FFFFFF',
    borderRadius: '17.5px',
    borderColor: '#FFFFFF',
  }),
  emailContainer: {
    display: 'flex',
    height: '46px',
    width: '271px',
    backgroundColor: '#FFFFFF',
    borderRadius: '17.5px',
    opacity: '0.93',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
      '&:hover fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
      '&:not(:hover) fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
    },
  },
  passwordContainer: {
    display: 'flex',
    height: '46px',
    width: '271px',
    backgroundColor: '#FFFFFF',
    borderRadius: '17.5px',
    opacity: '0.93',
    userSelect: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
      '&:hover fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
      '&:not(:hover) fieldset': {
        border: '1.5px solid #FFFFFF',
        borderColor: (err) => (err?.wrongEmailFormat ? 'red' : 'white'),
        borderRadius: '17.5px',
        opacity: '0.93',
      },
    },
  },
  confirmButton: {
    height: '57px',
    width: '189px',
    marginTop: '26.6px',
    marginBottom: ' 18px',
    backgroundColor: '#B24E38',
    '&:disabled': {
      color: 'white',
      backgroundColor: '#B24E38',
      boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.272536)',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#B24E38',
      boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.272536)',
    },
    '&:click': {
      color: 'white',
      backgroundColor: '#B24E38',
      boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.272536)',
    },
    '&:focus': {
      color: 'white',
      backgroundColor: '#B24E38',
      boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.272536)',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#B24E38',
      boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.272536)',
    },
    borderRadius: '29px',
  },
  buttonText: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '400',
    color: '#FFFFFF',
    fontFamily: 'Poppins-Regular',
  },
  statement: {
    marginTop: '17.5px',
    paddingBottom: '20px',
    fontFamily: 'MuseoSans',
    fontSize: '11px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: '-0.17px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  forgotPassText: {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '400',
    color: '#1D5EDB',
    fontFamily: 'Poppins-Regular',
  },
  or: {
    position: 'relative',
    marginBottom: '10px',
    '&:before, &:after': {
      content: "''",
      display: 'inline-block',
      height: '1px',
      width: '35%',
      background: '#000000',
      top: '50%',
    },
    '&:before': {
      transform: 'translate(-20%, -50%)',
    },
    '&:after': {
      transform: 'translate(20%, -50%)',
    },
  },
  continueWithText: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
  },
  continueWithContainer: {
    zIndex: 999,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid gray',
    height: '42px',
    width: '236px',
    marginTop: '180px',
    marginBottom: '250px',
    borderRadius: '10px',
    boxShadow:
      '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
    padding: '0 20px 0 15px',
  },

  privacyPolicyText: {
    fontSize: '14px',
    lineHeight: '28px',
    fontWeight: '300',
    fontFamily: 'Poppins-Regular',
    textAlign: 'center',
  },
}));

export const inputProps = {
  fontFamily: 'Poppins-Light',
  fontWeight: '300',
  fontSize: '15px',
  lineHeight: '22px',
  color: '#C5B0A6',
  padding: '12px',
  userSelect: 'none',
};
