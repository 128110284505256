import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import DietaryPreferencesQuestion from 'components/organisms/Questions/Food/DietaryPreferencesQuestion';
import {
  getNutritionData,
  getNutritionObject,
} from 'components/helpers/nutritionHelper';
import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { usePageStyles } from 'components/pages/commonStyles';

export default function DietaryPreferencesPage(props) {
  const classes = usePageStyles(props);
  const actions = useActions();
  const { t } = useTranslation();
  const overmindState = useOvermindState();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <DietaryPreferencesQuestion
        title={t('onboarding.foodSection.step1.title')}
        caption={t('onboarding.foodSection.step1.subtitle')}
        items={getNutritionData(overmindState.profileNutrition)}
        onAnswered={(values) => {
          const obj = getNutritionObject(values);
          actions.nutritionPreferencesAnswered(obj);
        }}
      />
    </GridContainer>
  );
}
