import React from 'react';

import Box from 'components/atoms/Layout/Box';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Question from 'components/organisms/Inputs/Question/Question';

import { formatDate } from 'components/helpers/dateHelper';

export default function LastPeriodQuestion(props) {
  const handleDatePickerChange = (date) => {
    props.onDateChange && props.onDateChange(date);
  };

  const handleButtonClick = () => {
    props.onButtonClick && props.onButtonClick();
  };

  return (
    <Question title={props.title}>
      <Box display="flex" justifyContent="center" pt={5}>
        <DatePicker
          disableFuture={true}
          format="YYYY/MM/DD"
          id="last-period-date-input"
          value={props.selectedDate}
          onChange={(e) => {
            handleDatePickerChange(e);
          }}
          inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
        />
      </Box>

      <Box display="flex" justifyContent="center" pt={5}>
        <Button
          id="last-period-button-input"
          variant="outlined"
          onClick={handleButtonClick}
          disabled={!props.selectedDate}
        >
          {props.buttonText}
        </Button>
      </Box>
    </Question>
  );
}
