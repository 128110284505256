import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import ModalWindow from 'components/organisms/ModalWindow/ModalWindow';
import GeneralSection from './ProfileDetailsSections/GeneralSection';
import PregnancySection from './ProfileDetailsSections/PregnancySection';
import LifestylelSection from './ProfileDetailsSections/LifestylelSection';
import NutritionSection from './ProfileDetailsSections/NutritionSection';
import DeleteSection from './ProfileDetailsSections/DeleteSection';

import { useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { useActions } from 'overmind/index';
import { formatDate } from 'components/helpers/dateHelper';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getEthnicity,
  getSleep,
  getSleepIcon,
  getStress,
  getStressIcon,
  getExercise,
  getExerciseIcon,
  getDietaryPreferences,
  getDietaryPreferencesIcon,
  getAlergiesRestrictions,
  getAlergiesRestrictionsIcon,
} from 'components/helpers/profileInfoHelper';
import DefaultUserIcon from 'assets/icons/default_user_icon.svg';
import SignoutSection from './ProfileDetailsSections/SignoutSection';
import { useLocation } from 'react-router-dom';

let today = new Date();

export default function ProfileView(props) {
  const nutritionRef = React.createRef();
  const { PUBLIC_URL } = process.env;
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const actions = useActions();
  const { logout } = useAuth0();
  const location = useLocation();

  const [name, setName] = useState(
    overmindState.metaData.user_metadata.firstName &&
      overmindState.metaData.user_metadata.firstName != null
      ? overmindState.metaData.user_metadata.firstName
      : props.name
      ? props.name
      : ''
  );
  const [ethnicity, setEthnicity] = useState(
    overmindState.metaData.user_metadata.selectedEthnicity
      ? overmindState.metaData.user_metadata.selectedEthnicity
      : null
  );
  const [age, setAge] = useState(
    overmindState.profile.year_of_birth != null
      ? today.getFullYear() - overmindState.profile.year_of_birth
      : props.year_of_birth
      ? today.getFullYear() - props.year_of_birth
      : null
  );
  const [height, setHeight] = useState(
    overmindState.profile.height ? overmindState.profile.height : null
  );

  const [heightIn, setHeightIn] = useState(
    overmindState.metaData.user_metadata.heightIn
      ? overmindState.metaData.user_metadata.heightIn
      : null
  );

  const [heightFt, setHeightFt] = useState(
    overmindState.metaData.user_metadata.heightFt
      ? overmindState.metaData.user_metadata.heightFt
      : null
  );

  const getSavedWeight = () => {
    return overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? overmindState.profile.weight
      : overmindState.metaData.user_metadata.weightLbs;
  };

  const [weight, setWeight] = useState(getSavedWeight());
  const [dueDate, setDueDate] = useState(
    overmindState.profilePregnancy.due_date &&
      overmindState.profilePregnancy.due_date != null
      ? formatDate(overmindState.profilePregnancy.due_date, 'MMMM Do YYYY')
      : props.dueDate
      ? props.dueDate
      : null
  );
  const [sleep, setSleep] = useState();
  const [sleepIcon, setSleepIcon] = useState();
  const [stress, setStress] = useState();
  const [stressIcon, setStressIcon] = useState();
  const [exercise, setExercise] = useState();
  const [exerciseIcon, setExerciseIcon] = useState();
  const [dietaryPreferences, setDietaryPreferences] = useState();
  const [dietaryPreferencesIcons, setDietaryPreferencesIcons] = useState();
  const [alergiesRestrictions, setAlergiesRestrictions] = useState();
  const [alergiesRestrictionsIcons, setAlergiesRestrictionsIcons] = useState();
  const [modalWindowOpened, setModalWindowOpened] = useState(false);

  useEffect(() => {
    if (location.search.endsWith('?nutrition')) {
      nutritionRef.current.scrollIntoView();
    }
  }, [location, nutritionRef]);

  useEffect(() => {
    setName(overmindState.metaData.user_metadata.firstName);
  }, [overmindState.metaData.user_metadata.firstName]);

  useEffect(() => {
    if (overmindState.profile.year_of_birth != null) {
      setAge(today.getFullYear() - overmindState.profile.year_of_birth);
    }
  }, [overmindState.profile.year_of_birth]);

  useEffect(() => {
    setHeight(overmindState.profile.height);
  }, [overmindState.profile.height]);

  useEffect(() => {
    setHeightIn(overmindState.metaData.user_metadata.heightIn);
  }, [overmindState.metaData.user_metadata.heightIn]);

  useEffect(() => {
    setHeightFt(overmindState.metaData.user_metadata.heightFt);
  }, [overmindState.metaData.user_metadata.heightFt]);

  useEffect(() => {
    setWeight(getSavedWeight());
  }, [overmindState.profile.weight]);

  useEffect(() => {
    if (overmindState.profilePregnancy.due_date) {
      setDueDate(
        formatDate(overmindState.profilePregnancy.due_date, 'MMMM Do YYYY')
      );
    }
  }, [overmindState.profilePregnancy.due_date]);

  useEffect(() => {
    setEthnicity(
      t(getEthnicity(overmindState.metaData.user_metadata.selectedEthnicity))
    );
  }, [overmindState.metaData.user_metadata.selectedEthnicity]);

  useEffect(() => {
    setSleep(t(getSleep(overmindState.profilePregnancy.sleep_quality)));
    setSleepIcon(getSleepIcon(overmindState.profilePregnancy.sleep_quality));
  }, [overmindState.profilePregnancy.sleep_quality]);

  useEffect(() => {
    setStress(t(getStress(overmindState.profilePregnancy.stress_level)));
    setStressIcon(getStressIcon(overmindState.profilePregnancy.stress_level));
  }, [overmindState.profilePregnancy.stress_level]);

  useEffect(() => {
    setExercise(t(getExercise(overmindState.profilePregnancy.exercise_level)));
    setExerciseIcon(
      getExerciseIcon(overmindState.profilePregnancy.exercise_level)
    );
  }, [overmindState.profilePregnancy.exercise_level]);

  useEffect(() => {
    const profileNutrition = Object.keys(overmindState.profileNutrition);

    setDietaryPreferences(
      getDietaryPreferences(overmindState.profileNutrition, profileNutrition)
    );
    setDietaryPreferencesIcons(
      getDietaryPreferencesIcon(
        overmindState.profileNutrition,
        profileNutrition
      )
    );
    setAlergiesRestrictions(
      getAlergiesRestrictions(overmindState.profileNutrition, profileNutrition)
    );
    setAlergiesRestrictionsIcons(
      getAlergiesRestrictionsIcon(
        overmindState.profileNutrition,
        profileNutrition
      )
    );
  }, [overmindState.profileNutrition]);

  const deleteProfile = () => {
    actions.deleteUserData().then(() => {
      window.localStorage.removeItem('seenLandingPage');
      logOutAndRemoveToken(true);
    });
  };

  const logOutAndRemoveToken = (profileDeleted) => {
    let url = `${window.location.origin}${PUBLIC_URL}`;
    if (profileDeleted) {
      url = url + '/?profileDeleted=true';
    }
    logout({
      returnTo: url,
    });
    window.localStorage.removeItem('access_token');
  };

  const handleModalWindow = (opened) => {
    setModalWindowOpened(opened);
  };

  return (
    <GridContainer
      style={{
        marginBottom: '20px',
        paddingLeft: '23px',
        paddingRight: '23px',
        maxWidth: '1200px',
      }}
    >
      <GridContainer>
        <GridItem
          style={{
            marginTop: '40px',
            textAlign: 'left',
          }}
        >
          <Typography style={{}}>
            {t('myProfile.profileDetailsInfo')}
          </Typography>
        </GridItem>
      </GridContainer>
      {/*<GeneralSection
        name={name}
        ethnicity={ethnicity}
        age={age}
        height={height}
        heightFt={heightFt}
        heightIn={heightIn}
        weight={weight}
        userIcon={DefaultUserIcon}
        onTileClick={() => handleModalWindow(true)}
      />*/}
      <PregnancySection
        dueDate={dueDate}
        onTileClick={() => handleModalWindow(true)}
      />
      <LifestylelSection
        sleep={sleep}
        stress={stress}
        exercise={exercise}
        sleepIcon={sleepIcon}
        stressIcon={stressIcon}
        exerciseIcon={exerciseIcon}
        onTileClick={() => handleModalWindow(true)}
      />
      <div ref={nutritionRef}>
        <NutritionSection
          dietaryPreferences={dietaryPreferences}
          dietaryPreferencesIcons={dietaryPreferencesIcons}
          alergiesRestrictions={alergiesRestrictions}
          alergiesRestrictionsIcons={alergiesRestrictionsIcons}
          onTileClick={() => handleModalWindow(true)}
        />
      </div>
      <DeleteSection onDeleteClick={deleteProfile} />
      <SignoutSection onSignout={() => logOutAndRemoveToken(false)} />
      <ModalWindow
        opened={modalWindowOpened}
        onClose={() => handleModalWindow(false)}
      />
    </GridContainer>
  );
}
