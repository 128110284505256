import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box/Box';
import Question from 'components/organisms/Inputs/Question/Question';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import SelectableIconGroup from 'components/organisms/Inputs/SelectableIconGroup/SelectableIconGroup';

export default function FoodAllergiesQuestion(props) {
  return (
    <Question title={props.title} caption={props.caption}>
      <Box justifyContent="center" display="flex">
        <SelectableIconGroup
          columnNumber={window.innerWidth < 800 ? 2 : 3}
          width={window.innerWidth < 800 ? 240 : 500}
          isRadio={false}
          name={'foodAllergies'}
          rowSpacing={8}
          items={
            props.items &&
            props.items.map((i) => {
              return {
                uid: i.uid,
                label: i.label,
                value: i.id,
                icon: <SvgIcon height={35} icon={i.icon} />,
                checkedIcon: <SvgIcon height={35} icon={i.icon} />,
                labelFontSize: 12,
                checked: i.checked,
              };
            })
          }
          onChange={(selectedValues) => {
            props.onAnswered && props.onAnswered(selectedValues);
          }}
          noneValue={'0'}
        />
      </Box>
    </Question>
  );
}

FoodAllergiesQuestion.propTypes = {
  items: PropTypes.arrayOf({}),
};
