import { makeStyles } from '@material-ui/core';

export const useMealStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '11px',
  },
  summaries: {
    /*marginRight: '14px',*/
    marginBottom: '2px',
  },
}));
