import React from 'react';
import MuiModal from '@material-ui/core/Modal/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Modal(props) {
  const { opened, close, children } = props;

  return (
    <MuiModal open={opened} onClose={() => close && close()}>
      {children}
    </MuiModal>
  );
}
