import React, { useState, useEffect } from 'react';
import Typography from 'components/atoms/Typography/Typography';
import { useOvermindState } from 'overmind/index';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { APP_TEXT_HINT } from 'themes';

import {
  AddGroceryList,
  BookmarkAdded,
  BookmarkFavorite,
  BookmarkSaveForLater,
  BookmarkAddedEmpty,
  BookmarkFavoriteEmpty,
  BookmarkSaveForLaterEmpty,
} from 'assets/iconsList';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';
import ChipList from 'components/molecules/ChipList/ChipList';
import Ingredients from 'components/molecules/Content/Accordion/Ingredients';
import Instructions from 'components/molecules/Content/Accordion/Instructions';
import CollapsedSummary from 'components/molecules/Content/Accordion/CollapsedSummary';
import ExpandedSummary from 'components/molecules/Content/Accordion/ExpandedSummary';
import RecipeNutrientsNeedsCovered from 'components/organisms/RecipeNutrientsNeedsCovered/RecipeNutrientsNeedsCovered';
import DetailsHeader from 'components/molecules/Header/DetailsHeader';

import { useRecipeDetailsStyles } from './recipeDetailsStyles.js';
import {
  getFoodAllergiesData,
  getNutritionData,
} from 'components/helpers/nutritionHelper';
import { useActions } from 'overmind/index';
import ConfirmActionDialog from 'components/organisms/ModalWindow/ConfirmActionDialog';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function RecipeDetails(props) {
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const classes = useRecipeDetailsStyles(props);
  const [expandedIngredients, setExpandedIngredients] = useState(false);
  const [expandedInstructions, setExpandedInstructions] = useState(false);
  const [recipe, setRecipe] = useState(props.recipe);
  const [meal, setMeal] = useState(props.meal);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [isUserFavorite, setIsUserFavorite] = useState(
    props.recipe.isUserFavorite
  );
  const [isSaved, setIsSaved] = useState(props.recipe.isSaved);
  const [inUserShoppingList, setInUserShoppingList] = useState(
    overmindState.recipeDetails.inUserShoppingList &&
      overmindState.recipeDetails.inUserShoppingList
  );
  const [hideSelect, setHideSelect] = useState(props.hideSelect);
  const [unselectedMeal] = useState(!props.hideSelect ? props.meal : null);
  const actions = useActions();
  const recipeActionFavorite = 'favorite';
  const recipeActionSave = 'save';
  const recipeActionLog = 'log';

  useEffect(() => {
    setInUserShoppingList(overmindState.recipeDetails.inUserShoppingList);
    renderRecipeButtons();
  }, [overmindState.recipeDetails.inUserShoppingList]);

  useEffect(() => {
    actions.fetchRecipeDetails(recipe.id);
  }, [overmindState.recipeDetails.inUserShoppingList === undefined]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (overmindState.isNutrientsReportLoaded === false) {
      actions.fetchNutrientsReport();
    }
  }, [overmindState.isNutrientsReportLoaded, actions]);

  const handleChange = (panel) => {
    panel === 'instructions'
      ? setExpandedInstructions(!expandedInstructions)
      : setExpandedIngredients(!expandedIngredients);
  };

  const macronutrients =
    recipe.daily_need && overmindState.nutrientsReport.macronutrients
      ? [
          {
            name: 'Carbs',
            covered: recipe.daily_need.carbs,
            need:
              overmindState.nutrientsReport.macronutrients.components.carbs
                .calories,
            units: 'kcal',
          },
          {
            name: 'Proteins',
            covered: recipe.daily_need.protein,
            need:
              overmindState.nutrientsReport.macronutrients.components.proteins
                .calories,
            units: 'kcal',
          },
          {
            name: 'Fat',
            covered: recipe.daily_need.fat,
            need:
              overmindState.nutrientsReport.macronutrients.components.fat
                .calories,
            units: 'kcal',
          },
        ]
      : [];

  const getMicronutrients = () => {
    let lowerCase = null;
    let micronutrients = [];
    if (recipe.daily_need && overmindState.nutrientsReport.micronutrients) {
      let micronutrientsNames = Object.keys(recipe.daily_need);
      overmindState.nutrientsReport.micronutrients.items.forEach((e) => {
        let code1 = e.code.toLowerCase().replace('-', '');
        for (let i = 0; i < micronutrientsNames.length; i++) {
          lowerCase = micronutrientsNames[i].toLowerCase();
          const percentage = recipe.daily_need[micronutrientsNames[i]];
          if (lowerCase === code1 && percentage != null) {
            micronutrients.push({
              lc: recipe.daily_need,
              name: e.standard_name,
              covered: percentage,
              need: e.rda,
              units: e.rda_units,
            });
          }
        }
      });
    }

    return micronutrients;
  };

  const findNewRecipe = (meal) => {
    if (meal === unselectedMeal) {
      setHideSelect(false);
    } else {
      setHideSelect(true);
    }
    for (let i = 0; i < props.dailyRecipes.length; i++) {
      if (props.dailyRecipes[i].meal === meal) {
        actions
          .fetchRecipeDetails(props.dailyRecipes[i].recipe.id)
          .then(
            setInUserShoppingList(
              overmindState.recipeDetails.inUserShoppingList &&
                overmindState.recipeDetails.inUserShoppingList
            )
          );
        setRecipe(props.dailyRecipes[i].recipe);
        setMeal(props.dailyRecipes[i].meal);
        setIsUserFavorite(props.dailyRecipes[i].recipe.isUserFavorite);
        setIsSaved(props.dailyRecipes[i].recipe.isSaved);
        return;
      }
    }
  };

  const handleChangeRecipeClick = (navigate) => {
    switch (meal) {
      case 'breakfast':
        return findNewRecipe('lunch');
      case 'lunch':
        return findNewRecipe(navigate === 'previous' ? 'breakfast' : 'dinner');
      case 'dinner':
        return findNewRecipe(navigate === 'previous' ? 'lunch' : 'snack');
      case 'snack':
        return findNewRecipe('dinner');
      default:
        break;
    }
  };

  const micronutrients = getMicronutrients();

  const getReasons = () => {
    let reasons = [];
    recipe.reason.forEach((reason) => {
      let code = reason.code;
      if (code && code.length > 0) {
        if (reason.type === 'nutrient') {
          code = code + ' Need';
        } else if (reason.type === 'preference') {
          const allergies = getFoodAllergiesData().filter(
            (a) => a.uid === code
          );
          if (allergies && allergies.length === 1) {
            code = allergies[0].label;
          } else {
            const nutrition = getNutritionData().filter((a) => a.uid === code);
            if (nutrition && nutrition.length === 1) {
              code = nutrition[0].label;
            }
          }
        }
        code = code.charAt(0).toUpperCase() + code.substr(1).toLowerCase();
        reasons.push(code);
      }
    });
    return reasons;
  };

  const hasReasons = () => {
    return recipe && recipe && recipe.reason && recipe.reason.length > 0;
  };

  const getInstructions = () => {
    if (recipe && recipe.instructions && recipe.instructions.length > 0) {
      return recipe.instructions;
    } else if (
      recipe &&
      recipe.source &&
      recipe.source.recipeUrl &&
      recipe.source.displayName &&
      recipe.source.recipeUrl.length > 0 &&
      recipe.source.displayName.length > 0
    ) {
      return recipe.source;
    } else {
      return null;
    }
  };

  const handleBookmarkClick = (code) => {
    let data = null;
    if (code === recipeActionFavorite) {
      if (!isUserFavorite) {
        trackEvent('Recipe Liked', '');
      }
      setIsUserFavorite(!isUserFavorite);
      data = {
        recipe_id: props.recipe.id,
        recipe_db_id: props.recipe.databaseId,
        code: code,
      };
    } else if (code === recipeActionSave) {
      if (!isSaved) {
        trackEvent('Recipe Saved', '');
      }
      setIsSaved(!isSaved);
      data = { recipe_id: props.recipe.id, code: code };
    } else if (code === recipeActionLog) {
      setConfirmDialogOpened(true);
    }

    if (code !== recipeActionLog) {
      actions.editRecipeActions(data);
    }
  };

  const swapRecipe = async () => {
    if (props.dateIndex == null) {
      return;
    }
    const date = Object.keys(overmindState.mealPlan)[props.dateIndex];
    let meals = overmindState.mealPlan[date].meals;
    let selectedMeal = meals.filter((m) => m['meal'] === props.meal)[0];
    await actions.swapRecipe({ mealId: selectedMeal.id, recipeId: recipe.id });
    await actions.fetchMealPlan();
    props.onSwap && props.onSwap();
  };

  const renderRecipeButton = (icon, label, handleClick) => {
    return (
      <GridContainer
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <GridItem>{icon}</GridItem>
        <GridItem>
          <Typography
            variant="body1"
            textSize="14px"
            textAlign="center"
            lineHeight="14px"
            height="34px"
          >
            {label}
          </Typography>
        </GridItem>
      </GridContainer>
    );
  };

  const renderRecipeButtons = () => {
    let shoppingListLabel;
    let shoppingListAction;
    if (inUserShoppingList) {
      shoppingListLabel = t('recipeDetails.removeFromGroceryList');
      shoppingListAction = () => {
        setInUserShoppingList(false);
        actions
          .removeFromShoppingList(recipe.id)
          .then(actions.fetchRecipeDetails(recipe.id));
      };
    } else {
      shoppingListLabel = t('recipeDetails.addToGroceryList');
      shoppingListAction = () => {
        setInUserShoppingList(true);
        trackEvent('Recipe added to Grocery List', '');
        actions
          .addToShoppingList({
            databaseId: recipe.databaseId,
            id: recipe.id,
          })
          .then(actions.fetchRecipeDetails(recipe.id));
      };
    }

    return (
      <GridContainer
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{
          paddingTop: '23px',
          paddingBottom: '10px',
          paddingLeft: '20px',
          paddingRight: '40px',
          borderTop: '1px solid #e5e5e5',
          borderBottom: '1px solid #e5e5e5',
        }}
      >
        {props.parentComponent &&
          props.parentComponent !== 'profile' &&
          hideSelect !== true && (
            <GridItem xs={4} style={{ marginBottom: 10 }}>
              {renderRecipeButton(
                props.recipe.isLogged ? (
                  <BookmarkAdded />
                ) : (
                  <BookmarkAddedEmpty />
                ),
                props.recipe.isLogged
                  ? t('recipeDetails.removeSelectedMark')
                  : t('recipeDetails.markSelected'),
                () => {
                  handleBookmarkClick(recipeActionLog);
                }
              )}
            </GridItem>
          )}

        <GridItem xs={4} style={{ marginBottom: 10 }}>
          {renderRecipeButton(
            isUserFavorite ? <BookmarkFavorite /> : <BookmarkFavoriteEmpty />,
            isUserFavorite
              ? t('recipeDetails.removeLikedMark')
              : t('recipeDetails.markLiked'),
            () => {
              handleBookmarkClick(recipeActionFavorite);
            }
          )}
        </GridItem>
        <GridItem xs={4} style={{ marginBottom: 10 }}>
          {renderRecipeButton(
            isSaved ? <BookmarkSaveForLater /> : <BookmarkSaveForLaterEmpty />,
            isSaved
              ? t('recipeDetails.removeSavedMark')
              : t('recipeDetails.markSaved'),
            () => {
              handleBookmarkClick(recipeActionSave);
            }
          )}
        </GridItem>
        <GridItem
          xs={
            props.parentComponent && props.parentComponent !== 'profile' ? 6 : 4
          }
        >
          {renderRecipeButton(
            <AddGroceryList />,
            shoppingListLabel,
            shoppingListAction
          )}
        </GridItem>
      </GridContainer>
    );
  };

  const calculateCalories = () => {
    let calories = 0;
    try {
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.protein;
      }
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.carbs;
      }
      if (recipe.caloriesPerServing.fat) {
        calories += recipe.caloriesPerServing.fat;
      }
    } catch {}
    return calories;
  };

  const renderInfoText = (number, text) => {
    return (
      <>
        <Typography variant="body1" className={classes.hint}>
          {number}
        </Typography>
        <Typography variant="caption" textSize="10px">
          {text}
        </Typography>
      </>
    );
  };

  return (
    <>
      <GridContainer
        direction="column"
        style={{
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.47)) , url(${
            recipe.mainImage ? recipe.mainImage : ''
          })`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <GridItem style={{ width: '100%', marginTop: '50px' }}>
          <DetailsHeader
            title={recipe.name}
            onLeftClick={() =>
              meal !== 'breakfast' && handleChangeRecipeClick('previous')
            }
            disableLeftButton={
              props.disableNavigation
                ? props.disableNavigation
                : meal === 'breakfast'
            }
            onRightClick={() =>
              meal !== 'snack' && handleChangeRecipeClick('next')
            }
            disableRightButton={
              props.disableNavigation
                ? props.disableNavigation
                : meal === 'snack'
            }
          />
          <Typography
            variant="subtitle2"
            style={{ textTransform: 'uppercase', fontWeight: 900 }}
          >
            {meal}
          </Typography>
        </GridItem>

        <GridItem style={{ width: '100%' }}>
          <GridContainer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '40px',
              paddingRight: '40px',
              paddingBottom: '10px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}
          >
            {recipe.ingredients && (
              <GridItem>
                {renderInfoText(
                  recipe.ingredients.length,
                  t('recipeDetails.ingredients')
                )}
              </GridItem>
            )}
            {recipe.totalTime && (
              <GridItem>
                {renderInfoText(
                  recipe.totalTime.substring(
                    0,
                    recipe.totalTime.lastIndexOf(' ')
                  ),
                  t('recipeDetails.minutes')
                )}
              </GridItem>
            )}
            {calculateCalories() > 0 && (
              <GridItem>
                {renderInfoText(
                  Math.round(calculateCalories()),
                  t('recipeDetails.servingInfo')
                )}
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer direction="column">
        {props.numberOfServings && (
          <GridItem
            xs={12}
            style={{
              padding: '16px',
              height: '60px',
            }}
          >
            <GridContainer direction="column">
              <GridItem>
                <Typography variant="h5">
                  {t('recipeDetails.recipeYield')}
                  <span style={{ color: APP_TEXT_HINT }}>
                    {recipe.numberOfServings}{' '}
                    {recipe.numberOfServings > 1 ? 'servings' : 'serving'}
                  </span>
                </Typography>
              </GridItem>
              <GridItem style={{ marginTop: '4px' }}>
                <Typography variant="h5">
                  {t('recipeDetails.recipeYouShould')}
                  <span style={{ color: APP_TEXT_HINT }}>
                    {props.numberOfServings}{' '}
                    {props.numberOfServings > 1 ? 'servings' : 'serving'}
                  </span>
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}

        {recipe.instructions && (
          <GridItem>
            <Accordion
              square
              expanded={expandedIngredients}
              onChange={() => handleChange('ingredients')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                {expandedIngredients !== props.panelName ? (
                  <CollapsedSummary title={'Ingredients'} />
                ) : (
                  <ExpandedSummary title={'Ingredients'} />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Ingredients ingredients={recipe} />
              </AccordionDetails>
            </Accordion>
          </GridItem>
        )}

        {getInstructions() && (
          <GridItem>
            <Accordion
              expanded={expandedInstructions}
              onChange={() => handleChange('instructions')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />}
              >
                {expandedInstructions !== props.panelName ? (
                  <CollapsedSummary title={'Instructions'} />
                ) : (
                  <ExpandedSummary title={'Instructions'} />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Instructions instructions={getInstructions()} />
              </AccordionDetails>
            </Accordion>
          </GridItem>
        )}

        {renderRecipeButtons()}

        {hasReasons() && (
          <GridContainer
            direction="column"
            style={{
              paddingTop: '23px',
              paddingBottom: '33px',
              paddingLeft: '20px',
              paddingRight: '40px',
            }}
          >
            <GridItem>
              <Typography variant="h3" textAlign="left">
                {t('recipeDetails.recommendationReason')}
              </Typography>
            </GridItem>
            <GridItem style={{ marginTop: '10px' }}>
              <ChipList chipsTexts={getReasons()} />
            </GridItem>
          </GridContainer>
        )}

        {((macronutrients && macronutrients.length > 0) ||
          (micronutrients && micronutrients.length > 0)) && (
          <GridContainer
            direction="column"
            style={{
              paddingTop: '23px',
              paddingBottom: '33px',
              paddingLeft: '20px',
              paddingRight: '40px',
              backgroundColor: '#f7f7f5',
            }}
          >
            <GridItem>
              <Typography variant="h3" textAlign="left">
                {t('recipeDetails.nutritionalValues')}
              </Typography>
            </GridItem>
            <GridItem style={{ marginBottom: '30px' }}>
              {props.parentComponent === 'profile' ? (
                <Typography
                  variant="body2"
                  textColor="grey"
                  textSize="10px"
                  textAlign="left"
                  fontWeight="normal"
                >
                  {t('recipeDetails.goalPerOneServing')}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  textColor="grey"
                  textSize="10px"
                  fontWeight="normal"
                >
                  {t('recipeDetails.goalPerRecommendedServing')}
                </Typography>
              )}
            </GridItem>
            {macronutrients && macronutrients.length > 0 && (
              <GridItem style={{ marginBottom: '30px' }}>
                <RecipeNutrientsNeedsCovered
                  name={'Macronutrients'}
                  nutrients={macronutrients}
                  firstColumnTitle={
                    props.parentComponent && props.parentComponent === 'profile'
                      ? t('recipeDetails.nutritionalValuesFirstColumnNoMeal')
                      : t('recipeDetails.nutritionalValuesFirstColumn')
                  }
                  secondColumnTitle={t(
                    'recipeDetails.nutritionalValuesSecondColumn'
                  )}
                />
              </GridItem>
            )}
            {micronutrients && micronutrients.length > 0 && (
              <GridItem style={{ marginBottom: '10px' }}>
                <RecipeNutrientsNeedsCovered
                  name={'Micronutrients'}
                  nutrients={micronutrients}
                />
              </GridItem>
            )}
          </GridContainer>
        )}
      </GridContainer>

      <ConfirmActionDialog
        openDialog={confirmDialogOpened}
        title={t('recipeDetails.swapQuestion')}
        confirmText={t('common.answerYes')}
        cancelText={t('common.answerNo')}
        onClose={() => setConfirmDialogOpened(false)}
        onConfirm={() => swapRecipe()}
      />
    </>
  );
}
