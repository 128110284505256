import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import SingleSelectionQuestion from 'components/organisms/Questions/Pregnancy/SingleSelectionQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function HowOftenDoYouSmoke(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [question1Value, setQuestion1Value] = useState(
    overmindState.smokingHabits == null
      ? undefined
      : overmindState.smokingHabits
  );

  useEffect(() => {
    if (question1Value) {
      setQuestion1Value(question1Value);
      actions.updateStateProp({
        key: 'smokingHabits',
        value: question1Value,
        nextEnabled: true,
      });
    }
  }, []);

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <SingleSelectionQuestion
          title={t('calculator.howOftenDoYouSmoke.question')}
          data={[
            {
              label: t('calculator.howOftenDoYouSmoke.answer1'),
              value: '0',
              checked: question1Value === '0',
            },
            {
              label: t('calculator.howOftenDoYouSmoke.answer2'),
              value: '1',
              checked: question1Value === '1',
            },
            {
              label: t('calculator.howOftenDoYouSmoke.answer3'),
              value: '2',
              checked: question1Value === '2',
            },
            {
              label: t('calculator.howOftenDoYouSmoke.answer4'),
              value: '3',
              checked: question1Value === '3',
            },
            {
              label: t('calculator.howOftenDoYouSmoke.answer5'),
              value: '4',
              checked: question1Value === '4',
            },
          ]}
          onChange={(value) => {
            setQuestion1Value(value);
            actions.updateStateProp({
              key: 'smokingHabits',
              value: value,
              nextEnabled: true,
            });
          }}
          offset={100}
        />
      </GridItem>
    </GridContainer>
  );
}
