import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';

/**
 * Title of tab. Can contain icon and text, or just text.
 */
export default function TabTitle(props) {
  const { icon, label } = props;
  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <GridItem style={{ marginRight: 10 }}>{icon}</GridItem>
      <GridItem>
        <Typography
          variant="body1"
          style={{
            fontSize: '14px',
            lineHeight: '14px',
            textTransform: 'uppercase',
            color: props.color ? props.color : 'primary',
          }}
        >
          {label}
        </Typography>
      </GridItem>
    </GridContainer>
  );
}

TabTitle.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
};
