import React from 'react';
import PropTypes from 'prop-types';
import MuiPopover from '@material-ui/core/Popover';

/**
 * Popover component based on https://material-ui.com/components/popover/#popover
 * @param {*} props All possible props can be found on https://material-ui.com/api/popover/
 */
export default function Popover(props) {
  const { children, ...rest } = props;

  return (
    <MuiPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...rest}
    >
      {children}
    </MuiPopover>
  );
}

Popover.propTypes = {
  children: PropTypes.node,
};
