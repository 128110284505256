import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RecipeSummary from 'components/molecules/RecipeSummary/RecipeSummary';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';

import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import { useMealStyles } from './mealStyles';
import { useOvermindState, useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function Meal(props) {
  const overmindState = useOvermindState();
  const classes = useMealStyles();
  const [bookmarksActionsLoaded, setBookmarksActionsLoaded] = useState(false);
  const actions = useActions();

  const handleBookmarkChange = (data, code) => {
    let mealData = null;
    if (code === 'favorite') {
      mealData = { ...data, code: code };
      trackEvent('Recipe Liked', '');
    } else if (code === 'save') {
      mealData = { recipe_id: data, code: code };
      trackEvent('Recipe Saved', '');
    } else if (code === 'log') {
      mealData = { ...data, code: code };
    }
    actions.editRecipeActions(mealData);
  };

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === true &&
      overmindState.isMealPlanLoaded === true &&
      props.recipe
    ) {
      let favorites = [];
      overmindState.recipeFavorites.forEach((recipeAction) => {
        if (recipeAction.recipe_id === props.recipe.id) {
          favorites.push(recipeAction);
        }
      });
      setBookmarksActionsLoaded(true);
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  return (
    <GridContainer direction="column">
      {props.name && (
        <GridItem className={classes.title}>
          <Typography
            variant={'body1'}
            style={{ textTransform: 'uppercase', fontSize: '10px' }}
          >
            {props.name}
          </Typography>
        </GridItem>
      )}
      {props.recipe && (
        <GridItem className={classes.summaries}>
          <RecipeSummary
            onClick={() =>
              props.onClicked &&
              props.recipe &&
              props.onClicked(props.recipe.id)
            }
            onBookmarkChange={handleBookmarkChange}
            isHorizontal={
              props.isHorizontal != null
                ? props.isHorizontal
                : overmindState.showPlan === 'daily'
                ? true
                : false
            }
            uid={props.recipe.id}
            bookmarksActionsLoaded={bookmarksActionsLoaded}
            largeImage={props.largeImage ? props.largeImage : false}
            parentComponent={props.parentComponent}
            meal={props.name}
            {...props.recipe}
            hideBookmarks={props.hideBookmarks}
            isPremium={props.isPremium}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}

Meal.propTypes = {
  name: PropTypes.string,
  recipe: getRecipeProps(),
  onClicked: PropTypes.func,
};
