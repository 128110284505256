//add onboarding steps in order how it is defined in design
export const onboardingSteps = [
  'terms_of_service',
  'research_agreement_page',
  'general_intro',
  'name_and_age_page',
  'bmi_page',
  'pregnancy_intro_page',
  'due_data_page',
  'last_period_page',
  'congratulations_page',
  'children_question_page',
  'exercise_intro',
  'exercise_per_day',
  'lifestyle_intro',
  'lifestyle_sleep_and_stress',
  'food_intro',
  'dietary_preferences_page',
  'food_allergies_page',
  'ready_to_use_the_app_page',
  'our_genomics_intro',
  'has_dna_page',
  'kit_number_input_page',
  'kit_not_received_page',
  'onboarding_finished',
  'assessments',
];

export const calculatorSteps = [
  'general_intro',
  'name',
  'age',
  'height',
  'weight',
  'your_first_baby_page',
  'first_baby_week',
  'post_term_pregnancy_page',
  'have_you_been_diagnosed',
  'how_often_do_you_smoke',
  'how_stressful_is_your_file',
  /*'do_you_know_your_due_date',*/
  'cycle_length',
  'conception_experience',
  'date_of_transfer',
  'do_you_know_last_period',
  'do_you_know_when_you_have_conceived',
  'first_name',
  'capture_email',
  'calculate_due_date',
];

export const getStepIndex = (stepName) => {
  return onboardingSteps.indexOf(stepName);
};

export const getCalculatorStepIndex = (stepName) => {
  return calculatorSteps.indexOf(stepName);
};
