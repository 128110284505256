import React, { useEffect } from 'react';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundExercise } from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'components/atoms/Layout/Box/Box';
import { useActions, useOvermindState } from 'overmind/index';

export default function ReadyToUseTheAppPage() {
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const getDueDateString = () => {
    let ddParts = overmindState.dueDate.split('-');
    return `${ddParts[1]}/${ddParts[2]}/${ddParts[0]}`;
  };

  useEffect(() => {
    if (
      overmindState.dueDate != null &&
      !overmindState.savedEmailToMailchimp &&
      overmindState.email != null
    ) {
      actions.saveEmail({
        email: overmindState.email,
        first_name: overmindState.metaData.user_metadata.firstName,
        due_date: getDueDateString(),
      });
    }
  }, []);

  return (
    <ContainerWithBackgroundImg
      image={backgroundExercise}
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        maxWidth="306px"
        position="relative"
        pr={{ xs: 4 }}
        pl={{ xs: 4 }}
        left={{ sm: '27%' }}
        top={{ sm: '30%', xs: '40%' }}
      >
        <Typography variant="h1">{t('onboarding.readyToUseTheApp')}</Typography>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
