import NeedsCovered from 'components/molecules/NeedCovered/NeedCovered';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import PropTypes from 'prop-types';
import Typography from 'components/atoms/Typography/Typography';

export default function RecipeNutrientsNeedsCovered(props) {
  return (
    <GridContainer direction="column">
      <GridItem>
        <Typography
          variant="h5"
          style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'left' }}
        >
          {props.name}
        </Typography>
      </GridItem>
      <GridItem>
        <GridContainer direction="row" style={{ marginBottom: '10px' }}>
          <GridItem xs={4} style={{ height: '18px' }}>
            <Typography variant="body1" style={{ fontSize: '12px' }}>
              {' '}
            </Typography>
          </GridItem>
          <GridItem xs={8} style={{ height: '18px' }}>
            <GridContainer direction="row" alignItems="center">
              <GridItem xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '9px',
                    textAlign: 'center',
                    lineHeight: '1.1',
                  }}
                >
                  {props.firstColumnTitle}
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '9px',
                    textAlign: 'center',
                    lineHeight: '1.1',
                  }}
                >
                  {props.secondColumnTitle}
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
      {props.nutrients.map((nutrient, ix) => {
        return (
          <GridItem key={ix}>
            <NeedsCovered
              name={nutrient.name}
              covered={nutrient.covered}
              need={nutrient.need}
              unit={nutrient.units}
            />
          </GridItem>
        );
      })}
    </GridContainer>
  );
}

RecipeNutrientsNeedsCovered.propTypes = {
  nutrients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      covered: PropTypes.number,
      need: PropTypes.string,
      unit: PropTypes.string,
    })
  ),
  name: PropTypes.string.isRequired,
  firstColumnTitle: PropTypes.string.isRequired,
  secondColumnTitle: PropTypes.string.isRequired,
};
