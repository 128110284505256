import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import SingleSelectionQuestion from 'components/organisms/Questions/Pregnancy/SingleSelectionQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';
import { formatDate } from 'components/helpers/dateHelper';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';
import { Box } from '@material-ui/core';
import Typography from 'components/atoms/Typography/Typography';

export default function DoYouKnowLastPeriod(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [question1Value, setQuestion1Value] = useState(
    overmindState.lastPeriod == null ? undefined : overmindState.lastPeriod
  );
  const [lastPeriodDateStart, setLastPeriodDateStart] = useState(
    overmindState.lastPeriodDateStart == null
      ? undefined
      : overmindState.lastPeriodDateStart
  );
  const [lastPeriodDateEnd, setLastPeriodDateEnd] = useState(
    overmindState.lastPeriodDateEnd == null
      ? undefined
      : overmindState.lastPeriodDateEnd
  );

  useEffect(() => {
    if (question1Value) {
      setQuestion1Value(question1Value);
      actions.doYouKnowLastPeriodAnswered(question1Value);
    }
  }, []);

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <SingleSelectionQuestion
          title={t('calculator.doYouKnowLastPeriod.question')}
          data={[
            {
              label: t('calculator.doYouKnowLastPeriod.option1'),
              value: '1',
              checked: question1Value === '1',
            },
            {
              label: t('calculator.doYouKnowLastPeriod.option2'),
              value: '2',
              checked: question1Value === '2',
            },
            {
              label: t('calculator.doYouKnowLastPeriod.option3'),
              value: '3',
              checked: question1Value === '3',
            },
          ]}
          onChange={(value) => {
            setQuestion1Value(value);
            actions.doYouKnowLastPeriodAnswered(value);
          }}
          offset={80}
        />
      </GridItem>

      {question1Value === '1' && (
        <GridItem>
          <DatePicker
            disableFuture={true}
            format="YYYY/MM/DD"
            id="last-period-input"
            value={
              overmindState.lastPeriodDate ? overmindState.lastPeriodDate : null
            }
            onChange={(e) => {
              actions.updateStateProp({
                key: 'lastPeriodDate',
                value: e,
                nextEnabled: true,
              });
            }}
            inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
          />
        </GridItem>
      )}

      {question1Value === '2' && (
        <GridItem>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem xs={12} style={{ marginBottom: '20px' }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4" fontWeight="bold">
                  It was sometime between
                </Typography>
              </Box>
            </GridItem>
            <GridItem xs={6}>
              <DatePicker
                disableFuture={true}
                format="YYYY/MM/DD"
                id="last-period-input-start"
                value={lastPeriodDateStart ? lastPeriodDateStart : null}
                onChange={(e) => {
                  setLastPeriodDateStart(e);
                  actions.updateStateProp({
                    key: 'lastPeriodDateStart',
                    value: e,
                    nextEnabled:
                      lastPeriodDateEnd != null &&
                      e != null &&
                      overmindState.lastPeriodDateEnd.diff(e, 'days') > 0,
                  });
                }}
                inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
                style={{ paddingLeft: '20px', paddingRight: '5px' }}
              />
            </GridItem>
            <GridItem xs={6}>
              <DatePicker
                disableFuture={true}
                format="YYYY/MM/DD"
                id="last-period-input-end"
                value={lastPeriodDateEnd ? lastPeriodDateEnd : null}
                onChange={(e) => {
                  setLastPeriodDateEnd(e);
                  actions.updateStateProp({
                    key: 'lastPeriodDateEnd',
                    value: e,
                    nextEnabled:
                      lastPeriodDateStart != null &&
                      e != null &&
                      e.diff(overmindState.lastPeriodDateStart, 'days') > 0,
                  });
                }}
                inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
                style={{ paddingLeft: '5px', paddingRight: '10px' }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      )}
    </GridContainer>
  );
}
