import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import LastPeriodQuestion from 'components/organisms/Questions/Pregnancy/LastPeriodQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

import {
  addDays,
  subtractDays,
  formatDate,
} from 'components/helpers/dateHelper';

export default function LastPeriod(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [selectedDate, setSelectedDate] = useState(
    overmindState.profilePregnancy.last_period_date
      ? overmindState.profilePregnancy.last_period_date
      : subtractDays(new Date(), 28)
  );

  const handleDatePickerChange = (date) => {
    setSelectedDate(formatDate(date, 'YYYY-MM-DD'));
  };

  const handleButtonClick = () => {
    let dueDate = addDays(selectedDate, 280);

    actions.lastPeriodDateSet({
      lastPeriodDate: selectedDate,
      dueDate: dueDate,
    });
  };

  return (
    <GridContainer className={classes.container}>
      <LastPeriodQuestion
        title={t('onboarding.pregnancySection.step2.title')}
        buttonText={t('onboarding.pregnancySection.step2.buttonText')}
        selectedDate={selectedDate}
        onDateChange={handleDatePickerChange}
        onButtonClick={handleButtonClick}
      />
    </GridContainer>
  );
}
