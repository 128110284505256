import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Divider from '@material-ui/core/Divider';

export default function QuestionRefactored(props) {
  const { title, subtitle, children, divider } = props;

  return (
    <GridContainer justify="center" alignItems="center">
      <GridItem xs={12}>
        <GridContainer
          style={{ marginBottom: '24px' }}
          justify="center"
          alignItems="center"
        >
          {title && (
            <GridItem
              xs={12}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                marginBottom: '8px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h4">{title}</Typography>
            </GridItem>
          )}
          {subtitle && (
            <GridItem xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="subtitle1">{subtitle}</Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
      <GridItem xs={12} style={{ marginBottom: '24px' }}>
        {children}
      </GridItem>
      <GridItem xs={12}>{divider ? <Divider /> : null}</GridItem>
    </GridContainer>
  );
}

QuestionRefactored.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  divider: PropTypes.bool,
};
