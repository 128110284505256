import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import { pregnancyCalculator2 } from 'assets/imagesList';

export default function CalculatorIntro() {
  const isMobile = window.innerWidth < 800;
  const { t } = useTranslation();

  return (
    <>
      {isMobile && (
        <GridContainer
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem
            style={{
              marginTop: '30px',
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '30px',
                color: '#977AB7',
                textAlign: 'center',
              }}
            >
              {t('calculator.introScreen.paragraph1')}
            </Typography>
          </GridItem>

          <GridItem
            style={{
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '20px',
                color: '#525060',
                textAlign: 'center',
              }}
            >
              {t('calculator.introScreen.paragraph2')}
            </Typography>
          </GridItem>

          <GridItem style={{ marginTop: '20px' }}>
            <img
              src={pregnancyCalculator2}
              alt="Pregnancy Calculator Illustration"
              height={250}
            />
          </GridItem>

          <GridItem
            style={{
              marginTop: '30px',
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                color: '#525060',
                textAlign: 'center',
              }}
            >
              {t('calculator.introScreen.paragraph3')}
            </Typography>
          </GridItem>

          <GridItem
            style={{
              marginTop: '30px',
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                color: '#525060',
                textAlign: 'center',
              }}
            >
              {t('calculator.introScreen.paragraph4')}
            </Typography>
          </GridItem>
        </GridContainer>
      )}
      {!isMobile && (
        <GridContainer
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <GridContainer
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <GridItem style={{ margin: '10px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '25px',
                    color: '#977AB7',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('calculator.introScreen.paragraph1')}
                </Typography>
              </GridItem>
              <GridItem style={{ margin: '10px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '20px',
                    color: '#2d2c39',
                  }}
                >
                  {t('calculator.introScreen.paragraph2')}
                </Typography>
              </GridItem>
              <GridItem style={{ margin: '10px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '20px',
                    color: '#2d2c39',
                  }}
                >
                  {t('calculator.introScreen.paragraph3')}
                </Typography>
              </GridItem>
              <GridItem style={{ margin: '10px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '20px',
                    color: '#2d2c39',
                  }}
                >
                  {t('calculator.introScreen.paragraph4')}
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} style={{ marginTop: '20px' }}>
            <img
              src={pregnancyCalculator2}
              alt="Pregnancy Calculator Illustration"
              height={500}
            />
          </GridItem>
        </GridContainer>
      )}
      {/*<div>
      <ContainerWithBackgroundImg
        image={landingPageImage}
        maxWidth={'300px'}
        maxHeight={'300px'}
        height={'50vh'}
        width={'100vw'}
        marginTop={'8vh'}
      />
      <Box m={5} display="flex" justifyContent="center">
        <Typography variant="h5" style={{ maxWidth: '500px' }}>
          We're all about precision at 9Moons, so we created the world’s most
          accurate due date calculator so that you can start your pregnancy
          planning now!
        </Typography>
      </Box>
    </div>*/}
    </>
  );
}
