import moment from 'moment';

export const getDate = (date, format) => {
  return moment(date, format);
};

export const addDays = (date, days) => {
  const new_date = moment(date, 'YYYY-MM-DD').add(days, 'days');
  return new_date.format('YYYY-MM-DD');
};

export const subtractDays = (date, days) => {
  const new_date = moment(date, 'YYYY-MM-DD').subtract(days, 'days');
  return new_date.format('YYYY-MM-DD');
};

export const getDayDifferenceInWeeks = (date1, date2) => {
  return moment(date2).diff(moment(date1), 'week');
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const getCurrentDay = () => {
  let currentDate = moment();
  return currentDate;
};

export const getWeekStart = () => {
  let currentDate = moment();
  return currentDate.clone().startOf('isoWeek');
};

export const getWeekEnd = () => {
  let currentDate = moment();
  return currentDate.clone().endOf('isoWeek');
};

export const getCurrentWeek = () => {
  let days = [];

  for (let i = 0; i <= 6; i++) {
    days.push(moment(getWeekStart()).add(i, 'days'));
  }
  return days;
};

export const getCurrentWeekFromDate = (date) => {
  let days = [];

  for (let i = 0; i <= 6; i++) {
    const start = date.clone();
    days.push(moment(start).add(i, 'days'));
  }
  return days;
};

export const isSameDay = (date1, date2) => {
  return moment(date1.format('YYYY-MM-DD')).isSame(date2.format('YYYY-MM-DD'));
};

export const getIndexOfCurrentDayInWeek = (firstDayInWeek) => {
  const days = getCurrentWeekFromDate(firstDayInWeek);
  for (let i = 0; i < days.length; i++) {
    if (isSameDay(days[i], moment())) {
      return i;
    }
  }
};

export const getTodaysHours = () => {
  const hours = new Date().getHours();
  return hours;
};
