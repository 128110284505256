import React, { useState, useEffect } from 'react';

import Box from 'components/atoms/Layout/Box';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import BaseInput from 'components/atoms/Inputs/TextInput/BaseInput';

export default function WeightQuestionObsolete(props) {
  const [isWeightInLbs, setIsWeightInLbs] = useState(
    props.selectedWeightMetric === 2 ? false : true
  );

  const [weight, setWeight] = useState(
    props.weight ? Math.round(props.weight) : null
  );

  const changeWeightUnit = () => {
    setIsWeightInLbs(!isWeightInLbs);
  };

  useEffect(() => {
    props.onChange &&
      props.onChange({
        weight: weight,
        selectedWeightMetric: isWeightInLbs ? 1 : 2,
      });
  }, [weight, isWeightInLbs]);

  return (
    <QuestionRefactored title={props.title} subtitle={props.subtitle}>
      <Box display="flex" justifyContent="center" alignContent="flex-start">
        <BaseInput
          id="weight-input"
          value={weight}
          color="primary"
          onChange={(value) => setWeight(value)}
          inputProps={{
            style: { textAlign: 'center' },
            inputType: 'number',
          }}
        />
        <RadioButtonGroup
          id="weight-radio-input"
          color="primary"
          items={props.data}
          rowNumber={1}
          onChange={changeWeightUnit}
          colorUnchecked={props.colorUnchecked}
        />
      </Box>
    </QuestionRefactored>
  );
}
