import React, { useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import WeightQuestion from 'components/organisms/Questions/General/WeightQuestion';

import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import { lbsToKg } from 'components/helpers/converterHelper';

export default function YourWeight(props) {
  const classes = usePageStyles(props);
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const initialOptions = [
    {
      label: t('onboarding.generalSection.step3.lbs'),
      value: '1',
      checked:
        overmindState.calc_selectedWeightMetric === '1' ||
        overmindState.calc_selectedWeightMetric == null,
    },
    {
      label: t('onboarding.generalSection.step3.kg'),
      value: '2',
      checked: overmindState.calc_selectedWeightMetric === '2',
    },
  ];
  const [options, setOptions] = useState(initialOptions);
  const [displayedValue, setDisplayedValue] = useState(
    overmindState.calc_selectedWeightMetric === '2'
      ? overmindState.calc_weight
      : overmindState.calc_weightLbs
  );

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <WeightQuestion
        title={t('onboarding.generalSection.step3.weight')}
        subtitle={t('onboarding.generalSection.step3.weightSubtitle')}
        data={options}
        weight={displayedValue}
        selectedWeightMetric={overmindState.calc_selectedWeightMetric}
        onWeightChange={(value) => {
          const weightMetric =
            overmindState.calc_selectedWeightMetric == null
              ? '1'
              : overmindState.calc_selectedWeightMetric;
          const isLbs = weightMetric === '1' || weightMetric == null;
          const weightInKg = isLbs ? lbsToKg(value) : value;
          const nextEnabled = weightInKg >= 40 && weightInKg <= 160;
          actions.updateStateProp({
            key: 'calc_weightLbs',
            value: isLbs ? value : null,
          });
          actions.updateStateProp({
            key: 'calc_selectedWeightMetric',
            value: weightMetric,
          });
          actions.updateStateProp({
            key: 'calc_weight',
            value: weightInKg,
            nextEnabled: nextEnabled,
          });
          setDisplayedValue(value);
        }}
        onMetricChange={(value) => {
          actions.updateStateProp({
            key: 'calc_selectedWeightMetric',
            value: value,
          });
          actions.updateStateProp({
            key: 'calc_weight',
            value: displayedValue,
          });
          if (value === '1') {
            initialOptions[0].checked = true;
            initialOptions[1].checked = false;
            actions.updateStateProp({
              key: 'calc_weightLbs',
              value: value,
              nextEnabled:
                lbsToKg(displayedValue) >= 40 && lbsToKg(displayedValue) <= 160,
            });
          } else {
            initialOptions[0].checked = false;
            initialOptions[1].checked = true;
            actions.updateStateProp({
              key: 'calc_weightLbs',
              value: null,
              nextEnabled: displayedValue >= 40 && displayedValue <= 160,
            });
          }
          setOptions(initialOptions);
        }}
      />
    </GridContainer>
  );
}
