import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useOvermindState } from 'overmind/index';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

const HOME = 'home';

export default function ThankYouPage() {
  const overmindState = useOvermindState();
  const [displayMode, setDisplayMode] = useState(HOME);

  useEffect(() => {
    try {
      console.log('window.ReactNativeWebView');
      window &&
        window.ReactNativeWebView &&
        // window.ReactNativeWebView.postMessage({ seenThankYouPage: true });
        window.ReactNativeWebView.postMessage('TEST STRING');
    } catch (e) {
      console.log('e ', e.error);
    }
  }, []);

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={
          displayMode !== HOME ? (
            'Recipe'
          ) : overmindState.metaData && overmindState.metaData.user_metadata ? (
            <Typography variant="h5">
              Hello {overmindState.metaData.user_metadata.firstName}!
            </Typography>
          ) : (
            <Typography variant="h5">Hello!</Typography>
          )
        }
        showBack={displayMode !== HOME}
        goBack={() => setDisplayMode(HOME)}
      />

      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          marginTop: displayMode !== HOME ? 90 : 0,
          fontSize: '15px',
        }}
      >
        <GridItem>
          <Box
            style={{
              maxWidth: '1200px',
              marginTop: '200px',
            }}
          >
            Thank you for the payment
          </Box>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
}
