import React, { useEffect } from 'react';
import { useActions, useOvermindState } from 'overmind/index';

import { ThemeProvider } from '@material-ui/core/styles';
import { generalTheme, pregnancyTheme } from 'themes';

import Footer from 'components/molecules/Footer/Footer';
import CalculatorHeader from 'components/molecules/Header/CalculatorHeader';
import Slider from 'components/molecules/Slider/Slider';
import ChildrenQuestion from 'components/pages/Start/Questions/ChildrenQuestion';
import PostTermPregnancyQuestion from 'components/pages/Start/Questions/PostTermPregnancyQuestion';
import HowOftenDoYouSmoke from 'components/pages/Start/Questions/HowOftenDoYouSmoke';
import HowStressfulIsYourLife from 'components/pages/Start/Questions/HowStressfulIsYourLife';
import CalculatorIntro from 'components/pages/Start/CalculatorIntro';
import ProgressIndicator from 'components/organisms/ProgressIndicator/ProgressIndicator';

import {
  getCalculatorProgressItems,
  getCalculatorSectionByStepId,
  isIntroStep,
} from 'components/helpers/progressIndicatorHelper';
import { getCalculatorStepIndex } from 'components/helpers/onboardingHelper';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import HaveYouBeenDiagnosed from './Questions/HaveYouBeenDiagnosed';
import DoYouKnowYourDueDate from './Questions/DoYouKnowYourDueDate';
import MenstrualCycleLength from './Questions/MenstrualCycleLength';
import ClosestConceptionExperience from './Questions/ClosestConceptionExperience';
import DateOfTransfer from './Questions/DateOfTransfer';
import DoYouKnowLastPeriod from './Questions/DoYouKnowLastPeriod';
import DoYouKnowWhenConceived from './Questions/DoYouKnowWhenConceived';
import DueDateResults from './Questions/DueDateResults';
import YourName from './Questions/YourName';
import YourAge from './Questions/YourAge';
import YourHeight from './Questions/YourHeight';
import YourWeight from './Questions/YourWeight';
import FirstChildWeek from './Questions/FirstChildWeek';
import CaptureEmail from './Questions/CaptureEmail';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  trackEvent,
  trackFbPixelEvent,
} from 'components/helpers/userTrackingEventsHelper';
import { Redirect } from 'react-router-dom';
import { ROUTE_LANDING_PAGE } from 'routes';
import moment from 'moment';

export default function StartPage(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // on each refresh it is fine to set progress to the first step of the onboarding
    if (overmindState.metaData.user_metadata.usedCalculator) {
      actions.setProgressStep(getCalculatorStepIndex('calculate_due_date'));
    } else {
      actions.setProgressStep(getCalculatorStepIndex('general_intro'));
      actions.updateStateProp({
        key: 'onboardingForwardEnabled',
        value: true,
      });
    }
  }, [actions]);

  useEffect(() => {
    const seenCalcDueDate =
      window.localStorage.getItem('seenCalcDueDate') === 'true';
    if (seenCalcDueDate) {
      window.localStorage.removeItem('seenCalcDueDate');
    }

    if (window.location.search !== '?returning_user=true') {
      trackEvent('PregnancyCalc', 'Calc Visited');
    } else if (
      window.location.search === '?returning_user=true' &&
      !overmindState.metaData?.user_metadata?.nine_moons_first_signing_date
    ) {
      saveFirstSigningDate();
    }
  }, []);

  async function saveFirstSigningDate() {
    let currentDate = moment().format('YYYY-MM-DD');
    await actions.saveFirstSigningDate(currentDate);
    trackEvent('SignUp', 'Completed');
    trackFbPixelEvent('Lead');
  }

  return (
    <>
      <HeaderWrapper />
      {window.innerWidth > 800 ? (
        <GridContainer direction="column" justify="center" alignItems="center">
          <GridItem
            style={{
              maxWidth: '1200px',
            }}
          >
            <ProgressIndicatorWrapper />
          </GridItem>
          <GridItem
            style={{
              maxWidth: '1200px',
            }}
          >
            <SliderWrapper />
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <ProgressIndicatorWrapper />
          <SliderWrapper />
        </>
      )}
      {overmindState.progressStep !==
        getCalculatorStepIndex('capture_email') && (
        <Footer
          onBackClick={() => {
            actions.goBackInCalculator();
          }}
          onNextClick={() => {
            actions.saveCalcStateToLocalStorage();
            if (overmindState.redirectToSignup) {
              actions.changeRedirectToSignup(false);
              loginWithRedirect({
                /*screen_hint: 'signup',*/
                redirectUri: window.location.origin,
              });
            } else {
              actions.goNextInCalculator();
            }
          }}
          text={
            overmindState.progressStep ===
            getCalculatorStepIndex('general_intro')
              ? t('calculator.introScreen.next')
              : t('calculator.next')
          }
          hideRightButton={
            overmindState.progressStep ===
            getCalculatorStepIndex('calculate_due_date')
          }
          centerRightButton={
            overmindState.progressStep ===
            getCalculatorStepIndex('general_intro')
          }
          showProgressInSteps={
            overmindState.progressStep !==
              getCalculatorStepIndex('general_intro') &&
            overmindState.progressStep !==
              getCalculatorStepIndex('calculate_due_date')
          }
          showLeftButton={
            overmindState.progressStep > getCalculatorStepIndex('age') &&
            overmindState.progressStep <
              getCalculatorStepIndex('calculate_due_date')
          }
        />
      )}
    </>
  );
}

export const HeaderWrapper = () => {
  const overmindState = useOvermindState();

  return (
    <CalculatorHeader
      hideHeader={overmindState.hideHeader}
      adjustMargin={isIntroStep(overmindState.progressStep)}
      hideLeftButton={true}
    />
  );
};

export function ProgressIndicatorWrapper() {
  const overmindState = useOvermindState();
  const step = overmindState.progressStep;

  return step !== getCalculatorStepIndex('general_intro') &&
    step !== getCalculatorStepIndex('capture_email') &&
    step !== getCalculatorStepIndex('calculate_due_date') ? (
    <ProgressIndicator
      items={getCalculatorProgressItems()}
      selectedSlideIx={getCalculatorSectionByStepId(step).id}
      lastVisitedSectionId={overmindState.lastSectionVisited}
      uid="navigation-swiper"
      slideClassName="q-nav-swiper-slide"
      onProgressStepClick={(sectionId, lastVisitedSectionId) => {}}
      centered={true}
    />
  ) : null;
}

export function SliderWrapper() {
  const overmindState = useOvermindState();
  const renderStep = (step) => {
    switch (step) {
      case getCalculatorStepIndex('general_intro'):
      case getCalculatorStepIndex('name'):
      case getCalculatorStepIndex('age'):
      case getCalculatorStepIndex('height'):
      case getCalculatorStepIndex('weight'):
        return (
          <ThemeProvider theme={generalTheme}>
            {renderGeneralSectionStep(step)}
          </ThemeProvider>
        );
      case getCalculatorStepIndex('your_first_baby_page'):
      case getCalculatorStepIndex('first_baby_week'):
      case getCalculatorStepIndex('post_term_pregnancy_page'):
      case getCalculatorStepIndex('have_you_been_diagnosed'):
      case getCalculatorStepIndex('how_often_do_you_smoke'):
      case getCalculatorStepIndex('how_stressful_is_your_file'):
      case getCalculatorStepIndex('do_you_know_your_due_date'):
      case getCalculatorStepIndex('cycle_length'):
      case getCalculatorStepIndex('conception_experience'):
      case getCalculatorStepIndex('date_of_transfer'):
      case getCalculatorStepIndex('do_you_know_last_period'):
      case getCalculatorStepIndex('do_you_know_when_you_have_conceived'):
      case getCalculatorStepIndex('first_name'):
      case getCalculatorStepIndex('capture_email'):
      case getCalculatorStepIndex('calculate_due_date'):
        return (
          <ThemeProvider theme={pregnancyTheme}>
            {renderPregnancySectionStep(step)}
          </ThemeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderGeneralSectionStep = (step) => {
    switch (step) {
      case getCalculatorStepIndex('general_intro'):
        return <CalculatorIntro />;
      case getCalculatorStepIndex('name'):
        return <YourName />;
      case getCalculatorStepIndex('age'):
        return <YourAge />;
      case getCalculatorStepIndex('height'):
        return <YourHeight />;
      case getCalculatorStepIndex('weight'):
      default:
        return <YourWeight />;
    }
  };

  const renderPregnancySectionStep = (step) => {
    switch (step) {
      case getCalculatorStepIndex('your_first_baby_page'):
        return <ChildrenQuestion />;
      case getCalculatorStepIndex('first_baby_week'):
        return <FirstChildWeek />;
      case getCalculatorStepIndex('post_term_pregnancy_page'):
        return <PostTermPregnancyQuestion />;
      case getCalculatorStepIndex('have_you_been_diagnosed'):
        return <HaveYouBeenDiagnosed />;
      case getCalculatorStepIndex('how_often_do_you_smoke'):
        return <HowOftenDoYouSmoke />;
      case getCalculatorStepIndex('how_stressful_is_your_file'):
        return <HowStressfulIsYourLife />;
      case getCalculatorStepIndex('do_you_know_your_due_date'):
        return <DoYouKnowYourDueDate />;
      case getCalculatorStepIndex('cycle_length'):
        return <MenstrualCycleLength />;
      case getCalculatorStepIndex('conception_experience'):
        return <ClosestConceptionExperience />;
      case getCalculatorStepIndex('date_of_transfer'):
        return <DateOfTransfer />;
      case getCalculatorStepIndex('do_you_know_last_period'):
        return <DoYouKnowLastPeriod />;
      case getCalculatorStepIndex('do_you_know_when_you_have_conceived'):
        return <DoYouKnowWhenConceived />;
      case getCalculatorStepIndex('first_name'):
        return <YourName />;
      case getCalculatorStepIndex('capture_email'):
        return <Redirect to={ROUTE_LANDING_PAGE} />;
      case getCalculatorStepIndex('calculate_due_date'):
        return <DueDateResults />;
      default:
        return <ChildrenQuestion />;
    }
  };

  return window.innerWidth < 800 ? (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderStep(overmindState.progressStep)}
    </Slider>
  ) : (
    <>{renderStep(overmindState.progressStep)}</>
  );
}
