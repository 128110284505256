import React, { useState } from 'react';
import { useOvermindState } from 'overmind/index';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Tabs from 'components/molecules/Navigation/Tabs/Tabs';
import Tab from 'components/molecules/Navigation/Tabs/Tab';
import TabTitle from 'components/molecules/Navigation/Tabs/TabTitle';
import {
  prepareMonitorLineChartData,
  renderSymptomChartVertical,
  getCategories,
} from 'components/helpers/chartHelper';
import {
  getAdviceText,
  getBmi,
  getWeightGain,
  getWeightGainCategory,
} from 'components/helpers/pregnancySymptopmsHelper';

export default function Health() {
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const [tabValue, setTabValue] = useState(0);

  const checkins = overmindState.weightWeeklyCheckinsByPregnancyWeek;

  const bmi = getBmi(
    overmindState.profile.weight,
    overmindState.profile.height
  );

  const trimester = overmindState.profilePregnancy.pregnancy_trimester;

  const weightGain = getWeightGain(checkins, overmindState.profile.weight);

  const getSleepData = () =>
    getData(overmindState.sleepWeeklyCheckinsByPregnancyWeek);

  const getStressData = () =>
    getData(overmindState.stressWeeklyCheckinsByPregnancyWeek);

  const getActivityData = () =>
    getData(overmindState.activityWeeklyCheckinsByPregnancyWeek);

  const getWeightData = () =>
    getHealthData(overmindState.weightWeeklyCheckinsByPregnancyWeek);

  const getData = (stateProp) => {
    const data = [1];
    for (let i = 1; i < 41; i++) {
      if (stateProp != null) {
        const entry = stateProp.find((d) => d.week_of_pregnancy === i);
        if (entry == null) {
          data.push(null);
        } else {
          data.push(entry.value);
        }
      }
    }

    return data;
  };

  const getHealthData = (stateProp) => {
    let lastWeight = overmindState.profile.weight;
    let lastWeek = 0;
    const data = [1];
    for (let i = 1; i < 41; i++) {
      if (stateProp != null) {
        const entry = stateProp.find((d) => d.week_of_pregnancy === i);
        if (entry == null) {
          data.push(1);
        } else {
          let weightGain =
            entry.value - lastWeight / (entry.week_of_pregnancy - lastWeek);
          const gain = getWeightGainCategory(weightGain, bmi, trimester);
          data.push(gain);
          lastWeight = entry.value;
          lastWeek = entry.week_of_pregnancy;
        }
      }
    }

    return data;
  };

  const getSleepAdvice = () => {
    const checkin = overmindState.sleepWeeklyCheckinsByPregnancyWeek;
    if (checkin && checkin.length > 0) {
      const value = checkin[checkin.length - 1].value;
      return t(`monitor.sleepAdvices.trimester${trimester}.option${value}`);
    } else {
      return '';
    }
  };

  const getStressAdvice = () => {
    const checkin = overmindState.stressWeeklyCheckinsByPregnancyWeek;
    if (checkin && checkin.length > 0) {
      const value = checkin[checkin.length - 1].value;
      return t(`monitor.stressAdvices.trimester${trimester}.option${value}`);
    } else {
      return '';
    }
  };

  const getActivityLevelAdvice = () => {
    const checkin = overmindState.activityWeeklyCheckinsByPregnancyWeek;
    if (checkin && checkin.length > 0) {
      const value = checkin[checkin.length - 1].value;
      return t(`monitor.activityAdvices.trimester${trimester}.option${value}`);
    } else {
      return '';
    }
  };

  return (
    <Box mx="24px">
      {renderSymptomChartVertical(
        'SLEEP',
        prepareMonitorLineChartData(
          getSleepData(),
          overmindState.profilePregnancy.pregnancy_week - 1
        ),
        '#879ae5',
        getCategories(),
        'monitor.sleepQuestion'
      )}

      {renderSymptomChartVertical(
        'STRESS',
        prepareMonitorLineChartData(
          getStressData(),
          overmindState.profilePregnancy.pregnancy_week - 1
        ),
        '#66DA26',
        getCategories(),
        'monitor.stressQuestion'
      )}

      {renderSymptomChartVertical(
        'ACTIVITY LEVEL',
        prepareMonitorLineChartData(
          getActivityData(),
          overmindState.profilePregnancy.pregnancy_week - 1
        ),
        '#e68a67',
        getCategories(),
        'monitor.exerciseQuestion'
      )}

      <Box>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          indicatorColor=""
          aria-label="health tabs"
        >
          {overmindState.sleepWeeklyCheckinsByPregnancyWeek &&
            overmindState.sleepWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Tab
                label={
                  <TabTitle
                    label="Sleep"
                    color={tabValue === 0 ? '#879ae5' : '#bebebe'}
                  />
                }
              />
            )}
          {overmindState.stressWeeklyCheckinsByPregnancyWeek &&
            overmindState.stressWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Tab
                label={
                  <TabTitle
                    label="Stress"
                    color={tabValue === 1 ? '#66DA26' : '#bebebe'}
                  />
                }
              />
            )}
          {overmindState.activityWeeklyCheckinsByPregnancyWeek &&
            overmindState.activityWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Tab
                label={
                  <TabTitle
                    label="Activity level"
                    color={tabValue === 2 ? '#e68a67' : '#bebebe'}
                  />
                }
              />
            )}
        </Tabs>

        {tabValue === 0 &&
          overmindState.sleepWeeklyCheckinsByPregnancyWeek &&
          overmindState.sleepWeeklyCheckinsByPregnancyWeek.length > 0 &&
          trimester > 0 && (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: getSleepAdvice() }}
            />
          )}
        {tabValue === 1 && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: getStressAdvice() }}
          />
        )}
        {tabValue === 2 && (
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: getActivityLevelAdvice() }}
          />
        )}
      </Box>

      {renderSymptomChartVertical(
        'WEIGHT',
        prepareMonitorLineChartData(
          getWeightData(),
          overmindState.profilePregnancy.pregnancy_week - 1
        ),
        '#ffb324',
        getCategories(),
        'monitor.weightGain',
        0,
        2
      )}

      {checkins != null && checkins.length > 0 && (
        <Box>
          <Typography variant="body1">
            {getAdviceText(bmi, weightGain, trimester)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
