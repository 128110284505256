import * as React from 'react';

export default function DueDateBarChart(props) {
  return (
    <svg width="362.249" height="248.081" viewBox="0 0 362.249 248.081">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_5344"
            data-name="Rectangle 5344"
            width="329.064"
            height="221.046"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_6586"
        data-name="Group 6586"
        transform="translate(-13.633 -207.107)"
      >
        <line
          id="Line_1615"
          data-name="Line 1615"
          x2="288.877"
          transform="translate(68.978 414.472)"
          fill="none"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <g
          id="Group_6546"
          data-name="Group 6546"
          transform="translate(35.201 211.107)"
        >
          <g id="Group_6559" data-name="Group 6559" transform="translate(0 0)">
            <text
              id="_10_"
              data-name="10%"
              transform="translate(0 150.36)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                10%
              </tspan>
            </text>
            <text
              id="_15_"
              data-name="15%"
              transform="translate(0 122.288)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                15%
              </tspan>
            </text>
            <text
              id="_20_"
              data-name="20%"
              transform="translate(0 94.216)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                20%
              </tspan>
            </text>
            <text
              id="_25_"
              data-name="25%"
              transform="translate(0 66.144)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                25%
              </tspan>
            </text>
            <text
              id="_30_"
              data-name="30%"
              transform="translate(0 38.072)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                30%
              </tspan>
            </text>
            <text
              id="_35_"
              data-name="35%"
              transform="translate(1.459 10)"
              font-size="13"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                35%
              </tspan>
            </text>
          </g>
        </g>
        <line
          id="Line_1616"
          data-name="Line 1616"
          y1="200.469"
          transform="translate(68.978 214.002)"
          fill="none"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <rect
          id="Rectangle_5338"
          data-name="Rectangle 5338"
          width="27.735"
          height="200.469"
          transform="translate(209.424 214.002)"
          fill="#977ab7"
        />
        <path
          id="Path_23028"
          data-name="Path 23028"
          d="M69.322,211.8h0Z"
          transform="translate(59.057 202.671)"
        />
        <path
          id="Path_23029"
          data-name="Path 23029"
          d="M69.322,211.8h0Z"
          transform="translate(59.057 202.671)"
          fill="none"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_23030"
          data-name="Path 23030"
          d="M121.895,211.8h0Z"
          transform="translate(63.887 202.671)"
        />
        <path
          id="Path_23031"
          data-name="Path 23031"
          d="M121.895,211.8h0Z"
          transform="translate(63.887 202.671)"
          fill="none"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <g
          id="Group_6548"
          data-name="Group 6548"
          transform="translate(46.817 208.465)"
        >
          <g
            id="Mask_Group_6547"
            data-name="Mask Group 6547"
            clip-path="url(#clip-path)"
          >
            <text
              id="_7_3_-_7_5"
              data-name="7/3 - 7/5"
              transform="translate(221.876 219.319)"
              font-size="9"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                {/* right */}
                {props.d4}
              </tspan>
            </text>
            <text
              id="_6_26_-_6_28"
              data-name="6/26 - 6/28"
              transform="translate(156.071 219.319)"
              font-size="9"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                {/*center*/}
                {props.d3}
              </tspan>
            </text>
            <text
              id="_6_19_-_6_21"
              data-name="6/19 - 6/21"
              transform="translate(91.717 219.319)"
              font-size="9"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                {/*left*/}
                {props.d2}
              </tspan>
            </text>
            <text
              id="_6_12_-_6_14"
              data-name="6/12 - 6/14"
              transform="translate(18.717 219.046)"
              font-size="9"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                {/*top left */}
                {props.d1}
              </tspan>
            </text>
            <text
              id="_7_10_-_7_12"
              data-name="7/10 - 7/12"
              transform="translate(279.875 219.046)"
              font-size="9"
              font-family="SegoeUI, Segoe UI"
            >
              <tspan x="0" y="0">
                {/* top right */}
                {props.d5}
              </tspan>
            </text>
          </g>
        </g>
        <rect
          id="Rectangle_5345"
          data-name="Rectangle 5345"
          width="27.735"
          height="143.083"
          transform="translate(142.64 271.388)"
          fill="#977ab7"
        />
        <rect
          id="Rectangle_5346"
          data-name="Rectangle 5346"
          width="27.735"
          height="115.526"
          transform="translate(272.646 298.945)"
          fill="#977ab7"
        />
        <rect
          id="Rectangle_5347"
          data-name="Rectangle 5347"
          width="27.735"
          height="56.791"
          transform="translate(75.199 357.195)"
          fill="#977ab7"
        />
        <rect
          id="Rectangle_5348"
          data-name="Rectangle 5348"
          width="27.735"
          height="56.791"
          transform="translate(335.867 357.304)"
          fill="#977ab7"
        />
        <text
          id="Dates"
          transform="translate(220 452.188)"
          fill="#818286"
          font-size="12"
          font-family="SegoeUI, Segoe UI"
          letter-spacing="-0.015em"
        >
          <tspan x="-16.506" y="0">
            DATES
          </tspan>
        </text>
        <line
          id="Line_1617"
          data-name="Line 1617"
          x2="5.836"
          transform="translate(69.128 214.487)"
          fill="none"
          stroke="#707070"
          stroke-width="1"
        />
        <text
          id="Chance_of_Birth"
          data-name="Chance of Birth"
          transform="translate(26.633 308.708) rotate(-90)"
          fill="#818286"
          font-size="12"
          font-family="SegoeUI, Segoe UI"
          letter-spacing="-0.015em"
        >
          <tspan x="-48.322" y="0">
            CHANCE OF BIRTH
          </tspan>
        </text>
      </g>
    </svg>
  );
}
