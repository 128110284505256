import { createMuiTheme } from '@material-ui/core/styles';

// ------------------------------------------------------------------------
// Basic colour values defined. Names are defined to follow names used in Zeplin.
// ------------------------------------------------------------------------

const COOL_BLUE = '#33b0ce';
const FEDORA_GRAY = '#5b575c';
const PINKISH_TAN = '#e39287';
const LIGHT_PINK = '#eaa49d';
const UGLY_PURPLE = '#aa4793';
const SOFT_PURPLE = '#bf67c3';
const EXERCISE_GREEN = '#add429';
const SECOND_EXERCISE_GREEN = '#64c5ab';
const SOFT_BLUE = '#6a8be5';
const NUTRITION_YELLOW = '#e5b515';
const REDDISH = '#e8716d';
const BLACK = '#000000';
const WHITE = '#fff';
const GRAY = '#808080';
const UNSATURATED_RED = '#d18282';
const YELLOWISH_ORANGE = '#f6a409';
const SLATE_GRAY_TWO = '#636771';
const GREENISH_GREY = '#8ace84';

// ------------------------------------------------------------------------
// Basic colours exposed to use across app and in components.
// ------------------------------------------------------------------------

export const APP_PRIMARY = COOL_BLUE;
export const APP_SECONDARY = FEDORA_GRAY;
export const APP_TEXT_PRIMARY = BLACK;
export const APP_TEXT_SECONDARY = COOL_BLUE;
export const APP_TEXT_HINT = UNSATURATED_RED;

export const APP_SECTION_DNA = COOL_BLUE;

const commonProperties = {
  palette: {
    primary: {
      main: APP_PRIMARY,
      contrastText: WHITE,
    },
    secondary: {
      main: APP_SECONDARY,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: APP_TEXT_PRIMARY,
      secondary: APP_TEXT_SECONDARY,
    },
  },
  typography: {
    h1: {
      fontFamily: 'MuseoSans-900',
      fontSize: '38px',
      fontStyle: 'normal',
      lineHeight: '40px',
    },
    h3: {
      fontFamily: 'Museo',
      fontSize: '25px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.09,
    },
    h4: {
      fontFamily: 'Museo',
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.09,
    },
    h5: {
      fontFamily: 'Museo',
      fontSize: '18px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.09,
    },
    body1: {
      fontFamily: 'Museo',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
    },
    body2: {
      fontFamily: 'Museo',
      fontSize: '14px',
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
    },
    subtitle1: {
      fontFamily: 'MuseoSans-300',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'italic',
      lineHeight: 1.15,
      color: GRAY,
    },
  },
  overrides: {
    MuiBottomNavigationAction: {
      label: {
        fontFamily: 'Museo',
        fontWeight: 800,
        fontSize: '10px',
        lineHeight: '10px',
      },
    },
  },
};

export const commonTheme = createMuiTheme({
  ...commonProperties,
});

/**
 * Theme for app intro section.
 */
export const introTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: PINKISH_TAN,
      contrastText: WHITE,
    },
    text: {
      primary: PINKISH_TAN,
      secondary: PINKISH_TAN,
    },
  },
});

/**
 * Theme for cookies consent dialog.
 */
export const cookiesConsentDialog = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: LIGHT_PINK,
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: WHITE,
    },
  },
});

/**
 * Theme for general section.
 */
export const generalTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: UGLY_PURPLE,
      contrastText: WHITE,
    },
    text: {
      primary: UGLY_PURPLE,
      secondary: UGLY_PURPLE,
    },
  },
});

/**
 * Theme for pregnancy section.
 */
export const pregnancyTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: SOFT_PURPLE,
      contrastText: WHITE,
    },
    text: {
      primary: SOFT_PURPLE,
      secondary: SOFT_PURPLE,
    },
  },
});

/**
 * Theme for exercise section.
 */
export const exerciseTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: EXERCISE_GREEN,
      contrastText: WHITE,
    },
    text: {
      primary: EXERCISE_GREEN,
      secondary: EXERCISE_GREEN,
    },
  },
});

/**
 * Theme for lifestyle section.
 */
export const lifestyleTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: SOFT_BLUE,
      contrastText: WHITE,
    },
    text: {
      primary: SOFT_BLUE,
      secondary: SOFT_BLUE,
    },
    secondary: {
      main: SECOND_EXERCISE_GREEN,
      contrastText: WHITE,
    },
  },
});

/**
 * Theme for nutrition section.
 */
export const nutritionTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: NUTRITION_YELLOW,
      contrastText: WHITE,
    },
    text: {
      primary: NUTRITION_YELLOW,
      secondary: NUTRITION_YELLOW,
    },
  },
});

/**
 * Theme for app DNA section.
 */
export const dnaTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: APP_SECTION_DNA,
      contrastText: WHITE,
    },
    text: {
      primary: APP_SECTION_DNA,
      secondary: APP_SECTION_DNA,
    },
  },
});

/**
 * Theme for app assessment section.
 */
export const assessmentTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: REDDISH,
      contrastText: WHITE,
      ctaBackground: 'linear-gradient(to left, #e8af44, #e86e6e)',
      weeklyCheckinModal: 'linear-gradient(to bottom, #e8af44, #e86e6e)',
    },
    secondary: {
      main: YELLOWISH_ORANGE,
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: WHITE,
      tertiary: SLATE_GRAY_TWO,
    },
    icon: {
      primary: GREENISH_GREY,
    },
  },
});

/**
 * Theme for Profile General section.
 */
export const profileGeneralTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: APP_TEXT_HINT,
      contrastText: WHITE,
    },
  },
});

/**
 * Theme for Weekly checkin and Monitoring section.
 */
export const monitoringTheme = createMuiTheme({
  ...commonProperties,

  palette: {
    ...commonProperties.palette,
    primary: {
      main: WHITE,
      contrastText: BLACK,
    },
    text: {
      primary: WHITE,
      secondary: WHITE,
    },
    secondary: {
      main: WHITE,
      contrastText: BLACK,
    },
  },
});
