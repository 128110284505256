import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { HasDnaIcon, HasNotDnaIcon } from 'assets/iconsList';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import Box from 'components/atoms/Layout/Box/Box';

import Question from 'components/organisms/Inputs/Question/Question';
import SelectableIconGroup from 'components/organisms/Inputs/SelectableIconGroup/SelectableIconGroup';

export default function HasDnaQuestion(props) {
  const [selected, setSelected] = useState(props.selectedQuestion);

  return (
    <Question title={props.title} divider={false}>
      <Box justifyContent="center" display="flex" mt={2} mb={3}>
        <SelectableIconGroup
          columnNumber={2}
          width={240}
          isRadio={true}
          name={'having-dna'}
          items={[
            {
              label: 'Yes',
              value: '1',
              checked: selected === '1',
              icon: <SvgIcon height={49} icon={HasDnaIcon} />,
              checkedIcon: <SvgIcon height={49} icon={HasDnaIcon} />,
              labelFontSize: 12,
              uid: 'has-dna',
            },
            {
              label: 'No',
              value: '2',
              checked: selected === '2',
              icon: <SvgIcon height={49} icon={HasNotDnaIcon} />,
              checkedIcon: <SvgIcon height={49} icon={HasNotDnaIcon} />,
              labelFontSize: 12,
              uid: 'has-not-dna',
            },
          ]}
          onChange={(val) => {
            setSelected(val);
            if (props.onQuestionAnswered) {
              props.onQuestionAnswered(val);
            }
          }}
        />
      </Box>
    </Question>
  );
}

HasDnaQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  onQuestionAnswered: PropTypes.func,
  selectedQuestion: PropTypes.oneOf(['1', '2']),
};
