import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import Tabs from 'components/molecules/Navigation/Tabs/Tabs';
import Tab from 'components/molecules/Navigation/Tabs/Tab';
import TabTitle from 'components/molecules/Navigation/Tabs/TabTitle';
import RecipeList from './RecipeList';

import { BookmarkFavorite, BookmarkSaveForLater } from 'assets/iconsList';

import { useActions, useOvermindState } from 'overmind/index';
import { profileGeneralTheme } from 'themes';

export default function RecipeBox(props) {
  const actions = useActions();
  const state = useOvermindState();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    actions.fetchFavoriteRecipes();
    actions.fetchSavedForLaterRecipes();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRecipeSelect = async (id) => {
    await actions.fetchRecipeDetails(id).then(() => {
      props.changeProfileView('recipe_details');
    });
  };

  return (
    <ThemeProvider theme={profileGeneralTheme}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="recipe-box-tabs"
        style={{ width: window.innerWidth < 800 ? '100vw' : 1200 }}
      >
        <Tab
          label={<TabTitle icon={<BookmarkFavorite />} label="Favorites" />}
        />
        <Tab
          label={
            <TabTitle icon={<BookmarkSaveForLater />} label="Saved for later" />
          }
        />
      </Tabs>

      {tabValue === 0 && (
        <RecipeList
          recipes={state.favoriteRecipes}
          onRecipeClick={handleRecipeSelect}
          parentComponent={'recipe_list'}
        />
      )}

      {tabValue === 1 && (
        <RecipeList
          recipes={state.savedForLaterRecipes}
          onRecipeClick={handleRecipeSelect}
          parentComponent={'recipe_list'}
        />
      )}
    </ThemeProvider>
  );
}
