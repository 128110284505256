import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useCollapsedSummaryStyles } from './accordionContentStyle';

export default function DailyNeed(props) {
  const classes = useCollapsedSummaryStyles(props);

  return (
    <GridContainer>
      <GridItem xs={6}>
        <GridContainer
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <GridItem xs={12}>
            <Typography className={classes.title} variant="h5">
              {props.title}
            </Typography>
          </GridItem>
          {props.subTitle != null && (
            <GridItem xs={12}>
              <Typography variant="body1" className={classes.subTitle}>
                {props.subTitle}
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
      <GridItem xs={6}>
        <GridContainer
          direction="column"
          justify="center"
          alignItems="flex-end"
        >
          <GridItem>
            <GridContainer direction="row" justify="center">
              <GridItem>
                <Typography variant="h5" className={classes.hint}>
                  {props.value}
                </Typography>
              </GridItem>
              <GridItem style={{ marginLeft: '2px' }}>
                <Typography variant="caption" className={classes.hint}>
                  {props.units && `${props.units}/day`}
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
          {props.percentage && (
            <GridItem>
              <Typography variant="body1">{`${props.percentage}%`}</Typography>
            </GridItem>
          )}
          {props.tolerance && (
            <GridItem>
              <Typography
                variant="caption"
                className={classes.hint}
              >{`+- ${props.tolerance}% tolerance`}</Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
