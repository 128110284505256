import { makeStyles } from '@material-ui/core';

export const useFooterStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: props.isIntro ? 'absolute' : 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    paddingTop: 15,
    paddingBottom: 14,
    backgroundColor: props.transparent
      ? ''
      : props.backgroundColor
      ? props.backgroundColor
      : 'rgba(255, 255, 255, 0.7)',
  }),
}));

export const useButtonStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#977AB7 !important',
    '&:disabled': {
      color: `rgba(0, 0, 0, 0.26) !important`,
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
  alternateRoot: {
    border: '1px Solid #977AB7',
  },
  rightSide: {
    marginLeft: 'auto',
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  back: {
    color: '#977AB7 !important',
  },
}));
