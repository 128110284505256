import i18n from '../../i18n';

import {
  exerciseSliderIcon1,
  exerciseSliderIcon2,
  exerciseSliderIcon3,
  exerciseSliderIcon4,
  exerciseSliderIcon5,
  exerciseSliderIconWhite1,
  exerciseSliderIconWhite2,
  exerciseSliderIconWhite3,
  exerciseSliderIconWhite4,
  exerciseSliderIconWhite5,
  lifestyleSliderSleepIcon1,
  lifestyleSliderSleepIcon2,
  lifestyleSliderSleepIcon3,
  lifestyleSliderSleepIcon4,
  lifestyleSliderSleepIcon5,
  lifestyleSliderSleepIconWhite1,
  lifestyleSliderSleepIconWhite2,
  lifestyleSliderSleepIconWhite3,
  lifestyleSliderSleepIconWhite4,
  lifestyleSliderSleepIconWhite5,
  lifestyleSliderStressIcon1,
  lifestyleSliderStressIcon2,
  lifestyleSliderStressIcon3,
  lifestyleSliderStressIcon4,
  lifestyleSliderStressIcon5,
  lifestyleSliderStressIconWhite1,
  lifestyleSliderStressIconWhite2,
  lifestyleSliderStressIconWhite3,
  lifestyleSliderStressIconWhite4,
  lifestyleSliderStressIconWhite5,
  lifestyleSliderBackpainIconWhite1,
  lifestyleSliderBackpainIconWhite2,
  lifestyleSliderBackpainIconWhite3,
  lifestyleSliderBackpainIconWhite4,
  lifestyleSliderBackpainIconWhite5,
  nausea_slider_icon_1,
  nausea_slider_icon_2,
  nausea_slider_icon_3,
  nausea_slider_icon_4,
  nausea_slider_icon_5,
  mood_swings_slider_icon_1,
  mood_swings_slider_icon_2,
  mood_swings_slider_icon_3,
  mood_swings_slider_icon_4,
  mood_swings_slider_icon_5,
  fatigue_slider_icon_1,
  fatigue_slider_icon_2,
  fatigue_slider_icon_3,
  fatigue_slider_icon_4,
  fatigue_slider_icon_5,
} from 'assets/iconsList';

export function getSleep(color) {
  return [
    {
      illustration:
        color === 'white'
          ? lifestyleSliderSleepIconWhite1
          : lifestyleSliderSleepIcon1,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question1.slide1'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderSleepIconWhite2
          : lifestyleSliderSleepIcon2,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question1.slide2'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderSleepIconWhite3
          : lifestyleSliderSleepIcon3,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question1.slide3'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderSleepIconWhite4
          : lifestyleSliderSleepIcon4,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question1.slide4'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderSleepIconWhite5
          : lifestyleSliderSleepIcon5,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question1.slide5'),
    },
  ];
}

export function getStress(color) {
  return [
    {
      illustration:
        color === 'white'
          ? lifestyleSliderStressIconWhite1
          : lifestyleSliderStressIcon1,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question2.slide1'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderStressIconWhite2
          : lifestyleSliderStressIcon2,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question2.slide2'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderStressIconWhite3
          : lifestyleSliderStressIcon3,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question2.slide3'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderStressIconWhite4
          : lifestyleSliderStressIcon4,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question2.slide4'),
    },
    {
      illustration:
        color === 'white'
          ? lifestyleSliderStressIconWhite5
          : lifestyleSliderStressIcon5,
      height: 85,
      text: i18n.t('onboarding.lifestyleSection.step1.question2.slide5'),
    },
  ];
}

export function getExercise(color) {
  return [
    {
      illustration:
        color === 'white' ? exerciseSliderIconWhite5 : exerciseSliderIcon5,
      height: 85,
      text: i18n.t('onboarding.exerciseSection.step1.slide1'),
    },
    {
      illustration:
        color === 'white' ? exerciseSliderIconWhite1 : exerciseSliderIcon1,
      height: 85,
      text: i18n.t('onboarding.exerciseSection.step1.slide2'),
    },
    {
      illustration:
        color === 'white' ? exerciseSliderIconWhite2 : exerciseSliderIcon2,
      height: 85,
      text: i18n.t('onboarding.exerciseSection.step1.slide3'),
    },
    {
      illustration:
        color === 'white' ? exerciseSliderIconWhite3 : exerciseSliderIcon3,
      height: 85,
      text: i18n.t('onboarding.exerciseSection.step1.slide4'),
    },
    {
      illustration:
        color === 'white' ? exerciseSliderIconWhite4 : exerciseSliderIcon4,
      height: 85,
      text: i18n.t('onboarding.exerciseSection.step1.slide5'),
    },
  ];
}

export function getNausea() {
  return [
    {
      illustration: nausea_slider_icon_1,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.nauseaQuestion.slide1'),
    },
    {
      illustration: nausea_slider_icon_2,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.nauseaQuestion.slide2'),
    },
    {
      illustration: nausea_slider_icon_3,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.nauseaQuestion.slide3'),
    },
    {
      illustration: nausea_slider_icon_4,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.nauseaQuestion.slide4'),
    },
    {
      illustration: nausea_slider_icon_5,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.nauseaQuestion.slide5'),
    },
  ];
}

export function getExhaustion() {
  return [
    {
      illustration: fatigue_slider_icon_1,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.tiredQuestion.slide1'),
    },
    {
      illustration: fatigue_slider_icon_2,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.tiredQuestion.slide2'),
    },
    {
      illustration: fatigue_slider_icon_3,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.tiredQuestion.slide3'),
    },
    {
      illustration: fatigue_slider_icon_4,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.tiredQuestion.slide4'),
    },
    {
      illustration: fatigue_slider_icon_5,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.tiredQuestion.slide5'),
    },
  ];
}

export function getAchingBack() {
  return [
    {
      illustration: lifestyleSliderBackpainIconWhite1,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.backPainQuestion.slide1'),
    },
    {
      illustration: lifestyleSliderBackpainIconWhite2,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.backPainQuestion.slide2'),
    },
    {
      illustration: lifestyleSliderBackpainIconWhite3,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.backPainQuestion.slide3'),
    },
    {
      illustration: lifestyleSliderBackpainIconWhite4,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.backPainQuestion.slide4'),
    },
    {
      illustration: lifestyleSliderBackpainIconWhite5,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.backPainQuestion.slide5'),
    },
  ];
}

export function getMoodSwings() {
  return [
    {
      illustration: mood_swings_slider_icon_1,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.moodSwingsQuestion.slide1'),
    },
    {
      illustration: mood_swings_slider_icon_2,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.moodSwingsQuestion.slide2'),
    },
    {
      illustration: mood_swings_slider_icon_3,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.moodSwingsQuestion.slide3'),
    },
    {
      illustration: mood_swings_slider_icon_4,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.moodSwingsQuestion.slide4'),
    },
    {
      illustration: mood_swings_slider_icon_5,
      width: 85,
      height: 85,
      text: i18n.t('home.weeklyCheckinQuestions.moodSwingsQuestion.slide5'),
    },
  ];
}
