import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import CheckboxGroup from 'components/organisms/Inputs/CheckboxGroup/CheckboxGroup';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

export default function MonitorComplicationsQuestion(props) {
  const selected =
    props.selectedComplications == null ? [] : props.selectedComplications;

  const handleChange = (index) => {
    props.onChange && props.onChange(index);
  };

  return (
    <QuestionRefactored
      title="Did you experience any of the following for a longer period of time?"
      divider={false}
    >
      <Box px={2.5} mt={2}>
        <CheckboxGroup
          color="primary"
          rowNumber={1}
          items={props.complications}
          onChange={(val) => {
            handleChange(val);
          }}
        />
      </Box>
    </QuestionRefactored>
  );
}
