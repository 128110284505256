import { makeStyles } from '@material-ui/core';

export const useProfileTilesStyles = makeStyles(() => ({
  root: {},
  tileTitle: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  tileInfo: {
    marginBottom: '10px',
    marginTop: '25px',
    fontWeight: 'bold',
  },
  tile: {
    marginBottom: '10px',
    border: '1px solid #EEEEEE',
    borderRadius: '5px',
    padding: '10px',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.10)',
    width: '100%',
  },
}));
