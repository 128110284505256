import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useActions, useOvermindState } from 'overmind/index';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTE_MONITOR } from 'routes';
import { getQueryString } from 'components/helpers/utmParametersHelper';

const HOME = 'home';

export default function ThankYouChatSubscriptionPage() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const history = useHistory();

  const [displayMode, setDisplayMode] = useState(HOME);

  useEffect(() => {
    actions.fetchSubscriptionStatus();

    const timeout = setTimeout(() => {
      history.push(ROUTE_MONITOR + getQueryString());
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={
          displayMode !== HOME ? (
            'Recipe'
          ) : overmindState.metaData && overmindState.metaData.user_metadata ? (
            <Typography variant="h5">
              {t('thankYouPage.hello')}{' '}
              {overmindState.metaData.user_metadata.firstName}!
            </Typography>
          ) : (
            <Typography variant="h5"> {t('thankYouPage.hello')}!</Typography>
          )
        }
      />

      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          marginTop: displayMode !== HOME ? 90 : 0,
          fontSize: '15px',
        }}
      >
        <GridItem>
          <Box
            style={{
              maxWidth: '1200px',
              marginTop: '200px',
            }}
          >
            {t('thankYouPage.thankYouForThePayment')}
          </Box>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
}
