import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import Question from 'components/organisms/Inputs/Question/Question';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';

export default function Name(props) {
  const handleChange = (value) => {
    props.onChange && props.onChange(value);
  };

  return (
    <Question
      title={props.title}
      divider={props.divider !== null ? props.divider : true}
    >
      <Box justifyContent="center" display="flex" mt={2} mb={3}>
        <TextInput
          value={props.name}
          color={'primary'}
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{
            style: {
              fontSize: '18px',
              width: '300px',
              color: 'black',
            },
          }}
        />
      </Box>
    </Question>
  );
}
