export const DAILY_MODE = 'daily';
export const WEEKLY_MODE = 'weekly';
export const DETAILS_MODE = 'recipeDetails';

export const state = {
  isLoadingData: true,
  email: null,
  genotype: {},
  isGenotypeFetched: false,
  profile: {},
  progressStep: 0,
  lastSectionVisited: 0,
  introPageIndex: 0,
  hideHeader: false,
  hideFooter: false,
  onboardingForwardEnabled: false,
  forwardNavigation: true,
  hasFetchedMetaData: null,
  metaData: {
    user_metadata: {},
  },
  profilePregnancy: {},
  isProfilePregnancyFetched: false,
  profileNutrition: {},
  upload: {
    progress: 0,
    showUploadStatus: false,
    successful: false,
  },
  kitNumberSubmit: {
    submitSuccessful: false,
    showSubmitStatus: false,
  },
  isAssessmentLoaded: false,
  areRecipeActionsLoaded: false,
  assessment: {},
  isNutrientsReportLoaded: false,
  nutrientsReport: {},
  isInitialReportLoaded: false,
  initialReport: {},
  isMealPlanLoaded: false,
  mealPlan: {},
  isMealPlanFetching: false,
  errors: {},
  isWeeklyPlan: false,
  similarRecipes: {},
  favoriteRecipes: [],
  savedForLaterRecipes: [],
  recipeDetails: {},
  recipeMealType: {},
  shoppingList: [],
  weeklyCheckin: {},
  monitorPregnancy: {},
  weightWeeklyCheckinsByPregnancyWeek: undefined,
  activityWeeklyCheckinsByPregnancyWeek: undefined,
  sleepWeeklyCheckinsByPregnancyWeek: undefined,
  stressWeeklyCheckinsByPregnancyWeek: undefined,
  nauseaWeeklyCheckinsByPregnancyWeek: undefined,
  fatigueWeeklyCheckinsByPregnancyWeek: undefined,
  achingBackWeeklyCheckinsByPregnancyWeek: undefined,
  moodSwingsBackWeeklyCheckinsByPregnancyWeek: undefined,
  complicationsWeeklyCheckinsByPregnancyWeek: undefined,
  firstBabyWeek: null,
  postTermPregnancy: null,
  diagnosedWith: [],
  smokingHabits: null,
  stressLevel: null,
  knowsDueDate: null,
  dueDate: null,
  cycleLength: null,
  conceptionExperience: null,
  dateOfTransfer: null,
  lastPeriod: null,
  lastPeriodDate: null,
  lastPeriodDateStart: null,
  lastPeriodDateEnd: null,
  doYouKnowWhenConceived: null,
  conceivedDateStart: null,
  conceivedDateEnd: null,
  calc_data_saved: null,
  calc_firstName: null,
  calc_age: null,
  calc_year_of_birth: null,
  calc_height: null,
  calc_heightFt: null,
  calc_heightIn: null,
  calc_selectedHeightMetric: null,
  calc_weight: null,
  calc_selectedWeightMetric: null,
  calc_weightLbs: null,
  calc_other_children: null,
  calc_first_name: null,
  bdd: null,
  savedEmailToMailchimp: false,
  savedOnboardedEmailToMailchimp: false,
  redirectToSignup: false,
  payment: {
    intent: null,
  },
  subscription: {
    isBeingFetched: false,
    status: undefined,
    expires_in: undefined,
  },
};
