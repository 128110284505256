import i18n from '../../i18n';

export function getEthnicities(userEthnicity) {
  return [
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.nonHispanic'
      ),
      value: 1,
      checked: userEthnicity === 1,
    },
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.africanAmerican'
      ),
      value: 2,
      checked: userEthnicity === 2,
    },
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.hispanic'
      ),
      value: 3,
      checked: userEthnicity === 3,
    },
    {
      label: i18n.t('onboarding.generalSection.step2.ethnicityOptions.asian'),
      value: 4,
      checked: userEthnicity === 4,
    },
    {
      label: i18n.t('onboarding.generalSection.step2.ethnicityOptions.other'),
      value: 5,
      checked: userEthnicity === 5,
    },
    {
      label: i18n.t(
        'onboarding.generalSection.step2.ethnicityOptions.dontWantToSay'
      ),
      value: 6,
      checked: userEthnicity === 6,
    },
  ];
}
