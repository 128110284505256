import React from 'react';

import Box from 'components/atoms/Layout/Box';
import Question from 'components/organisms/Inputs/Question/Question';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';

export default function EthnicityQuestion(props) {
  const handleChange = (value) => {
    props.onChange && props.onChange(value);
  };

  return (
    <Question title={props.title} divider={true}>
      <Box display="flex" justifyContent="center">
        <RadioButtonGroup
          items={props.data}
          rowNumber={2}
          color="primary"
          colorUnchecked={props.colorUnchecked}
          onChange={handleChange}
          defaultValue={props.userEthnicity}
        />
      </Box>
    </Question>
  );
}
