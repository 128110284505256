import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    margin: props.margin ? props.margin : '10px',
    width: props.width ? props.width : '100px',
    height: props.height ? props.height : '60px',
    fontSize: props.fontSize ? props.fontSize : '3em',
  }),
}));

export const useTextInputStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => props.textSize,
  },
}));
