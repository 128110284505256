import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from 'components/atoms/Layout/Box/Box';

export default function MobileLogoutPage(props) {
  const { isAuthenticated, logout, isLoading } = useAuth0();
  const { PUBLIC_URL } = process.env;

  useEffect(() => {
    const logOutAndRemoveToken = () => {
      let url = `${window.location.origin}${PUBLIC_URL}`;
      logout({
        returnTo: url,
      });
      window.localStorage.removeItem('access_token');
    };

    logOutAndRemoveToken()
  }, [isAuthenticated, isLoading]);

  return (
    <React.Fragment>
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    </React.Fragment>
  );
}
