import React from 'react';
import PaymentDialog from './PaymentDialog';
import { dnaKitImage } from 'assets/imagesList';
import { useTranslation } from 'react-i18next';
import { ROUTE_MONITOR } from 'routes';

export default function PaymentDialogMonitor(props) {
  const { openDialog, children, ...rest } = props;
  const { t } = useTranslation();
  const dialogContent = [
    {
      bulletText: t('monitor.paymentDialog.bullet1'),
    },
    {
      title: t('monitor.paymentDialog.title2'),
      image: (
        <img
          src={dnaKitImage}
          resizeMode="contain"
          width="100%"
          alt="DNA kit"
        />
      ),
      bulletText: t('monitor.paymentDialog.bullet2'),
    },
  ];

  const { REACT_APP_STRIPE_PREMIUM_REDIRECT_URL } = process.env;

  //fallback url is currently redirecting to test Stripe page
  const redirectUrl = REACT_APP_STRIPE_PREMIUM_REDIRECT_URL;

  return (
    <PaymentDialog
      openDialog={props.openDialog}
      onCloseDialog={props.onCloseDialog}
      dialogContent={dialogContent}
      redirectUrl={redirectUrl}
      redirectType="redirect"
      redirectState={{ initiator: ROUTE_MONITOR }}
    />
  );
}
