import { makeStyles } from '@material-ui/core';

export const useHeaderStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  leftSide: {
    position: 'absolute',
    left: 0,
  },
}));

export const useNavigationHeaderStyles = makeStyles((theme) => ({
  container: (props) => ({
    minHeight: '50px',
    textAlign: 'center',
    padding: '5px',
    ...props.style,
  }),
  button: {
    cursor: 'pointer',
    color: 'default',
  },
  headerMargin: {
    paddingBottom: '50px',
  },
}));
