import React from 'react';

import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DailyNeed from 'components/molecules/Content/Accordion/DailyNeed';
import CollapsedSummary from 'components/molecules/Content/Accordion/CollapsedSummary';
import ExpandedSummary from 'components/molecules/Content/Accordion/ExpandedSummary';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function Micronutrient(props) {
  const { expanded } = props;

  const handleChange = (panel) => (event, newExpanded) => {
    trackEvent('Needs Assessment', 'Details Expanded', {
      need: props.standard_name,
    });
    if (newExpanded) {
      props.onNewExpanded && props.onNewExpanded(panel);
    } else {
      props.onNewExpanded && props.onNewExpanded('-');
    }
  };

  const isSodium = () => {
    return props.standard_name === 'Sodium';
  };
  return (
    <Accordion
      square
      expanded={expanded === `panel${props.index}`}
      onChange={handleChange(`panel${props.index}`)}
      key={props.index}
    >
      <AccordionSummary
        aria-controls={`panel${props.index}d-content`}
        id={`panel${props.index}d-header`}
        expandIcon={<ExpandMoreIcon />}
      >
        {expanded !== `panel${props.index}` ? (
          <CollapsedSummary
            title={props.standard_name}
            subTitle={
              props.alternative_name !== props.standard_name
                ? props.alternative_name
                : null
            }
            value={props.rda}
            units={props.rda_units}
            tolerance={5}
          />
        ) : (
          <ExpandedSummary
            title={props.standard_name}
            subTitle={
              props.alternative_name !== props.standard_name
                ? props.alternative_name
                : null
            }
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <DailyNeed
          title={
            isSodium()
              ? 'Your personalized daily needs assessment'
              : 'Your daily need'
          }
          dataCard={`${isSodium() ? '<' : ''}${props.rda} ${
            props.rda_units
          }/day`}
          shortDescription={props.short_description}
          description={props.scientific_description}
          links={props.links}
          foodsTitle={`${props.standard_name} rich foods`}
          foodsList={props.foods}
          benefitsTitle={'Health benefits'}
          healthBenefits={props.health_benefits}
          assessmentTitle={'Your Genetic Predisposition'}
          traitAssessments={props.trait_assessment}
        />
      </AccordionDetails>
    </Accordion>
  );
}
