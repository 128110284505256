import React, { useState, useEffect } from 'react';

import MonitorWeightQuestion from 'components/organisms/Questions/WeeklyCheckin/MonitorWeightQuestion';
import SleepQuestion from 'components/organisms/Questions/Lifestyle/SleepQuestion';
import StressQuestion from 'components/organisms/Questions/Lifestyle/StressQuestion';
import ExercisePerDayQuestion from 'components/organisms/Questions/Exercise/ExercisePerDayQuestion';
import MonitorComplicationsQuestion from 'components/organisms/Questions/WeeklyCheckin/MonitorComplicationsQuestion';
import NauseaQuestion from 'components/organisms/Questions/WeeklyCheckin/NauseaQuestion';
import ExhaustionQuestion from 'components/organisms/Questions/WeeklyCheckin/ExhaustionQuestion';
import BackPainQuestion from 'components/organisms/Questions/WeeklyCheckin/BackPainQuestion';
import MoodSwingsQuestion from 'components/organisms/Questions/WeeklyCheckin/MoodSwingsQuestion';
import Slider from 'components/molecules/Slider/Slider';

import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import {
  getSleep,
  getStress,
  getExercise,
  getNausea,
  getExhaustion,
  getAchingBack,
  getMoodSwings,
} from 'components/helpers/lifestyleExerciseHelper';
import { formatDate } from 'components/helpers/dateHelper';

export default function MonitorQuestionsSet(props) {
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const getSavedWeight = () => {
    return overmindState.monitorPregnancy &&
      overmindState.monitorPregnancy.weight
      ? overmindState.monitorPregnancy.weight
      : overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? overmindState.profile.weight
      : overmindState.metaData.user_metadata.weightLbs;
  };

  const [monitorWeight, setMonitorWeight] = useState(getSavedWeight());
  const [sleepQuality, setSleepQuality] = useState(0);
  const [stressLevel, setStressLevel] = useState(0);
  const [exercisePerDay, setExercisePerDay] = useState(0);
  const [complications, setComplications] = useState([
    {
      label: 'Bleeding from the vagina',
      value: '1',
      checked: false,
    },
    {
      label: 'Sudden swelling of the face or hands',
      value: '2',
      checked: false,
    },
    {
      label: 'Fever',
      value: '3',
      checked: false,
    },
    {
      label: 'Pain in the abdomen',
      value: '4',
      checked: false,
    },
    {
      label: 'Severe headache',
      value: '5',
      checked: false,
    },
  ]);
  const [nauseaLevel, setNauseaLevel] = useState(0);
  const [tiredLevel, setTiredLevel] = useState(0);
  const [backPainLevel, setBackPainLevel] = useState(0);
  const [moodSwingsLevel, setMoodSwingsLevel] = useState(0);

  function onWeightChange(value) {
    actions.saveMonitorPregnancyState({
      weight: value,
      data: 'weight',
    });
    //setMonitorWeight(value);
  }
  function onSleepQualityChange(value) {
    actions.saveMonitorPregnancyState({
      sleep_quality: value,
      data: 'sleep',
    });
    setSleepQuality(value);
  }
  function onStressLevelChange(value) {
    actions.saveMonitorPregnancyState({
      stress_level: value,
      data: 'stress',
    });
    setStressLevel(value);
  }
  function onExercisePerDayChange(value) {
    actions.saveMonitorPregnancyState({
      exercise_level: value,
      data: 'activity',
    });
    setExercisePerDay(value);
  }
  function onComplicationsChange(value) {
    actions.saveMonitorPregnancyState({
      bleeding_from_vagina: value[0].checked,
      sudden_sweling: value[1].checked,
      fever: value[2].checked,
      abdomen_pain: value[3].checked,
      severe_headaches: value[4].checked,
      data: 'complications',
    });
    setComplications(value);
  }
  function onNauseaLevelChange(value) {
    actions.saveMonitorPregnancyState({
      nausea_level: value,
      data: 'nausea_level',
    });
    setNauseaLevel(value);
  }
  function onTiredLevelChange(value) {
    actions.saveMonitorPregnancyState({
      tired_level: value,
      data: 'tired_level',
    });
    setTiredLevel(value);
  }
  function onBackPainLevelChange(value) {
    actions.saveMonitorPregnancyState({
      back_pain_level: value,
      data: 'back_pain_level',
    });
    setBackPainLevel(value);
  }
  function onMoodSwingsLevelChange(value) {
    actions.saveMonitorPregnancyState({
      mood_swings_level: value,
      data: 'mood_swings_level',
    });
    setMoodSwingsLevel(value);
  }

  useEffect(() => {
    switch (props.question) {
      case 'monitorWeight':
        actions.saveMonitorPregnancyState({
          weight: monitorWeight,
          data: 'weight',
        });
        break;
      case 'monitorSleep':
        actions.saveMonitorPregnancyState({
          sleep_quality: sleepQuality,
          data: 'sleep',
        });
        break;
      case 'monitorStress':
        actions.saveMonitorPregnancyState({
          stress_level: stressLevel,
          data: 'stress',
        });
        break;
      case 'monitorActivityLevel':
        actions.saveMonitorPregnancyState({
          exercise_level: exercisePerDay,
          data: 'activity',
        });
        break;
      case 'monitorComplications':
        actions.saveMonitorPregnancyState({
          bleeding_from_vagina: complications[0].checked,
          sudden_sweling: complications[1].checked,
          fever: complications[2].checked,
          abdomen_pain: complications[3].checked,
          severe_headaches: complications[4].checked,
          data: 'complications',
        });
        break;
      default:
        return null;
    }
  }, [monitorWeight, sleepQuality, stressLevel, exercisePerDay, complications]);

  useEffect(() => {
    switch (props.step) {
      case 0:
        actions.saveMonitorPregnancyState({
          nausea_level: nauseaLevel,
          data: 'nausea_level',
        });
        break;
      case 1:
        actions.saveMonitorPregnancyState({
          tired_level: tiredLevel,
          data: 'tired_level',
        });
        break;
      case 2:
        actions.saveMonitorPregnancyState({
          back_pain_level: backPainLevel,
          data: 'back_pain_level',
        });
        break;
      case 3:
        actions.saveMonitorPregnancyState({
          mood_swings_level: moodSwingsLevel,
          data: 'mood_swings_level',
        });
        break;
      default:
        return null;
    }
  }, [nauseaLevel, tiredLevel, backPainLevel, moodSwingsLevel]);

  useEffect(() => {
    let data;
    switch (props.step) {
      case 1:
        data = 'nausea_level';
        break;
      case 2:
        data = 'tired_level';
        break;
      case 3:
        data = 'back_pain_level';
        break;
      case 4:
        data = 'mood_swings_level';
        break;
      default:
        data = '';
        break;
    }
    actions.saveWeeklyCheckinState({
      nausea_level: nauseaLevel,
      tired_level: tiredLevel,
      back_pain_level: backPainLevel,
      mood_swings_level: moodSwingsLevel,
      data: data,
      date: formatDate(new Date(), 'YYYY-MM-DD'),
    });
  }, [props.step]);

  const renderQuestion = (question) => {
    switch (question) {
      case 'monitorWeight':
        return (
          <MonitorWeightQuestion
            onChange={(value) => onWeightChange(value)}
            weight={monitorWeight}
          />
        );
      case 'monitorSleep':
        return (
          <SleepQuestion
            title={t('home.weeklyCheckinQuestions.sleepQuestion.title')}
            subtitle={t('home.weeklyCheckinQuestions.sleepQuestion.subtitle')}
            data={getSleep('white')}
            selectedIllustrationIndex={sleepQuality}
            onChange={(index) => onSleepQualityChange(index)}
            theme={'white'}
          />
        );
      case 'monitorStress':
        return (
          <StressQuestion
            title={t('home.weeklyCheckinQuestions.stressQuestion.title')}
            subtitle={t('home.weeklyCheckinQuestions.stressQuestion.subtitle')}
            data={getStress('white')}
            selectedIllustrationIndex={stressLevel}
            onChange={(index) => onStressLevelChange(index)}
            theme={'white'}
          />
        );
      case 'monitorActivityLevel':
        return (
          <ExercisePerDayQuestion
            title={t('home.weeklyCheckinQuestions.exerciseQuestion.title')}
            subtitle={t(
              'home.weeklyCheckinQuestions.exerciseQuestion.subtitle'
            )}
            exerciseLevel={exercisePerDay}
            onChange={(index) => onExercisePerDayChange(index)}
            data={getExercise('white')}
            theme={'white'}
          />
        );
      case 'monitorComplications':
        return (
          <MonitorComplicationsQuestion
            onChange={(value) => onComplicationsChange(value)}
            selectedComplications={[]}
            complications={complications}
          />
        );
      case 'monitorPregnancySymptoms':
        switch (props.step) {
          case 0:
            return (
              <NauseaQuestion
                title={t('home.weeklyCheckinQuestions.nauseaQuestion.title')}
                selectedIllustrationIndex={nauseaLevel}
                data={getNausea()}
                onChange={(index) => onNauseaLevelChange(index)}
              />
            );
          case 1:
            return (
              <ExhaustionQuestion
                title={t('home.weeklyCheckinQuestions.tiredQuestion.title')}
                selectedIllustrationIndex={tiredLevel}
                data={getExhaustion()}
                onChange={(index) => onTiredLevelChange(index)}
              />
            );
          case 2:
            return (
              <BackPainQuestion
                title={t('home.weeklyCheckinQuestions.backPainQuestion.title')}
                selectedIllustrationIndex={backPainLevel}
                data={getAchingBack()}
                onChange={(index) => onBackPainLevelChange(index)}
              />
            );
          case 3:
            return (
              <MoodSwingsQuestion
                title={t(
                  'home.weeklyCheckinQuestions.moodSwingsQuestion.title'
                )}
                selectedIllustrationIndex={moodSwingsLevel}
                data={getMoodSwings()}
                onChange={(index) => onMoodSwingsLevelChange(index)}
              />
            );
          default:
            return <></>;
        }
      default:
        return <></>;
    }
  };

  return (
    <>
      {props.question === 'monitorPregnancySymptoms' ? (
        <Slider
          isForward={overmindState.forwardNavigation}
          index={overmindState.progressStep}
        >
          {renderQuestion('monitorPregnancySymptoms')}
        </Slider>
      ) : (
        renderQuestion(props.question)
      )}
    </>
  );
}
