import React from 'react';

export default function HorizontalLine(props) {
  return (
    <hr
      style={{
        margin: props.margin,
        border: props.border,
      }}
    />
  );
}
