import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import Question from 'components/organisms/Inputs/Question/Question';

export default function BackPainQuestion(props) {
  const handleChange = (index) => {
    props.onChange && props.onChange(index);
  };

  return (
    <Question title={props.title}>
      <Box px={2.5} mt={2}>
        <IllustratedSlider
          id="illustrated-slider-sleep"
          illustrations={props.data}
          theme={'white'}
          selectedIllustrationIndex={
            props.selectedIllustrationIndex
              ? props.selectedIllustrationIndex
              : 0
          }
          onValueChanged={handleChange}
        />
      </Box>
    </Question>
  );
}
