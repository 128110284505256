import React from 'react';
import PropTypes from 'prop-types';

import MuiRadio from '@material-ui/core/Radio';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

/**
 * Default Radio component based on https://material-ui.com/components/radio-buttons/#radio
 * @param {} props All possible props can be found in docs https://material-ui.com/api/radio/
 */

export default function Radio(props) {
  let smallIcon = {
    width: 20,
    height: 20,
  };

  let mediumIcon = {
    width: 25,
    height: 25,
  };

  const setSize = (size) => {
    if (size === 'medium') {
      return mediumIcon;
    } else if (typeof size === 'number') {
      return { width: size, height: size };
    } else {
      return smallIcon;
    }
  };

  return (
    <MuiRadio
      icon={
        <RadioButtonUncheckedIcon
          style={setSize(props.size)}
          color={props.colorUnchecked}
        />
      }
      checkedIcon={<RadioButtonCheckedIcon style={setSize(props.size)} />}
      {...props}
    />
  );
}

Radio.propTypes = {
  checked: PropTypes.bool,
};
