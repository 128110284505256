import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { useAccordionDetailsStyles } from './accordionStyles';

export default function Accordion(props) {
  const { children, ...rest } = props;
  const classes = useAccordionDetailsStyles(props);

  return (
    <MuiAccordionDetails classes={{ ...classes }} {...rest}>
      {children}
    </MuiAccordionDetails>
  );
}
