import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import MultiselectionQuestion from 'components/organisms/Questions/Pregnancy/MultiselectionQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function HaveYouBeenDiagnosed(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const selected =
    overmindState.diagnosedWith == null ? [] : overmindState.diagnosedWith;

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <MultiselectionQuestion
          title={t('calculator.haveYouBeenDiagnosed.question')}
          subtitle={t('calculator.haveYouBeenDiagnosed.subtitle')}
          data={[
            {
              label: t('calculator.haveYouBeenDiagnosed.answer1'),
              value: '1',
              checked: selected.includes('1'),
            },
            {
              label: t('calculator.haveYouBeenDiagnosed.answer2'),
              value: '2',
              checked: selected.includes('2'),
            },
            {
              label: t('calculator.haveYouBeenDiagnosed.answer3'),
              value: '3',
              checked: selected.includes('3'),
            },
            {
              label: t('calculator.haveYouBeenDiagnosed.answer4'),
              value: '4',
              checked: selected.includes('4'),
            },
            {
              label: t('calculator.haveYouBeenDiagnosed.answer5'),
              value: '5',
              checked: selected.includes('5'),
            },
            {
              label: t('calculator.haveYouBeenDiagnosed.answer6'),
              value: '6',
              checked: selected.includes('6'),
            },
          ]}
          onQuestionAnswered={(value) => {
            actions.updateStateProp({
              key: 'diagnosedWith',
              value: value.filter((v) => v.checked).map((v) => v.value),
              nextEnabled: true,
            });
          }}
          deselectAllValue="6"
        />
      </GridItem>
    </GridContainer>
  );
}
