import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function Instructions(props) {
  const displayInstructions = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ margin: '20px' }}>
          <ol style={{ marginRight: '20px' }}>
            {props.instructions.map((instruction, ix) => (
              <li key={ix}>
                <Typography
                  variant="body2"
                  style={{ color: 'black', paddingBottom: '4px' }}
                >
                  {instruction}
                </Typography>
              </li>
            ))}
          </ol>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        {props.instructions && props.instructions.recipeUrl != null && (
          <GridItem xs={12} style={{ margin: '20px 0' }}>
            <Typography
              variant="body1"
              style={{ textAlign: 'left', margin: '0 5%' }}
            >
              <a
                href={props.instructions.recipeUrl}
                target="_blank"
                rel="noreferrer"
              >
                {props.instructions.displayName}
              </a>
            </Typography>
          </GridItem>
        )}
        {props.instructions.recipeUrl == null &&
          props.instructions.length > 0 &&
          displayInstructions()}
      </GridItem>
    </GridContainer>
  );
}
