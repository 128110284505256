import { makeStyles } from '@material-ui/core';

export const useLandingModalStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    backgroundColor: 'white',
    border: '1px solid white',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
  },
  contentContainer: {
    maxWidth: 300,
    marginTop: 18.5,
    paddingLeft: 17.5,
    paddingRight: 17.5,
    paddingBottom: 22.5,
    textAlign: 'center',
  },
  content: {
    wordWrap: 'break-word',
    fontFamily: 'MuseoSans-500',
    fontSize: '14px',
    lineHeight: '18px',
    userSelect: 'none',
  },
  alertIcon: {
    textAlign: 'center',
  },
  closeAlertIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: 10.5,
    marginTop: 12,
    cursor: 'pointer',
  },
}));
