import React, { useEffect, useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useFooterMenuStyles } from './footerMenuStyles';
import PropTypes from 'prop-types';
import { useOvermindState, useActions } from 'overmind/index';
import PaymentDialogHome from 'components/organisms/PaymentDialog/PaymentDialogHome';
import { ROUTE_MEAL_PLAN } from 'routes';

export default function FooterMenu(props) {
  const classes = useFooterMenuStyles();
  const overmindState = useOvermindState();
  const actions = useActions();
  const [value, setValue] = React.useState(
    props.selectedValue != null ? props.selectedValue : 0
  );
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (
      overmindState.subscription.status == null &&
      !overmindState.subscription.isBeingFetched
    ) {
      actions.fetchSubscriptionStatus();
    }
  }, [actions]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (!overmindState.subscription.status && newValue === 1) {
      setPaymentDialogOpened(true);
    } else {
      props.onSelected && props.onSelected(newValue);
    }
  };

  return props.hideFooter ? (
    <></>
  ) : (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => handleChange(event, newValue)}
        showLabels
        className={classes.root}
      >
        {props.menuOptions.map((menuOption, ix) => {
          return (
            <BottomNavigationAction
              classes={{
                root: classes.labelRoot,
                label: classes.label,
              }}
              {...menuOption}
              key={ix}
            />
          );
        })}
      </BottomNavigation>
      <PaymentDialogHome
        openDialog={paymentDialogOpened}
        onCloseDialog={() => setPaymentDialogOpened(false)}
        redirectState={{ initiator: ROUTE_MEAL_PLAN }}
      />
    </>
  );
}

FooterMenu.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      showLabel: PropTypes.bool,
      value: PropTypes.number,
    })
  ),
  onSelected: PropTypes.func,
  selectedValue: PropTypes.number,
};
