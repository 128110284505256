import React, { useState } from 'react';

import Micronutrient from './Micronutrient';

export default function MineralsMicronutrientsPage(props) {
  const [expanded, setExpanded] = useState('-');
  const isMineral = (micronutrient, groups) => {
    return groups && groups.minerals.includes(micronutrient.code);
  };

  const getMinerals = (micronutrients) => {
    return micronutrients.items.filter((micronutrient) =>
      isMineral(micronutrient, micronutrients.groups)
    );
  };

  const { micronutrients } = props;

  return (
    micronutrients &&
    micronutrients.items &&
    getMinerals(micronutrients).map((micronutrient, ix) => (
      <>
        {isMineral(micronutrient, micronutrients.groups) && (
          <Micronutrient
            index={'minerals' + ix}
            {...micronutrient}
            onNewExpanded={(newExpanded) => setExpanded(newExpanded)}
            expanded={expanded}
          />
        )}
      </>
    ))
  );
}
