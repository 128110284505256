import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';
import { formatDate, getDate } from 'components/helpers/dateHelper';
import { getBDD } from 'components/helpers/dueDateCalcHelper';
import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import Button from 'components/atoms/Inputs/Buttons/Button';
import DueDateBarChart from 'components/shared/CustomIcons/DueDateBarChart';
import {
  trackEvent,
  /*trackGoogleAdsConversionEvent,*/
} from 'components/helpers/userTrackingEventsHelper';

export default function DueDateResults(props) {
  const classes = usePageStyles(props);
  const overmindState = useOvermindState();
  const actions = useActions();

  useEffect(() => {
    trackEvent('Lead');
    //trackGoogleAdsConversionEvent();
    if (
      overmindState.bdd == null &&
      (overmindState.lastPeriod != null ||
        (overmindState.lastPeriodDateEnd != null &&
          overmindState.lastPeriodDateStart != null) ||
        overmindState.lastPeriodDate != null ||
        overmindState.dateOfTransfer != null ||
        (overmindState.conceivedDateEnd != null &&
          overmindState.conceivedDateStart != null))
    ) {
      actions.updateStateProp({
        key: 'bdd',
        value: getBDD(),
      });
    }
    const seenCalcDueDate =
      window.localStorage.getItem('seenCalcDueDate') == null;
    if (seenCalcDueDate) {
      window.localStorage.setItem('seenCalcDueDate', 'true');
    }
  }, []);

  const getDueDateString = () => {
    let ddParts = overmindState.dueDate.split('-');
    return `${ddParts[1]}/${ddParts[2]}/${ddParts[0]}`;
  };

  useEffect(() => {
    if (
      overmindState.dueDate != null &&
      !overmindState.savedEmailToMailchimp &&
      overmindState.email != null
    ) {
      actions.saveEmail({
        email: overmindState.email,
        first_name: overmindState.metaData.user_metadata.firstName,
        due_date: getDueDateString(),
      });
    }
  }, [overmindState.dueDate]);

  const getBDD = () => {
    if (
      overmindState.conceptionExperience === '2' &&
      overmindState.dateOfTransfer != null
    ) {
      let dateOfTransferFormatted = getDate(overmindState.dateOfTransfer);
      let dd = dateOfTransferFormatted.clone();
      return getPDD(dd);
    } else {
      if (
        overmindState.lastPeriod === '1' &&
        overmindState.lastPeriodDate != null
      ) {
        let dueDateFormatted = getDate(overmindState.lastPeriodDate);
        let dd = dueDateFormatted.clone();
        dd.add(overmindState.cycleLength, 'days');
        return getPDD(dd);
      } else if (
        overmindState.lastPeriod === '2' &&
        overmindState.lastPeriodDateEnd != null &&
        overmindState.lastPeriodDateStart != null
      ) {
        let lastPeriodDateEndFormatted = getDate(
          overmindState.lastPeriodDateEnd
        );
        let diff = lastPeriodDateEndFormatted.diff(
          overmindState.lastPeriodDateStart,
          'days'
        );
        let lastPeriodDateStartFormatted = getDate(
          overmindState.lastPeriodDateStart
        );
        let dd = lastPeriodDateStartFormatted.clone();
        dd.add(diff / 2, 'days');
        dd.add(overmindState.cycleLength, 'days');
        return getPDD(dd);
      } else if (
        overmindState.lastPeriod === '3' &&
        overmindState.conceivedDateEnd != null &&
        overmindState.conceivedDateStart != null
      ) {
        let conceivedDateEndFormatted = getDate(overmindState.conceivedDateEnd);
        let diff = conceivedDateEndFormatted.diff(
          overmindState.conceivedDateStart,
          'days'
        );
        let conceivedDateStartFormatted = getDate(
          overmindState.conceivedDateStart
        );
        let dd = conceivedDateStartFormatted.clone();
        dd.add(diff / 2, 'days');
        dd.add(overmindState.cycleLength, 'days');
        return getPDD(dd);
      } else {
        let dateOfTransferFormatted = getDate(overmindState.dateOfTransfer);
        let dd = dateOfTransferFormatted.clone();
        return getPDD(dd);
      }
    }
  };

  const getPDD = (dd) => {
    if (overmindState.firstBabyWeek != null) {
      if (overmindState.firstBabyWeek === '1') {
        dd.subtract(14, 'days');
      } else if (overmindState.firstBabyWeek === '2') {
        dd.subtract(10, 'days');
      } else if (overmindState.firstBabyWeek === '3') {
        dd.subtract(5, 'days');
      } else if (overmindState.firstBabyWeek === '5') {
        dd.add(5, 'days');
      } else if (overmindState.firstBabyWeek === '6') {
        dd.add(10, 'days');
      }
    }

    if (overmindState.postTermPregnancy === '1') {
      dd.add(7, 'days');
    }

    if (
      overmindState.diagnosedWith != null &&
      overmindState.diagnosedWith.filter((d) => d !== '6').length > 0
    ) {
      const len = overmindState.diagnosedWith.filter((d) => d !== '6').length;
      dd.add(len === 1 ? 4 : len === 2 ? 7 : len >= 3 ? 10 : 0, 'days');
    }

    if (overmindState.smokingHabits != null) {
      const smoking = overmindState.smokingHabits;
      dd.subtract(
        smoking === '3' ? 3 : smoking === '4' ? 7 : smoking === '5' ? 7 : 0,
        'days'
      );
    }

    if (overmindState.stressLevel != null) {
      const stress = overmindState.stressLevel;
      dd.subtract(
        stress === '3' ? 3 : stress === '4' ? 7 : stress === '5' ? 7 : 0,
        'days'
      );
    }

    dd.add(252, 'days');
    let dueDate = formatDate(dd, 'MMM Do YYYY');
    actions.updateStateProp({
      key: 'dueDate',
      value: dd.format('YYYY-MM-DD'),
      nextEnabled: true,
    });

    return {
      dueDate: dd,
      formatedDueDate: dueDate,
      d1: formatDate(dd.clone().subtract(14, 'days'), 'MM/DD/YY'),
      d2: formatDate(dd.clone().subtract(7, 'days'), 'MM/DD/YY'),
      d3: formatDate(dd, 'MM/DD/YY'),
      d4: formatDate(dd.clone().add(7, 'days'), 'MM/DD/YY'),
      d5: formatDate(dd.clone().add(14, 'days'), 'MM/DD/YY'),
    };
  };

  const getMonthAndDay = (dd) => {
    const els = dd.split('/');
    return els[0] + '/' + els[1];
  };

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {/*<GridItem
        style={{
          backgroundImage: `url('${resultsCircle}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'auto',
          transition: 'all 0.5s ease',
          height: '300px',
          width: '320px',
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginTop: '120px',
            marginLeft: '20px',
            marginRight: '20px',
            color: 'white',
            fontWeight: 'normal',
            textAlign: 'center',
            lineHeight: '24px',
          }}
        >
          {"There's a "}
          {<span style={{ fontWeight: 'bold' }}>70%</span>}
          {' your baby will arrive by'}
          <br />
          {'['}
          <span style={{ fontWeight: 'bold' }}>{getBDD().formatedDueDate}</span>
          {']'}
        </Typography>
      </GridItem>*/}

      {/*<GridItem
        style={{
          textAlign: 'center',
          marginTop: '50px',
          marginLeft: '50px',
          marginRight: '50px',
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: 'normal', textTransform: 'uppercase' }}
        >
          {t('calculator.dueDateResults.3daysText')}
        </Typography>
      </GridItem>
      <GridItem style={{ marginTop: '10px' }}></GridItem>*/}

      <GridItem
        style={{
          textAlign: 'center',
          marginLeft: '50px',
          marginRight: '50px',
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: 'normal', fontSize: '25px', color: '#977AB7' }}
        >
          {'Congrats Mom!'}
        </Typography>
      </GridItem>
      <GridItem
        style={{
          textAlign: 'center',
          marginLeft: '30px',
          marginRight: '30px',
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: 'normal', fontSize: '20px', color: '#525060' }}
        >
          {'Here are your likeliest due dates!'}
        </Typography>
      </GridItem>
      <GridItem style={{ marginTop: '45px', marginRight: '20px' }}>
        {overmindState.bdd && (
          <DueDateBarChart
            dueDate={overmindState.bdd.formatedDueDate}
            d1={overmindState.bdd.d1}
            d2={overmindState.bdd.d2}
            d3={overmindState.bdd.d3}
            d4={overmindState.bdd.d4}
            d5={overmindState.bdd.d5}
          />
        )}
      </GridItem>

      {overmindState.bdd && (
        <GridItem
          style={{
            textAlign: 'center',
            marginTop: '30px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: 'normal',
              fontSize: '16px',
              color: '#525060',
              lineHeight: '18px',
            }}
          >
            {'There is a:'}
            <br />
            <br />
            {`25% chance your baby's birthday will be ${getMonthAndDay(
              overmindState.bdd.d2
            )}`}
            <br />
            {`35% chance your baby's birthday will be ${getMonthAndDay(
              overmindState.bdd.d3
            )}`}
            <br />
            {`20% chance your baby's birthday will be ${getMonthAndDay(
              overmindState.bdd.d4
            )}`}
          </Typography>
        </GridItem>
      )}

      <GridItem style={{ marginTop: '40px' }}>
        <Box p={{ xs: 2, sm: 3, md: 4 }} justifyContent="center" display="flex">
          <Button
            component="span"
            variant="contained"
            onClick={() => {
              trackEvent('PregnancyCalc', 'Redirected to 9moons');
              trackEvent('Onboarding', 'Finished');
              actions.saveOnboardedEmail({
                email: overmindState.email,
                first_name: overmindState.metaData.user_metadata.firstName,
                due_date: getDueDateString(),
                finishOnboarding: true,
              });
            }}
            style={{ backgroundColor: '#977AB7' }}
          >
            {'Next'}
          </Button>
        </Box>
      </GridItem>
    </GridContainer>
  );
}
