import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';

const cookiesConsent = window.localStorage.getItem('cookies_consent');

const isLocalhost = () => {
  const location = window.location;
  return location.host.indexOf('localhost') !== -1;
  // uncomment line below and comment line above to test locally, also add variables to .env.development
  // return false;
};

const isTrackingForbidden = () => {
  return isLocalhost() || cookiesConsent == null || cookiesConsent === 'false';
};

const getGoogleAnalyticsId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GA_ID;
};

const getFacebookPixelId = () => {
  if (isLocalhost()) {
    // no FB Pixel id for localhost
    return null;
  }

  if (process.env.REACT_APP_FBP_ID) {
    return process.env.REACT_APP_FBP_ID;
  }

  return '547785746376860';
};

const getGoogleAdsId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GADS_ID;
};

const getGoogleAdsSendToId = () => {
  if (isLocalhost()) {
    return null;
  }

  return process.env.REACT_APP_GADS_SENDTO_ID;
};

/**
 * Initializes tracking.
 */
export const initializeTracking = () => {
  const gaId = getGoogleAnalyticsId();

  if (gaId != null) {
    ReactGA.initialize(gaId);
  }

  const pixelId = getFacebookPixelId();
  if (pixelId != null) {
    ReactPixel.init(pixelId, null, {
      autoConfig: true,
      debug: isLocalhost(),
    });
  }
};

export const initializeMixpanelTracking = () => {
  mixpanel.init('0f694a170e5fef95636090ba1391dc1d', {
    batch_requests: true,
    debug: isLocalhost(),
  });
};

/**
 * Tracks visit to the page.
 * @param {string} pagePath Path of the tracked page.
 */
export const trackPageVisit = (pagePath) => {
  if (isTrackingForbidden()) {
    return;
  }
  mixpanel.track('PageView', { page: pagePath });
  ReactGA.pageview(pagePath);
};

export const trackEvent = (category, action, data) => {
  if (isTrackingForbidden()) {
    return;
  }
  mixpanel.track(category, { action: action, ...data });
  trackGoogleEvent(category, action);
};

export const trackGoogleAdsConversionEvent = () => {
  if (isTrackingForbidden()) {
    return;
  }
  const adsId = getGoogleAdsId();
  const sendToId = getGoogleAdsSendToId();
  if (!adsId || !sendToId) {
    return;
  }

  window.gtag('config', adsId);
  window.gtag('event', 'conversion', { send_to: sendToId });
};

/**
 * Tracks Google events specified with category and action.
 * @param {string} category Event's category
 * @param {string} action Event's action
 */
const trackGoogleEvent = (category, action) => {
  ReactGA.event({
    category: category,
    action: action,
  });
};

export const trackFbPixelEvent = (event) => {
  ReactPixel.track(event);
};
