import React, { useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import HeightQuestion from 'components/organisms/Questions/General/HeightQuestion';
import WeightQuestionObsolete from 'components/organisms/Questions/General/WeightQuestionObsolete';

import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import { lbsToKg } from 'components/helpers/converterHelper';

export default function BMIPage(props) {
  const classes = usePageStyles(props);
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();

  const [weight, setWeight] = useState(
    overmindState.metaData.user_metadata.selectedWeightMetric === 1
      ? overmindState.metaData.user_metadata.weightLbs
      : overmindState.profile.weight
      ? Math.round(overmindState.profile.weight)
      : null
  );

  const [height, setHeight] = useState(
    overmindState.profile.height
      ? Math.floor(overmindState.profile.height)
      : null
  );

  const [weightIsInLbs, setWeightIsInLbs] = useState(
    overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? false
      : true
  );

  const [heightCheckedInFeet, setHeightCheckedInFeet] = useState(
    overmindState.metaData.user_metadata.selectedHeightMetric === 2
      ? false
      : true
  );

  const radioButtonsHeight = [
    {
      label: t('onboarding.generalSection.step3.ft'),
      value: 1,
      checked: heightCheckedInFeet,
    },
    {
      label: t('onboarding.generalSection.step3.cm'),
      value: 2,
      checked: !heightCheckedInFeet,
    },
  ];

  const radioButtonsWeight = [
    {
      label: t('onboarding.generalSection.step3.lbs'),
      value: 1,
      checked: weightIsInLbs,
    },
    {
      label: t('onboarding.generalSection.step3.kg'),
      value: 2,
      checked: !weightIsInLbs,
    },
  ];

  const onHeightChange = (data) => {
    setHeight(data.height);
    setHeightCheckedInFeet(data.selectedHeightMetric === 1);
    actions.BMISubmitted({
      weight: weight,
      height: height,
      enableForward: isForwardEnabled(),
      heightFt: data.heightFt,
      heightIn: data.heightIn,
      selectedWeightMetric: weightIsInLbs ? 1 : 2,
      selectedHeightMetric: data.selectedHeightMetric,
    });
  };

  const onWeightChange = (data) => {
    setWeight(data.weight);
    setWeightIsInLbs(data.selectedWeightMetric === 1);
    actions.BMISubmitted({
      weight: weight,
      height: height,
      enableForward: isForwardEnabled(),
      selectedWeightMetric: data.selectedWeightMetric,
      selectedHeightMetric: heightCheckedInFeet ? 1 : 2,
    });
  };

  const getWeightInKg = () => {
    return overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? overmindState.profile.weight
      : lbsToKg(overmindState.metaData.user_metadata.weightLbs);
  };

  const isForwardEnabled = () => {
    const weightInKg = getWeightInKg();
    return (
      weightInKg >= 40 && weightInKg <= 160 && height >= 120 && height <= 230
    );
  };

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <HeightQuestion
        title={t('onboarding.generalSection.step3.height')}
        data={radioButtonsHeight}
        height={overmindState.profile.height}
        heightFt={overmindState.metaData.user_metadata.heightFt}
        heightIn={overmindState.metaData.user_metadata.heightIn}
        selectedHeightMetric={
          overmindState.metaData.user_metadata.selectedHeightMetric
        }
        onChange={onHeightChange}
      />

      <WeightQuestionObsolete
        title={t('onboarding.generalSection.step3.weight')}
        data={radioButtonsWeight}
        weight={
          overmindState.metaData.user_metadata.selectedWeightMetric === 2
            ? getWeightInKg()
            : overmindState.metaData.user_metadata.weightLbs
        }
        selectedWeightMetric={
          overmindState.metaData.user_metadata.selectedWeightMetric
        }
        onChange={onWeightChange}
      />
    </GridContainer>
  );
}
