import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import SingleSelectionQuestion from 'components/organisms/Questions/Pregnancy/SingleSelectionQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';
import { formatDate } from 'components/helpers/dateHelper';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';
import Typography from 'components/atoms/Typography/Typography';
import { Box } from '@material-ui/core';
import { ContactsOutlined } from '@material-ui/icons';

export default function DoYouKnowWhenConceived(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [question1Value, setQuestion1Value] = useState(
    overmindState.doYouKnowWhenConceived == null
      ? undefined
      : overmindState.doYouKnowWhenConceived
  );

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <SingleSelectionQuestion
          title={t('calculator.doYouKnowWhenConceived.question')}
          data={[
            {
              label: t('calculator.doYouKnowWhenConceived.option1'),
              value: '1',
              checked: question1Value === '1',
            },
            {
              label: t('calculator.doYouKnowWhenConceived.option2'),
              value: '2',
              checked: question1Value === '2',
            },
          ]}
          onChange={(value) => {
            setQuestion1Value(value);
            actions.updateStateProp({
              key: 'doYouKnowWhenConceived',
              value: value,
              nextEnabled: true,
            });
          }}
          offset={100}
        />
      </GridItem>
      {question1Value === '1' && (
        <GridItem>
          <GridContainer
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <GridItem xs={12} style={{ marginBottom: '20px' }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4" fontWeight="bold">
                  It was sometime between
                </Typography>
              </Box>
            </GridItem>
            <GridItem xs={6}>
              <DatePicker
                style={{ paddingLeft: '20px', paddingRight: '5px' }}
                disableFuture={true}
                format="YYYY/MM/DD"
                id="last-period-input-start"
                value={
                  overmindState.conceivedDateStart
                    ? overmindState.conceivedDateStart
                    : null
                }
                onChange={(e) => {
                  actions.updateStateProp({
                    key: 'conceivedDateStart',
                    value: e,
                    nextEnabled:
                      overmindState.conceivedDateEnd != null &&
                      e != null &&
                      overmindState.conceivedDateEnd.diff(e, 'days') > 0,
                  });
                }}
                inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
              />
            </GridItem>
            <GridItem xs={6}>
              <DatePicker
                style={{ paddingLeft: '5px', paddingRight: '10px' }}
                disableFuture={true}
                format="YYYY/MM/DD"
                id="last-period-input-start"
                value={
                  overmindState.conceivedDateEnd
                    ? overmindState.conceivedDateEnd
                    : null
                }
                onChange={(e) => {
                  actions.updateStateProp({
                    key: 'conceivedDateEnd',
                    value: e,
                    nextEnabled:
                      overmindState.conceivedDateStart != null &&
                      e != null &&
                      e.diff(overmindState.conceivedDateStart, 'days') > 0,
                  });
                }}
                inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      )}
      {question1Value === '2' && (
        <GridContainer
          style={{ marginBottom: '24px' }}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <GridItem
            xs={12}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              marginTop: '8px',
              marginBottom: '8px',
              textAlign: 'center',
              maxWidth: '300px',
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {t('calculator.doYouKnowWhenConceived.option2Explanation')}
            </Typography>
          </GridItem>
          <GridItem
            xs={12}
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              marginTop: '8px',
              marginBottom: '8px',
              textAlign: 'center',
              maxWidth: '300px',
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {t('calculator.doYouKnowWhenConceived.option2Nudge')}
            </Typography>
          </GridItem>
        </GridContainer>
      )}
    </GridContainer>
  );
}
