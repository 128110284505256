import { makeStyles } from '@material-ui/core';
import { APP_TEXT_HINT } from 'themes';

export const useChipStyles = makeStyles((theme) => ({
  chip: {
    padding: '1.5px 10px 2.5px 8px',
    borderRadius: '6.5px',
    border: 'solid 0.5px ' + APP_TEXT_HINT,
    marginRight: '4px',
    marginBottom: '4px',
    color: APP_TEXT_HINT,
    fontSize: '13px',
  },
  hint: {
    color: APP_TEXT_HINT,
  },
}));
