import React, { useState } from 'react';
import { useOvermindState } from 'overmind/index';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import FilledCircleIcon from 'components/atoms/Layout/FilledCircleIcon';
import { makeStyles } from '@material-ui/core';

import Backdrop from '@material-ui/core/Backdrop';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Typography from 'components/atoms/Typography/Typography';
import ModalDialog from 'components/organisms/ModalWindow/WeeklyCheckinDialog';
import { useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: '80px',
    right: '20px',
    zIndex: 9999,
  },
  fab: {
    width: '65px',
    height: '65px',
  },
  addComponent: {
    transition: theme.transitions.create(['transform'], {
      duration: '200ms',
    }),
  },
  addOpened: {
    width: '50px',
    height: '50px',
    transform: 'rotate(45deg)',
  },
  add: {
    width: '50px',
    height: '50px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#FFFFFFEE',
  },
}));

export default function FABMenu(props) {
  const classes = useStyles();
  const actions = useActions();
  const overmindState = useOvermindState();
  const [backdropOpened, setBackdropOpened] = useState(false);
  const [modalWindowOpened, setModalWindowOpened] = useState(false);
  const [modalWindowType, setModalWindowType] = useState();

  const handleModalWindow = async (opened, type) => {
    if (opened) {
      trackEvent('FABMenu option', 'Clicked', {
        option: type,
      });

      if (type === 'monitorWeight') {
        await actions.fetchMonitorPregnancyWeight().then(() => {
          setModalWindowOpened(opened);
        });
      } else if (type === 'monitorStress') {
        await actions.fetchMonitorPregnancyStress().then(() => {
          setModalWindowOpened(opened);
        });
      } else if (type === 'monitorSleep') {
        await actions.fetchMonitorPregnancySleep().then(() => {
          setModalWindowOpened(opened);
        });
      } else if (type === 'monitorActivityLevel') {
        await actions.fetchMonitorPregnancyActivityLevel().then(() => {
          setModalWindowOpened(opened);
        });
      } else if (type === 'monitorComplications') {
        if (overmindState.monitorPregnancy.complications === undefined) {
          await actions.fetchMonitorPregnancyComplications().then(() => {
            setModalWindowOpened(opened);
          });
        } else {
          setModalWindowOpened(opened);
        }
      } else if (type === 'monitorPregnancySymptoms') {
        setModalWindowOpened(opened);
      }
      setModalWindowType(type);
    } else {
      setModalWindowType(null);
      setModalWindowOpened(opened);
    }
  };

  const handleFABWindow = (opened) => {
    trackEvent('FABMenu', 'Clicked', {
      opened: opened,
    });

    if (!opened) {
      setModalWindowOpened(false);
      setModalWindowType(null);
    }
    setBackdropOpened(opened);
  };

  return (
    <GridContainer direction="column" className={classes.component} style={{}}>
      <Backdrop className={classes.backdrop} open={backdropOpened}>
        <GridContainer
          direction="column"
          style={{
            position: 'absolute',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          {modalWindowType ? (
            <ModalDialog
              type={modalWindowType}
              opened={modalWindowOpened}
              step={0}
              onClose={() => handleModalWindow(false)}
            />
          ) : (
            <GridItem xs={12}>
              <GridContainer
                direction="row"
                style={{ marginBottom: '20px' }}
                onClick={() =>
                  handleModalWindow(true, 'monitorPregnancySymptoms')
                }
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#961a7e'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Pregnancy symptoms</Typography>
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                style={{ marginBottom: '20px' }}
                onClick={() => handleModalWindow(true, 'monitorSleep')}
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#879ae5'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Sleep</Typography>
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                style={{ marginBottom: '20px' }}
                onClick={() => handleModalWindow(true, 'monitorStress')}
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#e68a67'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Stress</Typography>
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                style={{ marginBottom: '20px' }}
                onClick={() => handleModalWindow(true, 'monitorActivityLevel')}
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#bfd671'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Activity level</Typography>
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                style={{ marginBottom: '20px' }}
                onClick={() => handleModalWindow(true, 'monitorWeight')}
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#8ace84'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Weight</Typography>
                </GridItem>
              </GridContainer>
              <GridContainer
                direction="row"
                style={{}}
                onClick={() => handleModalWindow(true, 'monitorComplications')}
              >
                <GridItem xs={2} style={{}}>
                  <FilledCircleIcon color={'#ffbd41'} />
                </GridItem>
                <GridItem
                  xs={10}
                  style={{ paddingLeft: '40px', margin: 'auto 0' }}
                >
                  <Typography variant="body2">Complications</Typography>
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
        </GridContainer>
      </Backdrop>
      {!props.hideFabIcon && (
        <div className={classes.root}>
          <Fab
            color="primary"
            aria-label="add"
            size="medium"
            className={classes.fab}
            onClick={() => handleFABWindow(!backdropOpened)}
          >
            <AddIcon
              className={[
                classes.addComponent,
                backdropOpened ? classes.addOpened : classes.add,
              ].join(' ')}
              style={{ transform: [{ rotateX: '60deg' }] }}
            />
          </Fab>
        </div>
      )}
    </GridContainer>
  );
}
