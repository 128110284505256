import ReactApexChart from 'react-apexcharts';

export default function LineChart(props) {
  return (
    <ReactApexChart
      options={{
        chart: {
          animations: {
            enabled: false,
          },
          sparkline: { enabled: props.sparkline },
          type: 'line',
          height: props.height ? props.height : 250,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
            autoSelected: 'zoom',
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        yaxis: {
          labels: {
            show: props.yFormatter ? true : false,
            formatter: props.yFormatter,
          },
          min: props.min,
          max: props.max,
          tickAmount:
            props.max != null && props.min != null
              ? props.max - props.min
              : undefined,
        },
        xaxis: {
          type: 'category',
          labels: {
            show: true,
          },
          categories: props.categories,
          tickAmount: 5,
        },
        stroke: {
          curve: 'smooth',
        },
        colors: props.colors,
      }}
      series={props.data}
      height={props.height ? props.height : 250}
    />
  );
}
