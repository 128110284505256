import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import { useHomeStyles } from './HomeStyles';

import Skeleton from '@material-ui/lab/Skeleton';
import Meal from 'components/organisms/Meal/Meal';

export default function HomeRecipes(props) {
  const classes = useHomeStyles();

  return (
    <GridContainer
      direction="column"
      className={!props.isPremium && classes.component}
    >
      {props.recipesTitle && (
        <GridItem
          xs={12}
          style={{
            margin: '20px 0',
          }}
        >
          <Typography variant="body2" style={{ textTransform: 'uppercase' }}>
            {props.recipesTitle}
          </Typography>
        </GridItem>
      )}
      <GridContainer direction="row" style={{}}>
        {props.isLoaded &&
          [
            props.firstRecipe,
            props.secondRecipe,
            props.thirdRecipe,
            props.fourthRecipe,
          ]
            .filter((recipe) => recipe != null)
            .map((recipe, ix) => {
              return (
                <GridItem xs={props.thirdRecipe == null ? 6 : 3} key={ix}>
                  {
                    <Meal
                      recipe={recipe}
                      onClicked={
                        ix === 0
                          ? () =>
                              props.handleRecipeSelected &&
                              props.handleRecipeSelected(
                                recipe,
                                props.firstRecipeMealType,
                                props.firstRecipe.numberOfServings
                              )
                          : () =>
                              props.handleRecipeSelected &&
                              props.handleRecipeSelected(
                                recipe,
                                props.secondRecipeMealType,
                                props.secondRecipe.numberOfServings
                              )
                      }
                      largeImage={true}
                      parentComponent={'home'}
                      name={
                        ix === 0
                          ? props.firstRecipeMealType
                          : ix === 1
                          ? props.secondRecipeMealType
                          : ix === 2
                          ? props.thirdRecipeMealType
                          : props.fourthRecipeMealType
                      }
                      hideBookmarks={props.hideBookmarks}
                      isPremium={props.isPremium}
                    />
                  }
                </GridItem>
              );
            })}
        {props.isLoaded === false && (
          <Skeleton variant="rect" width={'100%'} height={150} />
        )}
      </GridContainer>
    </GridContainer>
  );
}
