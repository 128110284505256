import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import { useHomeStyles } from './HomeStyles';
import { useTranslation } from 'react-i18next';

export default function ScienceFact(props) {
  const classes = useHomeStyles();
  const { t } = useTranslation();

  return (
    <GridContainer
      direction="row"
      className={classes.scienceFactComponent}
      style={{
        paddingTop: '20px',
        paddingBottom: '20px',
      }}
    >
      <GridItem
        style={{
          marginBottom: '20px',
        }}
      >
        <Typography variant="body2">{t('home.scienceFact.title')}</Typography>
        <GridItem
          style={{
            marginBottom: '20px',
            marginTop: '20px',
          }}
        >
          {props.title && <Typography variant="h5">{props.title}</Typography>}
        </GridItem>
        {props.image && (
          <GridItem
            style={{
              marginTop: '20px',
            }}
          >
            <img
              src={props.image}
              alt="science_fact_image"
              style={{
                width: window.innerWidth < 800 ? '100%' : '500px',
                maxHeight: 500,
              }}
            />
          </GridItem>
        )}
      </GridItem>
      <GridItem>
        <Typography variant="body1">{props.text}</Typography>
      </GridItem>
    </GridContainer>
  );
}
