import React from 'react';

import Box from 'components/atoms/Layout/Box';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';
import Question from 'components/organisms/Inputs/Question/Question';

import { addDays, formatDate } from 'components/helpers/dateHelper';

export default function DueDateDatePickerQuestion(props) {
  const handleDatePickerChange = (date) => {
    props.onChange && props.onChange(date);
  };

  return (
    <Question title={props.title}>
      <Box
        display="flex"
        justifyContent="center"
        pt="18px"
        style={{ marginLeft: '12px', marginRight: '12px' }}
      >
        <DatePicker
          value={props.dueDate}
          disablePast
          format="YYYY/MM/DD"
          id="due-date-date-input"
          onChange={(e) => {
            handleDatePickerChange(e);
          }}
          inputProps={{ min: formatDate(new Date(), 'YYYY-MM-DD') }}
          maxDate={addDays(formatDate(new Date(), 'YYYY-MM-DD'), 240)}
        />
      </Box>
    </Question>
  );
}
