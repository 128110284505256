import React from 'react';
import PropTypes from 'prop-types';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

import SelectableIcon from 'components/molecules/Inputs/SelectableIcon/SelectableIcon';

export default function SelectableIconGroup(props) {
  const getSelectedValue = (items) => {
    const selectedItems = getSelectedValues(items);
    if (selectedItems == null || selectedItems.length === 0) {
      return null;
    }

    return selectedItems[0].value;
  };

  const getSelectedValues = (items) => {
    if (items == null) {
      return [];
    }

    const selectedItems = items.filter((i) => i.checked);
    if (selectedItems == null || selectedItems.length === 0) {
      return [];
    }

    return selectedItems.map((i) => i.value);
  };

  //e is not always event, sometimes it can be value
  const handleChange = (e, checked) => {
    if (e.target) {
      props.onChange && props.onChange(e.target.value);
    } else {
      let selectedValue = e;
      let vals = [];
      if (props.noneValue && selectedValue === props.noneValue && checked) {
        vals.push(selectedValue);
      } else {
        if (checked) {
          props.items.forEach((v) => {
            if (
              selectedValue !== props.noneValue &&
              v.checked &&
              v.value !== props.noneValue
            ) {
              vals.push(v.value);
            }
          });
          vals.push(selectedValue);
        } else {
          props.items.forEach((v) => {
            if (v.value !== selectedValue && v.checked) {
              vals.push(v.value);
            }
          });
        }
      }

      props.onChange && props.onChange(vals);
    }
  };

  return props.isRadio ? (
    <MuiRadioGroup
      aria-label={props.name}
      name={props.name}
      value={getSelectedValue(props.items)}
      onChange={handleChange}
    >
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        style={{ width: props.width ? props.width : 'auto' }}
      >
        {props.items &&
          props.items.map((item, ix) => (
            <GridItem
              xs={props.wideVersion ? 12 : 12 / props.columnNumber}
              style={{
                marginTop: props.rowSpacing ? props.rowSpacing : 'auto',
                marginBottom: props.rowSpacing ? props.rowSpacing : 'auto',
              }}
              key={ix}
            >
              <SelectableIcon
                {...item}
                isRadio={true}
                checked={item.checked}
                onChange={handleChange}
                wideVersion={props.wideVersion}
                moveTextHigher={props.moveTextHigher}
              />
            </GridItem>
          ))}
      </GridContainer>
    </MuiRadioGroup>
  ) : (
    <GridContainer
      direction="row"
      style={{ width: props.width ? props.width : 'auto' }}
    >
      {props.items &&
        props.items.map((item) => (
          <GridItem
            xs={props.wideVersion ? 12 : 12 / props.columnNumber}
            style={{
              marginTop: props.rowSpacing ? props.rowSpacing : 'auto',
              marginBottom: props.rowSpacing ? props.rowSpacing : 'auto',
            }}
          >
            <SelectableIcon
              {...item}
              checked={item.checked}
              onChange={handleChange}
              wideVersion={props.wideVersion}
              moveTextHigher={props.moveTextHigher}
            />
          </GridItem>
        ))}
    </GridContainer>
  );
}

SelectableIconGroup.propTypes = {
  color: PropTypes.string,
  columnNumber: PropTypes.number.isRequired,
  width: PropTypes.number,
  isRadio: PropTypes.bool,
  name: PropTypes.string,
  rowSpacing: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.object.isRequired,
      checkedIcon: PropTypes.object.isRequired,
      checked: PropTypes.bool,
      labelFontSize: PropTypes.number,
      uid: PropTypes.string.isRequired,
    })
  ).isRequired,
};
