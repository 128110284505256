import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { NMoons_Logo } from 'assets/imagesList';
import Button from 'components/atoms/Inputs/Buttons/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useHeaderStyles } from './headerStyles';
import { useActions } from '../../../overmind';

export default function Header(props) {
  const classes = useHeaderStyles();
  const actions = useActions();

  return (
    <>
      {props.hideHeader ? (
        <></>
      ) : (
        <GridContainer
          direction="row"
          justify="center"
          alignItems="center"
          style={{
            marginTop: '4px',
            marginBottom: props.adjustMargin ? '0px' : '24px',
          }}
        >
          {!props.hideLeftButton && (
            <GridItem className={classes.leftSide}>
              <Button
                color={props.buttonColor ? props.buttonColor : 'default'}
                onClick={() => actions.goBack()}
                style={{ paddingLeft: 0, marginLeft: 0 }}
              >
                <>
                  <NavigateBeforeIcon />
                </>
              </Button>
            </GridItem>
          )}
          <img src={NMoons_Logo} width="58px" alt="9moons logo" />
        </GridContainer>
      )}
    </>
  );
}

Header.propTypes = {
  hideHeader: PropTypes.bool,
  hideLeftButton: PropTypes.bool,
  previousPageRoute: PropTypes.string,
  buttonColor: PropTypes.string,
};
