import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useOvermindState } from 'overmind/index';
import {
  getTodaysHours,
  getIndexOfCurrentDayInWeek,
  getDate,
} from 'components/helpers/dateHelper';
import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import RecipeDetails from './RecipeDetails/RecipeDetails';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import LinkBox from 'components/molecules/Navigation/LinkBox/LinkBox';
import Box from 'components/atoms/Layout/Box/Box';
import PersonalizedTips from './Home/PersonalizedTips';
import PregnancyTips from './Home/PregnancyTips';
import HomeRecipes from './Home/HomeRecipes';
import ScienceFact from './Home/ScienceFact';
import WeeklyCheckin from 'components/organisms/ModalWindow/WeeklyCheckinDialog';
import Typography from 'components/atoms/Typography/Typography';

import { useHistory, useLocation } from 'react-router-dom';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_HOME, ROUTE_PROFILE } from 'routes';
import { getRecipe } from 'components/helpers/planHelper';
import { formatDate } from 'components/helpers/dateHelper';

import { useActions } from 'overmind/index';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import MealPlanProblemsDialog from 'components/organisms/ModalWindow/MealPlanProblemsDialog';
import { useTranslation } from 'react-i18next';
import PaymentDialogHome from 'components/organisms/PaymentDialog/PaymentDialogHome';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

const HOME = 'home';
const RECIPE_DETAILS = 'recipe_details';

export default function Home() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [hoursToday, setHoursToday] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstRecipe, setFirstRecipe] = useState();
  const [secondRecipe, setSecondRecipe] = useState();
  const [thirdRecipe, setThirdRecipe] = useState();
  const [fourthRecipe, setFourthRecipe] = useState();
  const [firstRecipeMealType, setFirstRecipeMealType] = useState();
  const [secondRecipeMealType, setSecondRecipeMealType] = useState();
  const [thirdRecipeMealType, setThirdRecipeMealType] = useState();
  const [fourthRecipeMealType, setFourthRecipeMealType] = useState();
  const [recipeMealType, setRecipeMealType] = useState();
  const [numberOfServings, setNumberOfServings] = useState(undefined);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [displayMode, setDisplayMode] = useState(HOME);
  const [weeklyCheckinStep, setWeeklyCheckInStep] = useState(1);
  const [showMealPlanDialog, setShowMealPlanDialog] = useState(false);
  const [modalWindowOpened, setModalWindowOpened] = useState(false);
  const [modalWindowType, setModalWindowType] = useState();
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (!overmindState.isProfilePregnancyFetched) {
      actions.fetchProfilePregnancy();
    } else {
      setSelectedDate(
        getIndexOfCurrentDayInWeek(
          getDate(overmindState.profilePregnancy.pregnancy_week_start)
        )
      );
    }
  }, [actions, overmindState.isProfilePregnancyFetched]);

  const findRecipe = (mealCode) => {
    return getRecipe(overmindState.mealPlan, mealCode, selectedDate, false);
  };

  useEffect(() => {
    if (location.pathname === ROUTE_HOME) {
      setDisplayMode(HOME);
    }
  }, [location]);

  useEffect(() => {
    if (overmindState.isInitialReportLoaded === false) {
      actions.fetchInitialReport();
    }
  }, [overmindState.isInitialReportLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.isMealPlanLoaded === false &&
      overmindState.isMealPlanFetching !== true
    ) {
      actions.fetchMealPlan();
    }
    setHoursToday(getTodaysHours());
  }, [overmindState.isMealPlanLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === false &&
      overmindState.isMealPlanLoaded === true
    ) {
      actions.fetchRecipeActions();
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  useEffect(() => {
    if (
      overmindState.isMealPlanLoaded &&
      hoursToday != null &&
      selectedDate != null
    ) {
      let firstMeal;
      let secondMeal;
      let thirdMeal;
      let fourthMeal;
      if (window.innerWidth < 800) {
        if (hoursToday < 12) {
          firstMeal = 'breakfast';
          secondMeal = 'lunch';
        } else if (hoursToday >= 12 && hoursToday < 18) {
          firstMeal = 'lunch';
          secondMeal = 'dinner';
        } else if (hoursToday >= 18) {
          firstMeal = 'dinner';
          secondMeal = 'snack';
        }
      } else {
        firstMeal = 'breakfast';
        secondMeal = 'lunch';
        thirdMeal = 'dinner';
        fourthMeal = 'snack';
      }

      setFirstRecipe(findRecipe(firstMeal));
      setSecondRecipe(findRecipe(secondMeal));
      setFirstRecipeMealType(firstMeal);
      setSecondRecipeMealType(secondMeal);
      if (thirdMeal && fourthMeal) {
        setThirdRecipe(findRecipe(thirdMeal));
        setFourthRecipe(findRecipe(fourthMeal));
        setThirdRecipeMealType(thirdMeal);
        setFourthRecipeMealType(fourthMeal);
      }
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    JSON.stringify(overmindState.mealPlan),
    hoursToday,
    actions,
    selectedDate,
  ]);

  useEffect(() => {
    if (
      overmindState.errors.errorGettingMealPlan &&
      overmindState.errors.errorGettingMealPlan !== ''
    ) {
      setShowMealPlanDialog(true);
    } else {
      setShowMealPlanDialog(false);
    }
  }, [overmindState.errors.errorGettingMealPlan, actions]);

  useEffect(() => {
    if (
      overmindState.subscription.status == null &&
      !overmindState.subscription.isBeingFetched
    ) {
      actions.fetchSubscriptionStatus();
    }

    if (
      overmindState.metaData == null ||
      overmindState.metaData.user_metadata == null ||
      overmindState.metaData.user_metadata.terms_9moons == null
    ) {
      actions.fetchUserMetadata();
    }
  }, [actions]);

  const changeHomeView = (view) => {
    if (view === 'home') {
      setDisplayMode(HOME);
    } else if (view === 'recipe_details') {
      setDisplayMode(RECIPE_DETAILS);
    }
  };

  const handleRecipeSelected = (recipe, type, numberOfServings) => {
    trackEvent('Recipe Details', 'Clicked', { location: 'Home' });

    if (!overmindState.subscription.status) {
      setPaymentDialogOpened(true);
      return;
    }

    setRecipeMealType(type);
    setNumberOfServings(numberOfServings);
    const date = formatDate(recipe.meal_datetime, 'YYYY-MM-DD');
    overmindState.mealPlan[date].meals.filter(
      (m) => m.recipe.id === recipe.id && setSelectedRecipe(m.recipe)
    );
    actions
      .fetchRecipeDetails(recipe.id)
      .then(() => changeHomeView(RECIPE_DETAILS));
  };

  const handleModalWindow = (opened, type) => {
    if (opened) {
      setWeeklyCheckInStep(1);
      if (type === 'monitorWeight') {
        actions.fetchMonitorPregnancy();
      }
      setModalWindowType(type);
    } else {
      setModalWindowType(null);
    }
    setModalWindowOpened(opened);
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={
          displayMode !== HOME ? (
            'Recipe'
          ) : overmindState.metaData && overmindState.metaData.user_metadata ? (
            <Typography variant="h5">
              Hello {overmindState.metaData.user_metadata.firstName}!
            </Typography>
          ) : (
            <Typography variant="h5">Hello!</Typography>
          )
        }
        showBack={displayMode !== HOME}
        goBack={() => setDisplayMode(HOME)}
        whiteText={displayMode === RECIPE_DETAILS}
      />

      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          fontSize: '15px',
        }}
      >
        <GridItem>
          <Box
            style={{
              maxWidth: '1200px',
            }}
          >
            {displayMode === HOME && (
              <GridContainer
                direction="row"
                style={{
                  marginBottom: '55px',
                }}
              >
                <GridItem xs={12}>
                  <PregnancyTips
                    openModalWindow={(type) => handleModalWindow(true, type)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HomeRecipes
                    isLoaded={overmindState.isMealPlanLoaded}
                    handleRecipeSelected={handleRecipeSelected}
                    firstRecipe={firstRecipe}
                    secondRecipe={secondRecipe}
                    thirdRecipe={thirdRecipe}
                    fourthRecipe={fourthRecipe}
                    firstRecipeMealType={firstRecipeMealType}
                    secondRecipeMealType={secondRecipeMealType}
                    thirdRecipeMealType={thirdRecipeMealType}
                    fourthRecipeMealType={fourthRecipeMealType}
                    recipesTitle={t('home.recipes.title')}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <LinkBox
                    title={t('home.nutritionDetails').toUpperCase()}
                    onClick={() => {
                      trackEvent('Nutrition Details', 'Clicked');
                      history.push(handleNavigation(3));
                    }}
                  />
                </GridItem>

                {overmindState.initialReport &&
                  overmindState.initialReport.personalized_tip &&
                  overmindState.initialReport.personalized_tip.title && (
                    <GridItem xs={12} md={6}>
                      <PersonalizedTips
                        title={
                          overmindState.initialReport.personalized_tip.title
                        }
                        image={
                          overmindState.initialReport.personalized_tip
                            .image_link
                        }
                        text={
                          overmindState.initialReport.personalized_tip.advice
                        }
                        category={
                          overmindState.initialReport.personalized_tip.category
                        }
                        needs={
                          overmindState.initialReport.personalized_tip.needs
                        }
                      />
                    </GridItem>
                  )}

                {overmindState.initialReport &&
                  overmindState.initialReport.science_fact &&
                  overmindState.initialReport.science_fact.text && (
                    <GridItem xs={12} md={6}>
                      <ScienceFact
                        title={overmindState.initialReport.science_fact.title}
                        image={
                          overmindState.initialReport.science_fact.image_link
                        }
                        text={overmindState.initialReport.science_fact.text}
                      />
                    </GridItem>
                  )}
              </GridContainer>
            )}

            {displayMode === RECIPE_DETAILS &&
              selectedRecipe &&
              selectedDate != null && (
                <RecipeDetails
                  recipe={selectedRecipe}
                  meal={recipeMealType}
                  numberOfServings={numberOfServings}
                  onBackButtonClick={() => {
                    setDisplayMode(HOME);
                  }}
                  disableNavigation={true}
                  dateIndex={selectedDate}
                  parentComponent="home"
                  hideSelect={true}
                />
              )}
          </Box>
        </GridItem>
      </GridContainer>
      <PaymentDialogHome
        openDialog={paymentDialogOpened}
        onCloseDialog={() => setPaymentDialogOpened(false)}
        redirectState={{ initiator: ROUTE_HOME }}
      />
      {modalWindowType && (
        <WeeklyCheckin
          type={modalWindowType}
          opened={modalWindowOpened}
          step={weeklyCheckinStep}
          onClose={() => handleModalWindow(false)}
        />
      )}
      <MealPlanProblemsDialog
        openDialog={showMealPlanDialog}
        title={t('mealPlan.cantGenerateMessage')}
        confirmText={t('common.answerOk')}
        onClose={() => setShowMealPlanDialog(false)}
        onConfirm={() => history.push(ROUTE_PROFILE + '?nutrition')}
      />
      <FooterMenu
        selectedValue={0}
        onSelected={(val) => {
          history.push(handleNavigation(val));
        }}
        menuOptions={getMenuOptions(assessmentTheme, 0)}
      />
    </ThemeProvider>
  );
}
