import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import RecommendedItemsSwiper from 'components/organisms/RecommendedItems/RecommendedItemsSwiper';
import Meal from 'components/organisms/Meal/Meal';

import { useOvermindState } from 'overmind/index';
import { getRecipe } from 'components/helpers/planHelper';
import { useDailyPlanStyles } from './dailyPlanStyles';
import Box from 'components/atoms/Layout/Box/Box';
import { useTranslation } from 'react-i18next';

export default function DailyPlan(props) {
  const overmindState = useOvermindState();
  const classes = useDailyPlanStyles();
  const { t } = useTranslation();

  const findRecipe = (mealCode) => {
    return getRecipe(overmindState.mealPlan, mealCode, props.dateIndex, true);
  };

  const renderRecommendedProduct = (productType) => {
    const recipe = findRecipe(productType);
    return (
      <Meal
        recipe={recipe}
        onClicked={(id) =>
          props.onRecipeSelected && props.onRecipeSelected(id, productType)
        }
        parentComponent="meal"
        isHorizontal={true}
      />
    );
  };

  const renderProductsSwiper = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      <RecommendedItemsSwiper
        onItemSelected={(id) =>
          props.onRecipeSelected && props.onRecipeSelected(id, productType)
        }
        onSimilarItemSelected={(id, mainRecipeId) =>
          props.onSimilarRecipeSelected &&
          props.onSimilarRecipeSelected(id, mainRecipeId, productType)
        }
        mainItem={recipe}
        similarItems={
          overmindState.similarRecipes &&
          overmindState.similarRecipes[productId]
            ? overmindState.similarRecipes[productId]
            : []
        }
        maxSimilarItems={3}
        parentComponent="meal"
        showRecommendedItem={false}
        showBookmarks={false}
      />
    );
  };

  const areSimilarRecipesLoaded = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      overmindState.similarRecipes &&
      overmindState.similarRecipes[productId] != null
    );
  };

  const hasSimilarRecipes = (productType) => {
    const recipe = findRecipe(productType);
    const productId = recipe && recipe.id;
    return (
      overmindState.similarRecipes &&
      overmindState.similarRecipes[productId] &&
      overmindState.similarRecipes[productId].length > 0
    );
  };

  const meals = ['breakfast', 'lunch', 'dinner', 'snack'];

  return (
    <GridContainer direction={window.innerWidth < 800 ? 'column' : 'row'}>
      {meals.map((meal, ix) => (
        <GridItem key={ix}>
          <GridContainer direction="column">
            <GridItem className={classes.title}>
              <Typography
                variant={'body1'}
                style={{ textTransform: 'uppercase', fontSize: '20px' }}
              >
                {meal}
              </Typography>
            </GridItem>
            <GridItem>
              <Box style={{ marginBottom: '3px' }}>
                {renderRecommendedProduct(meal)}
              </Box>
            </GridItem>
            {areSimilarRecipesLoaded(meal) && (
              <GridItem className={classes.title} style={{ marginTop: '12px' }}>
                <Typography
                  variant={'body1'}
                  style={{ textTransform: 'uppercase', fontSize: '12px' }}
                >
                  {hasSimilarRecipes(meal)
                    ? t('mealPlan.alternativeRecipesTitle')
                    : t('mealPlan.missingAlternativeRecipesMessage')}
                </Typography>
              </GridItem>
            )}
            <GridItem>
              <Box style={{ marginBottom: '33px' }}>
                {renderProductsSwiper(meal)}
              </Box>
            </GridItem>
          </GridContainer>
        </GridItem>
      ))}
    </GridContainer>
  );
}
