import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from 'components/atoms/Layout/Box/Box';

export default function MobileLoginPage(props) {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  useEffect(() => {
    const loginUser = async () => {
      if (!isLoading) {
        try {
          const token = await getAccessTokenSilently();
          window.localStorage.setItem('access_token', token);
          console.log('SignedIn');
          window &&
            window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(token);
        } catch (e) {
          console.log('e ', e.error);
          if (e.error === 'login_required') {
            loginWithRedirect({ redirectUri: window.location.href });
          } else if (e.error === 'consent_required') {
            loginWithRedirect({ redirectUri: window.location.href });
          } else {
            window &&
              window.ReactNativeWebView &&
              window.ReactNativeWebView.postMessage(e);
          }
        }
      }
    };

    loginUser();
  }, [isAuthenticated, isLoading]);

  return (
    <React.Fragment>
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    </React.Fragment>
  );
}
