import MuiTabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

/**
 * Default Tabs component based on https://material-ui.com/components/tabs/
 * @param {} props All possible props can be found in docs https://material-ui.com/api/tabs/
 */
export default function Tabs(props) {
  const StyledTabs = withStyles((theme) => ({
    root: {
      '& div.MuiTabs-scroller': {
        zIndex: 9888,
        position: 'fixed',
        backgroundColor: '#ffffff',
        maxWidth: '1200px',
      },
    },

    selected: {},
  }))((props) => <MuiTabs {...props} />);

  const { children, ariaLabel, ...rest } = props;

  return (
    <StyledTabs aria-label={ariaLabel} {...rest}>
      {children}
    </StyledTabs>
  );
}

Tabs.propTypes = {
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
};
