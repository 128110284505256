import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import NumberInput from 'components/molecules/Inputs/NumberInput/NumberInput';
import { minAge, maxAge } from 'components/helpers/unitValueHelper';

import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

export default function AgeQuestion(props) {
  const handleChange = (value) => {
    props.onChange && props.onChange(value);
  };

  return (
    <QuestionRefactored title={props.title}>
      <Box justifyContent="center" display="flex" mt={2} mb={3}>
        <NumberInput
          min={minAge}
          max={maxAge}
          value={props.age}
          color={'primary'}
          onChange={handleChange}
          inputProps={{
            inputType: 'number',
          }}
        />
      </Box>
    </QuestionRefactored>
  );
}
