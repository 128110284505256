import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';

import DueDateQuestion from 'components/organisms/Questions/Pregnancy/DueDateQuestion';
import DueDateDatePickerQuestion from 'components/organisms/Questions/Pregnancy/DueDateDatePickerQuestion';

import { subtractDays, formatDate } from 'components/helpers/dateHelper';
import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function DueDatePage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [selectedValue, setSelectedValue] = useState(
    overmindState.metaData.user_metadata.due_date_question
      ? overmindState.metaData.user_metadata.due_date_question
      : undefined
  );
  const [dueDate, setDueDate] = useState(
    overmindState.profilePregnancy
      ? overmindState.profilePregnancy.due_date
      : formatDate(new Date(), 'YYYY-MM-DD')
  );
  const handleRadioChange = (value, checked) => {
    setSelectedValue(value + '');
    actions.dueDateQuestionAnswered(value);
  };

  const handleDatePickerChange = (date) => {
    let dueDate = formatDate(date, 'YYYY-MM-DD');
    const lastPeriodDate = subtractDays(date, 280);

    setDueDate(dueDate);
    actions.dueDateSet({
      dueDate: dueDate,
      lastPeriodDate: lastPeriodDate,
    });
  };

  return (
    <GridContainer className={classes.container}>
      <DueDateQuestion
        title={t('onboarding.pregnancySection.step1.title')}
        data={[
          {
            label: t('common.answerYes'),
            value: 1,
            checked: selectedValue === '1',
          },
          {
            label: t('common.answerNo'),
            value: 2,
            checked: selectedValue === '2',
          },
        ]}
        onChange={(val, checked) => {
          handleRadioChange(val, checked);
        }}
      />

      {selectedValue === '1' && (
        <DueDateDatePickerQuestion
          dueDate={dueDate}
          onChange={(e) => {
            handleDatePickerChange(e);
          }}
        />
      )}
    </GridContainer>
  );
}
