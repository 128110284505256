import { makeStyles } from '@material-ui/core';

export const useNumberInputStyles = makeStyles((theme) => ({
  root: {
    fontSize: '50px',
    color: theme.palette.primary.main,
    '& .MuiInputBase-input': {
      fontSize: '50px',
      color: theme.palette.primary.main,
      textAlign: 'center',
      width: 100,
      // hides arrows from input number
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        '-webkit-appearance': 'textfield',
        '-moz-appearance': 'textfield',
      },
    },
  },
  iconStart: {
    padding: 0,
    transform: 'rotate(-180deg)',
  },
  iconEnd: {
    padding: 0,
  },
}));
