import MuiAccordion from '@material-ui/core/Accordion';

import { useAccordionStyles } from './accordionStyles';

export default function Accordion(props) {
  const { expanded, children, ...rest } = props;
  const classes = useAccordionStyles(props);

  return (
    <MuiAccordion square expanded={expanded} classes={{ ...classes }} {...rest}>
      {children}
    </MuiAccordion>
  );
}
