import React from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import NameQuestion from 'components/organisms/Questions/General/NameQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';

export default function YourName(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <NameQuestion
          title={t('onboarding.generalSection.step1.nameTitle')}
          name={overmindState.calc_firstName}
          onChange={(newName) => {
            actions.updateStateProp({
              key: 'calc_firstName',
              value: newName,
              nextEnabled: newName != null && newName !== '',
            });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
