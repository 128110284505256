import React from 'react';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundExercise } from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'components/atoms/Layout/Box/Box';

export default function OurGenomicsIntro() {
  const { t } = useTranslation();
  return (
    <ContainerWithBackgroundImg
      image={backgroundExercise}
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        maxWidth="280px"
        position="relative"
        pr={{ xs: 5 }}
        pl={{ xs: 5 }}
        left={{ sm: '27%' }}
        top={{ sm: '30%', xs: '30%' }}
      >
        <Typography variant={'body1'} textSize={18}>
          {t('onboarding.dnaSection.ourGenomicsIntro')}
        </Typography>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
