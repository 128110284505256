import React, { useState, useEffect } from 'react';
import { useOvermindState } from 'overmind/index';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import Summary from 'components/pages/Assessment/Monitor/Summary';
import PregnancySymptomsHealth from 'components/pages/Assessment/Monitor/PregnancySymptomsHealth';
import Box from 'components/atoms/Layout/Box';
import PregnancyWeek from 'components/organisms/PregnancyWeek/PregnancyWeek';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import { Card, CardContent } from '@material-ui/core';
import { useSummaryStyles } from './Monitor/summaryStyles';

import { useHistory, useLocation } from 'react-router-dom';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_MONITOR } from 'routes';
import { useTranslation } from 'react-i18next';
import { useActions } from 'overmind/index';
import FABMenu from 'components/organisms/FABMenu/FABMenu';
import PaymentDialogMonitor from 'components/organisms/PaymentDialog/PaymentDialogMonitor';
import TalkToExperts from 'components/organisms/TalkToExperts/TalkToExperts';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function Monitor() {
  // possible values:
  // monitorSummary -> summary screen
  // monitorPregnancySymptoms -> pregnancy symptoms and health screen (1st tab)
  // monitorHealth -> pregnancy symptoms and health screen (2nd tab)
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState('monitorSummary');
  const history = useHistory();
  const location = useLocation();
  const classes = useSummaryStyles();
  const actions = useActions();
  const [pregnancyIssuesText, setPregnancyIssuesText] = useState('');
  const [pregnancyIssues, setPregnancyIssues] = useState(false);
  const [fetchPregnancyIssuesText, setFetchPregnancyIssuesText] = useState(
    true
  );
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.pathname === ROUTE_MONITOR) {
      setDisplayMode('monitorSummary');
    }
  }, [location]);

  useEffect(() => {
    if (
      fetchPregnancyIssuesText ||
      overmindState.monitorPregnancy.complications === undefined
    ) {
      setFetchPregnancyIssuesText(false);
      actions.fetchMonitorPregnancyComplications();
    }
  }, []);

  const isProfilePregnancyFetched = () => {
    return (
      overmindState.profilePregnancy != null ||
      Object.keys(overmindState.profilePregnancy).length > 0
    );
  };

  useEffect(() => {
    if (!isProfilePregnancyFetched()) {
      actions.fetchProfilePregnancy();
    }
  }, [actions, isProfilePregnancyFetched]);

  const isUserProfileFetched = () => {
    return (
      overmindState.profile != null ||
      Object.keys(overmindState.profile).length > 0
    );
  };

  useEffect(() => {
    if (!isUserProfileFetched()) {
      actions.fetchProfile();
    }
  }, [actions, isUserProfileFetched]);

  useEffect(() => {
    if (overmindState.monitorPregnancy.complications !== undefined) {
      const complications = Object.keys(
        overmindState.monitorPregnancy.complications
      );
      let pregComplications = undefined;
      complications.forEach((complication) => {
        if (pregComplications == null) {
          pregComplications = [];
        }

        if (
          overmindState.monitorPregnancy.complications[complication] === true
        ) {
          pregComplications.push(complication);
        }
      });

      if (pregComplications != null && pregComplications.length > 0) {
        setPregnancyIssuesText(t('monitor.progressState.seeDoctor'));
        setPregnancyIssues(true);
      } else if (pregComplications != null) {
        setPregnancyIssuesText(t('monitor.progressState.allGood'));
        setPregnancyIssues(false);
      }
    }
  }, [overmindState.monitorPregnancy.complications]);

  useEffect(() => {
    if (
      overmindState.subscription.status == null &&
      !overmindState.subscription.isBeingFetched
    ) {
      actions.fetchSubscriptionStatus();
    }
  }, [actions]);

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={'Monitor'}
        showBack={
          displayMode === 'monitorPregnancySymptoms' ||
          displayMode === 'monitorHealth'
        }
        goBack={() => setDisplayMode('monitorSummary')}
      />

      <GridContainer direction="column" justify="center" alignItems="center">
        <GridItem
          style={{
            maxWidth: '1200px',
          }}
        >
          <div
            style={{
              marginTop: '50px',
              marginBottom: '80px',
            }}
          >
            {displayMode === 'monitorSummary' && (
              <>
                <Box style={{ paddingTop: '10px' }}>
                  <PregnancyWeek />
                </Box>
                {overmindState.monitorPregnancy &&
                  overmindState.monitorPregnancy.complications && (
                    <Box mx="24px">
                      <GridContainer
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                        style={{ paddingTop: '26px', paddingBottom: '26px' }}
                      >
                        <GridItem xs={12}>
                          <Card
                            className={
                              !pregnancyIssues ? classes.card : classes.redCard
                            }
                            width="100%"
                          >
                            <CardContent>
                              <GridContainer
                                direction="row"
                                justify="space-between"
                                alignItems="flex-end"
                              >
                                <GridItem xs={12}>
                                  <Typography className={classes.cardText}>
                                    {pregnancyIssuesText}
                                  </Typography>
                                </GridItem>
                              </GridContainer>
                            </CardContent>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    </Box>
                  )}

                {isProfilePregnancyFetched() && isUserProfileFetched() && (
                  <Summary
                    onPregnancyDetails={() => {
                      trackEvent('Monitor', 'Clicked', {
                        option: 'Pregnancy Symptoms Details',
                      });
                      setDisplayMode('monitorPregnancySymptoms');
                    }}
                    onHealthDetails={() => {
                      trackEvent('Monitor', 'Clicked', {
                        option: 'Health Details',
                      });
                      setDisplayMode('monitorHealth');
                    }}
                  />
                )}

                {overmindState.subscription.status && (
                  <Box
                    mx="24px"
                    onClick={() => {
                      trackEvent('Nutrition Expert', 'Clicked', {
                        user: 'paid',
                      });
                    }}
                  >
                    <a id="talk_with_expert" href="#" className={classes.link}>
                      <TalkToExperts />
                    </a>
                  </Box>
                )}
                {!overmindState.subscription.status && (
                  <Box
                    mx="24px"
                    onClick={() => {
                      setPaymentDialogOpened(true);
                      trackEvent('Nutrition Expert', 'Clicked', {
                        user: 'free',
                      });
                    }}
                  >
                    <TalkToExperts />
                  </Box>
                )}
              </>
            )}
            {(displayMode === 'monitorPregnancySymptoms' ||
              displayMode === 'monitorHealth') && (
              <PregnancySymptomsHealth
                healthTabSelected={displayMode === 'monitorHealth'}
              />
            )}
          </div>
        </GridItem>
      </GridContainer>

      <FABMenu hideFabIcon={paymentDialogOpened} />
      <PaymentDialogMonitor
        openDialog={paymentDialogOpened}
        onCloseDialog={() => setPaymentDialogOpened(false)}
      />
      <FooterMenu
        selectedValue={2}
        onSelected={(val) => {
          if (val === 2) {
            setDisplayMode('monitorSummary');
          } else {
            history.push(handleNavigation(val));
          }
        }}
        menuOptions={getMenuOptions(assessmentTheme, 2)}
      />
    </ThemeProvider>
  );
}
