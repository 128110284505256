import LinearProgress from 'components/atoms/Progress/LinearProgress';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import PropTypes from 'prop-types';
import Typography from 'components/atoms/Typography/Typography';

export default function NeedCovered(props) {
  return (
    <GridContainer direction="column">
      <GridItem>
        <GridContainer direction="row">
          <GridItem xs={4} style={{ height: '18px' }}>
            <Typography
              variant="body1"
              style={{ fontSize: '12px', textAlign: 'left' }}
            >
              {props.name}
            </Typography>
          </GridItem>
          <GridItem xs={8} style={{ height: '18px' }}>
            <GridContainer direction="row" alignItems="center">
              <GridItem xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: props.textStyle,
                  }}
                >
                  {props.covered}
                  {'%'}
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: props.textStyle,
                  }}
                >
                  {props.need} {props.unit}
                </Typography>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem>
        <LinearProgress
          backgroundColor="#e0e0e0"
          borderRadius={6}
          height={6}
          progressColor="#bfd671"
          value={props.covered}
        />
      </GridItem>
    </GridContainer>
  );
}

NeedCovered.propTypes = {
  /**
   * Percentage of the need that is convered.
   */
  covered: PropTypes.number,

  /**
   * Amount of the need.
   */
  need: PropTypes.number,

  /**
   * Units for amount of the need
   */
  unit: PropTypes.string,

  /**
   * Name of the need.
   */
  name: PropTypes.string.isRequired,
};
