import { makeStyles } from '@material-ui/core';

export const useSummaryStyles = makeStyles((theme) => ({
  textValues: {
    fontSize: '40px',
    fontFamily: 'Museo',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  textValuesSubtitle: {
    fontSize: '10px',
    textAlign: 'right',
  },
  cardText: {
    fontFamily: 'Museo-500',
    fontWeight: '500',
    fontSize: '20px',
    textAlign: 'center',
  },
  cardTextLeft: {
    fontFamily: 'Museo-500',
    fontWeight: '500',
    fontSize: '20px',
  },
  caption: { fontFamily: 'MuseoSans-300', fontWeight: '500', fontSize: '14px' },
  textTitle: { fontFamily: 'Museo-500', fontWeight: '500', fontSize: '14px' },
  textSubtitle: { fontFamily: 'MuseoSans-300', fontSize: '14px' },
  chartLegend: { fontFamily: 'MuseoSans', fontSize: '12px', fontWeight: '900' },
  card: {
    minHeight: '154px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#000000',
  },
  redCard: {
    minHeight: '154px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#e8716d',
    color: '#FFFFFF',
  },
  cardContent: { height: '100%' },
  link: {
    color: '#000000',
    textDecoration: 'none',
    width: '100%',
  },
}));
