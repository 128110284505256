import * as React from 'react';

function NeedsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.107"
      height="23.093"
      viewBox="0 0 23.107 23.093"
    >
      <g transform="translate(-3623.623 -1213.305)">
        <path
          d="M3715.883,1216.532h-7.451a1.09,1.09,0,0,1-1.076-1.075v-1.076a1.064,1.064,0,0,1,1.076-1.076h7.451a1.064,1.064,0,0,1,1.076,1.076v1.076A1.09,1.09,0,0,1,3715.883,1216.532Zm-7.451-2.335a.177.177,0,0,0-.185.184v1.076a.194.194,0,0,0,.185.183h7.451a.193.193,0,0,0,.185-.183v-1.076a.177.177,0,0,0-.185-.184Z"
          transform="translate(-80.376 0)"
          fill={props.color}
        />
        <path
          d="M3634.983,1261.776h-9.314a2.048,2.048,0,0,1-2.046-2.046v-15.584a2.048,2.048,0,0,1,2.046-2.046h.21a.446.446,0,0,1,0,.892h-.21a1.156,1.156,0,0,0-1.154,1.154v15.584a1.156,1.156,0,0,0,1.154,1.155h9.314a.446.446,0,0,1,0,.891Zm4.512-10.048a.445.445,0,0,1-.445-.446v-7.136a1.156,1.156,0,0,0-1.154-1.154h-.21a.446.446,0,0,1,0-.892h.21a2.048,2.048,0,0,1,2.045,2.046v7.136A.446.446,0,0,1,3639.495,1251.728Z"
          transform="translate(0 -27.639)"
          fill={props.color}
        />
        <path
          d="M3690.043,1334.17a.447.447,0,0,1-.36-.182l-.577-.787a.446.446,0,1,1,.719-.528l.212.289.745-1.045a.446.446,0,1,1,.727.517l-1.1,1.548a.446.446,0,0,1-.36.188Z"
          transform="translate(-62.774 -113.675)"
          fill={props.color}
        />
        <path
          d="M3789.469,1351.6h-6.428a.446.446,0,0,1,0-.892h6.428a.446.446,0,0,1,0,.892Z"
          transform="translate(-152.597 -131.891)"
          fill={props.color}
        />
        <path
          d="M3690.047,1427.116a.445.445,0,0,1-.364-.188l-.577-.814a.446.446,0,1,1,.727-.516l.211.3.739-1.056a.446.446,0,1,1,.73.511l-1.1,1.574A.393.393,0,0,1,3690.047,1427.116Z"
          transform="translate(-62.778 -202.87)"
          fill={props.color}
        />
        <path
          d="M3789.469,1445.149h-6.428a.446.446,0,0,1,0-.892h6.428a.446.446,0,0,1,0,.892Z"
          transform="translate(-152.597 -221.69)"
          fill={props.color}
        />
        <path
          d="M3690.043,1520.013a.445.445,0,0,1-.36-.182l-.577-.787a.446.446,0,1,1,.719-.528l.21.286.744-1.065a.446.446,0,1,1,.73.512l-1.1,1.575A.5.5,0,0,1,3690.043,1520.013Z"
          transform="translate(-62.774 -292.041)"
          fill={props.color}
        />
        <path
          d="M3789.469,1538.067h-6.428a.446.446,0,1,1,0-.891h6.428a.446.446,0,1,1,0,.891Z"
          transform="translate(-152.597 -310.883)"
          fill={props.color}
        />
        <path
          d="M3690.047,1613.56a.445.445,0,0,1-.364-.187l-.577-.813a.446.446,0,0,1,.727-.517l.211.3.739-1.056a.446.446,0,1,1,.73.512l-1.1,1.574a.446.446,0,0,1-.363.19Z"
          transform="translate(-62.778 -381.836)"
          fill={props.color}
        />
        <path
          d="M3787.238,1631.629h-4.2a.446.446,0,1,1,0-.891h4.2a.446.446,0,1,1,0,.891Z"
          transform="translate(-152.597 -400.693)"
          fill={props.color}
        />
        <path
          d="M3940.819,1567.376a3.584,3.584,0,0,1-1.955-.611,5.436,5.436,0,0,1-2.235-3.474,4.511,4.511,0,0,1,.506-3.482,3.718,3.718,0,0,1,2.382-1.611,2.737,2.737,0,0,1,.67-.088.446.446,0,0,1,0,.891,1.879,1.879,0,0,0-.469.066,2.844,2.844,0,0,0-1.835,1.226,3.657,3.657,0,0,0-.382,2.811,4.609,4.609,0,0,0,1.851,2.913,2.515,2.515,0,0,0,1.923.418c.051-.01.1-.024.143-.037a2.7,2.7,0,0,1,.3-.071.474.474,0,0,1,.16,0,2.478,2.478,0,0,1,.24.063c.051.015.1.032.155.042a2.522,2.522,0,0,0,1.928-.417,4.606,4.606,0,0,0,1.851-2.909,3.661,3.661,0,0,0-.381-2.816,2.834,2.834,0,0,0-1.819-1.223,2.892,2.892,0,0,0-1.861.2.445.445,0,0,1-.4-.8,3.765,3.765,0,0,1,2.441-.274,3.719,3.719,0,0,1,2.384,1.611,4.517,4.517,0,0,1,.505,3.486,5.431,5.431,0,0,1-2.234,3.47,3.45,3.45,0,0,1-2.6.544c-.074-.015-.153-.038-.231-.062l-.079-.024-.114.032c-.071.021-.141.041-.209.054A3.089,3.089,0,0,1,3940.819,1567.376Z"
          transform="translate(-300.325 -330.977)"
          fill={props.color}
        />
        <path
          d="M4017.611,1515.344a.447.447,0,0,1-.436-.35,2.7,2.7,0,0,0-1.416-1.849.446.446,0,0,1,.4-.8,3.583,3.583,0,0,1,1.889,2.454.447.447,0,0,1-.436.543Z"
          transform="translate(-376.175 -287.005)"
          fill={props.color}
        />
        <path
          d="M4040.808,1478.318c-.033,0-.066,0-.1,0a.447.447,0,0,1-.4-.287,2.252,2.252,0,0,1,2.194-3.044.446.446,0,0,1,.395.28,2.268,2.268,0,0,1-.257,2.1A2.228,2.228,0,0,1,4040.808,1478.318Zm1.34-2.418a1.358,1.358,0,0,0-.825.529,1.4,1.4,0,0,0-.254.973,1.32,1.32,0,0,0,.841-.548A1.431,1.431,0,0,0,4042.147,1475.9Z"
          transform="translate(-399.841 -251.185)"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default NeedsIcon;
