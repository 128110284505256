export const ROUTE_LANDING_PAGE = '/';
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_AUTH0_TEST_PAGE = '/auth0-test-page';
export const ROUTE_MOBILE_LOGIN_PAGE = '/mobile-login-page';
export const ROUTE_MOBILE_LOGOUT_PAGE = '/mobile-logout-page';
export const ROUTE_INTRO = '/intro-page';
export const ROUTE_REGISTER_KIT = '/registerkit';
export const ROUTE_HOME = '/home';
export const ROUTE_ASSESSMENT = '/assessment';
export const ROUTE_MEAL_PLAN = '/meal-plan';
export const ROUTE_MONITOR = '/monitor';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_RESET_USER_DATA = '/reset-user-data';
export const ROUTE_START = '/start';
export const ROUTE_ACTIVATION = '/activate';
export const EMAIL_VERIFICATION = '/account/verified';
export const ROUTE_THANK_YOU = '/thank-you';
export const ROUTE_THANK_YOU_CHAT_SUBSCRIPTION = '/thank-you-chat-subscription';
export const ROUTE_PAY_SUBSCRIPTION = '/subscribe';
export const ROUTE_LANDING_PAGE_WITH_CUSTOM_LOGIN = '/custom-login';
export const ROUTE_MOBILE_LOGIN_PAGE_NEW = '/mobile-login-page-new';
