import { formatDate, getDate } from 'components/helpers/dateHelper';

export const getBDD = (overmindState) => {
  if (
    overmindState.conceptionExperience === '2' &&
    overmindState.dateOfTransfer != null
  ) {
    let dateOfTransferFormatted = getDate(overmindState.dateOfTransfer);
    let dd = dateOfTransferFormatted.clone();
    return getPDD(dd, overmindState);
  } else {
    if (
      overmindState.lastPeriod === '1' &&
      overmindState.lastPeriodDate != null
    ) {
      let dueDateFormatted = getDate(overmindState.lastPeriodDate);
      let dd = dueDateFormatted.clone();
      dd.add(overmindState.cycleLength, 'days');
      return getPDD(dd, overmindState);
    } else if (
      overmindState.lastPeriod === '2' &&
      overmindState.lastPeriodDateEnd != null &&
      overmindState.lastPeriodDateStart != null
    ) {
      let lastPeriodDateEndFormatted = getDate(overmindState.lastPeriodDateEnd);
      let diff = lastPeriodDateEndFormatted.diff(
        overmindState.lastPeriodDateStart,
        'days'
      );
      let lastPeriodDateStartFormatted = getDate(
        overmindState.lastPeriodDateStart
      );
      let dd = lastPeriodDateStartFormatted.clone();
      dd.add(diff / 2, 'days');
      dd.add(overmindState.cycleLength, 'days');
      return getPDD(dd, overmindState);
    } else if (
      overmindState.lastPeriod === '3' &&
      overmindState.conceivedDateEnd != null &&
      overmindState.conceivedDateStart != null
    ) {
      let conceivedDateEndFormatted = getDate(overmindState.conceivedDateEnd);
      let diff = conceivedDateEndFormatted.diff(
        overmindState.conceivedDateStart,
        'days'
      );
      let conceivedDateStartFormatted = getDate(
        overmindState.conceivedDateStart
      );
      let dd = conceivedDateStartFormatted.clone();
      dd.add(diff / 2, 'days');
      dd.add(overmindState.cycleLength, 'days');
      return getPDD(dd, overmindState);
    } else {
      let dateOfTransferFormatted = getDate(overmindState.dateOfTransfer);
      let dd = dateOfTransferFormatted.clone();
      return getPDD(dd, overmindState);
    }
  }
};

export const getPDD = (dd, overmindState) => {
  if (overmindState.firstBabyWeek != null) {
    if (overmindState.firstBabyWeek === '1') {
      dd.subtract(14, 'days');
    } else if (overmindState.firstBabyWeek === '2') {
      dd.subtract(10, 'days');
    } else if (overmindState.firstBabyWeek === '3') {
      dd.subtract(5, 'days');
    } else if (overmindState.firstBabyWeek === '5') {
      dd.add(5, 'days');
    } else if (overmindState.firstBabyWeek === '6') {
      dd.add(10, 'days');
    }
  }

  if (overmindState.postTermPregnancy === '1') {
    dd.add(7, 'days');
  }

  if (
    overmindState.diagnosedWith != null &&
    overmindState.diagnosedWith.filter((d) => d !== '6').length > 0
  ) {
    const len = overmindState.diagnosedWith.filter((d) => d !== '6').length;
    dd.add(len === 1 ? 4 : len === 2 ? 7 : len >= 3 ? 10 : 0, 'days');
  }

  if (overmindState.smokingHabits != null) {
    const smoking = overmindState.smokingHabits;
    dd.subtract(
      smoking === '3' ? 3 : smoking === '4' ? 7 : smoking === '5' ? 7 : 0,
      'days'
    );
  }

  if (overmindState.stressLevel != null) {
    const stress = overmindState.stressLevel;
    dd.subtract(
      stress === '3' ? 3 : stress === '4' ? 7 : stress === '5' ? 7 : 0,
      'days'
    );
  }

  dd.add(252, 'days');
  let dueDate = formatDate(dd, 'MMM Do YYYY');
  /*actions.updateStateProp({
    key: 'dueDate',
    value: dd.format('YYYY-MM-DD'),
    nextEnabled: true,
  });*/

  return {
    dueDate: dd,
    formatedDueDate: dueDate,
    d1: formatDate(dd.clone().subtract(14, 'days'), 'MM/DD/YY'),
    d2: formatDate(dd.clone().subtract(7, 'days'), 'MM/DD/YY'),
    d3: formatDate(dd, 'MM/DD/YY'),
    d4: formatDate(dd.clone().add(7, 'days'), 'MM/DD/YY'),
    d5: formatDate(dd.clone().add(14, 'days'), 'MM/DD/YY'),
  };
};
