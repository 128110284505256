import React, { useEffect } from 'react';

import { useOvermindState } from 'overmind/index';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import { useHomeStyles } from './HomeStyles';
import HorizontalSwiper from 'components/molecules/HorizontalSwiper/HorizontalSwiper';
import { useTranslation } from 'react-i18next';
import { useActions } from 'overmind/index';

export default function PersonalizedTips(props) {
  const overmindState = useOvermindState();
  const classes = useHomeStyles();
  const { t } = useTranslation();
  const actions = useActions();
  const { needs, text, title, image, category } = props;

  useEffect(() => {
    if (
      category === 'Nutrition' &&
      overmindState.isNutrientsReportLoaded === false
    ) {
      actions.fetchNutrientsReport();
    }
  }, [overmindState.isNutrientsReportLoaded, actions, category]);

  const stylizeHtml = (text) => {
    if (text.length === 0 || text == null) {
      return '';
    }

    if (text.includes('<ol>')) {
      return text.replace('<ol>', `<ol class='${classes.component}'>`);
    } else if (text.includes('<ul>')) {
      return text.replace('<ul>', `<ul class='${classes.component}'>`);
    } else return text;
  };

  const renderRecommendedFoods = (item) => {
    return (
      <GridItem>
        <GridContainer
          direction="column"
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <GridItem
            style={{
              paddingBottom: '10px',
              width: '90vw',
            }}
          >
            <Typography variant="body1" className={classes.subtitle}>
              {overmindState.nutrientsReport.micronutrients &&
                overmindState.nutrientsReport.micronutrients.items.map(
                  (micronutrient) => {
                    if (micronutrient.code === item) {
                      return micronutrient.standard_name.concat(
                        t('home.personalizedTips.swiperTitle')
                      );
                    }
                  }
                )}
            </Typography>
            <HorizontalSwiper uid="navigation-swiper">
              {overmindState.nutrientsReport.micronutrients &&
                overmindState.nutrientsReport.micronutrients.items.map(
                  (micronutrient) =>
                    micronutrient.code === item &&
                    micronutrient.foods.map((food, index) => (
                      <div
                        className={
                          ('swiper-slide q-nav-swiper-slide space-slider',
                          classes.transparentBg)
                        }
                        key={index}
                      >
                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                          <img
                            src={food.image_url}
                            height={'50px'}
                            width={'50px'}
                            alt={`${food.name}-icon`}
                          />
                          <Typography variant="body1">{food.name}</Typography>
                        </div>
                      </div>
                    ))
                )}
            </HorizontalSwiper>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  };

  return (
    <GridContainer
      direction="column"
      className={
        category === 'Nutrition'
          ? classes.nutritionTipComponent
          : classes.component
      }
      style={{
        paddingTop: '20px',
        paddingBottom: '20px',
        borderBottom: '1px solid #e5e5e5',
      }}
    >
      <GridItem
        style={{
          marginBottom: '20px',
        }}
      >
        <Typography variant="body2">
          {t('home.personalizedTips.title')}
        </Typography>
      </GridItem>
      <GridItem
        style={{
          marginBottom: '20px',
        }}
      >
        {title && <Typography variant="h5">{title}</Typography>}
      </GridItem>
      {image && (
        <GridItem
          style={{
            marginBottom: '20px',
          }}
        >
          <img
            src={image}
            alt="lifestyle_image"
            style={{
              maxWidth: window.innerWidth < 800 ? '100%' : '500px',
              display: 'block',
            }}
          />
        </GridItem>
      )}
      <GridItem>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: stylizeHtml(text),
          }}
        ></Typography>
      </GridItem>

      {category === 'Nutrition' &&
        needs &&
        needs.length > 0 &&
        needs.map((item) => renderRecommendedFoods(item))}
    </GridContainer>
  );
}
