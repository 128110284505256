import React from 'react';
import { useTranslation } from 'react-i18next';

import { backgroundLanding } from 'assets/imagesList';

import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';

export default function PregnancyIntro(props) {
  const { t } = useTranslation();

  return (
    <ContainerWithBackgroundImg
      image={backgroundLanding}
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        maxWidth="300px"
        position="relative"
        pr={{ xs: 6 }}
        pl={{ xs: 6 }}
        left={{ sm: '27%' }}
        top={{ sm: '25%', xs: '40%' }}
      >
        <Typography variant="h1" textSize={35}>
          {t('onboarding.pregnancySection.intro')}
        </Typography>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
