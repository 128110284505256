import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useActions, useOvermindState } from 'overmind/index';
import CookiesConsentDialog from 'components/organisms/ModalWindow/CookiesConsentDialog';

import {
  ROUTE_LANDING_PAGE,
  ROUTE_ONBOARDING,
  ROUTE_REGISTER_KIT,
  ROUTE_INTRO,
  ROUTE_ASSESSMENT,
  ROUTE_HOME,
  ROUTE_MEAL_PLAN,
  ROUTE_MONITOR,
  ROUTE_PROFILE,
  ROUTE_RESET_USER_DATA,
  ROUTE_MOBILE_LOGIN_PAGE,
  ROUTE_MOBILE_LOGOUT_PAGE,
  ROUTE_START,
  ROUTE_ACTIVATION,
  EMAIL_VERIFICATION,
  ROUTE_THANK_YOU,
  ROUTE_THANK_YOU_CHAT_SUBSCRIPTION,
  ROUTE_PAY_SUBSCRIPTION,
  ROUTE_LANDING_PAGE_WITH_CUSTOM_LOGIN,
  ROUTE_MOBILE_LOGIN_PAGE_NEW,
} from 'routes';

import IntroPage from 'components/pages/Onboarding/Intro/IntroPage/IntroPage';
import LandingPage from 'components/pages/Onboarding/LandingPage/LandingPage';
import Onboarding from 'components/pages/Onboarding/Onboarding';
import Home from 'components/pages/Assessment/Home';
import Assessment from 'components/pages/Assessment/Assessment';
import MealPlan from 'components/pages/Assessment/MealPlan';
import Monitor from 'components/pages/Assessment/Monitor';
import Profile from 'components/pages/Assessment/Profile';
import ResetUserData from 'components/pages/ResetUserData/ResetUserData';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import MobileLoginPage from 'components/pages/MobileLoginPage/MobileLoginPage';
import KitRegistrationPage from 'components/pages/DnaTemp/KitRegistrationPage';
import StartPage from 'components/pages/Start/StartPage';
import ThankYouPage from 'components/pages/ThankYouPage/ThankYouPage';
import PaymentPage from 'components/pages/Payment/PaymentPage';
import Box from 'components/atoms/Layout/Box/Box';

import {
  onboardingSteps,
  calculatorSteps,
} from 'components/helpers/onboardingHelper';
import MobileLogoutPage from 'components/pages/MobileLoginPage/MobileLogoutPage';
import ThankYouChatSubscriptionPage from 'components/pages/ThankYouPage/ThankYouChatSubscriptionPage';
import LandingPageWithCustomLogin from 'components/pages/Onboarding/LandingPage/LandingPageWithCustomLogin';
import {
  initializeTracking,
  initializeMixpanelTracking,
  trackPageVisit,
} from 'components/helpers/userTrackingEventsHelper';
import { ContactSupportOutlined } from '@material-ui/icons';
import NewLogin from 'components/pages/Onboarding/LandingPage/NewLogin';

var mixpanel = require('mixpanel-browser');

const cookiesConsent = window.localStorage.getItem('cookies_consent');

export default function App() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();
  let location = useLocation();
  const { isAuthenticated } = useAuth0();
  const [openCookiesConsentDialog, setOpenCookiesConsentDialog] = useState(
    window.localStorage.getItem('cookies_consent') == null &&
      !window.location === '/mobile-login-page-new'
  );
  const [startedTracking, setStartedTracking] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem('access_token');
    if (isAuthenticated && token) {
      actions.onInitialize();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (cookiesConsent === 'true' && !startedTracking) {
      initializeTracking();
      initializeMixpanelTracking();
      setStartedTracking(true);
    }
  }, []);

  useEffect(() => {
    const profileId = overmindState.profile.id;
    if (cookiesConsent === 'true' && profileId) {
      mixpanel.identify(profileId);
      mixpanel.people.set({
        USER_ID: profileId,
      });
      trackEvent('Authentication', 'SignedIn');
    }
  }, [overmindState.profile.id]);

  useEffect(() => {
    if (cookiesConsent === 'true') {
      if (location.pathname === '/start') {
        trackPageVisit(
          `${location.pathname}/${calculatorSteps[overmindState.progressStep]}`
        );
      } else if (location.pathname === '/onboarding') {
        trackPageVisit(
          `${location.pathname}/${onboardingSteps[overmindState.progressStep]}`
        );
      } else {
        trackPageVisit(location.pathname);
      }
    }

    //Added this to clear query params after user clicks on verification link
    if (
      location.pathname.includes('/account/verified') &&
      location.search.includes('?email')
    ) {
      const queryParams = '';
      history.replace({
        search: queryParams,
      });
    }
  }, [overmindState.progressStep, location]);

  return (
    <Box>
      <Switch>
        <Route
          exact
          path={ROUTE_LANDING_PAGE}
          component={LandingPageWithCustomLogin}
        />
        <Route
          exact
          path={ROUTE_ACTIVATION}
          component={LandingPageWithCustomLogin}
        />
        <Route
          exact
          path={EMAIL_VERIFICATION}
          component={LandingPageWithCustomLogin}
        />
        <Route exact path={ROUTE_INTRO} component={IntroPage} />
        <Route exact path={ROUTE_ONBOARDING} component={Onboarding} />
        <Route
          exact
          path={ROUTE_REGISTER_KIT}
          component={KitRegistrationPage}
        />
        <Route exact path={ROUTE_HOME} component={Home} />
        <Route exact path={ROUTE_ASSESSMENT} component={Assessment} />
        <Route exact path={ROUTE_MEAL_PLAN} component={MealPlan} />
        <Route exact path={ROUTE_MONITOR} component={Monitor} />
        <Route exact path={ROUTE_PROFILE} component={Profile} />
        <Route exact path={ROUTE_RESET_USER_DATA} component={ResetUserData} />
        <Route
          exact
          path={ROUTE_MOBILE_LOGIN_PAGE}
          component={MobileLoginPage}
        />
        <Route
          exact
          path={ROUTE_MOBILE_LOGOUT_PAGE}
          component={MobileLogoutPage}
        />
        <Route exact path={ROUTE_START} component={StartPage} />
        <Route exact path={ROUTE_THANK_YOU} component={ThankYouPage} />
        <Route
          exact
          path={ROUTE_THANK_YOU_CHAT_SUBSCRIPTION}
          component={ThankYouChatSubscriptionPage}
        />
        <Route exact path={ROUTE_PAY_SUBSCRIPTION} component={PaymentPage} />
        <Route
          exact
          path={ROUTE_LANDING_PAGE_WITH_CUSTOM_LOGIN}
          component={LandingPageWithCustomLogin}
        />
        <Route exact path={ROUTE_MOBILE_LOGIN_PAGE_NEW} component={NewLogin} />
      </Switch>
      {openCookiesConsentDialog && (
        <CookiesConsentDialog
          closeDialog={() => setOpenCookiesConsentDialog(false)}
        />
      )}
    </Box>
  );
}
