import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Divider from '@material-ui/core/Divider';

export default function Question(props) {
  const { title, subtitle, children, divider } = props;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer style={{ marginBottom: '24px' }}>
          {title && (
            <GridItem xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5">{title}</Typography>
            </GridItem>
          )}
          {subtitle && (
            <GridItem xs={12} style={{ textAlign: 'center' }}>
              <Typography
                variant={
                  props.subtitleStyle ? props.subtitleStyle : 'subtitle1'
                }
              >
                {subtitle}
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
      {children && (
        <GridItem xs={12} style={{ marginBottom: '24px' }}>
          {children}
        </GridItem>
      )}
      {divider && (
        <GridItem xs={12} style={{ marginBottom: '24px' }}>
          {divider ? <Divider /> : null}
        </GridItem>
      )}
    </GridContainer>
  );
}

Question.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.object,
};
