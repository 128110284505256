import React, { useEffect } from 'react';
import { useActions, useOvermindState } from 'overmind/index';

import { ThemeProvider } from '@material-ui/core/styles';
import { dnaTheme } from 'themes';

import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import Slider from 'components/molecules/Slider/Slider';

import HasDnaPage from 'components/pages/Onboarding/DNA/HasDnaPage';
import KitNumberInputPage from 'components/pages/Onboarding/DNA/KitNumberInputPage';

import { isIntroStep } from 'components/helpers/progressIndicatorHelper';
import OurGenomicsIntro from 'components/pages/Onboarding/DNA/OurGenomicsIntro';
import { getStepIndex } from 'components/helpers/onboardingHelper';
import OnboardingFinished from 'components/pages/Onboarding/OnboardingFinished/OnboardingFinished';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import KitNotReceived from 'components/pages/Onboarding/DNA/KitNotReceived';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import { ROUTE_ONBOARDING, ROUTE_HOME } from 'routes';

const { PUBLIC_URL } = process.env;

export default function KitRegistrationPage() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (overmindState.metaData.user_metadata.dnaKitReceived !== true) {
      if (overmindState.metaData.user_metadata.app_ready_to_use_page_seen) {
        // user comes from onboarding
        actions.goToStep(getStepIndex('our_genomics_intro'));
      } else {
        // user comes directly
        actions.goToStep(getStepIndex('kit_number_input_page'));
      }
    }
  }, [overmindState.metaData.user_metadata.dnaKitReceived, actions]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        overmindState.profile == null ||
        Object.keys(overmindState.profile).length === 0
      ) {
        actions.fetchUserMetadata();
        actions.fetchProfile();
      }
    } else {
      loginWithRedirect({
        redirectUri: `${window.location.origin}${PUBLIC_URL}?kitregister=true`,
      });
    }
  }, [overmindState.profile, actions]);

  return (
    <>
      {overmindState.profile && Object.keys(overmindState.profile).length > 0 && (
        <React.Fragment>
          <HeaderWrapper />
          {window.innerWidth > 800 ? (
            <GridContainer
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GridItem
                style={{
                  maxWidth: '1200px',
                }}
              >
                <SliderWrapper />
              </GridItem>
            </GridContainer>
          ) : (
            <>
              <SliderWrapper />
            </>
          )}
          {overmindState.metaData.user_metadata &&
            overmindState.metaData.user_metadata.terms_9moons && (
              <FooterWrapper />
            )}
        </React.Fragment>
      )}
    </>
  );
}

export const HeaderWrapper = () => {
  const overmindState = useOvermindState();

  return (
    <Header
      hideHeader={overmindState.hideHeader}
      hideLeftButton={
        getStepIndex('our_genomics_intro') &&
        overmindState.metaData.user_metadata &&
        overmindState.metaData.user_metadata.seen_intro_9moons !== true
      }
      adjustMargin={isIntroStep(overmindState.progressStep)}
    />
  );
};

export const FooterWrapper = () => {
  const actions = useActions();

  return (
    <Footer
      onBackClick={() => {
        actions.goBack();
      }}
      onNextClick={() => {
        actions.goNext();
      }}
    />
  );
};

export function SliderWrapper() {
  const overmindState = useOvermindState();
  const renderStep = (step) => {
    switch (step) {
      case getStepIndex('intro_dna'):
      case getStepIndex('our_genomics_intro'):
      case getStepIndex('has_dna_page'):
      case getStepIndex('kit_not_received_page'):
      case getStepIndex('kit_number_input_page'):
        return (
          <ThemeProvider theme={dnaTheme}>
            {renderDnaSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('onboarding_finished'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <OnboardingFinished />
          </ThemeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderDnaSectionStep = (step) => {
    switch (step) {
      case getStepIndex('our_genomics_intro'):
        return <OurGenomicsIntro />;
      case getStepIndex('has_dna_page'):
        return <HasDnaPage />;
      case getStepIndex('kit_number_input_page'):
        return <KitNumberInputPage />;
      case getStepIndex('kit_not_received_page'):
      default:
        return <KitNotReceived />;
    }
  };

  const requiredRedirectionToOnboarding = () => {
    return (
      overmindState.progressStep === getStepIndex('kit_number_input_page') &&
      overmindState.metaData.user_metadata.completed_kitregistration_9moons &&
      overmindState.metaData.user_metadata.completed_onboarding_9moons !== true
    );
  };

  const requiredRedirectionToHome = () => {
    return (
      overmindState.progressStep === getStepIndex('kit_number_input_page') &&
      overmindState.metaData.user_metadata.completed_kitregistration_9moons &&
      overmindState.metaData.user_metadata.completed_onboarding_9moons === true
    );
  };

  return requiredRedirectionToOnboarding() ? (
    <Redirect to={ROUTE_ONBOARDING} />
  ) : requiredRedirectionToHome() ? (
    <Redirect to={ROUTE_HOME} />
  ) : window.innerWidth < 800 ? (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderStep(overmindState.progressStep)}
    </Slider>
  ) : (
    <>{renderStep(overmindState.progressStep)}</>
  );
}
