import React from 'react';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Box from 'components/atoms/Layout/Box/Box';
import { useIntroPageStyles } from './introPageStyles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function IntroPageSlider(props) {
  const classes = useIntroPageStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ marginTop: '30px', marginBottom: '50px' }}
    >
      <Box
        width="80%"
        style={{
          maxWidth: '400px',
          marginTop: '50px',
          marginBottom: '100px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" className={classes.title}>
          {props.titleText}
        </Typography>
      </Box>

      <Box width="80%" style={{ maxWidth: '400px' }}>
        <Typography variant="body2" className={classes.text}>
          {props.introText}
        </Typography>
      </Box>
      {props.skipButtonFunction && props.buttonText && (
        <Box
          style={{
            marginTop: '100px',
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => props.skipButtonFunction()}
            className={classes.skipButton}
          >
            <>
              {props.buttonText} <NavigateNextIcon />
            </>
          </Button>
        </Box>
      )}
    </Box>
  );
}
