import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import NavigationHeader from './NavigationHeader';

export default function ReoccurringHeader(props) {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener('scroll', (e) => handleScroll(e));
    };
  }, [y]);

  const handleScroll = (e) => {
    const window = e.currentTarget;
    setY(window.scrollY);
  };

  const getBackgroundOpacity = () => {
    return 1 / (100 / y);
  };

  const getTextColor = () => {
    if (props.whiteText && getBackgroundOpacity() < 1) {
      return '#ffffff';
    } else {
      return '#272429';
    }
  };

  return (
    <NavigationHeader
      title={props.title}
      showBack={props.showBack}
      goBack={() => {
        window.scrollTo(0, 0);
        props.goBack();
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: `rgba(255, 255, 255, ${getBackgroundOpacity()})`,
        color: getTextColor(),
        ...props.style,
      }}
      addMargin={props.addMargin}
    />
  );
}

ReoccurringHeader.propTypes = {
  hideHeader: PropTypes.bool,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.func,
};
