import React from 'react';
import PropTypes from 'prop-types';
import MuiBox from '@material-ui/core/Box';

export default function Box(props) {
  const { children, ...rest } = props;
  return <MuiBox {...rest}>{children}</MuiBox>;
}

Box.propTypes = {
  children: PropTypes.node,
};
