import {
  swiperGeneralActiveIcon,
  swiperGeneralInactiveIcon,
  swiperExerciseActiveIcon,
  swiperExerciseInactiveIcon,
  swiperFoodActiveIcon,
  swiperFoodInactiveIcon,
  swiperLifestyleActiveIcon,
  swiperLifestyleInactiveIcon,
  swiperPregnancyActiveIcon,
  swiperPregnancyInactiveIcon,
} from 'assets/iconsList';
import { getStepIndex, getCalculatorStepIndex } from './onboardingHelper';

export const getProgressItems = () => [
  {
    id: 1,
    label: 'GENERAL',
    hideLeftDots: true,
    selectedIcon: swiperGeneralActiveIcon,
    notSelectedIcon: swiperGeneralInactiveIcon,
    introStepIds: [getStepIndex('general_intro')],
    stepIds: [getStepIndex('name_and_age_page'), getStepIndex('bmi_page')],
  },
  {
    id: 2,
    label: 'PREGNANCY',
    hideRightDots: false,
    selectedIcon: swiperPregnancyActiveIcon,
    notSelectedIcon: swiperPregnancyInactiveIcon,
    introStepIds: [getStepIndex('pregnancy_intro_page')],
    stepIds: [
      getStepIndex('due_data_page'),
      getStepIndex('last_period_page'),
      getStepIndex('congratulations_page'),
      getStepIndex('children_question_page'),
    ],
  },
  {
    id: 3,
    label: 'EXERCISE',
    selectedIcon: swiperExerciseActiveIcon,
    notSelectedIcon: swiperExerciseInactiveIcon,
    introStepIds: [getStepIndex('exercise_intro')],
    stepIds: [getStepIndex('exercise_per_day')],
  },
  {
    id: 4,
    label: 'LIFESTYLE',
    selectedIcon: swiperLifestyleActiveIcon,
    notSelectedIcon: swiperLifestyleInactiveIcon,
    introStepIds: [getStepIndex('lifestyle_intro')],
    stepIds: [getStepIndex('lifestyle_sleep_and_stress')],
  },
  {
    id: 5,
    label: 'FOOD',
    hideRightDots: true,
    selectedIcon: swiperFoodActiveIcon,
    notSelectedIcon: swiperFoodInactiveIcon,
    introStepIds: [getStepIndex('food_intro')],
    stepIds: [
      getStepIndex('dietary_preferences_page'),
      getStepIndex('food_allergies_page'),
    ],
  },
];

export const getCalculatorProgressItems = () => [
  {
    id: 1,
    label: 'GENERAL',
    hideLeftDots: true,
    selectedIcon: swiperGeneralActiveIcon,
    notSelectedIcon: swiperGeneralInactiveIcon,
    introStepIds: [getCalculatorStepIndex('general_intro')],
    stepIds: [
      getCalculatorStepIndex('general_intro'),
      getCalculatorStepIndex('name'),
      getCalculatorStepIndex('age'),
      getCalculatorStepIndex('height'),
      getCalculatorStepIndex('weight'),
    ],
  },
  {
    id: 2,
    label: 'PREGNANCY',
    hideRightDots: true,
    selectedIcon: swiperPregnancyActiveIcon,
    notSelectedIcon: swiperPregnancyInactiveIcon,
    introStepIds: [],
    stepIds: [
      getCalculatorStepIndex('your_first_baby_page'),
      getCalculatorStepIndex('first_baby_week'),
      getCalculatorStepIndex('post_term_pregnancy_page'),
      getCalculatorStepIndex('have_you_been_diagnosed'),
      getCalculatorStepIndex('how_often_do_you_smoke'),
      getCalculatorStepIndex('how_stressful_is_your_file'),
      getCalculatorStepIndex('do_you_know_your_due_date'),
      getCalculatorStepIndex('cycle_length'),
      getCalculatorStepIndex('conception_experience'),
      getCalculatorStepIndex('date_of_transfer'),
      getCalculatorStepIndex('do_you_know_last_period'),
      getCalculatorStepIndex('do_you_know_when_you_have_conceived'),
      getCalculatorStepIndex('first_name'),
      getCalculatorStepIndex('capture_email'),
      getCalculatorStepIndex('calculate_due_date'),
    ],
  },
];

export const getSectionByStepId = (stepId) => {
  const progressItems = getProgressItems();
  return progressItems.find((i) => i.stepIds.includes(stepId));
};

export const getCalculatorSectionByStepId = (stepId) => {
  const progressItems = getCalculatorProgressItems();
  return progressItems.find((i) => i.stepIds.includes(stepId));
};

export const getSectionBySectionId = (sectionId) => {
  const progressItems = getProgressItems();
  return progressItems.find((i) => i.id === sectionId);
};

export const getFirstStepInSection = (sectionId) => {
  return getSectionBySectionId(sectionId).stepIds[0];
};

export const isIntroStep = (step) => {
  const progressItems = getProgressItems();
  const firstSectionIntroId = progressItems[0].introStepIds[0];
  const lastSectionStepIds = progressItems[progressItems.length - 1].stepIds;

  return (
    step < firstSectionIntroId ||
    step > lastSectionStepIds[lastSectionStepIds.length - 1] ||
    progressItems.find((i) => i.introStepIds.includes(step))
  );
};
