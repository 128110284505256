import { useEffect } from 'react';
import { useActions } from 'overmind/index';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function ResetUserData() {
  const { PUBLIC_URL } = process.env;
  const actions = useActions();
  const history = useHistory();

  const { logout } = useAuth0();

  useEffect(() => {
    actions.deleteUserData().then(() => {
      window.localStorage.removeItem('seenLandingPage');
      logout({ returnTo: `${window.location.origin}${PUBLIC_URL}` });
      window.localStorage.removeItem('access_token');
    });
  }, [actions, history, logout]);

  return <>{'Deleting user data...'}</>;
}
