import React from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import AgeQuestion from 'components/organisms/Questions/General/AgeQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
let today = new Date();

export default function YourAge(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);

  const calculateYearOfBirth = (age) => {
    return today.getFullYear() - age;
  };

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <AgeQuestion
          title={t('onboarding.generalSection.step1.ageTitle')}
          age={overmindState.calc_age}
          onChange={(newAge) => {
            actions.updateStateProp({
              key: 'calc_age',
              value: newAge,
            });
            actions.updateStateProp({
              key: 'calc_year_of_birth',
              value: calculateYearOfBirth(newAge),
              nextEnabled: newAge >= 19 && newAge <= 99,
            });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
