import * as React from 'react';

function MonitorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.092"
      height="23.093"
      viewBox="0 0 25.092 15"
    >
      <g
        id="Group_5370"
        data-name="Group 5370"
        transform="translate(20570 -965)"
      >
        <g
          id="Group_5369"
          data-name="Group 5369"
          transform="translate(-20746 -1243)"
        >
          <line
            id="Line_1383"
            data-name="Line 1383"
            y2="14"
            transform="translate(176.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1384"
            data-name="Line 1384"
            y2="14"
            transform="translate(180.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1385"
            data-name="Line 1385"
            y2="14"
            transform="translate(184.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1386"
            data-name="Line 1386"
            y2="14"
            transform="translate(188.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1387"
            data-name="Line 1387"
            y2="14"
            transform="translate(192.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1388"
            data-name="Line 1388"
            y2="14"
            transform="translate(196.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <line
            id="Line_1389"
            data-name="Line 1389"
            y2="14"
            transform="translate(200.5 2208.5)"
            fill="none"
            stroke={props.color}
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_22420"
          data-name="Path 22420"
          d="M-23381.029-2163.5s.621-7.081,6.5-7.86,5.846,4.956,9.76,4.5c5.377.469,7.852-9.2,7.852-9.2"
          transform="translate(2811.529 3143)"
          fill="none"
          stroke={props.color}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default MonitorIcon;
