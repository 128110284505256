import React from 'react';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useButtonStyles } from './buttonStyles';

/**
 * Default Button component based on https://material-ui.com/components/buttons/
 * @param {} props All possible props can be found in docs https://material-ui.com/api/button/
 */
export default function Button(props) {
  const { children, ...rest } = props;
  const classes = useButtonStyles();

  return (
    <MuiButton
      disableElevation
      color="primary"
      size="medium"
      {...rest}
      classes={{
        ...classes,
      }}
    >
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  /**
   * Button variant
   */
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  /**
   * What background color to use
   */
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Should it be disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Button contents
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  onClick: () => {
    console.log('BUTTON CLICKED');
  },
};
