import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import SleepQuestion from 'components/organisms/Questions/Lifestyle/SleepQuestion';
import StressQuestion from 'components/organisms/Questions/Lifestyle/StressQuestion';

import {
  lifestyleSliderSleepIcon1,
  lifestyleSliderSleepIcon2,
  lifestyleSliderSleepIcon3,
  lifestyleSliderSleepIcon4,
  lifestyleSliderSleepIcon5,
  lifestyleSliderStressIcon1,
  lifestyleSliderStressIcon2,
  lifestyleSliderStressIcon3,
  lifestyleSliderStressIcon4,
  lifestyleSliderStressIcon5,
} from 'assets/iconsList';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function LifestyleSleepAndStressPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
          marginBottom: '20px',
        }}
      >
        <SleepQuestion
          title={t('onboarding.lifestyleSection.step1.question1.title')}
          data={[
            {
              illustration: lifestyleSliderSleepIcon1,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide1'),
            },
            {
              illustration: lifestyleSliderSleepIcon2,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide2'),
            },
            {
              illustration: lifestyleSliderSleepIcon3,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide3'),
            },
            {
              illustration: lifestyleSliderSleepIcon4,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide4'),
            },
            {
              illustration: lifestyleSliderSleepIcon5,
              height: 85,
              text: t('onboarding.lifestyleSection.step1.question1.slide5'),
            },
          ]}
          selectedIllustrationIndex={
            overmindState.profilePregnancy.sleep_quality
              ? overmindState.profilePregnancy.sleep_quality
              : 0
          }
          onChange={(index) => action.changeLifestyleSleepLevel(index)}
        />
      </GridItem>
      {overmindState.metaData.user_metadata.usedCalculator !== true && (
        <GridItem
          style={{
            maxWidth: '700px',
            marginBottom: '20px',
          }}
        >
          <StressQuestion
            title={t('onboarding.lifestyleSection.step1.question2.title')}
            data={[
              {
                illustration: lifestyleSliderStressIcon1,
                height: 85,
                text: t('onboarding.lifestyleSection.step1.question2.slide1'),
              },
              {
                illustration: lifestyleSliderStressIcon2,
                height: 85,
                text: t('onboarding.lifestyleSection.step1.question2.slide2'),
              },
              {
                illustration: lifestyleSliderStressIcon3,
                height: 85,
                text: t('onboarding.lifestyleSection.step1.question2.slide3'),
              },
              {
                illustration: lifestyleSliderStressIcon4,
                height: 85,
                text: t('onboarding.lifestyleSection.step1.question2.slide4'),
              },
              {
                illustration: lifestyleSliderStressIcon5,
                height: 85,
                text: t('onboarding.lifestyleSection.step1.question2.slide5'),
              },
            ]}
            selectedIllustrationIndex={
              overmindState.profilePregnancy.stress_level
                ? overmindState.profilePregnancy.stress_level
                : 0
            }
            onChange={(index) => action.changeLifestyleStressLevelIndex(index)}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}
