import React, { useState } from 'react';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import HasDnaQuestion from 'components/organisms/Questions/DNA/HasDnaQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/atoms/Typography/Typography';

/**
 * Questionnaire page asking user if user has DNA.
 * Exists for presentation in StoryBook. Actual page implemented as HasDnaPage component.
 */

export default function HasDna(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  // const [questionAnswered, setQuestionAnswered] = useState(
  //   props.selectedWantsToUseValue === '2'
  // );

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <HasDnaQuestion
          title={t('onboarding.dnaSection.step1.title')}
          selectedQuestion={props.selectedWantsToUseValue}
          onQuestionAnswered={(val) => {
            if (props.onWantsToUseDna) {
              props.onWantsToUseDna(val);
            }
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
