import React, { useEffect, useState } from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import SignUp from 'components/organisms/SingUp/SignUp';
import SignIn from 'components/organisms/SignIn/SignIn';
import { useAuth0 } from '@auth0/auth0-react';
import { useActions } from 'overmind/index';
import * as auth0 from 'auth0-js';
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  resetHash,
  isCookieError,
  getRedirectUriForEmbeddedSignIn,
} from 'components/helpers/auth0Helper';
import LandingModal from 'components/molecules/LandingModal/LandingModal';
import { ROUTE_MOBILE_LOGIN_PAGE_NEW } from 'routes';

export default function NewLogin() {
  const actions = useActions();
  const { getAccessTokenSilently, user, isAuthenticated, logout } = useAuth0();

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [showSignIn] = useState(
    window.location.search == null ||
      !window.location.search.startsWith('?signin=true')
  );
  const [showSignUp, setShowSignUp] = useState(
    window.location.search && window.location.search.startsWith('?signup=true')
  );
  const [autoLogin, setAutoLogin] = useState(
    window.location.search && window.location.search.startsWith('?username=')
  );
  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);
  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}${ROUTE_MOBILE_LOGIN_PAGE_NEW}`,
      responseType: 'code token',
    })
  );

  const hasAuthenticationToken = () => {
    return window.localStorage.getItem('access_token') != null;
  };

  const isReturnedFromAuth0SignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') === -1
    );
  };

  const isReturnedFromAuth0CustomSignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') !== -1
    );
  };

  const isNewAuthenticationRequested = () => {
    return (
      window.location.search && window.location.search.indexOf('auth=') !== -1
    );
  };

  const processCodeAndFetchMetaData = () => {
    let code = window.location.search.substring(
      window.location.search.indexOf('?code=') + 6
    );
    if (code.indexOf('&state=') !== -1) {
      code = code.substring(0, code.indexOf('&state='));
    }
    const verifier = window.localStorage.getItem('verifier');
    const queryString = new URLSearchParams();
    queryString.append('grant_type', 'authorization_code');
    queryString.append('client_id', REACT_APP_AUTH0_CLIENT_ID);
    queryString.append('code_verifier', verifier);
    queryString.append('code', code);
    queryString.append('redirect_uri', getRedirectUriForEmbeddedSignIn());
    actions.getAuthenticationTokensAndMetaData(queryString);
  };

  useEffect(() => {
    const hash = window.location.hash;
    webAuth.parseHash({ hash: hash }, (err, authResult) => {
      if (err) {
        if (isCookieError(err.errorDescription)) {
          setWarningContent(
            'Sign up is not possible because 3rd party cookies are disabled!'
          );
          setShowWarning(true);
        } else {
          setWarningContent(err.description);
          setShowWarning(true);
        }
        // we need to reset hash in case user does not delete the hash from URL
        resetHash();
      } else if (authResult) {
        window.localStorage.setItem('access_token', authResult.accessToken);
        signIn();
      }
    });
  }, [window.location]);

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search.indexOf('utm_campaign') !== -1
    ) {
      window.localStorage.setItem('qs', window.location.search);
    }

    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((newToken) => {
          window.localStorage.setItem('access_token', newToken);
          signIn({ silentSignIn: false });
          console.log('Pushing new token');
          window &&
            window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(newToken);
        })
        .catch(() => {
          if (user) {
            let url = getRedirectUriForEmbeddedSignIn();
            logout({
              returnTo: url,
            });
          }
        });
    }
  }, [isAuthenticated]);

  const signInSilently = async () => {
    try {
      const token = await getAccessTokenSilently();
      window.localStorage.setItem('access_token', token);
      signIn({ silentSignIn: false });
    } catch (e) {
      console.log('e ', e.error);
      if (e.error === 'login_required') {
        // if Login is required, then we have to do that
        // (that's what Auth0 does in https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token)
        setIsLoadingUserData(false);
      }
      if (e.error === 'consent_required') {
        // If Consent is required, then we have to do that
        // (that's what Auth0 does in https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token)
        setIsLoadingUserData(false);
      }
    }
  };

  const signIn = (options) => {
    if (hasAuthenticationToken() && !isNewAuthenticationRequested()) {
      const token = window.localStorage.getItem('access_token');
      console.log('Pushing token');
      window &&
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(token);
    } else {
      if (isReturnedFromAuth0CustomSignIn() && options.silentSignIn) {
        signInSilently();
      } else if (isReturnedFromAuth0SignIn()) {
        // user which isn't authenticated, but is returning from Auth0 SignIn
        processCodeAndFetchMetaData();
      } else {
        // stay here
        setIsLoadingUserData(false);
      }
    }
  };

  useEffect(() => {
    signIn({ silentSignIn: true });
  }, []);

  const handleLandingModalClick = () => {
    setShowWarning(false);
  };

  return (
    <Box
      style={{
        // display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#E8D6C0',
        minHeight: '100vh',
      }}
    >
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
        onClick={() => handleLandingModalClick()}
      />
      {isLoadingUserData && !showSignIn && (
        <Box
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Loading...
        </Box>
      )}
      {((!isLoadingUserData && !autoLogin) || showSignIn) && (
        <Box justifyContent="center" align="center">
          {showSignUp ? (
            <SignUp
              toggleForm={() => setShowSignUp((oldVal) => !oldVal)}
              signIn={() => {
                alert(getRedirectUriForEmbeddedSignIn());
                window.location = getRedirectUriForEmbeddedSignIn();
              }}
            />
          ) : (
            <SignIn toggleForm={() => setShowSignUp((oldVal) => !oldVal)} />
          )}
        </Box>
      )}
    </Box>
  );
}
