import i18n from 'i18next';

export const getAdviceText = (bmi, weightGain, trimester, shortVersion) => {
  if (trimester === 1) {
    if (bmi <= 18.5 && weightGain <= 1.4)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option1.short')
        : i18n.t('monitor.weightAdvices.trimester1.option1');
    else if (bmi <= 18.5 && weightGain > 1.4 && weightGain <= 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option2.short')
        : i18n.t('monitor.weightAdvices.trimester1.option2');
    else if (bmi <= 18.5 && weightGain > 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option3.short')
        : i18n.t('monitor.weightAdvices.trimester1.option3');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain <= 1)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option4.short')
        : i18n.t('monitor.weightAdvices.trimester1.option4');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain < 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option5.short')
        : i18n.t('monitor.weightAdvices.trimester1.option5');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain >= 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option6.short')
        : i18n.t('monitor.weightAdvices.trimester1.option6');
    else if (bmi >= 25 && weightGain <= 0.6)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option6.short')
        : i18n.t('monitor.weightAdvices.trimester1.option7');
    else if (bmi >= 25 && weightGain > 0.6 && weightGain <= 0.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option8')
        : i18n.t('monitor.weightAdvices.trimester1.option8');
    else
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester1.option9.short')
        : i18n.t('monitor.weightAdvices.trimester1.option9');
  } else if (trimester === 2) {
    if (bmi <= 18.5 && weightGain <= 1.4)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option1.short')
        : i18n.t('monitor.weightAdvices.trimester2.option1');
    else if (bmi <= 18.5 && weightGain > 1.4 && weightGain <= 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option2.short')
        : i18n.t('monitor.weightAdvices.trimester2.option2');
    else if (bmi <= 18.5 && weightGain > 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option3.short')
        : i18n.t('monitor.weightAdvices.trimester2.option3');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain <= 1)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option4.short')
        : i18n.t('monitor.weightAdvices.trimester2.option4');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain > 1 && weightGain <= 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option5.short')
        : i18n.t('monitor.weightAdvices.trimester2.option5');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain > 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option6.short')
        : i18n.t('monitor.weightAdvices.trimester2.option6');
    else if (bmi >= 25 && weightGain <= 0.6)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option7.short')
        : i18n.t('monitor.weightAdvices.trimester2.option7');
    else if (bmi >= 25 && weightGain <= 0.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option8.short')
        : i18n.t('monitor.weightAdvices.trimester2.option8');
    else
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester2.option9.short')
        : i18n.t('monitor.weightAdvices.trimester2.option9');
  } else if (trimester === 3) {
    if (bmi <= 18.5 && weightGain <= 1.4)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option1.short')
        : i18n.t('monitor.weightAdvices.trimester3.option1');
    else if (bmi <= 18.5 && weightGain > 1.4 && weightGain <= 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option2.short')
        : i18n.t('monitor.weightAdvices.trimester3.option2');
    else if (bmi <= 18.5 && weightGain > 1.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option3.short')
        : i18n.t('monitor.weightAdvices.trimester3.option3');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain <= 1)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option4.short')
        : i18n.t('monitor.weightAdvices.trimester3.option4');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain > 1 && weightGain <= 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option5.short')
        : i18n.t('monitor.weightAdvices.trimester3.option5');
    else if (bmi > 18.5 && bmi <= 24.9 && weightGain > 1.2)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option6.short')
        : i18n.t('monitor.weightAdvices.trimester3.option6');
    else if (bmi >= 25 && weightGain <= 0.6)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option7.short')
        : i18n.t('monitor.weightAdvices.trimester3.option7');
    else if (bmi >= 25 && weightGain <= 0.7)
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option8.short')
        : i18n.t('monitor.weightAdvices.trimester3.option8');
    else
      return shortVersion
        ? i18n.t('monitor.weightAdvices.trimester3.option9.short')
        : i18n.t('monitor.weightAdvices.trimester3.option9');
  } else {
    return '';
  }
};

export const getWeightGainCategory = (gain, bmi, trimester) => {
  if (trimester === 1) {
    if (bmi <= 18.5 && gain <= 1.4) return 0;
    else if (bmi <= 18.5 && gain > 1.4 && gain <= 1.7) return 1;
    else if (bmi <= 18.5 && gain > 1.7) return 2;
    else if (bmi > 18.5 && bmi <= 24.9 && gain <= 1) return 0;
    else if (bmi > 18.5 && bmi <= 24.9 && gain < 1.2) return 1;
    else if (bmi > 18.5 && bmi <= 24.9 && gain >= 1.2) return 2;
    else if (bmi >= 25 && gain <= 0.6) return 0;
    else if (bmi >= 25 && gain > 0.6 && gain <= 0.7) return 1;
    else return 2;
  } else if (trimester === 2) {
    if (bmi <= 18.5 && gain <= 1.4) return 0;
    else if (bmi <= 18.5 && gain > 1.4 && gain <= 1.7) return 1;
    else if (bmi <= 18.5 && gain > 1.7) return 2;
    else if (bmi > 18.5 && bmi <= 24.9 && gain <= 1) return 0;
    else if (bmi > 18.5 && bmi <= 24.9 && gain > 1 && gain <= 1.2) return 1;
    else if (bmi > 18.5 && bmi <= 24.9 && gain > 1.2) return 2;
    else if (bmi >= 25 && gain <= 0.6) return 0;
    else if (bmi >= 25 && gain <= 0.7) return 1;
    else return 2;
  } else if (trimester === 3) {
    if (bmi <= 18.5 && gain <= 1.4) return 0;
    else if (bmi <= 18.5 && gain > 1.4 && gain <= 1.7) return 1;
    else if (bmi <= 18.5 && gain > 1.7) return 2;
    else if (bmi > 18.5 && bmi <= 24.9 && gain <= 1) return 0;
    else if (bmi > 18.5 && bmi <= 24.9 && gain > 1 && gain <= 1.2) return 1;
    else if (bmi > 18.5 && bmi <= 24.9 && gain > 1.2) return 2;
    else if (bmi >= 25 && gain <= 0.6) return 0;
    else if (bmi >= 25 && gain <= 0.7) return 1;
    else return 2;
  } else {
    return '';
  }
};

export const getLastWeight = (checkins, initialWeight) => {
  if (checkins.length > 1) {
    return checkins[checkins.length - 2].value;
  } else {
    return initialWeight;
  }
};

export const getNumberOfWeeksBetweenWeightInputs = (checkins) => {
  if (checkins.length > 1) {
    return (
      checkins[checkins.length - 1].week_of_pregnancy -
      checkins[checkins.length - 2].week_of_pregnancy
    );
  } else if (checkins.length === 1) {
    return 1;
  } else {
    return checkins[checkins.length - 1].week_of_pregnancy + 1;
  }
};

export const getWeightGain = (checkins, currentWeight) => {
  if (checkins == null || checkins.length === 0) {
    return 0;
  } else {
    return (
      checkins[checkins.length - 1].value -
      getLastWeight(checkins, currentWeight) /
        getNumberOfWeeksBetweenWeightInputs(checkins)
    );
  }
};

export const getBmi = (weight, height) => {
  let bmi = weight / ((height / 100) * (height / 100));
  return bmi;
};
