import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';

export default function Dialog(props) {
  const { open, children, ...rest } = props;

  return (
    <MuiDialog
      open={open}
      onClose={() => {
        props.onClose();
      }}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
}
