import React, { useEffect, useState } from 'react';
import Dialog from 'components/atoms/Dialog/Dialog';
import Slide from '@material-ui/core/Slide';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { MoonList } from 'assets/iconsList';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { ROUTE_PAY_SUBSCRIPTION } from 'routes';

import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import {
  getTodaysHours,
  getDate,
  getIndexOfCurrentDayInWeek,
} from 'components/helpers/dateHelper';
import { getRecipe } from 'components/helpers/planHelper';
import HomeRecipes from 'components/pages/Assessment/Home/HomeRecipes';
import { useHistory } from 'react-router-dom';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentDialog(props) {
  const { openDialog, children, ...rest } = props;
  const { t } = useTranslation();
  const dialogContent = props.dialogContent && props.dialogContent;
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();

  const [hoursToday, setHoursToday] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstRecipe, setFirstRecipe] = useState();
  const [secondRecipe, setSecondRecipe] = useState();
  const [thirdRecipe, setThirdRecipe] = useState();
  const [fourthRecipe, setFourthRecipe] = useState();
  const [firstRecipeMealType, setFirstRecipeMealType] = useState();
  const [secondRecipeMealType, setSecondRecipeMealType] = useState();

  const findRecipe = (mealCode) => {
    return getRecipe(overmindState.mealPlan, mealCode, selectedDate, false);
  };

  useEffect(() => {
    if (openDialog) {
      trackEvent('Payment Popup Displayed', '');
    }
  }, []);

  useEffect(() => {
    if (!overmindState.isProfilePregnancyFetched) {
      actions.fetchProfilePregnancy();
    } else {
      let ix = getIndexOfCurrentDayInWeek(
        getDate(overmindState.profilePregnancy.pregnancy_week_start)
      );
      if (ix === 6) {
        ix = 0;
      } else {
        ix = ix + 1;
      }
      setSelectedDate(ix);
    }
  }, [actions, overmindState.isProfilePregnancyFetched]);

  useEffect(() => {
    if (
      overmindState.isMealPlanLoaded === false &&
      overmindState.isMealPlanFetching !== true
    ) {
      actions.fetchMealPlan();
    }
    setHoursToday(getTodaysHours());
  }, [overmindState.isMealPlanLoaded, actions]);

  useEffect(() => {
    if (
      overmindState.isMealPlanLoaded &&
      hoursToday != null &&
      selectedDate != null
    ) {
      let firstMeal;
      let secondMeal;
      if (hoursToday < 12) {
        firstMeal = 'breakfast';
        secondMeal = 'lunch';
      } else if (hoursToday >= 12 && hoursToday < 18) {
        firstMeal = 'lunch';
        secondMeal = 'dinner';
      } else if (hoursToday >= 18) {
        firstMeal = 'dinner';
        secondMeal = 'snack';
      }

      setFirstRecipe(findRecipe(firstMeal));
      setSecondRecipe(findRecipe(secondMeal));
      setFirstRecipeMealType(firstMeal);
      setSecondRecipeMealType(secondMeal);
    }
  }, [
    overmindState.isMealPlanLoaded,
    JSON.stringify(overmindState.mealPlan),
    hoursToday,
    actions,
    selectedDate,
  ]);

  const renderButton = (buttonLocation) => {
    return (
      <Box marginTop="32px">
        <Button
          onClick={() => {
            trackEvent('Payment Started', 'Button Clicked', {
              location: buttonLocation,
            });
            history.push({
              pathname: ROUTE_PAY_SUBSCRIPTION,
              state: props.redirectState,
            });
          }}
          variant="contained"
          color="primary"
          style={{
            width: '100%',
            maxWidth: '319px',
            height: '43px',
            fontSize: '1.1em',
          }}
        >
          {t('paymentDialog.unlockPlans')}
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={() => {
        props.onCloseDialog();
      }}
      style={{
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '40px',
        marginBottom: '40px',
      }}
      {...rest}
    >
      {props.dialogContent && (
        <Box style={{ margin: '20px', textAlign: 'center' }}>
          <Box marginTop="20px">
            <Typography
              variant="h1"
              style={{
                fontSize: '30px',
                fontFamily: 'MuseoSans-900',
              }}
            >
              {t('paymentDialog.getPlans')}
            </Typography>
          </Box>
          <Box marginTop="14px">
            <Typography variant="h4">{t('paymentDialog.unlock')}</Typography>
          </Box>

          <Box marginTop="32px">
            {renderButton('top')}
            <Box marginTop="14px">
              <Typography
                variant="h1"
                style={{ fontSize: '20px', lineHeight: 'auto' }}
              >
                {t('paymentDialog.whatElseIsThere')}
              </Typography>
            </Box>
          </Box>
          <Box marginTop="25px">
            <HomeRecipes
              isLoaded={overmindState.isMealPlanLoaded}
              firstRecipe={firstRecipe}
              secondRecipe={secondRecipe}
              thirdRecipe={thirdRecipe}
              fourthRecipe={fourthRecipe}
              hideBookmarks={true}
              isPremium={true}
            />
          </Box>

          <GridContainer direction="column">
            {dialogContent.map((item, ix) => (
              <GridItem style={{ marginTop: '20px' }} key={ix}>
                <Box>
                  {item.title && (
                    <Box marginBottom="25px" marginTop="20px">
                      <Typography
                        variant="h1"
                        style={{ fontSize: '20px', lineHeight: 1 }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  )}
                  {item.image && <Box marginBottom="20px">{item.image}</Box>}
                </Box>
                <GridContainer
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <GridItem xs={2}>
                    <MoonList />
                  </GridItem>

                  <GridItem xs={10}>
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      {item.bulletText && item.bulletText}
                    </Typography>
                  </GridItem>
                </GridContainer>
                <GridItem></GridItem>
              </GridItem>
            ))}
          </GridContainer>

          {renderButton('bottom')}
        </Box>
      )}
    </Dialog>
  );
}
