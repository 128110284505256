import React from 'react';
import PropTypes from 'prop-types';
import Meal from 'components/organisms/Meal/Meal';
import HorizontalSwiper from 'components/molecules/HorizontalSwiper/HorizontalSwiper';

import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import { useOvermindState } from 'overmind/index';
import Skeleton from '@material-ui/lab/Skeleton';

export default function MealSwiper(props) {
  const overmindState = useOvermindState();

  if (props.dailyMeals.length === 0) {
    props.getDailyMeals(props.index);
  }

  return (
    <HorizontalSwiper uid={props.uid}>
      {props.dailyMeals && props.dailyMeals.length > 0
        ? props.dailyMeals.map((item) => (
            <div
              className={'swiper-slide q-nav-swiper-slide space-slider'}
              key={props.uid}
            >
              <div
                style={{
                  marginRight: 10,
                  width: window.innerWidth < 800 ? 100 : '100%',
                  textAlign: 'left',
                }}
              >
                <Meal
                  name={item.meal}
                  recipe={
                    !props.recipe
                      ? props.getRecipe(
                          overmindState.mealPlan,
                          item.meal,
                          props.index,
                          false
                        )
                      : props.recipe
                  }
                  upperCase={true}
                  fontType={'body2'}
                  isHorizontal={props.isHorizontal ? props.isHorizontal : null}
                  onClicked={(id) =>
                    props.onRecipeSelected &&
                    props.onRecipeSelected(id, item.meal, props.index)
                  }
                  parentComponent={props.parentComponent}
                  largeImage={window.innerWidth < 800 ? false : true}
                />
              </div>
            </div>
          ))
        : [1, 2, 3, 4].map((plh, ix) => {
            return (
              <Skeleton
                variant="rect"
                width={100}
                height={205}
                key={ix}
                style={{ marginRight: 10 }}
              />
            );
          })}
    </HorizontalSwiper>
  );
}

MealSwiper.propTypes = {
  name: PropTypes.string,
  dailyMeals: PropTypes.array,
  recipe: getRecipeProps(),
  spaceBetween: PropTypes.number,
};
