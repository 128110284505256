import { makeStyles } from '@material-ui/core';

export const useRecipeSummaryStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.16)',
    /*maxWidth: '280px',*/
  },
  rootVertical: {
    borderRadius: '10px',
  },
  imageContainer: {
    cursor: 'pointer',
  },
  image: {
    height: '130px',
    width: '130px',
    borderRadius: '10px 0 0 10px',
    objectFit: 'cover',
  },
  imagePremium: {
    height: '120px',
    width: '120px',
    borderRadius: '10px 0 0 10px',
    objectFit: 'cover',
  },
  imageVertical: {
    height: '110px',
    width: '110px',
    borderRadius: '10px 0 10px  0',
    objectFit: 'cover',
  },
  imageVerticalLarge: {
    height: '140px',
    width: '140px',
    borderRadius: '10px 0 10px  0',
    objectFit: 'cover',
  },
  imageVerticalLarger: {
    height: '275px',
    width: '275px',
    borderRadius: '10px 0 10px  0',
    objectFit: 'cover',
  },
  text: {
    marginLeft: '10px',
    marginRight: '10px',
    maxWidth: '150px',
  },
  textVertical: { maxWidth: '275px' },
  bookmark: (props) => ({
    marginBottom: '-20px',
    marginRight: '15px',
  }),
  popoverContentContainer: {
    padding: 24,
  },
  popoverContent: {
    margin: theme.spacing(0.5),
  },
  recipeInfo: {
    justifyContent: 'left',
  },
  servings: {
    right: '95px',
    bottom: '10px',
  },
  checkIcon: {
    right: '20px',
    bottom: '8px',
  },
}));
