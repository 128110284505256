import React from 'react';
import TermsOfService from './TermsOfService';

import { useActions, useOvermindState } from '../../../../../overmind';

export default function TermsOfServicePage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  return (
    <TermsOfService
      onTermsChange={(value, checked) => {
        actions.termsChecked(checked);
      }}
      checked={
        overmindState.metaData.user_metadata.terms_9moons == null
          ? undefined
          : overmindState.metaData.user_metadata.terms_9moons
      }
    />
  );
}
