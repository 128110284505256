import React, { useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';

import { useTranslation } from 'react-i18next';
import { commonTheme } from 'themes';

export default function SignoutSection(props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <ThemeProvider theme={commonTheme}>
      <GridContainer>
        <Accordion
          square
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          style={{
            width: '100%',
            borderStyle: 'none',
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{
              borderStyle: 'none',
              padding: '0px',
            }}
          >
            <GridContainer direction="row">
              <GridItem
                xs={10}
                style={{
                  textAlign: 'left',
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '15px',
                  }}
                >
                  {t('myProfile.subtitleSignout')}
                </Typography>
              </GridItem>
              <GridItem
                xs={2}
                style={{
                  marginTop: '-5px',
                }}
              >
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </GridItem>
            </GridContainer>
          </AccordionSummary>
          <GridContainer direction="column" style={{ textAlign: 'left' }}>
            <GridItem xs={12}>
              <AccordionDetails>
                <Button
                  onClick={() => {
                    props.onSignout && props.onSignout();
                  }}
                  variant="contained"
                  color="primary"
                  style={{ width: '100%', height: '50px', fontSize: '1.1em' }}
                >
                  {t('myProfile.signoutFromApp')}
                </Button>
              </AccordionDetails>
            </GridItem>
          </GridContainer>
        </Accordion>
      </GridContainer>
    </ThemeProvider>
  );
}
