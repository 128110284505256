import React, { useState } from 'react';

import Box from 'components/atoms/Layout/Box';
import Question from 'components/organisms/Inputs/Question/Question';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import BaseInput from 'components/atoms/Inputs/TextInput/BaseInput';

import {
  ftToCm,
  inToCm,
  cmToFt,
  cmToIn,
  ftToIn,
  convertFtAndInToCm,
} from 'components/helpers/converterHelper';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

export default function HeightQuestion(props) {
  const [heightCheckedInFeet, setHeightCheckedInFeet] = useState(
    props.selectedHeightMetric
      ? props.selectedHeightMetric === 1
        ? true
        : false
      : true
  );

  const [heightFt, setHeightFtUnit] = useState(
    props.heightFt ? props.heightFt : null
  );
  const [heightIn, setHeightInUnit] = useState(
    props.heightIn ? props.heightIn : null
  );
  const [height, setHeight] = useState(
    props.height ? Math.floor(props.height) : null
  );

  const setHeightFt = (heightFt) => {
    setHeightFtUnit(heightFt);
    setHeight(Math.floor(ftToCm(heightFt) + Math.floor(inToCm(heightIn))));
  };

  const setHeightIn = (heightIn) => {
    setHeightInUnit(Math.floor(heightIn));
    setHeight(Math.floor(Math.floor(ftToCm(heightFt)) + inToCm(heightIn)));
  };

  const setHeightCm = (heightCm) => {
    setHeight(Math.floor(heightCm));
    setHeightFtUnit(Math.floor(cmToFt(heightCm)));
    setHeightInUnit(
      Math.floor(cmToIn(heightCm)) -
        Math.floor(ftToIn(Math.floor(cmToFt(heightCm))))
    );
  };

  const changeHeightUnit = () => {
    if (heightCheckedInFeet) {
      setHeight(Math.round(convertFtAndInToCm(heightFt, heightIn)));
    } else {
      setHeightFtUnit(Math.floor(parseInt(cmToFt(height), 10)));
      setHeightInUnit(Math.floor(ftToIn(cmToFt(height) % 1)));
    }

    setHeightCheckedInFeet(!heightCheckedInFeet);
  };

  props.onChange &&
    props.onChange({
      height: height,
      heightFt: heightFt,
      heightIn: heightIn,
      selectedHeightMetric: heightCheckedInFeet ? 1 : 2,
    });

  return (
    <QuestionRefactored title={props.title}>
      <Box display="flex" justifyContent="center">
        {heightCheckedInFeet ? (
          <>
            <BaseInput
              id="height-in-feet"
              value={heightFt}
              color="primary"
              onChange={setHeightFt}
              inputProps={{
                style: { textAlign: 'center' },
                inputType: 'number',
              }}
            />
            <BaseInput
              id="height-in-inch"
              value={heightIn}
              color="primary"
              onChange={setHeightIn}
              inputProps={{
                style: { textAlign: 'center' },
                inputType: 'number',
              }}
            />
          </>
        ) : (
          <BaseInput
            id="height-in-cm"
            value={height}
            color="primary"
            onChange={setHeightCm}
            inputProps={{
              style: { textAlign: 'center' },
              inputType: 'number',
            }}
          />
        )}

        <RadioButtonGroup
          id="height-radio-input"
          color="primary"
          items={props.data}
          rowNumber={1}
          onChange={changeHeightUnit}
          colorUnchecked={props.colorUnchecked}
        />
      </Box>
    </QuestionRefactored>
  );
}
