import React, { useState } from 'react';
import MuiInput from '@material-ui/core/Input';
import { useStyles } from './textInputStyles';

export default function BaseInput(props) {
  const [value, setValue] = useState(props.value ? props.value : null);
  const classes = useStyles(props);

  function checkInput(e) {
    let parsedValue = null;
    if (props.inputProps.inputType) {
      switch (props.inputProps.inputType) {
        case 'number':
          let value = e.target.value;
          let returnValue = /^[0-9]*$/.test(value);
          if (returnValue) {
            if (value !== '') {
              parsedValue = parseInt(e.target.value);
              setValue(parsedValue);
              props.onChange && props.onChange(parsedValue);
            } else {
              setValue('');
              props.onChange && props.onChange('');
            }
          } else {
            if (value.length > 1) {
              value = parseInt(value.replace(/\D/g, ''));
            } else {
              value = '';
            }
            setValue(value);
            props.onChange && props.onChange(value);
          }
          break;
        default:
          break;
      }
    } else {
      setValue(e.target.value);
      props.onChange && props.onChange(e);
    }
  }

  if (props.value && props.value !== value) {
    setValue(props.value);
  }

  return (
    <MuiInput
      {...props}
      value={value}
      classes={{
        ...classes,
      }}
      onChange={(e) => checkInput(e)}
    />
  );
}
