import React from 'react';
import Dialog from 'components/atoms/Dialog/Dialog';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';

export default function ProfileDeletedDialog(props) {
  const { title, confirmText, openDialog } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        props.closeDialog();
      }}
    >
      <Box style={{ margin: '20px', textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <Button
          onClick={() => {
            props.closeDialog();
          }}
          style={{ marginTop: 16 }}
          variant="contained"
        >
          <Typography textSize={12}>{confirmText}</Typography>
        </Button>
      </Box>
    </Dialog>
  );
}
