import React from 'react';
import PropTypes from 'prop-types';

import NavigationHeader from './NavigationHeader';

export default function DetailsHeader(props) {
  const {
    title,
    disableLeftButton,
    disableRightButton,
    onLeftClick,
    onRightClick,
  } = props;

  return (
    <NavigationHeader
      heading={title}
      showBack={!disableLeftButton}
      goBack={onLeftClick}
      showNext={!disableRightButton}
      goNext={onRightClick}
      style={props.style}
    />
  );
}

DetailsHeader.propTypes = {
  title: PropTypes.string,
  disableLeftButton: PropTypes.bool,
  disableRightButton: PropTypes.bool,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
};
