import React, { useState, useEffect } from 'react';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import Typography from 'components/atoms/Typography/Typography';
import AgeQuestion from 'components/organisms/Questions/General/AgeQuestion';
import NameQuestion from 'components/organisms/Questions/General/NameQuestion';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';
let today = new Date();

export default function NameAndAgePage(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const classes = usePageStyles(props);
  const [ageInputRequired, setAgeInputRequired] = useState(false);

  const getWelcomeText = (name) => {
    return name
      ? t('onboarding.generalSection.step1.welcome') + ', ' + name + '!'
      : t('onboarding.generalSection.step1.welcome') + '!';
  };

  const [welcomeTitle, setWelcomeTitle] = useState(
    getWelcomeText(overmindState.metaData.user_metadata.firstName)
  );

  const getForwardEnabled = (name, age) => {
    return name != null && name !== '' && age >= 19 && age <= 99;
  };

  const calculateAge = (yearOfBirth) => {
    return yearOfBirth != null
      ? today.getFullYear() - yearOfBirth
      : props.year_of_birth
      ? today.getFullYear() - props.year_of_birth
      : null;
  };

  const calculateYearOfBirth = (age) => {
    return today.getFullYear() - age;
  };

  useEffect(() => {
    actions.setEnableForward(
      getForwardEnabled(
        overmindState.metaData.user_metadata.firstName,
        calculateAge(overmindState.profile.year_of_birth)
      )
    );
  });

  useEffect(() => {
    if (
      overmindState.profile == null ||
      overmindState.profile.year_of_birth == null ||
      calculateAge(overmindState.profile.year_of_birth) < 19
    ) {
      setAgeInputRequired(true);
    }
  }, []);

  return (
    <GridContainer direction="column" className={classes.container}>
      <GridItem>
        <NameQuestion
          title={t('onboarding.generalSection.step1.nameTitle')}
          name={overmindState.metaData.user_metadata.firstName}
          onChange={(newName) => {
            actions.nameChanged(newName);
            setWelcomeTitle(getWelcomeText(newName));
            actions.setEnableForward(
              getForwardEnabled(
                newName,
                calculateAge(overmindState.profile.year_of_birth)
              )
            );
          }}
        />
      </GridItem>

      {(overmindState.metaData.user_metadata.usedCalculator !== true ||
        ageInputRequired) && (
        <GridItem>
          <Typography
            variant={'h4'}
            style={{ margin: '40px auto 60px auto', textAlign: 'center' }}
          >
            {welcomeTitle}
          </Typography>
          <AgeQuestion
            title={t('onboarding.generalSection.step1.ageTitle')}
            age={calculateAge(overmindState.profile.year_of_birth)}
            onChange={(newAge) => {
              actions.ageChanged(calculateYearOfBirth(newAge));
              actions.setEnableForward(
                getForwardEnabled(
                  overmindState.metaData.user_metadata.firstName,
                  newAge
                )
              );
            }}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}
