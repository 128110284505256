import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import Tabs from 'components/molecules/Navigation/Tabs/Tabs';
import Tab from 'components/molecules/Navigation/Tabs/Tab';
import TabTitle from 'components/molecules/Navigation/Tabs/TabTitle';
import { useOvermindState } from 'overmind/index';
import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

import Macronutrients from './Macronutrients/MacronutrientsPage';
import VitaminsMicronutrients from './Micronutrients/VitaminsMicronutrientsPage';
import MineralsMicronutrients from './Micronutrients/MineralsMicronutrientsPage';
import PdfReport from './PdfReport/PdfReport';
import PaymentDialog from 'components/organisms/PaymentDialog/PaymentDialog';

import { useHistory, useLocation } from 'react-router-dom';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_ASSESSMENT } from 'routes';

import { useActions } from 'overmind/index';
import useInterval from 'components/helpers/useInterval';

export default function Assessment() {
  const overmindState = useOvermindState();
  const [value, setValue] = useState(0);
  const actions = useActions();
  const history = useHistory();
  const location = useLocation();
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (location.pathname === ROUTE_ASSESSMENT) {
      setValue(0);
    }
  }, [location]);

  useEffect(() => {
    if (overmindState.isNutrientsReportLoaded === false) {
      actions.fetchNutrientsReport();
    }
  }, [overmindState.isNutrientsReportLoaded, actions]);

  useEffect(() => {
    if (overmindState.genotype && overmindState.genotype.status != null) {
      actions.getReport();
    }
  }, [actions, overmindState.genotype]);

  useInterval(() => {
    if (
      overmindState.genotype &&
      overmindState.genotype.status != null &&
      (overmindState.report == null ||
        overmindState.report.download_url == null)
    ) {
      actions.getReport();
    }
  }, 5000);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    trackEvent('Needs Assessment ', 'Tab Switched', {
      tab: newValue === 0 ? 'Macros' : newValue === 1 ? 'Vitamins' : 'Minerals',
    });
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader title={'My Nutritional Needs'} showBack={false} />

      <GridContainer
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          marginTop: '50px',
          marginBottom: '40px',
        }}
      >
        {overmindState.report && overmindState.report.download_url && (
          <GridItem style={{ width: '100%', maxWidth: '1200px' }}>
            <PdfReport
              url={overmindState.report.download_url}
              onDownloaded={() =>
                trackEvent('PDF', 'Downloaded', {
                  location: 'NeedsAssessment',
                })
              }
            />
          </GridItem>
        )}

        <GridItem style={{ width: '100%', maxWidth: '1200px' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="needs assessment tabs"
          >
            <Tab label={<TabTitle label="Macros" />} />
            <Tab label={<TabTitle label="Vitamins" />} />
            <Tab label={<TabTitle label="Minerals" />} />
          </Tabs>

          {value === 0 && (
            <Macronutrients
              macronutrients={overmindState.nutrientsReport.macronutrients}
            />
          )}
          {value === 1 && (
            <VitaminsMicronutrients
              micronutrients={overmindState.nutrientsReport.micronutrients}
            />
          )}
          {value === 2 && (
            <MineralsMicronutrients
              micronutrients={overmindState.nutrientsReport.micronutrients}
            />
          )}
        </GridItem>
      </GridContainer>
      <PaymentDialog
        openDialog={paymentDialogOpened}
        onCloseDialog={() => setPaymentDialogOpened(false)}
      />
      <FooterMenu
        selectedValue={3}
        onSelected={(val) => {
          history.push(handleNavigation(val));
        }}
        menuOptions={getMenuOptions(assessmentTheme, 3)}
      />
    </ThemeProvider>
  );
}
