import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

export default function CustomLink(props) {
  return (
    <Link
      color="inherit"
      variant="inherit"
      underline="always"
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      {props.title}
    </Link>
  );
}

CustomLink.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};
