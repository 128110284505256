import { makeStyles } from '@material-ui/core/styles';

export const useContainerWithBackgroundStyles = makeStyles((theme) => ({
  centeredontainer: (props) => ({
    marginTop: props.marginTop ? props.marginTop : '10vh',
    height: props.height,
    width: props.width,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all 0.5s ease',
    backgroundImage: `url('${props.image}')`,
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  }),
  container: (props) => ({
    marginTop: props.marginTop ? props.marginTop : '10vh',
    height: props.height,
    width: props.width,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'auto',
    transition: 'all 0.5s ease',
    backgroundImage: `url('${props.image}')`,
    maxWidth: props.maxWidth,
    maxHeight: props.maxHeight,
  }),
}));
