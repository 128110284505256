import React, { useState, useEffect } from 'react';
import { useOvermindState } from 'overmind/index';
import { useActions } from 'overmind/index';
import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card, CardContent } from '@material-ui/core';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { useSummaryStyles } from './summaryStyles';
import WeeklyCheckinDialog from '../../../organisms/ModalWindow/WeeklyCheckinDialog';
import {
  getSleep,
  getSleepIcon,
  getStress,
  getStressIcon,
  getExercise,
  getExerciseIcon,
} from 'components/helpers/profileInfoHelper';
import { useTranslation } from 'react-i18next';
import LineChart from 'components/molecules/Charts/LineChart';
import {
  prepareMonitorLineChartData,
  renderSymptomChartHorizontal,
} from 'components/helpers/chartHelper';
import { kgToLbs } from 'components/helpers/converterHelper';
import {
  getAdviceText,
  getBmi,
  getWeightGain,
} from 'components/helpers/pregnancySymptopmsHelper';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function Summary(props) {
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const actions = useActions();
  const classes = useSummaryStyles();
  const [modalWindowOpened, setModalWindowOpened] = useState(false);
  const [sleep, setSleep] = useState();
  const [sleepIcon, setSleepIcon] = useState();
  const [stress, setStress] = useState();
  const [stressIcon, setStressIcon] = useState();
  const [exercise, setExercise] = useState();
  const [exerciseIcon, setExerciseIcon] = useState();

  useEffect(() => {
    if (overmindState.weightWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchWeightCheckinsByPregnancyWeek();
    }
  }, [overmindState.weightWeeklyCheckinsByPregnancyWeek]);

  useEffect(() => {
    if (overmindState.activityWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchActivityCheckinsByPregnancyWeek();
    } else {
      const activityState = overmindState.activityWeeklyCheckinsByPregnancyWeek;
      if (activityState.length > 0) {
        const value = activityState[activityState.length - 1].value;
        setExercise(t(getExercise(value)));
        setExerciseIcon(getExerciseIcon(value));
      }
    }
  }, [overmindState.activityWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.sleepWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchSleepCheckinsByPregnancyWeek();
    } else {
      const sleepActivity = overmindState.sleepWeeklyCheckinsByPregnancyWeek;
      if (sleepActivity.length > 0) {
        const value = sleepActivity[sleepActivity.length - 1].value;
        setSleep(t(getSleep(value)));
        setSleepIcon(getSleepIcon(value));
      }
    }
  }, [overmindState.sleepWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.stressWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchStressCheckinsByPregnancyWeek();
    } else {
      const stressActivity = overmindState.stressWeeklyCheckinsByPregnancyWeek;
      if (stressActivity.length > 0) {
        const value = stressActivity[stressActivity.length - 1].value;
        setStress(t(getStress(value)));
        setStressIcon(getStressIcon(value));
      }
    }
  }, [overmindState.stressWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.nauseaWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchNauseaCheckinsByPregnancyWeek();
    }
  }, [overmindState.nauseaWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.fatigueWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchFatigueCheckinsByPregnancyWeek();
    }
  }, [overmindState.fatigueWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.achingBackWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchAchingBackCheckinsByPregnancyWeek();
    }
  }, [overmindState.achingBackWeeklyCheckinsByPregnancyWeek, actions]);

  useEffect(() => {
    if (overmindState.moodSwingsWeeklyCheckinsByPregnancyWeek == null) {
      actions.fetchMoodSwingsCheckinsByPregnancyWeek();
    }
  }, [overmindState.moodSwingsWeeklyCheckinsByPregnancyWeek, actions]);

  const handleModalClosed = async (opened, type) => {
    await actions.fetchWeightCheckinsByPregnancyWeek();
    setModalWindowOpened(opened);
  };

  const getNauseaData = () =>
    getData(overmindState.nauseaWeeklyCheckinsByPregnancyWeek);

  const getFatigueData = () =>
    getData(overmindState.fatigueWeeklyCheckinsByPregnancyWeek);

  const getAchingBackData = () =>
    getData(overmindState.achingBackWeeklyCheckinsByPregnancyWeek);

  const getMoodSwingData = () =>
    getData(overmindState.moodSwingsBackWeeklyCheckinsByPregnancyWeek);

  const getData = (stateProp) => {
    const data = [];
    for (let i = 1; i < 41; i++) {
      if (stateProp != null) {
        const entry = stateProp.find((d) => d.week_of_pregnancy === i);
        if (entry == null) {
          data.push(null);
        } else {
          data.push(entry.value);
        }
      }
    }
    return data;
  };

  const getLastWeight = () => {
    const lastWeight =
      overmindState.weightWeeklyCheckinsByPregnancyWeek.length > 0
        ? overmindState.weightWeeklyCheckinsByPregnancyWeek[
            overmindState.weightWeeklyCheckinsByPregnancyWeek.length - 1
          ].value
        : null;

    return lastWeight;
  };

  const getLastWeightCheckin = () => {
    const lastWeight = getLastWeight();
    if (lastWeight == null) {
      return '-';
    }

    if (overmindState.metaData.user_metadata.selectedWeightMetric === 2) {
      return lastWeight;
    } else {
      return Math.round(kgToLbs(lastWeight));
    }
  };
  const checkins = overmindState.weightWeeklyCheckinsByPregnancyWeek;

  const bmi = getBmi(
    overmindState.profile.weight,
    overmindState.profile.height
  );

  const trimester = overmindState.profilePregnancy.pregnancy_trimester;

  const weightGain = getWeightGain(checkins, overmindState.profile.weight);

  return (
    <Box mx="24px">
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        style={{ paddingTop: '26px', paddingBottom: '26px' }}
      >
        <GridItem xs={6}>
          <Typography variant="h3">Pregnancy Symptoms</Typography>
        </GridItem>
        <GridItem
          style={{ cursor: 'pointer' }}
          onClick={() => props.onPregnancyDetails && props.onPregnancyDetails()}
        >
          <Typography variant="caption">Details</Typography>
        </GridItem>
      </GridContainer>

      <Card className={classes.card}>
        <CardContent>
          {renderSymptomChartHorizontal(
            'NAUSEA',
            prepareMonitorLineChartData(
              getNauseaData(),
              overmindState.profilePregnancy.pregnancy_week
            ),
            '#f7c765'
          )}

          {renderSymptomChartHorizontal(
            'FATIGUE',
            prepareMonitorLineChartData(
              getFatigueData(),
              overmindState.profilePregnancy.pregnancy_week
            ),
            '#d07abf'
          )}

          {renderSymptomChartHorizontal(
            'ACHING BACK',
            prepareMonitorLineChartData(
              getAchingBackData(),
              overmindState.profilePregnancy.pregnancy_week
            ),
            '#8ace84'
          )}

          {renderSymptomChartHorizontal(
            'MOOD SWINGS',
            prepareMonitorLineChartData(
              getMoodSwingData(),
              overmindState.profilePregnancy.pregnancy_week
            ),
            '#961a7e'
          )}
        </CardContent>
      </Card>

      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        style={{ paddingTop: '26px' }}
      >
        <GridItem xs={6}>
          <Typography variant="h3">Health</Typography>
        </GridItem>
        <GridItem
          style={{ cursor: 'pointer' }}
          onClick={() => props.onHealthDetails && props.onHealthDetails()}
        >
          <Typography variant="caption">Details</Typography>
        </GridItem>
      </GridContainer>

      <GridContainer direction="row" style={{ marginTop: '18px' }} spacing={1}>
        <GridItem xs={window.innerWidth < 800 ? 12 : 3}>
          {overmindState.weightWeeklyCheckinsByPregnancyWeek == null && (
            <Skeleton width="100%" height="156px" />
          )}
          {overmindState.weightWeeklyCheckinsByPregnancyWeek != null && (
            <Card className={classes.card} width="100%">
              <CardContent>
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <GridItem xs={6}>
                    <Box pt={1}>
                      <Typography className={classes.textTitle}>
                        WEIGHT
                      </Typography>
                    </Box>
                    <Box pt={1}>
                      <Typography className={classes.textSubtitle}>
                        {getLastWeight() !== null &&
                          getAdviceText(bmi, weightGain, trimester, true)}
                      </Typography>
                    </Box>
                  </GridItem>

                  <GridItem xs={6}>
                    <GridContainer
                      direction="row"
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <Box>
                        <Box color="#f6a409">
                          <Typography
                            className={classes.textValues}
                            display="inline"
                          >
                            {getLastWeightCheckin()}
                          </Typography>
                          <Typography display="inline">
                            {overmindState.metaData.user_metadata
                              .selectedWeightMetric === 2
                              ? ' kg'
                              : ' lbs'}
                          </Typography>
                        </Box>
                      </Box>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12}>
                    <Box pt={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          trackEvent('Monitor', 'Clicked', {
                            option: 'Update weight',
                          });
                          setModalWindowOpened(true);
                        }}
                        style={{
                          fontSize: 10,
                          backgroundImage:
                            'linear-gradient(91deg, #e8af44 0%, #e86e6e 100%)',
                          textTransform: 'none',
                        }}
                      >
                        Update your Weight
                      </Button>
                    </Box>
                  </GridItem>
                </GridContainer>
              </CardContent>
            </Card>
          )}
        </GridItem>
        <GridItem xs={window.innerWidth < 800 ? 6 : 3}>
          {overmindState.activityWeeklyCheckinsByPregnancyWeek == null && (
            <Skeleton width="100%" height="156px" />
          )}
          {overmindState.activityWeeklyCheckinsByPregnancyWeek != null &&
            overmindState.activityWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Card className={classes.card} width="100%">
                <CardContent>
                  <GridContainer direction="column">
                    <GridItem>
                      <Typography
                        variant="body2"
                        style={{ lineHeight: 1, fontSize: 11 }}
                      >
                        Exercising per day in the past week
                      </Typography>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                  >
                    <GridItem>
                      <img
                        src={exerciseIcon}
                        alt={`${props.title}-icon`}
                        height={props.iconHeight ? props.iconHeight : '50px'}
                        width={props.iconWidth ? props.iconWidth : '80px'}
                      />
                    </GridItem>
                    <GridItem>
                      <Typography variant="body1">{exercise}</Typography>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            )}
        </GridItem>
        <GridItem xs={window.innerWidth < 800 ? 6 : 3}>
          {overmindState.sleepWeeklyCheckinsByPregnancyWeek == null && (
            <Skeleton width="100%" height="156px" />
          )}
          {overmindState.sleepWeeklyCheckinsByPregnancyWeek != null &&
            overmindState.sleepWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Card className={classes.card} width="100%">
                <CardContent>
                  <GridContainer direction="column">
                    <GridItem>
                      <Typography
                        variant="body2"
                        style={{ lineHeight: 1, fontSize: 11 }}
                      >
                        Slept more than 7 hours in the past week
                      </Typography>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                  >
                    <GridItem>
                      <img
                        src={sleepIcon}
                        alt={`${props.title}-icon`}
                        height={props.iconHeight ? props.iconHeight : '50px'}
                        width={props.iconWidth ? props.iconWidth : '80px'}
                      />
                    </GridItem>
                    <GridItem>
                      <Typography variant="body1">{sleep}</Typography>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            )}
        </GridItem>
        <GridItem xs={window.innerWidth < 800 ? 6 : 3}>
          {overmindState.stressWeeklyCheckinsByPregnancyWeek == null && (
            <Skeleton width="100%" height="156px" />
          )}
          {overmindState.stressWeeklyCheckinsByPregnancyWeek != null &&
            overmindState.stressWeeklyCheckinsByPregnancyWeek.length > 0 && (
              <Card className={classes.card} width="100%">
                <CardContent>
                  <GridContainer direction="column">
                    <GridItem>
                      <Typography
                        variant="body2"
                        style={{ lineHeight: 1, fontSize: 11 }}
                      >
                        Stress level in the past week
                      </Typography>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                  >
                    <GridItem>
                      <img
                        src={stressIcon}
                        alt={`${props.title}-icon`}
                        height={props.iconHeight ? props.iconHeight : '50px'}
                        width={props.iconWidth ? props.iconWidth : '80px'}
                      />
                    </GridItem>
                    <GridItem>
                      <Typography variant="body1">{stress}</Typography>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            )}
        </GridItem>
      </GridContainer>
      <WeeklyCheckinDialog
        type="monitorWeight"
        opened={modalWindowOpened}
        step={0}
        onClose={() => handleModalClosed(false)}
      />
    </Box>
  );
}
