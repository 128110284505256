import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

/**
 * Progress indicator component based on https://material-ui.com/components/progress/#linear
 * @param {*} props All possible props can be found in docs https://material-ui.com/api/linear-progress/
 */
export default function LinearProgress(props) {
  const {
    value,
    progressColor,
    backgroundColor,
    height,
    borderRadius,
    ...rest
  } = props;
  const classes = useStyles();

  const BorderLinearProgress = withStyles({
    root: {
      height: height,
      borderRadius: borderRadius,
      backgroundColor: backgroundColor,
      margin: 0,
    },
    bar: {
      borderRadius: borderRadius,
      backgroundColor: progressColor,
    },
  })(MuiLinearProgress);

  return (
    <div className={classes.root}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color={progressColor}
        value={value > 100 ? 100 : value}
        {...rest}
      />
    </div>
  );
}

LinearProgress.propTypes = {
  /**
   * Value between 0 and 100 representing progress.
   */
  value: PropTypes.number,

  /**
   * Color of the progress bar.
   */
  progressColor: PropTypes.string,

  /**
   * Color of the background.
   */
  backgroundColor: PropTypes.string.isRequired,

  /**
   * Height of the control.
   */
  height: PropTypes.number.isRequired,

  /**
   * Border radius of the control.
   */
  borderRadius: PropTypes.number.isRequired,
};
