import { makeStyles } from '@material-ui/core';

export const useAccordionStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export const useAccordionSummaryStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}));

export const useAccordionDetailsStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));
