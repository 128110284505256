import { makeStyles } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const useIntroPageStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'linear-gradient(to bottom, #e8af44, #e86e6e)',
    position: 'fixed',
    left: '0',
    top: '0',
    height: '100vh',
    width: '100%',
  },
  title: {
    color: 'white',
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    color: 'rgba(255, 255, 255)',
    fontWeight: 'normal',
    fontSize: '18px',
  },
  skipButton: {
    position: 'relative',
    top: '30px',
    color: 'rgba(255, 255, 255)',
  },
  sliderMarker: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));
