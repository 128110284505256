import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box/Box';

import { useChipStyles } from './chipStyles';

export default function ChipList(props) {
  const classes = useChipStyles();

  return (
    <GridContainer display="row">
      {props.chipsTexts &&
        props.chipsTexts.map((chipText, ix) => {
          return (
            <GridItem key={ix}>
              <Box className={classes.chip}>{chipText}</Box>
            </GridItem>
          );
        })}
    </GridContainer>
  );
}
