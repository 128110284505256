import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import CalendarItem from 'components/molecules/Calendar/CalendarItem';
import { useOvermindState } from 'overmind/index';

import { useCalendarWeekStyles } from './calendarWeekStyles';
import { getDate, getCurrentWeekFromDate } from 'components/helpers/dateHelper';
import Skeleton from '@material-ui/lab/Skeleton';

export default function CalendarWeek(props) {
  const overmindState = useOvermindState();
  const classes = useCalendarWeekStyles(props);
  const firstDay = getDate(overmindState.profilePregnancy.pregnancy_week_start);
  const [selected, setSelected] = useState(
    getCurrentWeekFromDate(firstDay)[props.dateIndex]
  );
  const [data, setData] = useState();

  useEffect(() => {
    let data = getWeekDaysData();
    setData(data);
  }, []);

  const getWeekDaysData = () => {
    let data = [];
    getCurrentWeekFromDate(firstDay).forEach((day, ix) => {
      data.push({
        selected: ix === props.dateIndex,
        date: day,
      });
    });

    return data;
  };

  const handleClick = (item, index) => {
    let newData = [...data];

    newData.forEach((i, ix) => {
      i.selected = false;
      if (ix === index) {
        i.selected = !i.selected;
      }
    });

    setData(newData);
    setSelected(item.date);
    props.onClick && props.onClick(item.date, index);

    return newData;
  };

  const renderSelectedDay = () => {
    return (
      <>
        <Typography
          variant="body2"
          style={{ fontSize: '12px', fontWeight: 'normal' }}
        >
          {selected.format('dddd, DD MMMM')}
        </Typography>
      </>
    );
  };

  const renderSelectedWeek = () => {
    return (
      <>
        <Typography
          variant="body2"
          style={{ fontSize: '12px', fontWeight: 'normal' }}
        >
          {`${getDate(
            overmindState.profilePregnancy.pregnancy_week_start
          ).format('dddd, DD MMMM')} - ${getDate(
            overmindState.profilePregnancy.pregnancy_week_end
          )
            .subtract(1, 'seconds')
            .format('dddd, DD MMMM')}`}
        </Typography>
      </>
    );
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.dates} display="flex" justifyContent="center">
        {data &&
          data.map((item, ix) => (
            <Box className={classes.item}>
              <CalendarItem
                date={item.date}
                selected={props.type !== 'week' && item.selected}
                onClick={() => handleClick(item, ix)}
                type={props.type}
              />
            </Box>
          ))}
      </Box>
      <Box className={classes.text}>
        <>
          <Typography variant="body1" className={classes.selectedDateText1}>
            {props.extraText ? (
              props.type === 'week' ? (
                `Week ${props.extraText}`
              ) : (
                `Day ${props.extraText}`
              )
            ) : (
              <Skeleton />
            )}
          </Typography>

          {props.type === 'week' ? renderSelectedWeek() : renderSelectedDay()}
        </>
      </Box>
    </Box>
  );
}

CalendarWeek.propTypes = {
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['day', 'week']),
};
