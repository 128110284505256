import React, { useState } from 'react';

import Typography from 'components/atoms/Typography/Typography';
import Link from 'components/atoms/Link/Link';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box/Box';
import HorizontalSwiper from 'components/molecules/HorizontalSwiper/HorizontalSwiper';
import ChipList from 'components/molecules/ChipList/ChipList';
import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDailyNeedStyles } from './accordionContentStyle';
import { useTranslation } from 'react-i18next';

export default function DailyNeed(props) {
  const classes = useDailyNeedStyles(props);
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  const renderDailyFoods = (title, foodsList) => {
    return (
      <>
        {title && (
          <GridItem xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
            <Typography variant="h6">{title}</Typography>
          </GridItem>
        )}
        <GridItem xs={12}>
          <HorizontalSwiper uid="navigation-swiper">
            {foodsList &&
              foodsList.map((item, index) => (
                <div
                  className={'swiper-slide q-nav-swiper-slide space-slider'}
                  key={index}
                >
                  <div>
                    <img
                      src={item.image_url}
                      height={'80px'}
                      width={'80px'}
                      alt={`${item.name}-icon`}
                    />
                    <Typography variant="body1">{item.name}</Typography>
                  </div>
                </div>
              ))}
          </HorizontalSwiper>
        </GridItem>
      </>
    );
  };

  const renderHealthBenefits = (title, healthBenefits) => {
    return (
      <>
        {title && (
          <GridItem xs={12} style={{ marginTop: '20px' }}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">{title}</Typography>
            </Box>
          </GridItem>
        )}
        <GridItem xs={12} style={{ marginTop: '20px' }}>
          <ChipList chipsTexts={healthBenefits} />
        </GridItem>
      </>
    );
  };

  const renderTraitAssessment = (traitAssessment) => {
    return (
      <GridItem xs={12} style={{ marginTop: '20px' }}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6">{props.assessmentTitle}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" style={{ color: '#3d3a4b' }}>
            {'for '} {traitAssessment.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          style={{ marginTop: '15px' }}
        >
          <Typography variant="h5" className={classes.assessmentLabel}>
            {traitAssessment.assessment_label}
          </Typography>
        </Box>
      </GridItem>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6">{props.title}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          style={{ marginTop: '12px' }}
        >
          <Box className={classes.dataCard}>{props.dataCard}</Box>
        </Box>
      </GridItem>

      {props.shortDescription && (
        <GridItem xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="body1">{props.shortDescription}</Typography>
        </GridItem>
      )}
      {props.description && showMore && (
        <GridItem xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="body1">{props.description}</Typography>
        </GridItem>
      )}

      {props.description && (
        <GridItem xs={12} style={{ marginTop: '12px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Typography
              variant="body1"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? t('assessment.showLess') : t('assessment.showMore')}
            </Typography>
          </Box>
        </GridItem>
      )}

      {props.links && props.links.length !== 0 && (
        <GridItem xs={12} style={{ marginTop: '12px' }}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <Typography style={{ fontWeight: 600 }}>
                {t('assessment.sources')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <ol style={{ fontWeight: 600 }}>
                {props.links.map((link) => (
                  <li style={{ paddingBottom: '0.5rem' }}>
                    <Typography variant="body1">
                      <Link title={link.title} url={link.url} />
                    </Typography>
                  </li>
                ))}
              </ol>
            </AccordionDetails>
          </Accordion>
        </GridItem>
      )}

      {props.foodsList && renderDailyFoods(props.foodsTitle, props.foodsList)}
      {props.healthBenefits &&
        renderHealthBenefits(props.benefitsTitle, props.healthBenefits)}
      {props.traitAssessments != null &&
        renderTraitAssessment(props.traitAssessments)}
    </GridContainer>
  );
}
