import React, { useState, useEffect } from 'react';

import Slider from 'components/molecules/Slider/Slider';
import Typography from 'components/atoms/Typography/Typography';
import SleepQuestion from 'components/organisms/Questions/Lifestyle/SleepQuestion';
import StressQuestion from 'components/organisms/Questions/Lifestyle/StressQuestion';
import ExercisePerDayQuestion from 'components/organisms/Questions/Exercise/ExercisePerDayQuestion';
import NauseaQuestion from 'components/organisms/Questions/WeeklyCheckin/NauseaQuestion';
import ExhaustionQuestion from 'components/organisms/Questions/WeeklyCheckin/ExhaustionQuestion';
import BackPainQuestion from 'components/organisms/Questions/WeeklyCheckin/BackPainQuestion';
import MoodSwingsQuestion from 'components/organisms/Questions/WeeklyCheckin/MoodSwingsQuestion';

import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import {
  getSleep,
  getStress,
  getExercise,
  getNausea,
  getExhaustion,
  getAchingBack,
  getMoodSwings,
} from 'components/helpers/lifestyleExerciseHelper';
import { formatDate } from 'components/helpers/dateHelper';

export default function CheckinQuestionsSet(props) {
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [sleepQuality, setSleepQuality] = useState(0);
  const [stressLevel, setStressLevel] = useState(0);
  const [exercisePerDay, setExercisePerDay] = useState(0);
  const [nauseaLevel, setNauseaLevel] = useState(0);
  const [tiredLevel, setTiredLevel] = useState(0);
  const [backPainLevel, setBackPainLevel] = useState(0);
  const [moodSwingsLevel, setMoodSwingsLevel] = useState(0);

  function onSleepQualityChange(value) {
    actions.saveWeeklyCheckinState({
      sleep_quality: value,
    });
    setSleepQuality(value);
  }
  function onStressLevelChange(value) {
    actions.saveWeeklyCheckinState({
      stress_level: value,
    });
    setStressLevel(value);
  }
  function onExercisePerDayChange(value) {
    actions.saveWeeklyCheckinState({
      exercise_level: value,
    });
    setExercisePerDay(value);
  }
  function onNauseaLevelChange(value) {
    actions.saveWeeklyCheckinState({
      nausea_level: value,
    });
    setNauseaLevel(value);
  }
  function onTiredLevelChange(value) {
    actions.saveWeeklyCheckinState({
      tired_level: value,
    });
    setTiredLevel(value);
  }
  function onBackPainLevelChange(value) {
    actions.saveWeeklyCheckinState({
      back_pain_level: value,
    });
    setBackPainLevel(value);
  }
  function onMoodSwingsLevelChange(value) {
    actions.saveWeeklyCheckinState({
      mood_swings_level: value,
    });
    setMoodSwingsLevel(value);
  }

  useEffect(() => {
    let data;
    switch (props.step) {
      case 2:
        data = 'sleep_quality';
        break;
      case 3:
        data = 'stress_level';
        break;
      case 4:
        data = 'exercise_level';
        break;
      case 5:
        data = 'nausea_level';
        break;
      case 6:
        data = 'tired_level';
        break;
      case 7:
        data = 'back_pain_level';
        break;
      default:
        data = '';
        break;
    }
    actions.saveWeeklyCheckinState({
      sleep_quality: sleepQuality,
      stress_level: stressLevel,
      exercise_level: exercisePerDay,
      nausea_level: nauseaLevel,
      tired_level: tiredLevel,
      back_pain_level: backPainLevel,
      mood_swings_level: moodSwingsLevel,
      data: data,
      date: formatDate(new Date(), 'YYYY-MM-DD'),
    });
  }, [props.step]);

  const renderQuestion = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <div
              style={{
                marginBottom: '30px',
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">
                {t('home.weeklyCheckinQuestions.title')}
              </Typography>
              <Typography variant="body1">
                {t('home.weeklyCheckinQuestions.subtitle')}
              </Typography>
            </div>
            <SleepQuestion
              title={t('home.weeklyCheckinQuestions.sleepQuestion.title')}
              subtitle={t('home.weeklyCheckinQuestions.sleepQuestion.subtitle')}
              data={getSleep('white')}
              selectedIllustrationIndex={sleepQuality}
              onChange={(index) => onSleepQualityChange(index)}
              theme={'white'}
            />
          </div>
        );
      case 2:
        return (
          <StressQuestion
            title={t('home.weeklyCheckinQuestions.stressQuestion.title')}
            subtitle={t('home.weeklyCheckinQuestions.stressQuestion.subtitle')}
            data={getStress('white')}
            selectedIllustrationIndex={stressLevel}
            onChange={(index) => onStressLevelChange(index)}
            theme={'white'}
          />
        );
      case 3:
        return (
          <ExercisePerDayQuestion
            title={t('home.weeklyCheckinQuestions.exerciseQuestion.title')}
            subtitle={t(
              'home.weeklyCheckinQuestions.exerciseQuestion.subtitle'
            )}
            exerciseLevel={exercisePerDay}
            onChange={(index) => onExercisePerDayChange(index)}
            data={getExercise('white')}
            theme={'white'}
          />
        );
      case 4:
        return (
          <NauseaQuestion
            title={t('home.weeklyCheckinQuestions.nauseaQuestion.title')}
            selectedIllustrationIndex={nauseaLevel}
            data={getNausea()}
            onChange={(index) => onNauseaLevelChange(index)}
          />
        );
      case 5:
        return (
          <ExhaustionQuestion
            title={t('home.weeklyCheckinQuestions.tiredQuestion.title')}
            selectedIllustrationIndex={tiredLevel}
            data={getExhaustion()}
            onChange={(index) => onTiredLevelChange(index)}
          />
        );
      case 6:
        return (
          <BackPainQuestion
            title={t('home.weeklyCheckinQuestions.backPainQuestion.title')}
            selectedIllustrationIndex={backPainLevel}
            data={getAchingBack()}
            onChange={(index) => onBackPainLevelChange(index)}
          />
        );
      case 7:
        return (
          <MoodSwingsQuestion
            title={t('home.weeklyCheckinQuestions.moodSwingsQuestion.title')}
            selectedIllustrationIndex={moodSwingsLevel}
            data={getMoodSwings()}
            onChange={(index) => onMoodSwingsLevelChange(index)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderQuestion(props.step)}
    </Slider>
  );
}
