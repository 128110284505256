import React, { useState } from 'react';

import Micronutrient from './Micronutrient';

export default function VitaminsMicronutrientsPage(props) {
  const [expanded, setExpanded] = useState('-');
  const isVitamin = (micronutrient, groups) => {
    return groups && groups.vitamins.includes(micronutrient.code);
  };

  const getVitamins = (micronutrients) => {
    return micronutrients.items.filter((micronutrient) =>
      isVitamin(micronutrient, micronutrients.groups)
    );
  };

  const { micronutrients } = props;

  return (
    micronutrients &&
    micronutrients.items &&
    getVitamins(micronutrients).map((micronutrient, ix) => (
      <>
        {isVitamin(micronutrient, micronutrients.groups) && (
          <Micronutrient
            index={'vitamin' + ix}
            {...micronutrient}
            onNewExpanded={(newExpanded) => setExpanded(newExpanded)}
            expanded={expanded}
          />
        )}
      </>
    ))
  );
}
