import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Box from 'components/atoms/Layout/Box/Box';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { dnaKitNumberStep0 } from 'assets/imagesList';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';
import { useActions, useOvermindState } from 'overmind/index';
import Typography from 'components/atoms/Typography/Typography';

export default function KitNumberInputQuestion(props) {
  const [kitNumber, setKitNumber] = useState(
    props.kitNumber == null ? '' : props.kitNumber
  );
  const [submitting, setSubmitting] = useState(false);
  const [isValidKitNumber, setIsValidKitNumber] = useState(true);
  const actions = useActions();
  const overmindState = useOvermindState();

  return props.showSubmitStatus ? (
    <QuestionRefactored title={props.title} subtitle={props.subtitle}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {props.submitSuccessful ? (
          <React.Fragment>
            <CheckCircleIcon color="primary" style={{ fontSize: '80px' }} />
            {overmindState.metaData.user_metadata.app_ready_to_use_page_seen !==
              true && (
              <Button
                component="span"
                variant="contained"
                style={{ marginTop: '30px' }}
                onClick={() => {
                  actions.disableForwardButton();
                  actions.goNext();
                }}
              >
                {props.continueText}
              </Button>
            )}
          </React.Fragment>
        ) : (
          <ErrorIcon color="error" style={{ fontSize: '80px' }} />
        )}
      </Box>
    </QuestionRefactored>
  ) : (
    <QuestionRefactored title={props.title} divider={false}>
      <Box justifyContent="center" display="flex">
        <GridContainer direction="column">
          <GridItem>
            <Box
              pt={{ xs: 2.5, sm: 3, md: 4 }}
              justifyContent="center"
              display="flex"
            >
              <img
                src={dnaKitNumberStep0}
                alt="kit number illustration"
                width={200}
              />
            </Box>
          </GridItem>
          <GridItem>
            <Box
              p={{ xs: 2, sm: 3, md: 4 }}
              justifyContent="center"
              display="flex"
            >
              <TextInput
                value={kitNumber}
                fullwidth
                variant={'standard'}
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: '18px',
                  },
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  setKitNumber(input);
                  if (props.onChange) {
                    props.onChange(input);
                  }
                }}
              />
            </Box>
          </GridItem>
          {!isValidKitNumber && kitNumber !== '' && kitNumber != null && (
            <GridItem>
              <Box
                px={{ xs: 2, sm: 3, md: 4 }}
                style={{ marginTop: '20px' }}
                justifyContent="center"
                display="flex"
              >
                <Typography variant="body1">
                  {props.invalidFormatText}
                </Typography>
              </Box>
            </GridItem>
          )}
          {(props.hideButton == null || props.hideButton === false) && (
            <GridItem>
              <Box
                p={{ xs: 2, sm: 3, md: 4 }}
                justifyContent="center"
                display="flex"
              >
                <Button
                  component="span"
                  variant="contained"
                  disabled={
                    (props.kitNumber === '' || submitting) && !props.error
                  }
                  onClick={() => {
                    const kitNumberInput = kitNumber.toUpperCase();
                    const regEx = /^LN\d{6}$/;
                    const validity = regEx.test(kitNumberInput);
                    setIsValidKitNumber(validity);
                    if (validity && props.onKitNumberSubmit) {
                      setSubmitting(true);
                      props.onKitNumberSubmit(kitNumberInput);
                    }
                  }}
                >
                  {props.error
                    ? 'Try again'
                    : submitting
                    ? 'Processing...'
                    : 'Save'}
                </Button>
              </Box>
            </GridItem>
          )}
        </GridContainer>
      </Box>
    </QuestionRefactored>
  );
}

KitNumberInputQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  onKitNumberSubmit: PropTypes.func,
  kitNumber: PropTypes.string,
  submitSuccessful: PropTypes.bool,
  showSubmitStatus: PropTypes.bool,
  error: PropTypes.string,
};
