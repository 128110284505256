import { makeStyles } from '@material-ui/core';

export const useTypographyStyles = makeStyles((theme) => ({
  root: {
    color: (props) => props.textColor,
    fontSize: (props) => props.textSize,
    fontStyle: (props) => props.fontStyle,
    fontWeight: (props) => props.fontWeight,
    textAlign: (props) => props.textAlign,
    height: (props) => props.height,
    lineHeight: (props) => props.lineHeight,
  },
}));
