import React from 'react';
import PropTypes from 'prop-types';
import MuiTypography from '@material-ui/core/Typography';
import { useTypographyStyles } from './typographyStyles';
import { getVariantValues } from 'components/helpers/propTypesHelpers';

/**
 * Default Typography component based on https://material-ui.com/components/typography/
 * @param {*} props All possible props can be found in docs https://material-ui.com/api/typography/
 */
export default function Typography(props) {
  const { children, textColor, ...rest } = props;
  const classes = useTypographyStyles(props);

  return (
    <MuiTypography className={classes.root} {...rest}>
      {children}
    </MuiTypography>
  );
}

Typography.propTypes = {
  /**
   * Represents text to display
   */
  children: PropTypes.node,
  /**
   * Available variant styles are overriden by styles defined in themes.js
   */
  variant: PropTypes.oneOf(getVariantValues()),
  /**
   * Custom color value, overrides default possible color values from theme
   */
  textColor: PropTypes.string,
  /**
   * Custom size value
   */
  textSize: PropTypes.number,
};
