import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import Question from 'components/organisms/Inputs/Question/Question';
import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';

export default function ExercisePerDayQuestion(props) {
  const handleChange = (index) => {
    props.onChange && props.onChange(index);
  };

  return (
    <Question
      title={props.title}
      subtitle={props.subtitle}
      subtitleStyle={'body1'}
      divider={false}
    >
      <Box px={2.5} mt={2}>
        <IllustratedSlider
          id="illustrated-slider-exercise"
          illustrations={props.data}
          selectedIllustrationIndex={
            props.exerciseLevel ? props.exerciseLevel : 0
          }
          onValueChanged={(index) => handleChange(index)}
          theme={props.theme}
          width={window.innerWidth > 800 ? '580px' : null}
        />
      </Box>
    </Question>
  );
}
