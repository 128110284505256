import React, { useState, useEffect } from 'react';
import TextInput from 'components/atoms/Inputs/TextInput/TextInput';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useSignInStyles, inputProps } from './signInStyles';
import {
  sha256,
  getCrypto,
  bufferToBase64UrlEncoded,
} from 'components/helpers/auth0Helper';
import { useAuth0 } from '@auth0/auth0-react';
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  PUBLIC_URL,
  DB_CONNECTION,
  getRedirectUriForEmbeddedSignIn,
  EMAIL_REGEX,
} from 'components/helpers/auth0Helper';
import Button from 'components/atoms/Inputs/Buttons/Button';
import * as auth0 from 'auth0-js';
import LandingModal from 'components/molecules/LandingModal/LandingModal';
import { ROUTE_MOBILE_LOGIN_PAGE_NEW } from 'routes';
import {
  BackIcon,
  MobileLoginPageFilledCircle,
  MobileLoginPageMoonCircle,
} from 'assets/iconsList';

export default function SignIn(props) {
  const signInStyles = useSignInStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [warningContent, setWarningContent] = useState(null);

  const options = ['apple' /*'google'*/];

  const { isLoading } = useAuth0();

  const [webAuth] = useState(
    new auth0.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || 'auth.lifenome.com',
      clientID: REACT_APP_AUTH0_CLIENT_ID || 'tSw6gdmNrsPDUalcY3aKOfsY2fI4M9eS',
      redirectUri: `${window.location.origin}${PUBLIC_URL}${ROUTE_MOBILE_LOGIN_PAGE_NEW}`,
      responseType: 'code token',
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      window.location.search &&
      window.location.search.startsWith('?email=')
    ) {
      const query = parseQueryString();
      setEmail(query['email']);
      setPassword(query['pass']);

      loginUser(query['email'], query['pass']);
    }
  }, []);

  const parseQueryString = () => {
    let str = window.location.search;
    let objURL = {};

    str.replace(
      new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
      function ($0, $1, $2, $3) {
        objURL[$1] = $3;
      }
    );
    return objURL;
  };

  async function getSignInRedirectUrl(provider) {
    if (!props.inStorybook && !isLoading) {
      const charset =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
      let random = '';
      const randomValues = Array.from(
        getCrypto().getRandomValues(new Uint8Array(43))
      );
      randomValues.forEach((v) => (random += charset[v % charset.length]));
      const verifier = random;
      const code_challengeBuffer = await sha256(verifier);
      const challenge = bufferToBase64UrlEncoded(code_challengeBuffer);

      window.localStorage.setItem('verifier', verifier);
      const url = `https://${REACT_APP_AUTH0_DOMAIN}/authorize?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=${REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${getRedirectUriForEmbeddedSignIn()}&scope=email%20name%20openid&connection=${provider}&audience=${REACT_APP_AUTH0_AUDIENCE}`;
      window.open(url, '_self');
    }
  }

  const loginUser = (email, password) => {
    webAuth.login(
      {
        realm: DB_CONNECTION,
        email: email,
        password: password,
        audience: REACT_APP_AUTH0_AUDIENCE,
      },
      (error, result) => {
        if (error) {
          setWarningContent(error.error_description);
          setShowWarning(true);
        }
      }
    );
  };

  const handleLogin = () => {
    loginUser(email, password);
  };

  const googleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fbc02d"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
      <path
        fill="#e53935"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      ></path>
      <path
        fill="#4caf50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      ></path>
      <path
        fill="#1565c0"
        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
    </svg>
  );

  const appleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 30 30"
    >
      <path
        fill="#ffffff"
        d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z"
      ></path>
    </svg>
  );

  return (
    <Box
      style={{
        padding: '0 22px',
        height: '100%',
      }}
    >
      <Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: 20,
          }}
          onClick={() => {
            window?.ReactNativeWebView?.postMessage('go_back');
          }}
        >
          <BackIcon style={{ padding: '40px 0 ', cursor: 'pointer' }} />
        </div>
        <Typography className={signInStyles.title}>{'nine.moons '}</Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            marginLeft: -22,
            marginBottom: -150,
          }}
        >
          <MobileLoginPageFilledCircle />
        </div>

        <Typography className={signInStyles.titleForSignIn}>
          <p>Log into</p>
          <p>nine.moons</p>
        </Typography>

        {/*<TextInput
          onChange={(e) => setEmail(e.target.value)}
          className={signInStyles.emailContainer}
          style={{
            marginTop: '25px',
            width: '271px',
          }}
          placeholder="Email"
          inputProps={{ style: inputProps }}
          color="primary"
        />
        <TextInput
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className={signInStyles.passwordContainer}
          style={{
            marginTop: '14px',
            width: '271px',
          }}
          placeholder="Password"
          inputProps={{ style: inputProps }}
        />
        <Box style={{ marginTop: 10 }}>
          <Button
            className={signInStyles.confirmButton}
            disabled={!email || !password}
            style={{ margin: 0, marginTop: 19 }}
            variant="contained"
            onClick={handleLogin}
          >
            <Typography className={signInStyles.buttonText}>
              {'Login'}
            </Typography>
          </Button>
          <Typography
            className={signInStyles.forgotPassText}
            style={{
              marginTop: '17px',
            }}
          >
            {'forgot password?'}
          </Typography>
          </Box>*/}
      </Box>

      {/*<Typography
        style={{ margin: '23px 0 52px 0' }}
        className={`${signInStyles.otherText} ${signInStyles.or}`}
      >
        {'or'}
        </Typography>*/}

      {/* <Box
        style={{
          borderTop: '1px solid white',
          borderBottom: '1px solid white',
        }}
        onClick={() => props.toggleForm && props.toggleForm()}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py="15px"
          style={{
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'white',
          }}
        >
          <Typography
            className={signInStyles.subtitleForSignUp}
            style={{
              textAlign: 'start',
              display: 'inline-block',
              fontSize: '14px',
              lineHeight: 1.43,
              paddingLeft: '5.5px',
              userSelect: 'none',
            }}
          >
            {'Create account'}
          </Typography>
        </Box>
      </Box> */}
      <LandingModal
        content={warningContent}
        opened={showWarning}
        close={() => setShowWarning(false)}
      />

      {options.map((option, ix) => {
        return (
          <Box
            className={signInStyles.continueWithContainer}
            mt={ix === 0 ? '40px' : '14px'}
            onClick={() => {
              if (option === 'google') {
                window.open(getSignInRedirectUrl('google-oauth2'), '_self');
              } else if (option === 'apple') {
                window.open(getSignInRedirectUrl('apple'), '_self');
              }
            }}
            style={{
              backgroundColor: `${option === 'apple' ? 'black' : 'white'}`,
              color: `${option === 'apple' ? 'white' : 'rgba(0, 0, 0, 0.54)'}`,
            }}
            key={ix}
          >
            <div style={{ marginRight: '8px' }}>
              {option === 'google' ? googleLogo : appleLogo}
            </div>
            <Typography
              variant="body1"
              className={signInStyles.continueWithText}
              style={{
                fontFamily:
                  option === 'google' ? 'Roboto-Regular' : 'SFProDisplay',
              }}
            >
              {option === 'google'
                ? 'Continue with Google'
                : 'Continue with Apple'}
            </Typography>
          </Box>
        );
      })}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginRight: -22,
          marginTop: -50,
        }}
      >
        <MobileLoginPageMoonCircle />
      </div>

      <Typography
        className={signInStyles.privacyPolicyText}
        style={{
          width: '320px',
          paddingBottom: '80px',
          marginTop: -50,
        }}
      >
        By creating an account you accept Nine Moons’{' '}
        <span
          style={{ color: '#1D5EDB' }}
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              'https://ninemoons.app/terms-of-service'
            );
          }}
        >
          terms of service
        </span>{' '}
        and{' '}
        <span
          style={{ color: '#1D5EDB' }}
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              'https://ninemoons.app/privacy-policy'
            );
          }}
        >
          privacy policy
        </span>{' '}
      </Typography>
    </Box>
  );
}
