import React from 'react';
import { useOvermindState } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import PregnancyWeek from 'components/organisms/PregnancyWeek/PregnancyWeek';
import {
  prepareMonitorLineChartData,
  renderSymptomChartVertical,
  getCategories,
} from 'components/helpers/chartHelper';

export default function PregnancySymptoms(props) {
  const overmindState = useOvermindState();

  const getNauseaData = () =>
    getData(overmindState.nauseaWeeklyCheckinsByPregnancyWeek);

  const getFatigueData = () =>
    getData(overmindState.fatigueWeeklyCheckinsByPregnancyWeek);

  const getAchingBackData = () =>
    getData(overmindState.achingBackWeeklyCheckinsByPregnancyWeek);

  const getMoodSwingData = () =>
    getData(overmindState.moodSwingsBackWeeklyCheckinsByPregnancyWeek);

  const getData = (stateProp) => {
    const data = [];
    for (let i = 1; i < 41; i++) {
      if (stateProp != null) {
        const entry = stateProp.find((d) => d.week_of_pregnancy === i);
        if (entry == null) {
          data.push(null);
        } else {
          data.push(entry.value);
        }
      }
    }
    return data;
  };

  return (
    <Box mx="24px">
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="flex-end"
        style={{ paddingTop: '26px' }}
      >
        <GridItem xs={6}>
          <Typography variant="h3">Pregnancy Symptoms</Typography>
        </GridItem>
        <GridItem></GridItem>
      </GridContainer>
      <Box style={{ marginTop: 20 }}>
        <PregnancyWeek />
      </Box>
      <Box style={{ marginTop: 30 }}>
        {renderSymptomChartVertical(
          'NAUSEA',
          prepareMonitorLineChartData(
            getNauseaData(),
            overmindState.profilePregnancy.pregnancy_week
          ),
          '#f7c765',
          getCategories(),
          'monitor.nauseaQuestion'
        )}

        {renderSymptomChartVertical(
          'FATIGUE',
          prepareMonitorLineChartData(
            getFatigueData(),
            overmindState.profilePregnancy.pregnancy_week
          ),
          '#d07abf',
          getCategories(),
          'monitor.tiredQuestion'
        )}

        {renderSymptomChartVertical(
          'ACHING BACK',
          prepareMonitorLineChartData(
            getAchingBackData(),
            overmindState.profilePregnancy.pregnancy_week
          ),
          '#8ace84',
          getCategories(),
          'monitor.backPainQuestion'
        )}

        {renderSymptomChartVertical(
          'MOOD SWINGS',
          prepareMonitorLineChartData(
            getMoodSwingData(),
            overmindState.profilePregnancy.pregnancy_week
          ),
          '#961a7e',
          getCategories(),
          'monitor.moodSwingsQuestion'
        )}
      </Box>
    </Box>
  );
}
