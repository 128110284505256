import React, { useEffect } from 'react';
import HasDna from './HasDna';

import { useActions, useOvermindState } from 'overmind/index';

/**
 * Actual questionnaire page asking user if user has DNA.
 * Basically renders HasDna component and manages its state.
 */
export default function HasDnaPage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  return (
    <HasDna
      onWantsToUseDna={(value) => {
        actions.wantsToUseDnaAnswered(value);
      }}
      onDnaOption={(value) => {
        actions.dnaOptionAnswered(value);
      }}
      selectedWantsToUseValue={
        overmindState.metaData.user_metadata.wantsToUseDna == null
          ? undefined
          : overmindState.metaData.user_metadata.wantsToUseDna
      }
      selectedDnaOptionValue={
        overmindState.metaData.user_metadata.dnaUsageOption == null
          ? undefined
          : overmindState.metaData.user_metadata.dnaUsageOption
      }
    ></HasDna>
  );
}
