import React from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import InfoContainer from '../../../../molecules/Common/InfoContainer/InfoContainer';
import { ThemeProvider } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { lifestyleTheme } from 'themes';
import { useActions } from 'overmind/index';

export default function LifestyleSection(props) {
  const { t } = useTranslation();
  const actions = useActions();

  const handleOpenPopup = (component) => {
    actions.saveComponentForModalWindow(component);
    props.onTileClick();
  };

  return (
    <ThemeProvider theme={lifestyleTheme}>
      <GridContainer>
        <GridContainer
          direction="row"
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <GridItem
            style={{
              width: '50%',
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                fontSize: '15px',
              }}
            >
              {t('myProfile.subtitleLifestyle')}
            </Typography>
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <GridItem>
            <InfoContainer
              title={t('myProfile.sleep')}
              value={props.sleep}
              icon={props.sleepIcon}
              color="primary"
              onTileClick={() => handleOpenPopup('sleep')}
            />
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <GridItem>
            <InfoContainer
              title={t('myProfile.stressLevel')}
              value={props.stress}
              icon={props.stressIcon}
              color="primary"
              onTileClick={() => handleOpenPopup('stress')}
            />
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <GridItem>
            <InfoContainer
              title={t('myProfile.exercise')}
              value={props.exercise}
              icon={props.exerciseIcon}
              color="secondary"
              onTileClick={() => handleOpenPopup('exercise')}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
    </ThemeProvider>
  );
}
