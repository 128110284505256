import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CheckinQuestionsSet from 'components/organisms/ModalWindow/CheckinQuestions/CheckinQuestionsSet';
import MonitorQuestionsSet from 'components/organisms/ModalWindow/CheckinQuestions/MonitorQuestionsSet';
import { ThemeProvider } from '@material-ui/core/styles';
import { monitoringTheme } from 'themes';
import { useTheme } from '@material-ui/core/styles';

import { useActions, useOvermindState } from 'overmind/index';
import { formatDate } from 'components/helpers/dateHelper';
import { useHistory } from 'react-router-dom';
import { handleNavigation } from 'components/helpers/footerNavigationHelper';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    width: '95%',
    height: '200vh',
    maxHeight: '800px',
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function WeeklyCheckinDialog(props) {
  const classes = useStyles();
  const actions = useActions();
  const theme = useTheme();
  const overmindState = useOvermindState();
  const history = useHistory();
  const [open, setOpen] = useState(props.opened ? props.opened : false);
  const [step, setStep] = useState(props.step ? props.step : 0);
  const [question, setQuestion] = useState(props.type ? props.type : '');

  useEffect(() => {
    setOpen(props.opened);
  }, [props.opened]);

  useEffect(() => {
    setQuestion(props.type);
  }, [props.type]);

  function nextStep() {
    setStep(step + 1);
    if (step === 7) {
      actions.saveWeeklyCheckinState({
        ...overmindState.weeklyCheckin,
        data: 'mood_swings_level',
      });
      props.onClose();
      history.push(handleNavigation(2));
    } else if (question === 'monitorPregnancySymptoms' && step === 3) {
      actions.saveWeeklyCheckinState({
        ...overmindState.monitorPregnancy,
        data: 'mood_swings_level',
      });
      props.onClose();
      history.push(handleNavigation(2));
    }
  }

  const saveAndClose = async () => {
    await actions.saveMonitorPregnancyChanges({
      date: formatDate(new Date(), 'YYYY-MM-DD'),
    });
    props.onClose();
    history.push(handleNavigation(2));
  };

  return (
    <div
      style={{
        width: '100%',
        margin: '0px',
      }}
    >
      <Dialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{
          width: '100%',
          margin: '0px',
          height: '90vh',
          maxHeight: '800px',
        }}
        fullWidth={true}
        maxWidth={'xl'}
        className={classes.root}
      >
        <div
          style={{
            backgroundImage: theme.palette.primary.weeklyCheckinModal,
            color: 'white',
          }}
        >
          <DialogContent
            style={{
              margin: '0',
              padding: '60px 20px',
              height: '40vh',
              maxHeight: '500px',
            }}
          >
            <ThemeProvider theme={monitoringTheme}>
              {props.type === 'weeklyProgress' ? (
                <CheckinQuestionsSet step={step} />
              ) : (
                <MonitorQuestionsSet
                  question={question}
                  step={question === 'monitorPregnancySymptoms' ? step : null}
                />
              )}
            </ThemeProvider>
          </DialogContent>
          <DialogActions
            style={{
              margin: '0 auto',
              paddingBottom: '40px',
            }}
          >
            <Button
              onClick={
                props.type === 'weeklyProgress' ||
                question === 'monitorPregnancySymptoms'
                  ? () => nextStep()
                  : () => saveAndClose()
              }
              color="inherit"
              variant="outlined"
              style={{
                margin: '0 auto',
              }}
            >
              {props.type === 'weeklyProgress'
                ? 'Next'
                : question === 'monitorPregnancySymptoms'
                ? 'Next'
                : 'Save'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
