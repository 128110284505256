import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import './horizontalSwiperStyles.css';

export default function HorizontalSwiper(props) {
  const { children } = props;
  const [selectedSlideIx] = useState(
    props.selectedSlideIx ? props.selectedSlideIx : 0
  );

  useEffect(() => {
    let swiper = new Swiper('#' + props.uid, {
      slidesPerView: 'auto',
      allowTouchMove: true,
      spaceBetween: 0,
    });

    // if selected slide is defined, slide to it
    if (selectedSlideIx) {
      if (props.firstStep) {
        swiper.slideTo(selectedSlideIx - 1);
      } else {
        swiper.slideTo(selectedSlideIx - 1, 0, false);
      }
    }
  }, []);

  return (
    <div className="swiper-container" id={props.uid}>
      <div className="swiper-wrapper">{children}</div>
    </div>
  );
}

HorizontalSwiper.propTypes = {
  uid: PropTypes.string,
  selectedSlideIx: PropTypes.number,
  slideClassName: PropTypes.string,
};
