import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './slider.css';

export default function Slider(props) {
  const classNames = props.isForward ? 'slideIn' : 'slideOut';

  return (
    <TransitionGroup
      id={props.id}
      component={null}
      childFactory={(child) => React.cloneElement(child, { classNames })}
    >
      <CSSTransition timeout={1200} classNames={classNames} key={props.index}>
        {props.children}
      </CSSTransition>
    </TransitionGroup>
  );
}

Slider.propTypes = {
  /**
   * Flag indicating if components are sliding in(right-left) or out(left-right).
   */
  isForward: PropTypes.bool,
  /**
   * Components that will be transitioned.
   */
  children: PropTypes.node.isRequired,
  /**
   * Component key.
   */
  index: PropTypes.number,
};
