import React, { useEffect } from 'react';
import PregnancyIntro from './PregnancyIntro';

import { useActions } from '../../../../../overmind';

export default function PregnancyIntroPage(props) {
  const actions = useActions();

  useEffect(() => {
    actions.enableForwardButton();
  });

  return <PregnancyIntro />;
}
