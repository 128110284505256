import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import ExercisePerDayQuestion from 'components/organisms/Questions/Exercise/ExercisePerDayQuestion';

import { useOvermindState, useActions } from 'overmind/index';
import { usePageStyles } from 'components/pages/commonStyles';

import {
  exerciseSliderIcon1,
  exerciseSliderIcon2,
  exerciseSliderIcon3,
  exerciseSliderIcon4,
  exerciseSliderIcon5,
} from 'assets/iconsList';

export default function ExercisePerDayPage(props) {
  const { t } = useTranslation();
  const classes = usePageStyles(props);
  const overmindState = useOvermindState();
  const action = useActions();

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '12px', paddingRight: '12px' }}
    >
      <GridItem
        style={{
          maxWidth: '700px',
        }}
      >
        <ExercisePerDayQuestion
          title={t('onboarding.exerciseSection.step1.title')}
          exerciseLevel={overmindState.profilePregnancy.exercise_level}
          onChange={(index) => action.changeExerciseLevel(index)}
          data={[
            {
              illustration: exerciseSliderIcon5,
              height: 85,
              text: t('onboarding.exerciseSection.step1.slide1'),
            },
            {
              illustration: exerciseSliderIcon1,
              height: 85,
              text: t('onboarding.exerciseSection.step1.slide2'),
            },
            {
              illustration: exerciseSliderIcon2,
              height: 85,
              text: t('onboarding.exerciseSection.step1.slide3'),
            },
            {
              illustration: exerciseSliderIcon3,
              height: 85,
              text: t('onboarding.exerciseSection.step1.slide4'),
            },
            {
              illustration: exerciseSliderIcon4,
              height: 85,
              text: t('onboarding.exerciseSection.step1.slide5'),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
