import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useActions, useOvermindState } from 'overmind/index';
import { ROUTE_LANDING_PAGE, ROUTE_REGISTER_KIT, ROUTE_HOME } from 'routes';

import { ThemeProvider } from '@material-ui/core/styles';
import {
  dnaTheme,
  generalTheme,
  pregnancyTheme,
  exerciseTheme,
  lifestyleTheme,
  nutritionTheme,
  introTheme,
} from 'themes';

import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import Slider from 'components/molecules/Slider/Slider';

import ResearchAgreementPage from './Intro/ResearchAgreement/ResearchAgreementPage';
import TermsOfServicePage from './Intro/TermsOfService/TermsOfServicePage';

import PregnancyIntroPage from 'components/pages/Onboarding/Pregnancy/PregnancyIntro/PregnancyIntroPage';
import DueDatePage from 'components/pages/Onboarding/Pregnancy/DueDate/DueDatePage';
import LastPeriodPage from 'components/pages/Onboarding/Pregnancy/LastPeriod/LastPeriodPage';
import CongratulationsPage from 'components/pages/Onboarding/Pregnancy/Congratulations/CongratulationsPage';
import ChildrenQuestionPage from 'components/pages/Onboarding/Pregnancy/ChildrenQuestion/ChildrenQuestionPage';

import GeneralIntro from 'components/pages/Onboarding/General/GeneralIntro';
import NameAndAgePage from 'components/pages/Onboarding/General/Age/NameAndAgePage';
import BMIPage from 'components/pages/Onboarding/General/BMI/BMIPage';
import EthnicityCountryPage from 'components/pages/Onboarding/General/EthnicityCountry/EthnicityCountryPage';

import FoodIntro from './Food/FoodIntroPage/FoodIntroPage';
import DietaryPreferencesPage from './Food/DietaryPreferences/DietaryPreferencesPage';
import FoodAllergiesPage from './Food/FoodAllergies/FoodAllergiesPage';
import ExerciseIntro from './Exercise/ExerciseIntro';
import ExercisePerDayPage from './Exercise/ExercisePerDayPage';
import LifestyleIntro from './Lifestyle/LifestyleIntro';
import LifestyleSleepAndStressPage from './Lifestyle/LifestyleSleepAndStressPage';
import ProgressIndicator from 'components/organisms/ProgressIndicator/ProgressIndicator';
import {
  getProgressItems,
  getSectionByStepId,
  getFirstStepInSection,
  isIntroStep,
} from 'components/helpers/progressIndicatorHelper';
import { getStepIndex } from 'components/helpers/onboardingHelper';
import OnboardingFinished from './OnboardingFinished/OnboardingFinished';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import ReadyToUseTheAppPage from './ReadyToUseTheAppPage';

import { getQueryString } from 'components/helpers/utmParametersHelper';

export default function Onboarding() {
  const actions = useActions();
  const overmindState = useOvermindState();

  useEffect(() => {
    if (
      overmindState.progressStep > getStepIndex('ready_to_use_the_app_page') &&
      overmindState.progressStep !== 1000 &&
      overmindState.metaData.user_metadata.completed_kitregistration_9moons &&
      overmindState.metaData.user_metadata.terms_9moons !== true
    ) {
      actions.goToStep(0);
    }
  }, []);

  return (
    <>
      <HeaderWrapper />
      {window.innerWidth > 800 ? (
        <GridContainer direction="column" justify="center" alignItems="center">
          <GridItem
            style={{
              maxWidth: '1200px',
            }}
          >
            <ProgressIndicatorWrapper />
          </GridItem>
          <GridItem
            style={{
              maxWidth: '1200px',
            }}
          >
            <SliderWrapper />
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <ProgressIndicatorWrapper />
          <SliderWrapper />
        </>
      )}
      <Footer
        onBackClick={() => {
          actions.goBack();
        }}
        onNextClick={() => {
          actions.goNext();
        }}
      />
    </>
  );
}

export const HeaderWrapper = () => {
  const overmindState = useOvermindState();

  return (
    <Header
      hideHeader={overmindState.hideHeader}
      adjustMargin={isIntroStep(overmindState.progressStep)}
      hideLeftButton={overmindState.progressStep <= 1}
    />
  );
};

export function ProgressIndicatorWrapper() {
  const overmindState = useOvermindState();
  const actions = useActions();

  const renderIndicator = (progressStep) => {
    return !isIntroStep(progressStep) ? (
      <ProgressIndicator
        items={getProgressItems()}
        selectedSlideIx={getSectionByStepId(progressStep).id}
        lastVisitedSectionId={overmindState.lastSectionVisited}
        uid="navigation-swiper"
        slideClassName="q-nav-swiper-slide"
        onProgressStepClick={(sectionId, lastVisitedSectionId) => {
          if (overmindState.lastSectionVisited < lastVisitedSectionId) {
            actions.updateLastSectionVisited(lastVisitedSectionId);
          }
          actions.goToStep(getFirstStepInSection(sectionId));
        }}
      />
    ) : null;
  };

  return renderIndicator(overmindState.progressStep);
}

export function SliderWrapper() {
  const overmindState = useOvermindState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderStep = (step) => {
    switch (step) {
      case getStepIndex('terms_of_service'):
        return (
          <ThemeProvider theme={introTheme}>
            <TermsOfServicePage />
          </ThemeProvider>
        );
      case getStepIndex('research_agreement_page'):
        return (
          <ThemeProvider theme={introTheme}>
            <ResearchAgreementPage />
          </ThemeProvider>
        );
      case getStepIndex('general_intro'):
      case getStepIndex('name_and_age_page'):
      case getStepIndex('bmi_page'):
        return (
          <ThemeProvider theme={generalTheme}>
            {renderGeneralSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('pregnancy_intro_page'):
      case getStepIndex('due_data_page'):
      case getStepIndex('last_period_page'):
      case getStepIndex('congratulations_page'):
      case getStepIndex('children_question_page'):
        return (
          <ThemeProvider theme={pregnancyTheme}>
            {renderPregnancySectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('exercise_intro'):
      case getStepIndex('exercise_per_day'):
        return (
          <ThemeProvider theme={exerciseTheme}>
            {renderExerciseSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('lifestyle_intro'):
      case getStepIndex('lifestyle_sleep_and_stress'):
        return (
          <ThemeProvider theme={lifestyleTheme}>
            {renderLifestyleSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('food_intro'):
      case getStepIndex('dietary_preferences_page'):
      case getStepIndex('food_allergies_page'):
        return (
          <ThemeProvider theme={nutritionTheme}>
            {renderNutritionSectionStep(step)}
          </ThemeProvider>
        );
      case getStepIndex('ready_to_use_the_app_page'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <ReadyToUseTheAppPage />
          </ThemeProvider>
        );
      case getStepIndex('onboarding_finished'):
        return (
          <ThemeProvider theme={dnaTheme}>
            <OnboardingFinished />
          </ThemeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderGeneralSectionStep = (step) => {
    switch (step) {
      case getStepIndex('general_intro'):
        return <GeneralIntro />;
      case getStepIndex('name_and_age_page'):
        return <NameAndAgePage />;
      case getStepIndex('bmi_page'):
      default:
        return <BMIPage />;
    }
  };

  const renderPregnancySectionStep = (step) => {
    switch (step) {
      case getStepIndex('pregnancy_intro_page'):
        return <PregnancyIntroPage />;
      case getStepIndex('due_data_page'):
        return <DueDatePage />;
      case getStepIndex('last_period_page'):
        return <LastPeriodPage />;
      case getStepIndex('congratulations_page'):
        return <CongratulationsPage />;
      case getStepIndex('children_question_page'):
      default:
        return <ChildrenQuestionPage />;
    }
  };

  const renderExerciseSectionStep = (step) => {
    switch (step) {
      case getStepIndex('exercise_intro'):
        return <ExerciseIntro />;
      case getStepIndex('exercise_per_day'):
      default:
        return <ExercisePerDayPage />;
    }
  };

  const renderLifestyleSectionStep = (step) => {
    switch (step) {
      case getStepIndex('lifestyle_intro'):
        return <LifestyleIntro />;
      case getStepIndex('lifestyle_sleep_and_stress'):
      default:
        return <LifestyleSleepAndStressPage />;
    }
  };

  const renderNutritionSectionStep = (step) => {
    switch (step) {
      case getStepIndex('food_intro'):
        return <FoodIntro />;
      case getStepIndex('dietary_preferences_page'):
        return <DietaryPreferencesPage />;
      case getStepIndex('food_allergies_page'):
        return <FoodAllergiesPage />;
      default:
        return <FoodIntro />;
    }
  };

  return overmindState.progressStep >= 1000 ? (
    <Redirect to={ROUTE_HOME + getQueryString()} />
  ) : overmindState.progressStep > getStepIndex('ready_to_use_the_app_page') &&
    overmindState.metaData.user_metadata.completed_kitregistration_9moons !==
      true ? (
    <Redirect to={ROUTE_REGISTER_KIT + getQueryString()} />
  ) : window.innerWidth < 800 ? (
    <Slider
      isForward={overmindState.forwardNavigation}
      index={overmindState.progressStep}
    >
      {renderStep(overmindState.progressStep)}
    </Slider>
  ) : (
    <>{renderStep(overmindState.progressStep)}</>
  );
}
