import React, { useEffect } from 'react';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundExercise, backgroundLanding } from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'components/atoms/Layout/Box/Box';
import { useActions, useOvermindState } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function OnboardingFinished() {
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  useEffect(() => {
    actions.fetchMealPlan();
    actions.hideHeader(true);
    trackEvent('Onboarding', 'Finished');
  }, [actions]);

  return (
    <ContainerWithBackgroundImg
      image={
        overmindState.previouslyHadDna ? backgroundLanding : backgroundExercise
      }
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        maxWidth="306px"
        position="relative"
        pr={{ xs: 4 }}
        pl={{ xs: 4 }}
        left={{ sm: '27%' }}
        top={{ sm: '25%', xs: '30%' }}
      >
        <Typography variant="h1">{t('onboarding.finished')}</Typography>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
