import React from 'react';

import Accordion from 'components/atoms/Navigation/Accordion/Accordion';
import AccordionSummary from 'components/atoms/Navigation/Accordion/AccordionSummary';
import AccordionDetails from 'components/atoms/Navigation/Accordion/AccordionDetails';

import DailyNeed from 'components/molecules/Content/Accordion/DailyNeed';
import CollapsedSummary from 'components/molecules/Content/Accordion/CollapsedSummary';
import ExpandedSummary from 'components/molecules/Content/Accordion/ExpandedSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function Macronutrient(props) {
  const { expanded } = props;

  const handleChange = (panel) => (event, newExpanded) => {
    trackEvent('Needs Assessment', 'Details Expanded', {
      need: props.macronutrientTitle,
    });
    if (newExpanded) {
      props.onNewExpanded && props.onNewExpanded(panel);
    } else {
      props.onNewExpanded && props.onNewExpanded('-');
    }
  };

  return (
    <Accordion
      square
      expanded={expanded === props.panelName}
      onChange={handleChange(props.panelName)}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        {expanded !== props.panelName ? (
          <CollapsedSummary
            title={props.macronutrientTitle}
            value={props.total}
            units={props.units}
            percentage={props.percentage}
          />
        ) : (
          <ExpandedSummary title={props.macronutrientTitle} />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <DailyNeed
          title={props.title}
          dataCard={`${props.total} ${props.units}/day`}
          shortDescription={props.description}
          links={props.links}
        />
      </AccordionDetails>
    </Accordion>
  );
}
