import React from 'react';
import Dialog from 'components/atoms/Dialog/Dialog';
import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';

export default function ConfirmActionDialog(props) {
  const { openDialog, title, confirmText, cancelText } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box style={{ margin: '20px', textAlign: 'center' }}>
        <Typography>{title}</Typography>
        <GridContainer style={{ marginTop: 8 }}>
          <GridItem xs={6}>
            <Button
              onClick={() => {
                props.onConfirm();
              }}
              variant="contained"
            >
              <Typography textSize={12}>{confirmText}</Typography>
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              onClick={() => {
                props.onClose();
              }}
              variant="contained"
            >
              <Typography textSize={12}>{cancelText}</Typography>
            </Button>
          </GridItem>
        </GridContainer>
      </Box>
    </Dialog>
  );
}
