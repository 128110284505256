import React from 'react';

import MuiCheckbox from '@material-ui/core/Checkbox';

/**
 * Default Checkbox component based on https://material-ui.com/components/checkboxes/#checkbox
 * @param {} props All possible props can be found in docs https://material-ui.com/api/checkbox/
 */

export default function Checkbox(props) {
  return <MuiCheckbox {...props} />;
}
