import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box';
import CheckboxWithLabel from 'components/molecules/Inputs/CheckboxInput/CheckboxWithLabel';
import Typography from 'components/atoms/Typography/Typography';
import { usePageStyles } from 'components/pages/commonStyles';

export default function TermsOfService(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();

  const [checked, setChecked] = useState(props.checked);

  return (
    <GridContainer
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ paddingLeft: '31px', paddingRight: '30px' }}
    >
      <Box textAlign="center" mt={2} mb={2.5}>
        <Typography variant="h4">
          {t('onboarding.introSection.introTerms.heading')}
        </Typography>
      </Box>

      <Box textAlign="left">
        {t('onboarding.introSection.introTerms.termsTextParagraphs', {
          returnObjects: true,
        }).map((paragraph, ix) => {
          return (
            <GridItem key={ix}>
              <Typography variant="body1" paragraph>
                {paragraph}
              </Typography>
            </GridItem>
          );
        })}
        <Box mt={2} mb={10}>
          <CheckboxWithLabel
            color="primary"
            label={t('onboarding.introSection.introTerms.acceptTerms')}
            checked={checked}
            onChange={(value, checked) => {
              setChecked(checked);
              if (props.onTermsChange) {
                props.onTermsChange(value, checked);
              }
            }}
          />
        </Box>
      </Box>
    </GridContainer>
  );
}
