import * as React from 'react';

function PlannerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.107"
      height="23.093"
      viewBox="0 0 23.107 23.093"
    >
      <g transform="translate(0.5 0.5)">
        <line
          x2="3.145"
          transform="translate(11.007 1.573)"
          fill="none"
          stroke={props.color}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="2.359"
          transform="translate(6.289 1.573)"
          fill="none"
          stroke={props.color}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M423.761,222h1.934a1.289,1.289,0,0,1,1.289,1.289v11.6l-4.513,4.513H408.289A1.289,1.289,0,0,1,407,238.116V223.289A1.289,1.289,0,0,1,408.289,222h2.256"
          transform="translate(-407 -220.711)"
          fill="none"
          stroke={props.color}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M455,266.19v-3.223a.967.967,0,0,1,.967-.967h1.289"
          transform="translate(-439.529 -247.817)"
          fill="none"
          stroke={props.color}
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x2="16.51"
          transform="translate(1.572 5.503)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="3.145"
          height="3.145"
          transform="translate(3.144 12.579)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="3.145"
          height="3.145"
          transform="translate(8.648 12.579)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="3.145"
          height="3.145"
          transform="translate(8.648 7.862)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          width="3.145"
          height="3.145"
          transform="translate(14.151 7.862)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M429.509,242l-2.105,3.112-1.4-1.556"
          transform="translate(-422.916 -234.342)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y1="3.145"
          transform="translate(4.717)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y1="3.145"
          transform="translate(15.723)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y1="3.145"
          transform="translate(10.22)"
          fill="none"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <ellipse
          cx="0.786"
          cy="0.393"
          rx="0.786"
          ry="0.393"
          transform="translate(3.931 3.145)"
          fill="none"
          stroke={props.color}
          strokeWidth="1"
        />
        <ellipse
          cx="0.786"
          cy="0.393"
          rx="0.786"
          ry="0.393"
          transform="translate(9.434 3.145)"
          fill="none"
          stroke={props.color}
          strokeWidth="1"
        />
        <circle
          cx="0.393"
          cy="0.393"
          r="0.393"
          transform="translate(14.938 3.145)"
          fill="none"
          stroke={props.color}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default PlannerIcon;
