import React, { useEffect } from 'react';
import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useOvermindState } from 'overmind/index';
import { useTheme } from '@material-ui/core/styles';
import { getDate } from 'components/helpers/dateHelper';
import { useActions } from 'overmind/index';

export default function PregnancyWeek() {
  const overmindState = useOvermindState();
  const theme = useTheme();
  const actions = useActions();

  /**
   * Fetch pregnancy profile if it isn't already stored in global state.
   */
  useEffect(() => {
    if (
      overmindState.profilePregnancy == null ||
      Object.keys(overmindState.profilePregnancy).length === 0
    ) {
      actions.fetchProfilePregnancy();
    }
  }, [overmindState.profilePregnancy, actions]);

  return (
    <>
      <Box display="flex" justifyContent="center">
        {overmindState.profilePregnancy.pregnancy_week != null && (
          <Typography
            variant="body1"
            style={{ color: theme.palette.primary.main }}
          >{`Week ${overmindState.profilePregnancy.pregnancy_week}`}</Typography>
        )}
        {overmindState.profilePregnancy.pregnancy_week == null && (
          <Skeleton width="70px" height="20px" />
        )}
      </Box>
      <Box display="flex" justifyContent="center" style={{ paddingTop: '5px' }}>
        {overmindState.profilePregnancy.pregnancy_week != null && (
          <Typography
            variant="body2"
            style={{ fontSize: '12px', fontWeight: 'normal' }}
          >
            {`${getDate(
              overmindState.profilePregnancy.pregnancy_week_start
            ).format('dddd, DD MMMM')} - ${getDate(
              overmindState.profilePregnancy.pregnancy_week_end
            )
              .subtract(1, 'seconds')
              .format('dddd, DD MMMM')}`}
          </Typography>
        )}
        {overmindState.profilePregnancy.pregnancy_week == null && (
          <Skeleton width="225px" height="20px" />
        )}
      </Box>
    </>
  );
}
