import { makeStyles } from '@material-ui/core';

export const useFooterMenuStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
    borderTop: '1px Solid #ececec',
  }),
  labelRoot: {
    color: theme.palette.text.tertiary,
    minWidth: '70px',
  },
  label: {
    marginTop: '4px',
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));
