import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from 'components/atoms/Layout/Box/Box';
import { NMoons_Logo } from 'assets/imagesList';
import { useOvermindState, useActions } from 'overmind/index';
import {
  ROUTE_INTRO,
  ROUTE_HOME,
  ROUTE_REGISTER_KIT,
  ROUTE_START,
  ROUTE_ONBOARDING,
} from 'routes';
import { ThemeProvider } from '@material-ui/core/styles';
import { commonTheme } from 'themes';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProfileDeletedDialog from 'components/organisms/ModalWindow/ProfileDeletedDialog';
import { White9moonsLogo } from 'assets/iconsList';
import FilledCircleIcon from 'components/atoms/Layout/FilledCircleIcon';
import Typography from 'components/atoms/Typography/Typography';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import { getQueryString } from 'components/helpers/utmParametersHelper';

export default function LandingPageWithCustomLogin(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const {
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    user,
  } = useAuth0();

  const {
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
  } = process.env;

  const calculatorDataExists = () => {
    return window.localStorage.getItem('9moons_calculator_data') != null;
  };

  const activationCodeExists = () => {
    return window.localStorage.getItem('activationCode') != null;
  };

  const kitRegistrationInProcess = () => {
    return window.localStorage.getItem('kitRegistrations') != null;
  };

  const hasAuthenticationToken = () => {
    return window.localStorage.getItem('access_token') != null;
  };

  const isReturnedFromAuth0SignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') === -1
    );
  };

  const isReturnedFromAuth0CustomSignIn = () => {
    return (
      window.location.search &&
      window.location.search.startsWith('?code=') &&
      window.location.search.indexOf('state=') !== -1
    );
  };

  const isComingFromKitRegistration = () => {
    return (
      window.location.search &&
      window.location.search.indexOf('kitregister=true') !== -1
    );
  };

  const isActivatingApp = () => {
    return (
      window.location.search &&
      window.location.search.indexOf('activation_code=') !== -1
    );
  };

  const isNewAuthenticationRequested = () => {
    return (
      window.location.search && window.location.search.indexOf('auth=') !== -1
    );
  };

  const processCodeAndFetchMetaData = () => {
    let code = window.location.search.substr(
      window.location.search.indexOf('?code=') + 6
    );
    const verifier = window.localStorage.getItem('verifier');
    const queryString = new URLSearchParams();
    queryString.append('grant_type', 'authorization_code');
    queryString.append('client_id', 'GaAK8XZNoqi8OhKUMeAlKSRUnMDCfubm');
    queryString.append('code_verifier', verifier);
    queryString.append('code', code);
    queryString.append('redirect_uri', window.location.origin);
    actions.getAuthenticationTokensAndMetaData(queryString);
  };

  useEffect(() => {
    if (overmindState.calc_data_saved) {
      trackEvent('PregnancyCalc', 'User SignedUp');
      props.history.push(`${ROUTE_START}?returning_user=true`);
    }
  }, [overmindState.calc_data_saved]);

  useEffect(() => {
    if (overmindState.hasFetchedMetaData) {
      if (calculatorDataExists()) {
        // redirect user back to calculator
        let data = window.localStorage.getItem('9moons_calculator_data');
        actions.fillCalculatorData(JSON.parse(data));
        return;
      }

      if (activationCodeExists()) {
        actions.activateSubscription(
          window.localStorage.getItem('activationCode')
        );
      }

      if (kitRegistrationInProcess()) {
        props.history.push(ROUTE_REGISTER_KIT + getQueryString());
        return;
      }

      // redirect user to app
      if (
        overmindState.metaData &&
        overmindState.metaData.user_metadata &&
        overmindState.metaData.user_metadata.completed_onboarding_9moons
      ) {
        props.history.push(ROUTE_HOME + getQueryString());
      } else {
        props.history.push(ROUTE_INTRO + getQueryString());
      }
    }
  }, [overmindState.hasFetchedMetaData]);

  const signInSilently = async () => {
    try {
      const token = await getAccessTokenSilently();
      window.localStorage.setItem('access_token', token);
      if (user != null) {
        actions.setUserEmail(user.email);
      } else {
        actions.getUserEmail();
      }
      signIn({ silentSignIn: false });
    } catch (e) {
      console.log('e ', e.error);
      if (e.error === 'login_required') {
        loginWithRedirect();
      }
      if (e.error === 'consent_required') {
        loginWithRedirect();
      }
    }
  };

  const signIn = (options) => {
    if (calculatorDataExists()) {
      if (hasAuthenticationToken() && !isNewAuthenticationRequested()) {
        actions.onInitialize({ fetchEmail: user == null });
      } else {
        if (isReturnedFromAuth0CustomSignIn() && options.silentSignIn) {
          signInSilently();
        } else if (isReturnedFromAuth0SignIn()) {
          processCodeAndFetchMetaData();
        } else {
          // stay here
          setIsLoadingUserData(false);
        }
      }
    } else {
      if (location.search.includes('profileDeleted')) {
        setOpenDialog(true);
        return;
      }

      if (isComingFromKitRegistration()) {
        window.localStorage.setItem('kitRegistrations', true);
      }

      if (isActivatingApp()) {
        const urlParams = new URLSearchParams(window.location.search);
        const activationCode = urlParams.get('activation_code');
        window.localStorage.setItem('activationCode', activationCode);
      }

      if (hasAuthenticationToken() && !isNewAuthenticationRequested()) {
        actions.onInitialize({ fetchEmail: user == null });
      } else {
        if (isReturnedFromAuth0CustomSignIn() && options.silentSignIn) {
          signInSilently();
        } else if (isReturnedFromAuth0SignIn()) {
          // user without calc data, which isn't authenticated, but is returning from Auth0 SignIn
          processCodeAndFetchMetaData();
        } else {
          // stay here
          setIsLoadingUserData(false);
        }
      }
    }
  };

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search.indexOf('utm_campaign') !== -1
    ) {
      window.localStorage.setItem('qs', window.location.search);
    }

    actions.enableForwardButton();
    signIn({ silentSignIn: true });
  }, []);

  const getCrypto = () => {
    //ie 11.x uses msCrypto
    return window.crypto || window.msCrypto;
  };

  const getCryptoSubtle = () => {
    const crypto = getCrypto();
    //safari 10.x uses webkitSubtle
    return crypto.subtle || crypto.webkitSubtle;
  };

  const bufferToBase64UrlEncoded = (input) => {
    const ie11SafeInput = new Uint8Array(input);
    return urlEncodeB64(
      window.btoa(String.fromCharCode(...Array.from(ie11SafeInput)))
    );
  };

  const urlEncodeB64 = (input) => {
    const b64Chars = { '+': '-', '/': '_', '=': '' };
    return input.replace(/[+/=]/g, (m) => b64Chars[m]);
  };

  const sha256 = async (s) => {
    const digestOp = getCryptoSubtle().digest(
      { name: 'SHA-256' },
      new TextEncoder().encode(s)
    );

    // msCrypto (IE11) uses the old spec, which is not Promise based
    // https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
    // Instead of returning a promise, it returns a CryptoOperation
    // with a result property in it.
    // As a result, the various events need to be handled in the event that we're
    // working in IE11 (hence the msCrypto check). These events just call resolve
    // or reject depending on their intention.
    if (window.msCrypto) {
      return new Promise((res, rej) => {
        digestOp.oncomplete = (e) => {
          res(e.target.result);
        };

        digestOp.onerror = (e) => {
          rej(e.error);
        };

        digestOp.onabort = () => {
          rej('The digest operation was aborted');
        };
      });
    }

    return await digestOp;
  };

  const googleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 48 48"
    >
      <path
        fill="#fbc02d"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
      <path
        fill="#e53935"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      ></path>
      <path
        fill="#4caf50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      ></path>
      <path
        fill="#1565c0"
        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      ></path>
    </svg>
  );

  const facebookLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 48 48"
    >
      <path
        fill="#ffffff"
        d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
      ></path>
      <path
        fill="#4267B2"
        d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"
      ></path>
    </svg>
  );

  const appleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22"
      height="22"
      viewBox="0 0 30 30"
    >
      <path
        fill="#ffffff"
        d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z"
      ></path>
    </svg>
  );

  async function getSignInRedirectUrl(provider) {
    if (!props.inStorybook && !isLoading) {
      const charset =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
      let random = '';
      const randomValues = Array.from(
        getCrypto().getRandomValues(new Uint8Array(43))
      );
      randomValues.forEach((v) => (random += charset[v % charset.length]));
      const verifier = random;
      const code_challengeBuffer = await sha256(verifier);
      const challenge = bufferToBase64UrlEncoded(code_challengeBuffer);

      window.localStorage.setItem('verifier', verifier);
      const url = `https://${REACT_APP_AUTH0_DOMAIN}/authorize?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=${REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=email%20name%20openid&connection=${provider}&audience=${REACT_APP_AUTH0_AUDIENCE}`;
      window.open(url, '_self');
    }
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={commonTheme}>
        {!isLoadingUserData ? (
          <Box>
            <Box display="flex" justifyContent="center" mt="30px">
              <img src={NMoons_Logo} width="100px" alt="9moons logo" />
            </Box>

            <Box display="flex" justifyContent="center" pt="40px" m="0 auto">
              <Typography
                variant="body1"
                style={{
                  fontSize: '24px',
                  textAlign: 'center',
                  fontWeight: 800,
                }}
              >
                {calculatorDataExists()
                  ? 'Log in to get your due date'
                  : 'Log in'}
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              pt="12px"
              m="0 auto"
              maxWidth="600px"
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                Once you login you&apos;ll receive your pregnancy due dates, a
                personalized vitamin &amp; mineral report, and a personalized
                diet plan tailored to your pregnancy and lifestyle.
              </Typography>
            </Box>

            {['google', 'facebook', 'apple'].map((option, ix) => {
              return (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={ix === 0 ? '40px' : '14px'}
                  m="0 auto"
                  width="200px"
                  pt="10px"
                  pb="10px"
                  px="20px"
                  borderRadius="4px"
                  onClick={() => {
                    if (option === 'google') {
                      trackEvent('SigninStarted', 'Google');
                      setTimeout(function () {
                        window.open(
                          getSignInRedirectUrl('google-oauth2'),
                          '_self'
                        );
                      }, 500);
                    } else if (option === 'facebook') {
                      trackEvent('SigninStarted', 'Facebook');
                      setTimeout(function () {
                        window.open(getSignInRedirectUrl('facebook'), '_self');
                      }, 500);
                    } else if (option === 'apple') {
                      trackEvent('SigninStarted', 'Apple');
                      setTimeout(function () {
                        window.open(getSignInRedirectUrl('apple'), '_self');
                      }, 500);
                    }
                  }}
                  style={{
                    cursor: 'pointer',
                    border: '1px Solid darkgray',
                    backgroundColor: `${
                      option === 'google'
                        ? 'white'
                        : option === 'facebook'
                        ? '#4267B2'
                        : 'black'
                    }`,
                    color: `${
                      option === 'google'
                        ? 'black'
                        : option === 'facebook'
                        ? 'white'
                        : 'white'
                    }`,
                  }}
                  key={ix}
                >
                  <div style={{ marginRight: '8px' }}>
                    {option === 'google'
                      ? googleLogo
                      : option === 'facebook'
                      ? facebookLogo
                      : appleLogo}
                  </div>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: '14px',
                      textAlign: 'center',
                    }}
                  >
                    {option === 'google'
                      ? 'Continue with Google'
                      : option === 'facebook'
                      ? 'Continue with Facebook'
                      : 'Continue with Apple'}
                  </Typography>
                </Box>
              );
            })}

            <Box display="flex" justifyContent="center" pt="20px" m="0 auto">
              <Typography
                variant="body1"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  trackEvent('SigninStarted', 'Auth0');
                  loginWithRedirect({
                    screen_hint: 'signup',
                    redirectUri: window.location.origin,
                  });
                }}
              >
                Sign up with email
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              pt="40px"
              m="0 auto"
              width="330px"
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                }}
              >
                by signing up for 9moons you agree to lifenome&apos;s terms and
                conditions of service
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              height: '100vh',
              backgroundImage:
                'linear-gradient(#fcc762 0%, #fa7360 85%, #f26f6d 100%)',
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              mr="3%"
              mb="5%"
              pt="110px"
              style={{ opacity: 0.13 }}
            >
              <FilledCircleIcon
                color={'#FFFFFF'}
                height={'40px'}
                width={'40px'}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <White9moonsLogo />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              pt="80px"
              width="50%"
              m="0 auto"
            >
              <Typography
                variant={'h3'}
                style={{
                  textTransform: 'uppercase',
                  fontSize: '20px',
                  color: '#FFFFFF',
                  textAlign: 'center',
                }}
              >
                YOUR PREGNANCY. UNIQUE AS YOU ARE.
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              pt="40px"
              width="50%"
              m="0 auto"
            >
              <Typography
                variant={'body1'}
                style={{
                  fontSize: '16px',
                  color: '#FFFFFF',
                  textAlign: 'center',
                }}
              >
                Personalized Pregnancy Nutrition Companion
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt="80px"
              mr="15%"
              style={{ height: '30px', opacity: 0.13 }}
            >
              <FilledCircleIcon
                color={'#FFFFFF'}
                height={'60px'}
                width={'60px'}
              />
            </Box>
          </Box>
        )}
      </ThemeProvider>
      <ProfileDeletedDialog
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        title={t('myProfile.profileDeleted')}
        confirmText={t('common.answerOk')}
      />
    </React.Fragment>
  );
}
