import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';

import { useCalendarItemStyles } from './calendarItemStyles';

export default function CalendarItem(props) {
  const classes = useCalendarItemStyles(props);
  const [selected, setSelected] = useState(
    props.selected ? props.selected : false
  );

  const handleClick = () => {
    setSelected(!selected);

    props.onClick && props.onClick();
  };

  return (
    props.date && (
      <Box className={classes.circle} onClick={handleClick}>
        <Typography className={classes.text1}>
          {props.date.format('dd')}
        </Typography>
        <Typography className={classes.text2}>
          {props.date.format('DD')}
        </Typography>
      </Box>
    )
  );
}

CalendarItem.propTypes = {
  selected: PropTypes.bool,
  date: PropTypes.object.isRequired,
};
