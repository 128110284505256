import React from 'react';
import PropTypes from 'prop-types';
import MuiGrid from '@material-ui/core/Grid';

export default function GridContainer(props) {
  const { children, ...rest } = props;
  return (
    <MuiGrid container {...rest}>
      {children}
    </MuiGrid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node,
};
