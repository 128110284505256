import { makeStyles } from '@material-ui/core/styles';

export const useSelectableIconStyles = makeStyles((theme) => ({
  container: (props) => ({
    width: props.width ? props.width : '107px',
    height: props.height ? props.height : '107px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    borderRadius: '12px',
    boxSizing: 'border-box',
  }),
  checked: {
    border: '3px solid ' + theme.palette.primary.main,
  },
  unChecked: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    webkitBoxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    mozBoxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
}));

export const useFormControlLabelStyles = makeStyles((theme) => ({
  label: (props) => ({
    fontWeight: 'bold',
    fontSize: props.labelFontSize ? props.labelFontSize : 16,
    color: props.color,
  }),
}));
