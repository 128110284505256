import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from 'components/atoms/Layout/Box/Box';
import Checkbox from 'components/atoms/Inputs/Checkbox/Checkbox';
import Radio from 'components/atoms/Inputs/Radio/Radio';
import Typography from 'components/atoms/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  useSelectableIconStyles,
  useFormControlLabelStyles,
} from './selectableIconStyles';

export default function SelectableIcon(props) {
  const classes = useSelectableIconStyles(props);
  const formControlLabelStyle = useFormControlLabelStyles(props);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value, e.target.checked);
    }
  };

  return (
    <FormControlLabel
      className={clsx(
        classes.container,
        props.checked ? classes.checked : classes.unChecked
      )}
      control={
        props.isRadio ? (
          <Radio {...props} />
        ) : (
          <Checkbox
            checked={checked ? checked : false}
            id={props.value}
            value={props.value}
            icon={props.icon}
            checkedIcon={props.checkedIcon}
            onChange={(e) => onChange(e)}
            uid={props.uid}
          />
        )
      }
      classes={formControlLabelStyle}
      label={
        <Box textAlign="center">
          <Typography
            className={
              props.moveTextHigher
                ? formControlLabelStyle.labelHigher
                : formControlLabelStyle.label
            }
          >
            {props.label}
          </Typography>
        </Box>
      }
    />
  );
}

SelectableIcon.propTypes = {
  /**
   * Is checkbox checked.
   */
  checked: PropTypes.bool,
  /**
   * Width of icon wrapper
   */
  width: PropTypes.number,
  /**
   * Height of icon wrapper
   */
  height: PropTypes.number,
  /**
   * Font size of label
   */
  labelFontSize: PropTypes.number,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Font color
   */
  color: PropTypes.string,
  /**
   * Selectable checkbox icon
   */
  icon: PropTypes.node,
  /**
   * Selectable checkbox checked icon
   */
  checkedIcon: PropTypes.node,
  /**
   * Checkbox value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Indicates whether radio button needs to be rendered
   */
  isRadio: PropTypes.bool,
};
