import React, { useEffect, useState } from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Button from 'components/atoms/Inputs/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import { cookiesConsentDialog } from 'themes';
import {
  initializeTracking,
  initializeMixpanelTracking,
} from 'components/helpers/userTrackingEventsHelper';
import { useOvermindState } from 'overmind/index';

export default function CookiesConsentDialog(props) {
  const { t } = useTranslation();
  const overmindState = useOvermindState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return () => window.removeEventListener('resize', null);
  }, []);

  const acceptCookies = () => {
    window.localStorage.setItem('cookies_consent', true);
    window.dispatchEvent(new Event('storage'));
    initializeTracking();
    initializeMixpanelTracking();
    props.closeDialog();
  };

  const declineCookies = () => {
    window.localStorage.setItem('cookies_consent', false);
    props.closeDialog();
  };

  return (
    <ThemeProvider theme={cookiesConsentDialog}>
      <Box
        style={{
          position: 'fixed',
          bottom: '0px',
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#FFFFFF',
          zIndex: 1000,
          height: windowWidth < 1000 ? '300px' : '220px',
          paddingTop: windowWidth < 1000 ? '43px' : '30px',
          margin: '0 auto',
          borderRadius: '20px 20px 0 0',
        }}
      >
        <Typography
          variant={'h3'}
          style={{
            fontFamily: 'Museo-500',
            fontSize: '18px',
          }}
        >
          {t('home.cookiesConsentDialog.title')}
        </Typography>
        <GridContainer
          direction={windowWidth < 1000 ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          style={{
            width: windowWidth < 1000 ? '100%' : '70%',
            margin: '0 auto',
          }}
        >
          <GridItem xs={windowWidth > 1000 ? (windowWidth < 1280 ? 6 : 8) : 6}>
            <Box
              style={{
                width: windowWidth > 1000 ? '80%' : '90%',
                textAlign: 'left',
                margin: windowWidth > 1000 ? '0 auto' : '0 20px',
                paddingTop: '13px',
                cursor: 'pointer',
                display: 'inline-block',
              }}
            >
              <Typography
                variant={'body1'}
                style={{
                  fontFamily: 'MuseoSans-500',
                  fontSize: '14px',
                  display: 'inline-block',
                }}
              >
                {t('home.cookiesConsentDialog.content')}
                <Typography
                  variant={'body1'}
                  style={{
                    fontFamily: 'MuseoSans-500',
                    fontSize: '14px',
                    textDecoration: 'underline',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    window.open('https://www.lifenome.com/privacy-policy/')
                  }
                >
                  {t('home.cookiesConsentDialog.privacyPolicyLink')}
                </Typography>
              </Typography>
            </Box>
          </GridItem>
          <GridItem xs={windowWidth > 1000 ? (windowWidth < 1280 ? 6 : 4) : 6}>
            <GridContainer style={{ margin: '22px auto' }} direction="row">
              <GridItem>
                <Button
                  onClick={() => {
                    declineCookies();
                  }}
                  variant="outlined"
                  color="primary"
                  style={{
                    height: '48px',
                    border: '2px solid #eaa49d',
                  }}
                >
                  <Typography
                    textSize={15}
                    style={{
                      fontFamily: 'MuseoSans-500',
                      fontSize: '15px',
                    }}
                  >
                    {t('home.cookiesConsentDialog.cancelText')}
                  </Typography>
                </Button>
              </GridItem>
              <GridItem
                style={{
                  marginLeft: '12px',
                }}
              >
                <Button
                  onClick={() => {
                    acceptCookies();
                  }}
                  variant="contained"
                  color="primary"
                  style={{ height: '48px' }}
                >
                  <Typography
                    textSize={15}
                    style={{
                      fontFamily: 'MuseoSans-500',
                      fontSize: '15px',
                    }}
                  >
                    {t('home.cookiesConsentDialog.confirmText')}
                  </Typography>
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Box>
    </ThemeProvider>
  );
}
