import lifestyleSliderSleepIcon1 from 'assets/icons/lifestyle_slider_sleep_icon_1.svg';
import lifestyleSliderSleepIcon2 from 'assets/icons/lifestyle_slider_sleep_icon_2.svg';
import lifestyleSliderSleepIcon3 from 'assets/icons/lifestyle_slider_sleep_icon_3.svg';
import lifestyleSliderSleepIcon4 from 'assets/icons/lifestyle_slider_sleep_icon_4.svg';
import lifestyleSliderSleepIcon5 from 'assets/icons/lifestyle_slider_sleep_icon_5.svg';
import lifestyleSliderStressIcon1 from 'assets/icons/lifestyle_slider_stress_icon_1.svg';
import lifestyleSliderStressIcon2 from 'assets/icons/lifestyle_slider_stress_icon_2.svg';
import lifestyleSliderStressIcon3 from 'assets/icons/lifestyle_slider_stress_icon_3.svg';
import lifestyleSliderStressIcon4 from 'assets/icons/lifestyle_slider_stress_icon_4.svg';
import lifestyleSliderStressIcon5 from 'assets/icons/lifestyle_slider_stress_icon_5.svg';
import exerciseSliderIcon1 from 'assets/icons/exercise_slider_icon_1.svg';
import exerciseSliderIcon2 from 'assets/icons/exercise_slider_icon_2.svg';
import exerciseSliderIcon3 from 'assets/icons/exercise_slider_icon_3.svg';
import exerciseSliderIcon4 from 'assets/icons/exercise_slider_icon_4.svg';
import exerciseSliderIcon5 from 'assets/icons/exercise_slider_icon_5.svg';
import PescatarianIcon from 'assets/icons/pescatarian-icon.svg';
import VeganIcon from 'assets/icons/vegan-icon.svg';
import VegetarianIcon from 'assets/icons/vegetarian-icon.svg';
import NutFreeIcon from 'assets/icons/nutFree-icon.svg';
import GlutenFreeIcon from 'assets/icons/glutenFree-Icon.svg';
import DairyFreeIcon from 'assets/icons/lactose-icon.svg';
import PorkFree from 'assets/icons/pork_free.svg';
import LowFat from 'assets/icons/low-fat-icon.svg';
import Egg from 'assets/icons/egg-icon.svg';
import Shellfish from 'assets/icons/shellfish-icon.svg';
import Sugar from 'assets/icons/sugar-icon.svg';
import Peanut from 'assets/icons/peanut-icon.svg';
import Sesame from 'assets/icons/sesame-icon.svg';
import Soy from 'assets/icons/soy-icon.svg';

export const getEthnicity = (selectedEthnicity) => {
  switch (selectedEthnicity) {
    case 1:
      return 'onboarding.generalSection.step2.ethnicityOptions.nonHispanic';
    case 2:
      return 'onboarding.generalSection.step2.ethnicityOptions.africanAmerican';
    case 3:
      return 'onboarding.generalSection.step2.ethnicityOptions.hispanic';
    case 4:
      return 'onboarding.generalSection.step2.ethnicityOptions.asian';
    case 5:
      return 'onboarding.generalSection.step2.ethnicityOptions.other';
    case 6:
      return 'onboarding.generalSection.step2.ethnicityOptions.dontWantToSay';
    default:
      return;
  }
};

export const getSleep = (sleepQuality) => {
  switch (sleepQuality) {
    case 0:
      return 'onboarding.lifestyleSection.step1.question1.slide1';
    case 1:
      return 'onboarding.lifestyleSection.step1.question1.slide2';
    case 2:
      return 'onboarding.lifestyleSection.step1.question1.slide3';
    case 3:
      return 'onboarding.lifestyleSection.step1.question1.slide4';
    case 4:
      return 'onboarding.lifestyleSection.step1.question1.slide5';
    default:
      return;
  }
};

export const getSleepIcon = (sleepQuality) => {
  switch (sleepQuality) {
    case 0:
      return lifestyleSliderSleepIcon1;
    case 1:
      return lifestyleSliderSleepIcon2;
    case 2:
      return lifestyleSliderSleepIcon3;
    case 3:
      return lifestyleSliderSleepIcon4;
    case 4:
      return lifestyleSliderSleepIcon5;
    default:
      return;
  }
};

export const getStress = (stressLevel) => {
  switch (stressLevel) {
    case 0:
      return 'onboarding.lifestyleSection.step1.question2.slide1';
    case 1:
      return 'onboarding.lifestyleSection.step1.question2.slide2';
    case 2:
      return 'onboarding.lifestyleSection.step1.question2.slide3';
    case 3:
      return 'onboarding.lifestyleSection.step1.question2.slide4';
    case 4:
      return 'onboarding.lifestyleSection.step1.question2.slide5';
    default:
      return;
  }
};

export const getStressIcon = (stressLevel) => {
  switch (stressLevel) {
    case 0:
      return lifestyleSliderStressIcon1;
    case 1:
      return lifestyleSliderStressIcon2;
    case 2:
      return lifestyleSliderStressIcon3;
    case 3:
      return lifestyleSliderStressIcon4;
    case 4:
      return lifestyleSliderStressIcon5;
    default:
      return;
  }
};

export const getExercise = (exerciseLevel) => {
  switch (exerciseLevel) {
    case 0:
      return 'onboarding.exerciseSection.step1.slide1';
    case 1:
      return 'onboarding.exerciseSection.step1.slide2';
    case 2:
      return 'onboarding.exerciseSection.step1.slide3';
    case 3:
      return 'onboarding.exerciseSection.step1.slide4';
    case 4:
      return 'onboarding.exerciseSection.step1.slide5';
    default:
      return;
  }
};

export const getExerciseIcon = (exerciseLevel) => {
  switch (exerciseLevel) {
    case 0:
      return exerciseSliderIcon5;
    case 1:
      return exerciseSliderIcon1;
    case 2:
      return exerciseSliderIcon2;
    case 3:
      return exerciseSliderIcon3;
    case 4:
      return exerciseSliderIcon4;
    default:
      return;
  }
};

export const getDietaryPreferences = (nutritionStates, profileNutrition) => {
  let preferences = [];

  for (let i = 0; i < profileNutrition.length; i++) {
    if (nutritionStates[profileNutrition[i]] === true) {
      switch (profileNutrition[i]) {
        case 'vegetarian':
          preferences.push('Vegetarian');
          break;
        case 'vegan':
          preferences.push('Vegan');
          break;
        case 'pescatarian':
          preferences.push('Pescatarian');
          break;
        case 'pork_free':
          preferences.push('Pork free');
          break;
        default:
          break;
      }
    }
  }
  return preferences;
};

export const getDietaryPreferencesIcon = (
  nutritionStates,
  profileNutrition
) => {
  let preferencesIcons = [];

  for (let i = 0; i < profileNutrition.length; i++) {
    if (nutritionStates[profileNutrition[i]] === true) {
      switch (profileNutrition[i]) {
        case 'vegetarian':
          preferencesIcons.push(VegetarianIcon);
          break;
        case 'vegan':
          preferencesIcons.push(VeganIcon);
          break;
        case 'pescatarian':
          preferencesIcons.push(PescatarianIcon);
          break;
        case 'pork_free':
          preferencesIcons.push(PorkFree);
          break;
        default:
          break;
      }
    }
  }
  return preferencesIcons;
};

export const getAlergiesRestrictions = (nutritionStates, profileNutrition) => {
  let restrictions = [];

  for (let i = 0; i < profileNutrition.length; i++) {
    if (nutritionStates[profileNutrition[i]] === true) {
      switch (profileNutrition[i]) {
        case 'low_fat':
          restrictions.push('Low fat');
          break;
        case 'dairy_free':
          restrictions.push('Dairy/Lactose');
          break;
        case 'gluten_free':
          restrictions.push('Gluten');
          break;
        case 'egg_free':
          restrictions.push('Egg');
          break;
        case 'nut_free':
          restrictions.push('Nut');
          break;
        case 'shellfish_free':
          restrictions.push('Shellfish');
          break;
        case 'soy_free':
          restrictions.push('Soy');
          break;
        case 'sugar_free':
          restrictions.push('Sugar');
          break;
        case 'peanut_free':
          restrictions.push('Peanut');
          break;
        case 'sesame_free':
          restrictions.push('Sesame');
          break;
        default:
          break;
      }
    }
  }
  return restrictions;
};

export const getAlergiesRestrictionsIcon = (
  nutritionStates,
  profileNutrition
) => {
  let restrictionsIcons = [];

  for (let i = 0; i < profileNutrition.length; i++) {
    if (nutritionStates[profileNutrition[i]] === true) {
      switch (profileNutrition[i]) {
        case 'low_fat':
          restrictionsIcons.push(LowFat);
          break;
        case 'dairy_free':
          restrictionsIcons.push(DairyFreeIcon);
          break;
        case 'gluten_free':
          restrictionsIcons.push(GlutenFreeIcon);
          break;
        case 'egg_free':
          restrictionsIcons.push(Egg);
          break;
        case 'nut_free':
          restrictionsIcons.push(NutFreeIcon);
          break;
        case 'shellfish_free':
          restrictionsIcons.push(Shellfish);
          break;
        case 'soy_free':
          restrictionsIcons.push(Soy);
          break;
        case 'sugar_free':
          restrictionsIcons.push(Sugar);
          break;
        case 'peanut_free':
          restrictionsIcons.push(Peanut);
          break;
        case 'sesame_free':
          restrictionsIcons.push(Sesame);
          break;
        default:
          break;
      }
    }
  }
  return restrictionsIcons;
};
