import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentDialog from './PaymentDialog';

export default function PaymentDialogHome(props) {
  const { t } = useTranslation();
  const dialogContent = [
    {
      bulletText: t('home.paymentDialog.bullet1'),
    },
    {
      bulletText: t('home.paymentDialog.bullet2'),
    },
    {
      bulletText: t('home.paymentDialog.bullet3'),
    },
  ];

  return (
    <PaymentDialog
      openDialog={props.openDialog}
      onCloseDialog={props.onCloseDialog}
      dialogContent={dialogContent}
      redirectUrl="https://www.9moons.com"
      redirectType="open"
      redirectState={props.redirectState}
    />
  );
}
