import React from 'react';
import HorizontalSwiper from 'components/molecules/HorizontalSwiper/HorizontalSwiper';
import Meal from 'components/organisms/Meal/Meal';
import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import PropTypes from 'prop-types';

export default function RecommendedItemsSwiper(props) {
  const { similarItems, mainItem, maxSimilarItems } = props;

  return (
    <>
      {similarItems != null && mainItem && (
        <HorizontalSwiper uid="navigation-swiper">
          {props.showRecommendedItem && (
            <div
              className={'swiper-slide q-nav-swiper-slide space-slider'}
              key={0}
            >
              <Meal
                recipe={mainItem}
                onClicked={(id) => props.onItemSelected(id)}
                parentComponent={props.parentComponent}
              />
            </div>
          )}
          {similarItems.map((item, index) => (
            <React.Fragment key={item.id + '' + index}>
              {(maxSimilarItems == null || index + 1 <= maxSimilarItems) && (
                <div
                  className={'swiper-slide q-nav-swiper-slide space-slider'}
                  style={{ width: '120px' }}
                >
                  <Meal
                    recipe={{
                      imageUrl: item.mainImage,
                      name: item.name,
                      isHorizontal: false,
                      uid: item.id,
                      id: item.id,
                      numberOfServings: mainItem.numberOfServings,
                    }}
                    onClicked={(id) =>
                      props.onSimilarItemSelected(id, mainItem.id)
                    }
                    parentComponent={props.parentComponent}
                    hideBookmarks={true}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </HorizontalSwiper>
      )}
    </>
  );
}

RecommendedItemsSwiper.propTypes = {
  mainItem: getRecipeProps(),
  onItemSelected: PropTypes.func,
  onSimilarItemSelected: PropTypes.func,
  similarItems: PropTypes.arrayOf(PropTypes.shape(getRecipeProps)),
  maxSimilarItems: PropTypes.number,
};
