import MuiTab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export default function Tab(props) {
  const StyledTab = withStyles((theme) => ({
    root: {
      flexGrow: 1,
      fontSize: '14px',
      textAlign: 'center',
      color: '#2a343f',
      lineHeight: '14px',
      textTransform: 'uppercase',
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '& div.MuiTabs-scroller.MuiTabs-fixed': {
        '& div.MuiTabs-flexContainer': {
          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          zIndex: 9888,
          position: 'fixed',
          backgroundColor: '#ffffff',
        },
      },
    },

    selected: {},
  }))((props) => <MuiTab {...props} />);

  const { children, ...rest } = props;
  return <StyledTab {...rest}>{children}</StyledTab>;
}
