import { makeStyles } from '@material-ui/core';

let height = 30;

export const useSwitchStyles = makeStyles((theme) => ({
  root: {
    height: height + 2,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translateX(24px)`,
      color: theme.palette.common.white,
      '& + $track': (props) => ({
        backgroundColor: props.checked
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      }),
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.dark,
    },
  },
  thumb: (props) => ({
    width: props.height,
    height: props.height,
  }),
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));
