import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Layout/Box';

/**
 * Svg Icon component based on https://material-ui.com/api/svg-icon/
 * @param {*} props All possible props can be found on https://material-ui.com/api/svg-icon/
 */
export default function SvgIcon(props) {
  const { children, icon, ...rest } = props;

  return (
    <Box {...rest} onClick={props.onClick}>
      {icon &&
        React.createElement(icon, {
          width: '100%',
          height: '100%',
        })}
    </Box>
  );
}

SvgIcon.propTypes = {
  /**
 Svg icon
 */
  children: PropTypes.node,
};
