import React from 'react';
import KitNumberInput from './KitNumberInput';

import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';

/**
 * Actual questionnaire page asking user to upload the file.
 * Basically renders FileToUpload component and manages its state.
 */
export default function KitNumberInputPage(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  return (
    <KitNumberInput
      title={
        overmindState.kitNumberSubmit.showSubmitStatus
          ? overmindState.kitNumberSubmit.submitSuccessful
            ? t('onboarding.dnaSection.step8.success')
            : t('onboarding.dnaSection.step8.failed')
          : t('onboarding.dnaSection.step8.caption')
      }
      subtitle={
        overmindState.kitNumberSubmit.showSubmitStatus
          ? overmindState.kitNumberSubmit.submitSuccessful
            ? t('onboarding.dnaSection.step8.successAdditionalMsg')
            : null
          : null
      }
      kitNumber={
        overmindState.genotype && overmindState.genotype.source_details
          ? overmindState.genotype.source_details.gxg_kit_number
          : null
      }
      submitSuccessful={overmindState.kitNumberSubmit.submitSuccessful}
      showSubmitStatus={overmindState.kitNumberSubmit.showSubmitStatus}
      onKitNumberSubmit={(kitNumber) => {
        actions.submitKitNumber(kitNumber);
      }}
      error={overmindState.errors && overmindState.errors.errorSavingGenotype}
      continueText={t('onboarding.dnaSection.step8.continueText')}
      invalidFormatText={t('onboarding.dnaSection.step8.invalidFormatText')}
    />
  );
}
