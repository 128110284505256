import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Checkout from './Checkout';
import { useActions, useOvermindState } from 'overmind/index';
import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';
import { useHistory } from 'react-router-dom';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_MEAL_PLAN, ROUTE_PROFILE, ROUTE_MONITOR } from 'routes';
import { getQueryString } from 'components/helpers/utmParametersHelper';

const {
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_STRIPE_SUBSCRIPTION,
} = process.env;

// Publishable key from https://dashboard.stripe.com/test/apikeys
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PaymentPage() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState('');

  const getSelectedFooterValue = () => {
    if (history?.location?.state?.initiator) {
      if (history.location.state.initiator === ROUTE_MEAL_PLAN) {
        return 1;
      } else if (history.location.state.initiator === ROUTE_MONITOR) {
        return 2;
      } else if (history.location.state.initiator === ROUTE_PROFILE) {
        return 4;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getReturningUrl = () => {
    if (history?.location?.state?.initiator) {
      return history.location.state.initiator;
    } else {
      return null;
    }
  };

  useEffect(() => {
    // Call API to fetch PaymentIntent (https://stripe.com/docs/api/payment_intents/object)
    // UI is interested only in client_secret returned in PaymentIntent object
    actions.createPaymentIntent({ price_id: REACT_APP_STRIPE_SUBSCRIPTION });
  }, [actions]);

  useEffect(() => {
    if (overmindState.payment.intent) {
      setClientSecret(overmindState.payment.intent.client_secret);
    }
  }, [overmindState.payment.intent]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <ReoccurringHeader
        title={'Payment'}
        showBack={true}
        goBack={() => {
          if (getReturningUrl()) {
            history.push(getReturningUrl() + getQueryString());
          }
        }}
      />
      <div style={{ paddingTop: '60px', margin: '0 14px 0 14px' }}>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <Checkout
              secret={options.clientSecret}
              initiator={
                history && history.location && history.location.state
                  ? history.location.state.initiator
                  : undefined
              }
            />
          </Elements>
        )}
      </div>
      <FooterMenu
        selectedValue={getSelectedFooterValue()}
        onSelected={(val) => {
          history.push(handleNavigation(val));
        }}
        menuOptions={getMenuOptions(assessmentTheme, getSelectedFooterValue())}
      />
    </ThemeProvider>
  );
}
