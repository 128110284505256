import React, { useState } from 'react';
import Tabs from 'components/molecules/Navigation/Tabs/Tabs';
import Tab from 'components/molecules/Navigation/Tabs/Tab';
import TabTitle from 'components/molecules/Navigation/Tabs/TabTitle';
import PregnancySymptoms from 'components/pages/Assessment/Monitor/PregnancySymptoms';
import Health from 'components/pages/Assessment/Monitor/Health';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function PregnancySymptomsHealth(props) {
  const [value, setValue] = useState(props.healthTabSelected ? 1 : 0);
  const values = ['Pregnancy', 'Health'];

  const handleChange = (event, newValue) => {
    trackEvent('Monitor', 'Clicked', {
      option: 'Summary Tab ' + values[newValue],
    });
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="monitor tabs"
        style={{ width: window.innerWidth < 800 ? 'auto' : 1200 }}
      >
        <Tab label={<TabTitle label={values[0]} />} />
        <Tab label={<TabTitle label={values[1]} />} />
      </Tabs>

      {value === 0 && <PregnancySymptoms />}
      {value === 1 && <Health />}
    </>
  );
}
