import {
  ROUTE_HOME,
  ROUTE_MEAL_PLAN,
  ROUTE_MONITOR,
  ROUTE_PROFILE,
  ROUTE_ASSESSMENT,
} from 'routes';
import {
  HomeIcon,
  PlannerIcon,
  NeedsIcon,
  ProfileIcon,
  MonitorIcon,
} from 'assets/iconsList';
import { getQueryString } from 'components/helpers/utmParametersHelper';

export const handleNavigation = (footerIndex) => {
  switch (footerIndex) {
    case 0:
      return ROUTE_HOME + getQueryString();
    case 1:
      return ROUTE_MEAL_PLAN + getQueryString();
    case 2:
      return ROUTE_MONITOR + getQueryString();
    case 3:
      return ROUTE_ASSESSMENT + getQueryString();
    case 4:
      return ROUTE_PROFILE + getQueryString();
    default:
      return;
  }
};

export const isAssessmentRoute = () => {
  return (
    ROUTE_HOME ||
    ROUTE_MEAL_PLAN ||
    ROUTE_MONITOR ||
    ROUTE_ASSESSMENT ||
    ROUTE_PROFILE
  );
};

export const getMenuOptions = (theme, selectedIx) => {
  return [
    {
      label: 'Home',
      showLabel: true,
      value: 0,
      icon: (
        <HomeIcon
          color={
            selectedIx === 0
              ? theme.palette.primary.main
              : theme.palette.text.tertiary
          }
        />
      ),
    },
    {
      label: 'Planner',
      showLabel: true,
      value: 1,
      icon: (
        <PlannerIcon
          color={
            selectedIx === 1
              ? theme.palette.primary.main
              : theme.palette.text.tertiary
          }
        />
      ),
    },
    {
      label: 'Monitor',
      showLabel: true,
      value: 2,
      icon: (
        <MonitorIcon
          color={
            selectedIx === 2
              ? theme.palette.primary.main
              : theme.palette.text.tertiary
          }
        />
      ),
    },
    {
      label: 'Needs',
      showLabel: true,
      value: 3,
      icon: (
        <NeedsIcon
          color={
            selectedIx === 3
              ? theme.palette.primary.main
              : theme.palette.text.tertiary
          }
        />
      ),
    },
    {
      label: 'Profile',
      showLabel: true,
      value: 4,
      icon: (
        <ProfileIcon
          color={
            selectedIx === 4
              ? theme.palette.primary.main
              : theme.palette.text.tertiary
          }
        />
      ),
    },
  ];
};
