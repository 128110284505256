import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { MoonList } from 'assets/iconsList';

export default function Ingredients(props) {
  return (
    <GridContainer>
      <GridItem xs={12} style={{ marginTop: '20px' }}>
        {props.ingredients.ingredientLines.map((ingredient, ix) => (
          <GridContainer direction="row" key={ix}>
            <GridItem
              xs={12}
              style={{
                marginBottom: '12px',
                display: 'flex',
                justifyContent: 'left',
              }}
              key={ix}
            >
              <Typography
                variant="body1"
                style={{ textAlign: 'left', margin: '0 10%' }}
              >
                <MoonList style={{ marginRight: '10px' }} />
                {ingredient}
              </Typography>
            </GridItem>
          </GridContainer>
        ))}
      </GridItem>
    </GridContainer>
  );
}
