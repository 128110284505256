import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import BaseInput from 'components/atoms/Inputs/TextInput/BaseInput';
import IconButton from 'components/atoms/Inputs/Buttons/IconButton';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useNumberInputStyles } from './numberInputStyles';

export default function NumberInput(props) {
  const { min, max, color } = props;
  const classes = useNumberInputStyles();
  const [value, setValue] = useState(props.value ? props.value : null);

  const handleInputChange = (newInputValue) => {
    const newValue = newInputValue;
    setValue(newValue);

    props.onChange && props.onChange(newValue);
  };

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      {props.showArrows === false ? null : (
        <IconButton
          className={classes.iconStart}
          onClick={() => {
            !min
              ? handleInputChange(value - 1)
              : value > min
              ? handleInputChange(value - 1)
              : handleInputChange(min);
          }}
        >
          <PlayArrowIcon color={color} />
        </IconButton>
      )}

      <BaseInput
        value={parseInt(value)}
        color={color}
        onChange={(e) => handleInputChange(e)}
        inputProps={{
          style: { textAlign: 'center' },
          inputType: props.inputProps.inputType,
        }}
        className={classes.root}
      />

      {props.showArrows === false ? null : (
        <IconButton
          className={classes.iconEnd}
          onClick={() => {
            !max
              ? handleInputChange(value + 1)
              : value === null || value === undefined || value === ''
              ? handleInputChange(min)
              : value < max
              ? handleInputChange(value + 1)
              : handleInputChange(max);
          }}
        >
          <PlayArrowIcon color={color} />
        </IconButton>
      )}
    </GridContainer>
  );
}

NumberInput.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
};
