import { TalkToExpertsIcon } from 'assets/iconsList';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import { useSummaryStyles } from 'components/pages/Assessment/Monitor/summaryStyles';
import { useTranslation } from 'react-i18next';

export default function TalkToExperts() {
  const { t } = useTranslation();
  const classes = useSummaryStyles();

  return (
    <Box
      display="flex"
      style={{
        paddingTop: '26px',
        paddingBottom: '26px',
      }}
    >
      <Box style={{ marginLeft: '-10px' }}>
        <TalkToExpertsIcon />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box marginBottom="8px" marginLeft="10px">
          <Typography className={classes.cardTextLeft}>
            {t('monitor.talkToExperts.title')}
          </Typography>

          <Typography className={classes.caption}>
            {t('monitor.talkToExperts.caption')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
