import React from 'react';

import { useProfileTilesStyles } from '../InfoContainer/profileTilesStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default function ProfileCard(props) {
  const classes = useProfileTilesStyles();

  return (
    <GridContainer onClick={props.onClicked && (() => props.onClicked())}>
      <div className={classes.tile} style={{ cursor: 'pointer' }}>
        <GridItem style={{ textAlign: 'left' }}>
          <Typography className={classes.tileTitle} variant="body1">
            {props.title}
          </Typography>
        </GridItem>
        <GridContainer direction="row">
          <GridItem
            xs={6}
            style={{
              textAlign: 'left',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '12px',
              }}
            >
              {props.infoText}
            </Typography>
          </GridItem>
          <GridItem
            xs={5}
            style={{
              textAlign: 'right',
            }}
          >
            <Typography
              variant="body1"
              className={classes.tileInfo}
              style={{ marginTop: '10px', marginLeft: '0' }}
            >
              {props.actionText}
            </Typography>
          </GridItem>
          <GridItem xs={1} style={{ textAlign: 'right' }}>
            {props.showClickIndicator && (
              <NavigateNextIcon style={{ marginTop: '10px' }} />
            )}
          </GridItem>
        </GridContainer>
      </div>
    </GridContainer>
  );
}

ProfileCard.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string,
  actionText: PropTypes.string,
  onClicked: PropTypes.func,
};
