import React from 'react';

import Box from 'components/atoms/Layout/Box/Box';
import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import Question from 'components/organisms/Inputs/Question/Question';

export default function SleepQuestion(props) {
  const handleChange = (index) => {
    props.onChange && props.onChange(index);
  };

  return (
    <Question
      title={props.title}
      subtitle={props.subtitle}
      subtitleStyle={'body1'}
    >
      <Box px={2.5} mt={2}>
        <IllustratedSlider
          id="illustrated-slider-sleep"
          illustrations={props.data}
          selectedIllustrationIndex={props.selectedIllustrationIndex}
          onValueChanged={handleChange}
          theme={props.theme}
          width={window.innerWidth > 800 ? '580px' : null}
        />
      </Box>
    </Question>
  );
}
