export const getRecipe = (mealPlan, mealName, dayOfTheWeek, isHorizontal) => {
  if (mealPlan && Object.keys(mealPlan).length >= dayOfTheWeek + 1) {
    const date = Object.keys(mealPlan)[dayOfTheWeek];
    if (mealPlan[date]) {
      const fullDate = mealPlan[date].date;
      const meals = mealPlan[date].meals.filter(
        (meal) => meal.meal === mealName
      );
      if (meals && meals.length !== 0) {
        return {
          name: meals[0].recipe.name,
          imageUrl: meals[0].recipe.mainImage,
          isHorizontal: isHorizontal,
          id: meals[0].recipe.id,
          recipe_db_id: meals[0].recipe.databaseId,
          isLogged: meals[0].recipe.isLogged,
          isSaved: meals[0].recipe.isSaved,
          isUserFavorite: meals[0].recipe.isUserFavorite,
          numberOfServings: meals[0].numOfServings,
          meal_datetime: fullDate,
        };
      }
    }
  }
};

export const getMeal = (mealPlan, mealName, dayOfTheWeek) => {
  if (mealPlan && Object.keys(mealPlan).length >= dayOfTheWeek + 1) {
    const date = Object.keys(mealPlan)[dayOfTheWeek];
    if (mealPlan[date]) {
      const meals = mealPlan[date].meals.filter(
        (meal) => meal.meal === mealName
      );
      if (meals && meals.length !== 0) {
        return meals[0];
      }
    }
  }
};
