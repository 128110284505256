import Typography from 'components/atoms/Typography/Typography';
import Box from 'components/atoms/Layout/Box';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export default function PdfReport(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      style={{
        height: '35px',
        padding: '40px 50px 38px 44px',
        backgroundImage: theme.palette.primary.ctaBackground,
        fontSize: '16px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={() => {
        props.onDownloaded && props.onDownloaded();
        window.open(props.url, '_blank');
      }}
    >
      <Typography
        variant="h5"
        style={{
          textTransform: 'uppercase',
          color: theme.palette.text.secondary,
        }}
      >
        {t('myProfile.downloadPdf')}
      </Typography>
    </Box>
  );
}

PropTypes.propTypes = {
  /**
   * PDF report URL.
   */
  url: PropTypes.string.isRequired,
};
