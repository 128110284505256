import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import RecipeSummary from 'components/molecules/RecipeSummary/RecipeSummary';

import { profileGeneralTheme } from 'themes';

export default function RecipeList(props) {
  return (
    <ThemeProvider theme={profileGeneralTheme}>
      <GridContainer
        style={{
          paddingTop: '20px',
        }}
      >
        {props.recipes &&
          props.recipes.map((item) => (
            <GridItem
              xs={4}
              style={{
                marginRight: 0,
                textAlign: 'left',
                marginBottom: 10,
              }}
            >
              <RecipeSummary
                isHorizontal={false}
                imageUrl={item.mainImage}
                name={item.name}
                onClick={() =>
                  props.onRecipeClick && props.onRecipeClick(item.id)
                }
                parentComponent={props.parentComponent}
                isUserFavorite={item.isUserFavorite}
                isSaved={item.isSaved}
                largeImage={window.innerWidth < 800 ? false : true}
              />
            </GridItem>
          ))}
      </GridContainer>
    </ThemeProvider>
  );
}
