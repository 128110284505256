import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { backgroundGeneral } from 'assets/imagesList';
import { useOvermindState } from 'overmind/index';

export default function GeneralIntro() {
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const [name] = useState(
    overmindState.metaData.user_metadata.firstName &&
      overmindState.metaData.user_metadata.firstName != null
      ? ' ' + overmindState.metaData.user_metadata.firstName
      : ''
  );

  return (
    <ContainerWithBackgroundImg
      image={backgroundGeneral}
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        maxWidth="265px"
        position="relative"
        pr={{ xs: 6 }}
        pl={{ xs: 6 }}
        left={{ sm: '27%' }}
        top={{ sm: '30%', xs: '40%' }}
      >
        <Typography variant="h1" fontWeight={'bold'} textSize={35}>
          {t('onboarding.generalSection.intro', {
            firstName: name,
          })}
        </Typography>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
