import {
  PescatarianIcon,
  VeganIcon,
  VegetarianIcon,
  Lactose,
  Gluten,
  Egg,
  Nut,
  Shellfish,
  Sugar,
  Sesame,
  Soy,
  Peanut,
  PorkFree,
  NoneIcon,
} from 'assets/iconsList';
import i18n from 'i18n';

const isAnyValueSelected = (nutritionValues) => {
  if (nutritionValues) {
    for (const [key, value] of Object.entries(nutritionValues)) {
      if (value === true) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

const filterObjectByValues = (object, filterValues) => {
  let filteredObject = {};

  if (object) {
    for (const [key, value] of Object.entries(object)) {
      if (filterValues.includes(key)) {
        filteredObject[key] = value;
      }
    }
  }

  return filteredObject;
};

export function getNutritionData(profileNutrition) {
  const filterValues = [
    'vegetarian',
    'vegan',
    'pescatarian',
    'pork_free',
    'no_preference_nutrition',
  ];

  const filteredProfileNutrition = filterObjectByValues(
    profileNutrition,
    filterValues
  );
  const isAnyNutritionValueSelected = isAnyValueSelected(
    filteredProfileNutrition
  );

  return [
    {
      id: '1',
      uid: 'vegetarian',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.vegetarian'),
      icon: VegetarianIcon,
      checked: filteredProfileNutrition?.vegetarian,
    },
    {
      id: '2',
      uid: 'vegan',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.vegan'),
      icon: VeganIcon,
      checked: filteredProfileNutrition?.vegan,
    },
    {
      id: '3',
      uid: 'pescatarian',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.pescatarian'),
      icon: PescatarianIcon,
      checked: filteredProfileNutrition?.pescatarian,
    },
    {
      id: '4',
      uid: 'pork_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.porkFree'),
      icon: PorkFree,
      checked: filteredProfileNutrition?.pork_free,
    },
    {
      id: '0',
      uid: 'no_preference_nutrition',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.noneAbove'),
      icon: NoneIcon,
      checked:
        isAnyNutritionValueSelected === false ||
        filteredProfileNutrition?.no_preference_nutrition,
    },
  ];
}

export function getNutritionObject(arr) {
  if (arr === undefined) {
    return;
  }

  const valuesArr = getNutritionData();

  return {
    // Dietary Preferences
    vegetarian: valuesArr.find(
      (i) => i.uid === 'vegetarian' && arr.includes(i.id)
    )
      ? true
      : false,
    vegan: valuesArr.find((i) => i.uid === 'vegan' && arr.includes(i.id))
      ? true
      : false,
    pescatarian: valuesArr.find(
      (i) => i.uid === 'pescatarian' && arr.includes(i.id)
    )
      ? true
      : false,
    fish_free: valuesArr.find(
      (i) => i.uid === 'fish_free' && arr.includes(i.id)
    )
      ? true
      : false,
    pork_free: valuesArr.find(
      (i) => i.uid === 'pork_free' && arr.includes(i.id)
    )
      ? true
      : false,
    no_preference: valuesArr.find(
      (i) => i.uid === 'no_preference' && arr.includes(i.id)
    )
      ? true
      : false,

    // Calories
    low_carb: valuesArr.find((i) => i.uid === 'low_carb' && arr.includes(i.id))
      ? true
      : false,
    low_fat: valuesArr.find((i) => i.uid === 'low_fat' && arr.includes(i.id))
      ? true
      : false,
    low_calorie: valuesArr.find(
      (i) => i.uid === 'low_calorie' && arr.includes(i.id)
    )
      ? true
      : false,
  };
}

export function getFoodAllergiesData(profileNutrition) {
  const filterValues = [
    'dairy_free',
    'gluten_free',
    'egg_free',
    'nut_free',
    'shellfish_free',
    'soy_free',
    'sugar_free',
    'peanut_free',
    'peanut_free',
    'sesame_free',
    'no_preference',
  ];

  const filteredProfileNutrition = filterObjectByValues(
    profileNutrition,
    filterValues
  );

  const isAnyFoodAllergiesValueSelected = isAnyValueSelected(
    filteredProfileNutrition
  );

  return [
    {
      id: '5',
      uid: 'dairy_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.lactose'),
      icon: Lactose,
      checked: filteredProfileNutrition?.dairy_free,
    },
    {
      id: '6',
      uid: 'gluten_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.gluten'),
      icon: Gluten,
      checked: filteredProfileNutrition?.gluten_free,
    },
    {
      id: '7',
      uid: 'egg_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.egg'),
      icon: Egg,
      checked: filteredProfileNutrition?.egg_free,
    },
    {
      id: '8',
      uid: 'nut_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.nut'),
      icon: Nut,
      checked: filteredProfileNutrition?.nut_free,
    },
    {
      id: '9',
      uid: 'shellfish_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.shellfish'),
      icon: Shellfish,
      checked: filteredProfileNutrition?.shellfish_free,
    },
    {
      id: '10',
      uid: 'soy_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.soy'),
      icon: Soy,
      checked: filteredProfileNutrition?.soy_free,
    },
    {
      id: '11',
      uid: 'sugar_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.sugar'),
      icon: Sugar,
      checked: filteredProfileNutrition?.sugar_free,
    },
    {
      id: '12',
      uid: 'peanut_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.peanut'),
      icon: Peanut,
      checked: filteredProfileNutrition?.peanut_free,
    },
    {
      id: '13',
      uid: 'sesame_free',
      label: i18n.t && i18n.t('onboarding.foodSection.step2.sesame'),
      icon: Sesame,
      checked: filteredProfileNutrition?.sesame_free,
    },
    {
      id: '0',
      uid: 'no_preference',
      label: i18n.t && i18n.t('onboarding.foodSection.step1.none'),
      icon: NoneIcon,
      checked:
        isAnyFoodAllergiesValueSelected === false ||
        filteredProfileNutrition?.no_preference,
    },
  ];
}

export function getFoodAllergiesObject(arr) {
  if (arr === undefined) {
    return;
  }

  const valuesArr = getFoodAllergiesData();

  return {
    dairy_free: valuesArr.find(
      (i) => i.uid === 'dairy_free' && arr.includes(i.id)
    )
      ? true
      : false,
    gluten_free: valuesArr.find(
      (i) => i.uid === 'gluten_free' && arr.includes(i.id)
    )
      ? true
      : false,
    egg_free: valuesArr.find((i) => i.uid === 'egg_free' && arr.includes(i.id))
      ? true
      : false,
    nut_free: valuesArr.find((i) => i.uid === 'nut_free' && arr.includes(i.id))
      ? true
      : false,

    shellfish_free: valuesArr.find(
      (i) => i.uid === 'shellfish_free' && arr.includes(i.id)
    )
      ? true
      : false,
    soy_free: valuesArr.find((i) => i.uid === 'soy_free' && arr.includes(i.id))
      ? true
      : false,
    sugar_free: valuesArr.find(
      (i) => i.uid === 'sugar_free' && arr.includes(i.id)
    )
      ? true
      : false,
    peanut_free: valuesArr.find(
      (i) => i.uid === 'peanut_free' && arr.includes(i.id)
    )
      ? true
      : false,
    sesame_free: valuesArr.find(
      (i) => i.uid === 'sesame_free' && arr.includes(i.id)
    )
      ? true
      : false,
    no_allergies: valuesArr.find(
      (i) => i.uid === 'no_preference' && arr.includes(i.id)
    )
      ? true
      : false,
  };
}
