import { makeStyles } from '@material-ui/core';

export const useHomeStyles = makeStyles((theme) => ({
  component: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  nutritionTipComponent: {
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: '#f7c76533',
  },
  scienceFactComponent: {
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: window.innerWidth < 800 ? '#f7c765' : 'none',
  },
  subtitle: {
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
  transparentBg: {
    backgroundColor: 'transparent',
  },
}));
