import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { useTextInputStyles } from './textInputStyles';

export default function BaseTextInput(props) {
  const classes = useTextInputStyles(props);

  return (
    <MuiTextField
      classes={{
        ...classes,
      }}
      color={props.color}
      {...props}
    />
  );
}

BaseTextInput.propTypes = {
  color: PropTypes.oneOf(['primary, secondary, error, warning, info, success']),
  textSize: PropTypes.number,
};
