import React, { useState, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { assessmentTheme } from 'themes';

import { useHistory, useLocation } from 'react-router-dom';
import { formatDate } from 'components/helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import {
  handleNavigation,
  getMenuOptions,
} from 'components/helpers/footerNavigationHelper';
import { ROUTE_PROFILE } from 'routes';
import { useOvermindState, useActions } from 'overmind/index';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

import ProfileView from './Profile/ProfileView';
import ProfileDetails from './Profile/ProfileDetails';
import RecipeBox from './Profile/RecipeBox/RecipeBox';
import GroceryList from './Profile/GroceryList/GroceryList';
import RecipeDetails from './RecipeDetails/RecipeDetails';

import FooterMenu from 'components/molecules/FooterMenu/FooterMenu';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import ReoccurringHeader from 'components/molecules/Header/ReoccurringHeader';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import PaymentDialogHome from 'components/organisms/PaymentDialog/PaymentDialogHome';

const PROFILE = 'profile';
const PROFILE_DETAILS = 'profile_details';
const RECIPE_BOX = 'recipe_box';
const GROCERY_LIST = 'grocery_list';
const RECIPE_DETAILS = 'recipe_details';

export default function Profile() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [name, setName] = useState(
    overmindState.metaData.user_metadata.firstName
      ? overmindState.metaData.user_metadata.firstName
      : null
  );
  const [dueDate, setDueDate] = useState(
    overmindState.profilePregnancy.due_date != null
      ? formatDate(overmindState.profilePregnancy.due_date, 'MMMM Do YYYY')
      : null
  );

  const [displayMode, setDisplayMode] = useState(PROFILE);
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (location.pathname === ROUTE_PROFILE) {
      if (location.search.endsWith('?nutrition')) {
        setDisplayMode(PROFILE_DETAILS);
      } else {
        setDisplayMode(PROFILE);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!name) {
      setName(overmindState.metaData.user_metadata.firstName);
    }
    if (overmindState.profilePregnancy.due_date != null) {
      setDueDate(
        formatDate(overmindState.profilePregnancy.due_date, 'MMMM Do YYYY')
      );
    }
  }, [
    overmindState.metaData.user_metadata.firstName,
    overmindState.profilePregnancy.due_date,
  ]);

  useEffect(() => {
    if (
      overmindState.areRecipeActionsLoaded === false &&
      overmindState.isMealPlanLoaded === true
    ) {
      actions.fetchRecipeActions();
    }
  }, [
    overmindState.areRecipeActionsLoaded,
    overmindState.isMealPlanLoaded,
    actions,
  ]);

  useEffect(() => {
    if (overmindState.genotype && overmindState.genotype.status != null) {
      actions.getReport();
    }
  }, [actions, overmindState.genotype]);

  const changeProfileView = (view) => {
    if (view === 'profile') {
      setDisplayMode(PROFILE);
    } else if (view === 'profile_details') {
      setDisplayMode(PROFILE_DETAILS);
    } else if (view === 'recipe_box') {
      setDisplayMode(RECIPE_BOX);
    } else if (view === 'grocery_list') {
      setDisplayMode(GROCERY_LIST);
    } else if (view === 'recipe_details') {
      setDisplayMode(RECIPE_DETAILS);
    }
  };

  const downloadClicked = () => {
    trackEvent('PDF', 'Downloaded', { location: 'UserProfile' });
    window.open(overmindState.report.download_url, '_blank');
  };

  const getTitle = () => {
    let title = t('myProfile.title');

    if (displayMode === RECIPE_BOX) {
      title = 'Recipe Box';
    } else if (displayMode === GROCERY_LIST) {
      title = 'Grocery List';
    } else if (displayMode === RECIPE_DETAILS) {
      title = 'Recipe';
    }
    return title;
  };

  return (
    <ThemeProvider theme={assessmentTheme}>
      <GridContainer justify="center" alignItems="center">
        <GridItem>
          <ReoccurringHeader
            title={getTitle()}
            showBack={displayMode !== PROFILE}
            goBack={() =>
              changeProfileView(
                displayMode === RECIPE_DETAILS ? RECIPE_BOX : PROFILE
              )
            }
          />

          <GridContainer
            direction="column"
            style={{
              marginTop:
                displayMode === RECIPE_DETAILS
                  ? 90
                  : displayMode === GROCERY_LIST
                  ? 70
                  : 50,
            }}
          >
            <GridItem
              style={{
                marginBottom: '120px',
                textAlign: 'center',
                width: '100%',
                maxWidth: '1200px',
              }}
            >
              {displayMode === PROFILE && (
                <ProfileView
                  changeProfileView={(view) => changeProfileView(view)}
                  downloadClicked={downloadClicked}
                  name={name}
                  dueDate={dueDate}
                />
              )}

              {displayMode === PROFILE_DETAILS && (
                <ProfileDetails
                  changeProfileView={(view) => changeProfileView(view)}
                  name={name}
                  dueDate={dueDate}
                />
              )}

              {displayMode === RECIPE_BOX && (
                <RecipeBox
                  changeProfileView={(view) => changeProfileView(view)}
                />
              )}

              {displayMode === GROCERY_LIST && (
                <GroceryList
                  changeProfileView={(view) => changeProfileView(view)}
                />
              )}

              {displayMode === RECIPE_DETAILS && (
                <RecipeDetails
                  recipe={overmindState.recipeDetails}
                  onBackButtonClick={() => {
                    setDisplayMode(RECIPE_BOX);
                  }}
                  hideLeftRightButtons={true}
                  parentComponent={'profile'}
                  hideSelect={true}
                />
              )}
            </GridItem>
            <GridItem>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  style={{ fontSize: '10px', fontWeight: 'normal' }}
                >
                  {' '}
                  9moons app v.{process.env.REACT_APP_VERSION} &copy; LifeNome
                  Inc.
                </Typography>
              </Box>
            </GridItem>
            <PaymentDialogHome
              openDialog={paymentDialogOpened}
              onCloseDialog={() => setPaymentDialogOpened(false)}
            />

            <GridItem>
              <FooterMenu
                selectedValue={4}
                onSelected={(val) => {
                  history.push(handleNavigation(val));
                }}
                menuOptions={getMenuOptions(assessmentTheme, 4)}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </ThemeProvider>
  );
}
