import React from 'react';

import Typography from 'components/atoms/Typography/Typography';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

import { useExpandedSummaryStyles } from './accordionContentStyle';

export default function ExpandedSummary(props) {
  const classes = useExpandedSummaryStyles(props);

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <GridItem xs={12}>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12}>
            <Typography variant="h5" className={classes.title}>
              {props.title}
            </Typography>
          </GridItem>
          {props.subTitle && (
            <GridItem xs={12}>
              <Typography variant="body1" className={classes.subTitle}>
                {props.subTitle}
              </Typography>
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
