import { makeStyles } from '@material-ui/core';

export const useSliderStyles = makeStyles((theme) => ({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: (props) => ({
    height: 27,
    width: 27,
    backgroundColor: props.theme ? "#FFFFFF" : theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: theme.palette.background.default,
      marginLeft: 1,
      marginRight: 1,
    },
  }),
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: (props) => ({
    height: 2,
    backgroundColor: props.theme ? "#FFFFFF" : theme.palette.primary.main,
  }),
  rail: (props) => ({
    height: 2,
    opacity: 1,
    backgroundColor: props.theme ? "#FFFFFF" : theme.palette.primary.main,
  }),
  mark: (props) => ({
    backgroundColor: props.theme ? "#FFFFFF" : theme.palette.primary.main,
    height: 9,
    width: 3,
    marginTop: -3,
  }),
  markActive: (props) => ({
    opacity: 1,
    backgroundColor: props.theme ? "#FFFFFF" : theme.palette.primary.main,
  }),
}));
