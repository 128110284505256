import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Macronutrient from './Macronutrient';

export default function Macronutrients(props) {
  const [expanded, setExpanded] = useState('-');
  const { t } = useTranslation();

  const formatMacronutrients = (data) => {
    let formattedData = [];

    if (!data) {
      return formattedData;
    }

    formattedData.push({
      title: 'Calories',
      panelName: 'caloriesPanel',
      units: 'kcal',
      total: data.total_calories,
      description: data.description,
    });

    formattedData.push({
      title: data.components.carbs.name,
      panelName: 'totalCarbsPanel',
      units: 'kcal',
      total: data.components.carbs.calories,
      percentage: data.components.carbs.percentage_of_total_calories,
      description: data.components.carbs.description,
      links: data.components.carbs.links,
    });

    formattedData.push({
      title: data.components.fat.name,
      panelName: 'totalFatPanel',
      units: 'kcal',
      total: data.components.fat.calories,
      percentage: data.components.fat.percentage_of_total_calories,
      description: data.components.fat.description,
      links: data.components.fat.links,
    });

    formattedData.push({
      title: data.components.proteins.name,
      panelName: 'totalProteinPanel',
      units: 'kcal',
      total: data.components.proteins.calories,
      percentage: data.components.proteins.percentage_of_total_calories,
      description: data.components.proteins.description,
      links: data.components.proteins.links,
    });

    return formattedData;
  };

  let data = props.macronutrients && formatMacronutrients(props.macronutrients);

  return data ? (
    data.map((item, ix) => (
      <Macronutrient
        panelName={item.panelName}
        macronutrientTitle={item.title}
        title={t('assessment.macronutrients.title')}
        total={item.total}
        units={item.units}
        percentage={item.percentage}
        description={item.description}
        links={item.links}
        key={ix}
        onNewExpanded={(newExpanded) => setExpanded(newExpanded)}
        expanded={expanded}
      />
    ))
  ) : (
    <div />
  );
}
