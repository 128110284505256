import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import CheckboxWithLabel from 'components/molecules/Inputs/CheckboxInput/CheckboxWithLabel';

export default function CheckboxGroup(props) {
  const { color, rowNumber } = props;
  const [deselectAllValue] = useState(
    props.deselectAllValue ? props.deselectAllValue : ''
  );
  const [items, setItems] = useState(props.items);

  const onCheckboxChange = (val, checked) => {
    if (deselectAllValue) {
      let deselectAll = val === deselectAllValue.toString() && checked === true;

      items.forEach((item) => {
        let itemValue = item.value.toString();

        if (deselectAll) {
          if (itemValue === deselectAllValue.toString()) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        } else {
          if (itemValue === val) {
            item.checked = checked;
          }

          if (itemValue === deselectAllValue.toString()) {
            item.checked = false;
          }
        }
      });
    } else {
      items.forEach((item) => {
        let itemValue = item.value.toString();

        if (itemValue === val) {
          item.checked = checked;
        }
      });
    }

    setItems([...items]);
    props.onChange && props.onChange([...items]);
  };

  return (
    <GridContainer direction={props.direction ? props.direction : 'row'}>
      {items &&
        items.map((item) => (
          <GridItem xs={12 / rowNumber}>
            <CheckboxWithLabel
              color={color}
              label={item.label}
              value={item.value}
              checked={item.checked}
              onChange={(val, checked) =>
                onCheckboxChange(val.toString(), checked)
              }
            />
          </GridItem>
        ))}
    </GridContainer>
  );
}

CheckboxGroup.propTypes = {
  color: PropTypes.string,
  rowNumber: PropTypes.number,
  deselectAllValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
