import React, { useEffect, useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import Popover from 'components/atoms/Popover/Popover';

import { useRecipeSummaryStyles } from './recipeSummaryStyles';
import { getRecipeProps } from 'components/helpers/propTypesHelpers';
import { useOvermindState } from 'overmind/index';
import CheckIcon from '@material-ui/icons/Check';

import {
  BookmarkFavorite,
  BookmarkSaveForLater,
  BookmarkFavoriteEmpty,
  BookmarkSaveForLaterEmpty,
} from 'assets/iconsList';
import { formatDate } from 'components/helpers/dateHelper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

export default function RecipeSummary(props) {
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isUserFavorite, setIsUserFavorite] = useState(props.isUserFavorite);
  const [isSaved, setIsSaved] = useState(props.isSaved);
  const classes = useRecipeSummaryStyles();
  const overmindState = useOvermindState();
  const recipeActionFavorite = 'favorite';
  const recipeActionSave = 'save';
  const recipeActionLog = 'log';
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setIsUserFavorite(props.isUserFavorite);
    setIsSaved(props.isSaved);
  }, [props.isUserFavorite, props.isSaved]);

  const handleBookmarkClick = (e) => {
    if (!openPopover) {
      trackEvent('Recipe Bookmark Opened', '');
    }
    setOpenPopover(!openPopover);
    setAnchorEl(e.currentTarget);
  };

  const renderPopover = () => {
    if (!props.bookmarksActionsLoaded) {
      return;
    }

    let data = [
      /*{
        icon: <BookmarkAdded />,
        text: props.isLogged ? 'Remove from Select' : 'Select',
        code: recipeActionLog,
      },*/
      {
        icon: <BookmarkFavorite />,
        text: isUserFavorite ? 'Remove from Like' : 'Like',
        code: recipeActionFavorite,
      },
      {
        icon: <BookmarkSaveForLater />,
        text: isSaved ? 'Remove from Save for later' : 'Save for later',
        code: recipeActionSave,
      },
    ];

    if (props.parentComponent === 'recipe_list') {
      data.shift();
    } else if (props.parentComponent === 'recipe_list') {
      data = [];
    }

    return (
      <Popover
        id="recipe-summary-bookmark-popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Box className={classes.popoverContentContainer}>
          {data.map((item, ix) => (
            <Box
              display="flex"
              flexDirection="row"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handlePopoverOptionClick(item.code);
              }}
              key={ix}
            >
              <Box className={classes.popoverContent}>{item.icon}</Box>
              <Box className={classes.popoverContent}>
                <Typography>{item.text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    );
  };

  const handlePopoverOptionClick = (code) => {
    handlePopoverClose();
    let data = null;
    if (code === recipeActionFavorite) {
      if (!isUserFavorite) {
        trackEvent('Recipe Liked', '');
      }
      setIsUserFavorite(!isUserFavorite);
      data = { recipe_id: props.id, recipe_db_id: props.recipe_db_id };
    } else if (code === recipeActionSave) {
      if (!isSaved) {
        trackEvent('Recipe Saved', '');
      }
      setIsSaved(!isSaved);
      data = props.id;
    } else if (code === recipeActionLog) {
      data = {
        recipe_id: props.id,
        meal_datetime: formatDate(props.meal_datetime, 'YYYY-MM-DD hh:mm'),
        num_of_servings: props.numberOfServings,
        meal_type: props.meal && props.meal,
        meal_id: props.id,
      };
    }
    props.onBookmarkChange && props.onBookmarkChange(data, code);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const handleRecipeClick = () => {
    props.onClick && props.onClick();
  };

  const getNormalizedName = () => {
    const name = props.name;
    /*!props.isHorizontal && props.name.length > 30
        ? props.name.substring(0, 25) + '...'
        : props.name;*/
    return name.normalize();
  };

  return (
    <div className={props.isHorizontal ? classes.root : classes.rootVertical}>
      {overmindState.areRecipeActionsLoaded &&
      (props.parentComponent === 'meal' || props.parentComponent === 'home') &&
      (props.hideBookmarks == null || props.hideBookmarks === false) ? (
        <Box
          className={classes.bookmark}
          display="flex"
          justifyContent="flex-end"
        >
          {/*<Box style={{ cursor: 'pointer', zIndex: 2 }} px={0.5}>
            {props.isLogged ? (
              <BookmarkAdded height={20} onClick={handleBookmarkClick} />
            ) : (
              <BookmarkAddedEmpty height={20} onClick={handleBookmarkClick} />
            )}
            </Box>*/}

          <Box style={{ cursor: 'pointer', zIndex: 2 }} px={0.5}>
            {isUserFavorite ? (
              <BookmarkFavorite height={20} onClick={handleBookmarkClick} />
            ) : (
              <BookmarkFavoriteEmpty
                height={20}
                onClick={handleBookmarkClick}
              />
            )}
          </Box>

          <Box style={{ cursor: 'pointer', zIndex: 2 }} px={0.5}>
            {isSaved ? (
              <BookmarkSaveForLater height={20} onClick={handleBookmarkClick} />
            ) : (
              <BookmarkSaveForLaterEmpty
                height={20}
                onClick={handleBookmarkClick}
              />
            )}
          </Box>
        </Box>
      ) : null}

      {renderPopover()}

      <GridContainer
        direction={props.isHorizontal ? 'row' : 'column'}
        justify="flex-start"
        alignItems={props.isHorizontal ? 'center' : 'flex-start'}
        onClick={handleRecipeClick}
      >
        <GridItem
          className={classes.imageContainer}
          style={{
            height: props.isPremium
              ? '120px'
              : props.isHorizontal
              ? '130px'
              : 'auto',
          }}
        >
          <img
            src={props.imageUrl}
            alt="recipe_image"
            className={
              props.isPremium
                ? classes.imagePremium
                : props.isHorizontal
                ? classes.image
                : props.largeImage
                ? window.innerWidth < 800
                  ? classes.imageVerticalLarge
                  : classes.imageVerticalLarger
                : classes.imageVertical
            }
          />
        </GridItem>
        <GridItem>
          <GridContainer
            direction={'column'}
            justify="space-between"
            alignItems={'flex-start'}
          >
            <GridItem>
              <Box
                display="flex"
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                className={
                  props.isHorizontal ? classes.text : classes.textVertical
                }
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  style={{
                    fontSize: '14px',
                    padding: '5px',
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                >
                  {getNormalizedName()}
                </Typography>
              </Box>
            </GridItem>
            {props.isHorizontal ? (
              <GridItem
                style={{
                  padding: '0 15px',
                  width: '100%',
                }}
              >
                <GridContainer
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  {props.numberOfServings ? (
                    <GridItem className={classes.servings}>
                      <Typography variant="caption">
                        {props.numberOfServings}{' '}
                        {props.numberOfServings > 1
                          ? t('home.recipes.servings')
                          : t('home.recipes.serving')}
                      </Typography>
                    </GridItem>
                  ) : null}
                  <GridItem className={classes.checkIcon}>
                    <CheckIcon
                      style={{
                        color: theme.palette.icon.primary,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : null}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

RecipeSummary.propTypes = getRecipeProps();
