import * as React from 'react';

function White9moonsLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="179.381" height="220.246" viewBox="0 0 179.381 220.246">
      <g transform="translate(0 27.328)">
        <path class="a" d="M94.16,104.991a42.134,42.134,0,0,1,25.288-38.645c2.287-1,1.223-3.224-.255-3.964a47.624,47.624,0,1,0,.191,85.12c1.411-.714,2.557-2.733-.478-4.109A42.144,42.144,0,0,1,94.16,104.991Z" transform="translate(-50.299 -57.366)" 
            fill="#FFFFFF"
            stroke="#FFFFFF"/>
      </g>
      <ellipse class="a" cx="21.496" cy="21.496" rx="21.496" ry="21.496" transform="translate(72.316 56.054)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <path class="a" d="M196.283,79.953c-.723-1.744,1.19-3.631,2.782-2.618,38.821,24.663,34.677,67.5,28.233,82.687-11.859,27.953-48.283,44.894-76.453,38.831-14.876-3.2-30.023-10.745-38.091-23.938a2.036,2.036,0,0,1,2.3-2.989c19.849,5.337,41.245,4.347,59.013-6.954,17.824-11.337,28.373-31.207,28.867-52.212C203.2,101.467,200.587,90.344,196.283,79.953Z" transform="translate(-59.094 -32.824)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <g transform="translate(18.001 129.068)">
        <path class="a" d="M85.557,184.672l7.2,1.374a1,1,0,0,1,0,1.954l-7.2,1.374a.994.994,0,0,0-.791.791l-1.374,7.2a1,1,0,0,1-1.955,0l-1.374-7.2a.994.994,0,0,0-.791-.791L72.075,188a.994.994,0,0,1,0-1.954l7.2-1.374a1,1,0,0,0,.791-.791l1.374-7.2a1,1,0,0,1,1.955,0l1.374,7.2A1,1,0,0,0,85.557,184.672Z" transform="translate(-71.266 -175.876)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      </g>
      <path class="a" d="M155.675,36.412l8.9,1.7a1.23,1.23,0,0,1,0,2.417l-8.9,1.7a1.23,1.23,0,0,0-.977.978l-1.7,8.9a1.231,1.231,0,0,1-2.418,0l-1.7-8.9a1.229,1.229,0,0,0-.978-.978l-8.9-1.7a1.23,1.23,0,0,1,0-2.417l8.9-1.7a1.229,1.229,0,0,0,.978-.978l1.7-8.9a1.231,1.231,0,0,1,2.418,0l1.7,8.9A1.23,1.23,0,0,0,155.675,36.412Z" transform="translate(-62.709 -25.534)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <path class="a" d="M180.583,47.628l5.832,1.114a.806.806,0,0,1,0,1.583l-5.832,1.114a.806.806,0,0,0-.64.64l-1.114,5.832a.806.806,0,0,1-1.583,0l-1.114-5.832a.806.806,0,0,0-.64-.64l-5.832-1.114a.806.806,0,0,1,0-1.583l5.832-1.114a.806.806,0,0,0,.64-.64l1.114-5.832a.806.806,0,0,1,1.583,0l1.114,5.832A.806.806,0,0,0,180.583,47.628Z" transform="translate(-67.095 -27.652)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <g transform="translate(90.486 25.082)">
        <path class="a" d="M166.64,61.485l5.511,1.053a.762.762,0,0,1,0,1.5l-5.511,1.053a.763.763,0,0,0-.605.605L164.982,71.2a.762.762,0,0,1-1.5,0l-1.053-5.511a.761.761,0,0,0-.605-.605l-5.511-1.053a.762.762,0,0,1,0-1.5l5.511-1.053a.761.761,0,0,0,.605-.605l1.053-5.511a.762.762,0,0,1,1.5,0l1.053,5.511A.763.763,0,0,0,166.64,61.485Z" transform="translate(-155.698 -54.75)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
        </g>
        <g transform="translate(1.592 188.916)">
        <path class="a" d="M91.658,250.145c0,7.371-2.845,11.311-8.026,16.7-1.425,1.481-4.838,1.269-6.134.92-1.7-.459-2.634-2.089-1.326-3.222,2.405-2.085,3.994-2.982,5.608-5.276a11.2,11.2,0,0,1-1.986.216,11.09,11.09,0,0,1-7.758-2.831,9.351,9.351,0,0,1-3.111-7.239,10.265,10.265,0,0,1,3.157-7.825,11.4,11.4,0,0,1,8.164-2.982,10.865,10.865,0,0,1,8.255,3.329A11.484,11.484,0,0,1,91.658,250.145Zm-8.48-1a2.961,2.961,0,0,0-.9-2.16,2.991,2.991,0,0,0-2.209-.909,2.932,2.932,0,0,0-2.211.951,3.157,3.157,0,0,0,2.3,5.4,2.728,2.728,0,0,0,2.21-.973A3.493,3.493,0,0,0,83.178,249.15Z" transform="translate(-68.925 -237.008)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <path class="a" d="M124.645,267.556H116.48v-9.833l-3.653,9.833h-5.773l-3.609-9.788v9.788H95.28V239h6.631l7.984,16.7,7.487-16.7h7.263Z" transform="translate(-68.037 -236.995)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <path class="a" d="M214.523,267.556h-6.9l-8.66-12.611v12.611h-8.12V239h6.9l8.66,12.61V239h8.12Z" transform="translate(-64.817 -236.995)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <path class="a" d="M241.719,258.739a8.912,8.912,0,0,1-3.892,6.873,13.891,13.891,0,0,1-8.192,2.405c-6.369,0-10.437-2.514-12.508-7.438a.975.975,0,0,1,.686-1.5l5.07-1.095s.9-.581,1.749.85c.881,1.493,2.575,2.219,5,2.219a5.154,5.154,0,0,0,2.572-.661,2.221,2.221,0,0,0,1.352-1.979q0-1.36-4.1-2.308a38.969,38.969,0,0,1-7.713-2.349,7.017,7.017,0,0,1-4.15-6.554,7.315,7.315,0,0,1,3.519-6.389,13.412,13.412,0,0,1,7.714-2.142c5.179,0,8.932,1.957,11.443,5.771.606.92-.492,1.484-.492,1.484l-4.721,1.971s-.768.6-1.546-.416a5.55,5.55,0,0,0-4.684-2.094,4.27,4.27,0,0,0-1.941.454q-1.126.5-1.127,1.36,0,1.155,4.1,2.144a34.011,34.011,0,0,1,7.714,2.554Q241.881,254.257,241.719,258.739Z" transform="translate(-63.936 -237.006)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <g transform="translate(59.667)">
        <path class="a" d="M141.784,268.389a15.674,15.674,0,1,1,15.136-15.666C156.921,261.362,150.13,268.389,141.784,268.389Zm0-23.056a7.411,7.411,0,1,0,6.863,7.39A7.15,7.15,0,0,0,141.784,245.334Z" transform="translate(-126.647 -237.06)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      </g>
      <g transform="translate(91.929)">
        <path class="a" d="M172.993,268.389c-8.346,0-15.136-7.027-15.136-15.666a15.146,15.146,0,1,1,15.136,15.666Zm0-23.056a7.411,7.411,0,1,0,6.864,7.39A7.15,7.15,0,0,0,172.993,245.334Z" transform="translate(-157.857 -237.06)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      </g>
      </g>
      <circle class="a" cx="5.788" cy="5.788" r="5.788" transform="translate(95.318 69.38)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <circle class="a" cx="2.057" cy="2.057" r="2.057" transform="translate(92.905 63.709)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
      <circle class="a" cx="2.057" cy="2.057" r="2.057" transform="translate(84.32 87.401)" fill="#FFFFFF"
            stroke="#FFFFFF"/>
    </svg>
  );
}

export default White9moonsLogo;
