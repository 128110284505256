import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import IllustratedSlider from 'components/molecules/Inputs/IllustratedSlider/IllustratedSlider';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function MenstrualCycleLength(props) {
  const classes = usePageStyles(props);
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();

  const [cycleLength, setCycleLength] = useState(
    overmindState.cycleLength ? overmindState.cycleLength : null
  );

  useEffect(() => {
    if (cycleLength !== null) actions.enableForwardButton();
  }, []);

  const getCycleLengthOptions = () => {
    let options = [];
    for (let i = 21; i < 41; i++) {
      options.push({
        text: i + ' days',
      });
    }
    return options;
  };

  const getCycleLengthValues = () => {
    let values = [];
    for (let i = 21; i < 41; i++) {
      values.push(i);
    }
    return values;
  };

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem style={{ marginLeft: '20px', marginRight: '20px' }}>
        <QuestionRefactored
          title={t('calculator.cycleLength.question')}
          subtitleStyle={'body1'}
        >
          <IllustratedSlider
            id="illustrated-slider-cycle"
            selectedIllustrationIndex={cycleLength ? cycleLength : 7}
            illustrations={getCycleLengthOptions()}
            onValueChanged={(ix) => {
              setCycleLength(ix);
              actions.updateStateProp({
                key: 'cycleLength',
                value: ix,
                nextEnabled: true,
              });
            }}
            theme={props.theme}
            step={1}
            valueFactor={21}
            values={getCycleLengthValues()}
            hideIllustration={true}
            min={getCycleLengthValues()[0]}
            max={getCycleLengthValues()[getCycleLengthValues().length - 1]}
            marks={[
              {
                value: 21,
                label: '21',
              },
              {
                value: 40,
                label: '40',
              },
            ]}
          />
        </QuestionRefactored>
      </GridItem>
    </GridContainer>
  );
}
