import React, { useEffect, useState } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Button from 'components/atoms/Inputs/Buttons/Button';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';
import { useHomeStyles } from './HomeStyles';

import { useActions, useOvermindState } from 'overmind/index';
import {
  backgroundTrimester1,
  backgroundTrimester2,
  backgroundTrimester3,
} from 'assets/imagesList';
import Typography from 'components/atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import ConfirmDialog from 'components/organisms/ModalWindow/ConfirmDialog';
import useInterval from 'components/helpers/useInterval';

export default function PregnancyTips(props) {
  const overmindState = useOvermindState();
  const actions = useActions();
  const classes = useHomeStyles();
  const initialReport = overmindState.initialReport;
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);
  const { t } = useTranslation();

  const { REACT_APP_STRIPE_PREMIUM_REDIRECT_URL } = process.env;

  useEffect(() => {
    if (overmindState.isNutrientsReportLoaded === false) {
      actions.fetchNutrientsReport();
    }
  }, [overmindState.isNutrientsReportLoaded, actions]);

  useEffect(() => {
    if (overmindState.genotype && overmindState.genotype.status != null) {
      actions.getReport();
    }
  }, [actions, overmindState.genotype]);

  useInterval(() => {
    if (
      overmindState.genotype &&
      overmindState.genotype.status != null &&
      (overmindState.report == null ||
        overmindState.report.download_url == null)
    ) {
      actions.getReport();
    }
  }, 3000);

  const onDownloadGeneticReport = () => {
    trackEvent('PDF', 'Shown Interest', {
      location: 'Home',
    });
    if (overmindState.subscription.status) {
      if (overmindState.report && overmindState.report.download_url) {
        window.open(overmindState.report.download_url, '_blank');
        actions.savePdfReportDownloaded(true);
        trackEvent('PDF', 'Downloaded', {
          location: 'Home',
        });
      }
    } else {
      setPaymentDialogOpened(true);
    }
  };

  const renderPdfDownloadButton = () => {
    return (
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          onDownloadGeneticReport();
        }}
        style={{
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        {t('pregnancyTips.downloadReport')}
      </Button>
    );
  };

  const getPaymentLink = () => {
    return REACT_APP_STRIPE_PREMIUM_REDIRECT_URL;
  };

  return (
    <ContainerWithBackgroundImg
      image={
        overmindState.profilePregnancy &&
        overmindState.profilePregnancy.pregnancy_trimester === 1
          ? backgroundTrimester1
          : overmindState.profilePregnancy.pregnancy_trimester === 2
          ? backgroundTrimester2
          : backgroundTrimester3
      }
      maxHeight={'700px'}
      height={'100%'}
      width={window.innerWidth < 800 ? window.innerWidth : 'auto'}
      marginTop={'50px'}
      component={'home'}
    >
      <GridContainer
        direction="column"
        className={classes.component}
        style={{
          borderBottom: '1px solid #e5e5e5',
          color: 'white',
        }}
      >
        <GridItem xs={12}>
          <GridContainer
            direction="column"
            style={{
              marginBottom: '30px',
              paddingLeft: window.innerWidth < 800 ? '20px' : '120px',
              paddingRight: window.innerWidth < 800 ? '20px' : '120px',
            }}
          >
            <GridItem
              xs={12}
              style={{
                margin: '0 auto 20px auto',
              }}
            >
              {initialReport && initialReport.pregnancy_weekly_status && (
                <img
                  src={initialReport.pregnancy_weekly_status.image_link}
                  height={'250px'}
                  width={'250px'}
                  alt={`${props.text}-icon`}
                />
              )}
            </GridItem>
            <GridItem
              xs={12}
              style={{
                marginBottom: '20px',
                marginTop:
                  initialReport &&
                  (initialReport.pregnancy_weekly_status == null ||
                    initialReport.pregnancy_weekly_status.image_link == null)
                    ? '50px'
                    : 0,
              }}
            >
              {initialReport && initialReport.pregnancy_weekly_status && (
                <Typography variant="body1">
                  {initialReport.pregnancy_weekly_status.short_status}
                </Typography>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem
          xs={12}
          style={{
            marginBottom: '30px',
          }}
        >
          <div
            style={{
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            {!overmindState.subscription.status ||
            (overmindState.metaData &&
              overmindState.metaData.user_metadata.pdf_report_downloaded !==
                true &&
              overmindState.report &&
              overmindState.report.download_url)
              ? renderPdfDownloadButton()
              : null}
          </div>
        </GridItem>
        <ConfirmDialog
          openDialog={paymentDialogOpened}
          title={t('pregnancyTips.purchaseDnaKitDialog.text')}
          confirmText={t('common.answerYes')}
          cancelText={t('common.answerNo')}
          onCloseDialog={() => setPaymentDialogOpened(false)}
          onConfirmClick={() => {
            window.open(getPaymentLink(), '_blank');
            setPaymentDialogOpened(false);
          }}
        />
      </GridContainer>
    </ContainerWithBackgroundImg>
  );
}
