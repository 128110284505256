import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import SvgIcon from 'components/atoms/SvgIcon/SvgIcon';
import { useProgressIndicatorStyles } from './progressIndicatorStyles';

import { NavSelectedLine } from 'assets/imagesList';

export default function ProgressIndicatorItem(props) {
  const classes = useProgressIndicatorStyles(props);
  const size = 40;
  const labelStyle = {
    marginTop: '10px',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontFamily: 'Museo-500',
  };

  return (
    <GridContainer
      classes={{ root: classes.progressItemContainerRoot }}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <GridContainer direction="row" justify="center" alignItems="center">
        {!props.hideLeftDots && (
          <SvgIcon height={size} width={{ xs: size }} icon={NavSelectedLine} />
        )}
        <SvgIcon
          style={{
            cursor: 'pointer',
            marginRight: props.hideRightDots ? size : 0,
            marginLeft: props.hideLeftDots ? size : 0,
          }}
          height={size}
          width={{ xs: size }}
          icon={props.selected ? props.selectedIcon : props.notSelectedIcon}
          onClick={props.onClick}
        />
        {!props.hideRightDots && (
          <SvgIcon height={size} width={{ xs: size }} icon={NavSelectedLine} />
        )}
      </GridContainer>
      <span style={labelStyle}>{props.label}</span>
    </GridContainer>
  );
}

ProgressIndicatorItem.propTypes = {
  id: PropTypes.number.isRequired,
  hideRightDots: PropTypes.bool,
  hideLeftDots: PropTypes.bool,
  selected: PropTypes.bool,
  selectedIcon: PropTypes.object,
  notSelectedIcon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
};
