import React from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import FirstBabyBornWeek from 'components/organisms/Questions/Pregnancy/FirstBabyBornWeek';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function FirstChildWeek(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();
  const questionValue =
    overmindState.firstBabyWeek == null
      ? undefined
      : overmindState.firstBabyWeek;

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <FirstBabyBornWeek
          title={t('calculator.firstBabyBornWeek.question')}
          data={[
            {
              label: t('calculator.firstBabyBornWeek.answer1'),
              value: '1',
              checked: questionValue === '1',
            },
            {
              label: t('calculator.firstBabyBornWeek.answer2'),
              value: '2',
              checked: questionValue === '2',
            },
            {
              label: t('calculator.firstBabyBornWeek.answer3'),
              value: '3',
              checked: questionValue === '3',
            },
            {
              label: t('calculator.firstBabyBornWeek.answer4'),
              value: '4',
              checked: questionValue === '4',
            },
            {
              label: t('calculator.firstBabyBornWeek.answer5'),
              value: '5',
              checked: questionValue === '5',
            },
            {
              label: t('calculator.firstBabyBornWeek.answer6'),
              value: '6',
              checked: questionValue === '6',
            },
          ]}
          onChange={(value, checked) => {
            actions.updateStateProp({
              key: 'firstBabyWeek',
              value: value,
              nextEnabled: true,
            });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
