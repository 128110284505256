import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useContainerWithBackgroundStyles } from './containerWithBackgroundStyles';

export default function ContainerWithBackgroundImg(props) {
  const { children } = props;
  const containerWithBackgroundStyles = useContainerWithBackgroundStyles(props);

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <div
          className={
            props.component === 'home'
              ? containerWithBackgroundStyles.container
              : containerWithBackgroundStyles.centeredontainer
          }
        >
          {children}
        </div>
      </Grid>
    </Grid>
  );
}

ContainerWithBackgroundImg.propTypes = {
  children: PropTypes.node,
};
