import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useActions, useOvermindState } from 'overmind/index';
import { formatDate } from 'components/helpers/dateHelper';
import Box from 'components/atoms/Layout/Box';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';
import { usePageStyles } from 'components/pages/commonStyles';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';

export default function ClosestConceptionExperience(props) {
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();
  const classes = usePageStyles(props);

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <QuestionRefactored title={t('calculator.dateOfTranfer.question')}>
          <Box display="flex" justifyContent="center" pt={5}>
            <DatePicker
              disableFuture={true}
              format="YYYY/MM/DD"
              id="last-period-date-input"
              value={
                overmindState.dateOfTransfer
                  ? overmindState.dateOfTransfer
                  : null
              }
              onChange={(e) => {
                actions.updateStateProp({
                  key: 'dateOfTransfer',
                  value: e,
                  nextEnabled: true,
                });
              }}
              inputProps={{ max: formatDate(new Date(), 'YYYY-MM-DD') }}
            />
          </Box>
        </QuestionRefactored>
      </GridItem>
    </GridContainer>
  );
}
