import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import OtherChildrenQuestion from 'components/organisms/Questions/Pregnancy/OtherChildrenQuestion';
import ComplicationsQuestion from 'components/organisms/Questions/Pregnancy/ComplicationsQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';

export default function ChildrenQuestionPage(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [question1Value, setQuestion1Value] = useState(
    overmindState.profilePregnancy.other_children == null
      ? undefined
      : overmindState.profilePregnancy.other_children
      ? '1'
      : '2'
  );
  const [question2Value, setQuestion2Value] = useState(
    overmindState.profilePregnancy.past_complications == null
      ? undefined
      : overmindState.profilePregnancy.past_complications
      ? '1'
      : '2'
  );

  return (
    <GridContainer className={classes.container}>
      <OtherChildrenQuestion
        title={t('onboarding.pregnancySection.step4.question1.title')}
        data={[
          {
            label: t('common.answerYes'),
            value: '1',
            checked: question1Value === '1',
          },
          {
            label: t('common.answerNo'),
            value: '2',
            checked: question1Value === '2',
          },
        ]}
        onChange={(value, checked) => {
          setQuestion1Value(value + '');
          actions.childrenQuestionAnswered(value);
          if (value === '2') {
            actions.complicationsQuestionAnswered(null);
          }
        }}
      />

      {question1Value === '1' && (
        <ComplicationsQuestion
          title={t('onboarding.pregnancySection.step4.question2.title')}
          data={[
            {
              label: t('common.answerYes'),
              value: '1',
              checked: question2Value === '1',
            },
            {
              label: t('common.answerNo'),
              value: '2',
              checked: question2Value === '2',
            },
          ]}
          onChange={(value, checked) => {
            setQuestion2Value(value + '');
            actions.complicationsQuestionAnswered(value);
          }}
        />
      )}
    </GridContainer>
  );
}
