import React from 'react';
import PropTypes from 'prop-types';
import Typography from './Typography';
import { formatDate } from 'components/helpers/dateHelper';

/**
 * Default Typography component based on https://material-ui.com/components/typography/
 * @param {*} props All possible props can be found in docs https://material-ui.com/api/typography/
 */
export default function FormattedDate(props) {
  const { format, variant } = props;

  return (
    <Typography variant={variant}>{formatDate(props.date, format)}</Typography>
  );
}

FormattedDate.propTypes = {
  variant: PropTypes.string,
  date: PropTypes.object,
  format: PropTypes.string,
};
