import React from 'react';

import Box from 'components/atoms/Layout/Box';
import CheckboxGroup from 'components/organisms/Inputs/CheckboxGroup/CheckboxGroup';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

export default function MultiselectQuestion(props) {
  return (
    <QuestionRefactored title={props.title} subtitle={props.subtitle}>
      <Box display="flex" justifyContent="center">
        <div style={{ maxWidth: '70vw' }}>
          <CheckboxGroup
            color="primary"
            rowNumber={1}
            items={props.data}
            onChange={(val) => {
              if (props.onQuestionAnswered) {
                props.onQuestionAnswered(val);
              }
            }}
            direction="column"
            deselectAllValue={props.deselectAllValue}
          />
        </div>
      </Box>
    </QuestionRefactored>
  );
}
