import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import Switch from 'components/atoms/Inputs/Switch/Switch';
import CheckboxWithLabel from 'components/molecules/Inputs/CheckboxInput/CheckboxWithLabel';
import Button from 'components/atoms/Inputs/Buttons/Button';

import { useActions, useOvermindState } from 'overmind/index';
import { profileGeneralTheme } from 'themes';

export default function GroceryList(props) {
  const actions = useActions();
  const overmindState = useOvermindState();

  const BY_RECIPES = 'recipes';
  const BY_AISLE = 'aisles';
  const [displayMode, setDisplayMode] = useState(BY_RECIPES);
  const [shoppingList, setShoppingList] = useState([]);

  useEffect(() => {
    actions.fetchShoppingList(displayMode);
  }, [displayMode, actions]);

  useEffect(() => {
    setShoppingList(overmindState.shoppingList);
  }, [overmindState.shoppingList]);

  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setDisplayMode(BY_AISLE);
    } else {
      setDisplayMode(BY_RECIPES);
    }
  };

  const renderToggle = () => {
    return (
      <GridContainer direction="row" alignItems="center">
        <GridItem>
          <Typography
            variant="h4"
            style={{
              fontSize: '14px',
              lineHeight: '14px',
              textTransform: 'uppercase',
            }}
          >
            {displayMode === BY_RECIPES ? 'By recipes' : 'By aisle'}
          </Typography>
        </GridItem>
        <GridItem style={{ marginLeft: 10 }}>
          <Switch
            checked={displayMode === BY_AISLE}
            onChange={handleSwitchChange}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderSelectOptions = () => {
    return (
      <GridContainer
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
      >
        <GridItem>{renderToggle()}</GridItem>
      </GridContainer>
    );
  };

  const renderListItem = (item, itemId, index) => {
    return (
      <GridContainer
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{
          padding: 5,
          backgroundColor: item.isDone ? '#e68a67' : '#ffffff',
          borderRadius: '50px',
          border: item.isDone ? '' : '1px solid #bbbbbb',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleListItemClick(item.databaseId, itemId, index);
        }}
      >
        <GridItem xs={9} style={{ textAlign: 'left', paddingLeft: '15px' }}>
          <CheckboxWithLabel
            color="primary"
            label={
              displayMode === BY_RECIPES ? item.ingredientLine : item.ingredient
            }
            checked={item.isDone}
          />
        </GridItem>
        {item.quantity != null && (
          <GridItem xs={3} style={{ textAlign: 'right', paddingRight: '15px' }}>
            <Typography>{`${item.quantity} ${item.unit}`}</Typography>
          </GridItem>
        )}
      </GridContainer>
    );
  };

  const handleListItemClick = (databaseId, itemId, index) => {
    actions.updateToShoppingListItem({
      displayMode: displayMode,
      value: {
        databaseId: databaseId,
        itemId: itemId,
        index: index,
      },
    });
  };

  const handleClearItems = () => {
    actions.deleteCheckedShoppingListItems(displayMode);
  };

  return (
    <ThemeProvider theme={profileGeneralTheme}>
      <div style={{ paddingBottom: '40px' }}>
        {renderSelectOptions()}
        <GridContainer direction="column" style={{ marginTop: '20px' }}>
          {shoppingList &&
            shoppingList.map((item, ix1) => {
              return (
                <div style={{ paddingBottom: '25px' }} key={'list' + ix1}>
                  <Typography variant="h5">{item.name}</Typography>

                  {item.items.map((listItem, ix2) => {
                    return (
                      <div style={{ padding: '10px' }} key={'item' + ix2}>
                        {renderListItem(listItem, item.id, ix2)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </GridContainer>
        <Button component="span" variant="contained" onClick={handleClearItems}>
          Clear checked items
        </Button>
      </div>
    </ThemeProvider>
  );
}
