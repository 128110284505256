import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import ProfileSummaryItem from './ProfileSummaryItem';
import ProfileCard from 'components/molecules/Common/ProfileCard/ProfileCard';
import LinkBox from 'components/molecules/Navigation/LinkBox/LinkBox';
import { ThemeProvider } from '@material-ui/core/styles';
import ModalWindow from 'components/organisms/ModalWindow/ModalWindow';

import { useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { profileGeneralTheme } from 'themes';
import { formatDate } from 'components/helpers/dateHelper';
import { useActions } from 'overmind/index';
import { useAuth0 } from '@auth0/auth0-react';
import { setUserEmail } from 'overmind/actions';

export default function ProfileView(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const { t } = useTranslation();
  const [kitNumber, setKitNumber] = useState(null);
  const [modalWindowOpened, setModalWindowOpened] = useState(false);
  const { user } = useAuth0();
  const [userEmail, setUserEmail] = useState(
    user != null ? user.email : overmindState.email
  );

  useEffect(() => {
    if (overmindState.email) {
      setUserEmail(overmindState.email);
    }
  }, [overmindState.email]);

  useEffect(() => {
    if (user == null) {
      actions.getUserEmail();
    }
  }, [actions]);

  useEffect(() => {
    if (overmindState.isGenotypeFetched) {
      setKitNumber(
        overmindState.genotype && overmindState.genotype.source_details
          ? overmindState.genotype.source_details.gxg_kit_number
          : null
      );
    }
  }, [overmindState.genotype, overmindState.isGenotypeFetched]);

  const renderLinkBox = (title, onClick) => {
    return <LinkBox title={title} onClick={onClick} checkPayment={true} />;
  };

  const downloadReport = () => {
    overmindState.report.download_url &&
      window.open(overmindState.report.download_url, '_blank');
  };

  return (
    <ThemeProvider theme={profileGeneralTheme}>
      <GridContainer
        style={{
          marginBottom: '20px',
          paddingLeft: '23px',
          paddingRight: '23px',
        }}
      >
        <GridContainer
          direction="row"
          style={{
            marginTop: '40px',
            marginBottom: '20px',
          }}
        >
          <GridItem
            style={{
              width: '50%',
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                fontSize: '15px',
              }}
            >
              {t('myProfile.subtitleProfile')}
            </Typography>
          </GridItem>
          <GridItem
            style={{
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Typography
              variant="body2"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '11px',
                fontWeight: 'normal',
              }}
              onClick={() => props.changeProfileView('profile_details')}
            >
              {t('myProfile.editYourProfileDetails')}
            </Typography>
          </GridItem>
        </GridContainer>

        <GridContainer direction="column" style={{ textAlign: 'left' }}>
          <GridItem>
            <ProfileSummaryItem
              title={t('myProfile.name')}
              value={props.name}
              color="primary"
            />
          </GridItem>
          {userEmail && (
            <GridItem>
              <ProfileSummaryItem
                title={'Email'}
                value={userEmail}
                color="primary"
              />
            </GridItem>
          )}
          <GridItem>
            <ProfileSummaryItem
              title={t('myProfile.pregnancyDueDate')}
              value={
                overmindState.profilePregnancy.due_date &&
                overmindState.profilePregnancy.due_date != null
                  ? formatDate(
                      overmindState.profilePregnancy.due_date,
                      'MMMM Do YYYY'
                    )
                  : props.dueDate
                  ? props.dueDate
                  : null
              }
              color="primary"
            />
          </GridItem>
        </GridContainer>

        <GridContainer direction="row">
          {overmindState.report && overmindState.report.download_url && (
            <ProfileCard
              onClicked={() => downloadReport()}
              title={t('myProfile.dnaAssessment')}
              infoText={t('myProfile.dnaAssessmentInfo')}
              actionText={t('myProfile.downloadPdf')}
              showClickIndicator={true}
            />
          )}
        </GridContainer>
        <GridContainer direction="row">
          {(overmindState.isGenotypeFetched ||
            (overmindState.genotype &&
              Object.keys(overmindState.genotype).length === 0)) && (
            <ProfileCard
              onClicked={() => {
                if (kitNumber == null || kitNumber === '') {
                  actions.saveComponentForModalWindow('dnaKitNumber');
                  setModalWindowOpened(true);
                }
              }}
              title={t('myProfile.kitCodeNumber')}
              infoText={
                kitNumber != null && kitNumber !== ''
                  ? ''
                  : t('myProfile.kitCodeNumberNotRegistered')
              }
              actionText={
                kitNumber != null && kitNumber !== ''
                  ? kitNumber
                  : t('myProfile.registerKitCodeNumber')
              }
              showClickIndicator={kitNumber == null || kitNumber === ''}
            />
          )}
        </GridContainer>
      </GridContainer>
      {renderLinkBox('Recipe Box', () => props.changeProfileView('recipe_box'))}
      {renderLinkBox('Grocery List', () =>
        props.changeProfileView('grocery_list')
      )}
      <ModalWindow
        opened={modalWindowOpened}
        onClose={() => setModalWindowOpened(false)}
      />
    </ThemeProvider>
  );
}
