import React from 'react';

import MomentUtils from '@date-io/moment';
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

export default function DatePicker(props) {
  const { ...rest } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiDatePicker {...rest} />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {};
