import React from 'react';

import MuiSwitch from '@material-ui/core/Switch';

import { ToggleIcon } from 'assets/iconsList';
import { useSwitchStyles } from './switchStyles';

/**
 * Default Switch component based on https://material-ui.com/components/switches/#switch
 * @param {} props All possible props can be found in docs https://material-ui.com/api/switch/
 */

export default function Switch(props) {
  const classes = useSwitchStyles(props);

  return (
    <MuiSwitch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      icon={
        props.icon ? (
          props.icon
        ) : (
          <ToggleIcon
            style={{
              width: 30,
              height: 30,
            }}
          />
        )
      }
      checkedIcon={
        props.checkedIcon ? (
          props.checkedIcon
        ) : (
          <ToggleIcon
            style={{
              width: 30,
              height: 30,
            }}
          />
        )
      }
      checked={props.checked ? props.checked : false}
      {...props}
    />
  );
}

Switch.propTypes = {};
