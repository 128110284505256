import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import SingleSelectionQuestion from 'components/organisms/Questions/Pregnancy/SingleSelectionQuestion';
import DueDateDatePickerQuestion from 'components/organisms/Questions/Pregnancy/DueDateDatePickerQuestion';

import { usePageStyles } from 'components/pages/commonStyles';
import { useActions, useOvermindState } from 'overmind/index';
import { subtractDays, formatDate } from 'components/helpers/dateHelper';

export default function DoYouKnowYourDueDate(props) {
  const classes = usePageStyles(props);
  const { t } = useTranslation();
  const actions = useActions();
  const overmindState = useOvermindState();

  const [question1Value, setQuestion1Value] = useState(
    overmindState.knowsDueDate == null ? undefined : overmindState.knowsDueDate
  );
  const [dueDate, setDueDate] = useState(
    overmindState.dueDate ? overmindState.dueDate : null
  );

  useEffect(() => {
    if (question1Value) {
      setQuestion1Value(question1Value);
      actions.knowsDueDateAnswered(question1Value);
    }

    if (question1Value === '1' && dueDate) {
      handleDatePickerChange(dueDate);
    }
  }, []);

  const handleDatePickerChange = (date) => {
    let dueDate = formatDate(date, 'YYYY-MM-DD');
    const lastPeriodDate = subtractDays(date, 280);

    setDueDate(dueDate);
    actions.updateStateProp({
      key: 'dueDate',
      value: date,
      nextEnabled: true,
    });
    actions.updateStateProp({
      key: 'lastPeriodDate',
      value: lastPeriodDate,
      nextEnabled: true,
    });
  };

  return (
    <GridContainer
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <GridItem>
        <SingleSelectionQuestion
          title={t('calculator.doYouKnowYourDueDate.question')}
          data={[
            {
              label: t('calculator.doYouKnowYourDueDate.yes'),
              value: '1',
              checked: question1Value === '1',
            },
            {
              label: t('calculator.doYouKnowYourDueDate.no'),
              value: '2',
              checked: question1Value === '2',
            },
          ]}
          onChange={(value) => {
            setQuestion1Value(value);
            actions.knowsDueDateAnswered(value);
          }}
          offset={120}
        />
      </GridItem>

      {question1Value === '1' && (
        <GridItem>
          <DueDateDatePickerQuestion
            dueDate={dueDate}
            onChange={(e) => {
              handleDatePickerChange(e);
            }}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}
