import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import NameQuestion from 'components/organisms/Questions/General/NameQuestion';
import EthnicityQuestion from 'components/organisms/Questions/General/EthnicityQuestion';
import AgeQuestion from 'components/organisms/Questions/General/AgeQuestion';
import HeightQuestion from 'components/organisms/Questions/General/HeightQuestion';
import WeightQuestionObsolete from 'components/organisms/Questions/General/WeightQuestionObsolete';
import DueDateQuestion from 'components/organisms/Questions/Pregnancy/DueDateQuestion';
import DueDateDatePickerQuestion from 'components/organisms/Questions/Pregnancy/DueDateDatePickerQuestion';
import SleepQuestion from 'components/organisms/Questions/Lifestyle/SleepQuestion';
import StressQuestion from 'components/organisms/Questions/Lifestyle/StressQuestion';
import ExercisePerDayQuestion from 'components/organisms/Questions/Exercise/ExercisePerDayQuestion';
import DietaryPreferencesQuestion from 'components/organisms/Questions/Food/DietaryPreferencesQuestion';
import FoodAllergiesQuestion from 'components/organisms/Questions/Food/FoodAllergiesQuestion';
import KitNumberInputQuestion from 'components/organisms/Questions/DNA/KitNumberInputQuestion';

import { useActions, useOvermindState } from 'overmind/index';
import { useTranslation } from 'react-i18next';
import { subtractDays, formatDate } from 'components/helpers/dateHelper';

import {
  getNutritionData,
  getNutritionObject,
  getFoodAllergiesData,
  getFoodAllergiesObject,
} from 'components/helpers/nutritionHelper';
import { getEthnicities } from 'components/helpers/ethnicityHelper';
import {
  getSleep,
  getStress,
  getExercise,
} from 'components/helpers/lifestyleExerciseHelper';
import { minAge, maxAge } from 'components/helpers/unitValueHelper';
import { lbsToKg } from 'components/helpers/converterHelper';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    width: '95%',
  },
  paper: {
    margin: 0,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let today = new Date();
let yearOfBirth = null;
let ethnicities = [];

export default function CustomizedDialogs(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const actions = useActions();
  const overmindState = useOvermindState();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [open, setOpen] = useState(props.opened ? props.opened : false);
  const [dnaKitNumber, setDnaKitNumber] = useState(null);
  const [name, setName] = useState(
    overmindState.metaData.user_metadata.firstName &&
      overmindState.metaData.user_metadata.firstName != null
      ? overmindState.metaData.user_metadata.firstName
      : ''
  );
  const [userEthnicity, setUserEthnicity] = useState(
    overmindState.metaData.user_metadata &&
      overmindState.metaData.user_metadata.selectedEthnicity
      ? overmindState.metaData.user_metadata.selectedEthnicity
      : []
  );
  const [age, setAge] = useState(
    overmindState.profile.year_of_birth != null
      ? today.getFullYear() - overmindState.profile.year_of_birth
      : null
  );

  const getSavedWeight = () => {
    return overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? overmindState.profile.weight
      : overmindState.metaData.user_metadata.weightLbs;
  };

  const [weight, setWeight] = useState(getSavedWeight());
  const [height, setHeight] = useState(
    overmindState.profile.height
      ? Math.floor(overmindState.profile.height)
      : null
  );
  const [heightIn, setHeightIn] = useState(
    overmindState.metaData.user_metadata.heightIn
      ? overmindState.metaData.user_metadata.heightIn
      : null
  );

  const [heightFt, setHeightFt] = useState(
    overmindState.metaData.user_metadata.heightFt
      ? overmindState.metaData.user_metadata.heightFt
      : null
  );
  const [weightIsInLbs, setWeightIsInLbs] = useState(
    overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? false
      : true
  );
  const [heightCheckedInFeet, setHeightCheckedInFeet] = useState(
    overmindState.metaData.user_metadata.selectedHeightMetric === 1
      ? true
      : false
  );
  const [selectedValue, setSelectedValue] = useState(
    overmindState.metaData.user_metadata.due_date_question
      ? overmindState.metaData.user_metadata.due_date_question
      : undefined
  );
  const [dueDate, setDueDate] = useState(
    overmindState.profilePregnancy
      ? overmindState.profilePregnancy.due_date
      : formatDate(new Date(), 'YYYY-MM-DD')
  );
  const [sleepQuality, setSleepQuality] = useState(
    overmindState.profilePregnancy
      ? overmindState.profilePregnancy.sleep_quality
      : 0
  );
  const [stressLevel, setStressLevel] = useState(
    overmindState.profilePregnancy
      ? overmindState.profilePregnancy.stress_level
      : 0
  );
  const [exercisePerDay, setExercisePerDay] = useState(
    overmindState.profilePregnancy
      ? overmindState.profilePregnancy.exercise_level
      : 0
  );
  const [dietaryPreferences, setDietaryPreferences] = useState(
    overmindState.profileNutrition ? overmindState.profileNutrition : null
  );

  useEffect(() => {
    if (overmindState.componentForModalWindow === 'age') {
      setSaveDisabled(!(age >= minAge && age <= maxAge));
    } else {
      setSaveDisabled(false);
    }
    setOpen(props.opened);
  }, [props.opened]);

  useEffect(() => {
    if (age !== null) {
      yearOfBirth = today.getFullYear() - age;
    }
  }, [age]);

  useEffect(() => {
    if (ethnicities.length === 0) {
      ethnicities = getEthnicities(userEthnicity);
    }
  }, [userEthnicity]);

  function setNameValue(newName) {
    setName(newName);
  }

  function setEthnicity(ethnicity) {
    setUserEthnicity(parseInt(ethnicity));
    ethnicities = getEthnicities(parseInt(ethnicity));
  }

  function setAgeValue(newAge) {
    if (newAge >= minAge && newAge <= maxAge) {
      setAge(newAge);
      yearOfBirth = today.getFullYear() - newAge;
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }

  const radioButtonsHeight = [
    {
      label: t('onboarding.generalSection.step3.ft'),
      value: 1,
      checked: heightCheckedInFeet,
    },
    {
      label: t('onboarding.generalSection.step3.cm'),
      value: 2,
      checked: !heightCheckedInFeet,
    },
  ];

  const radioButtonsWeight = [
    {
      label: t('onboarding.generalSection.step3.lbs'),
      value: 1,
      checked: weightIsInLbs,
    },
    {
      label: t('onboarding.generalSection.step3.kg'),
      value: 2,
      checked: !weightIsInLbs,
    },
  ];

  const onHeightChange = (data) => {
    setHeight(data.height);
    setHeightFt(data.heightFt);
    setHeightIn(data.heightIn);
    setHeightCheckedInFeet(data.selectedHeightMetric === 1);
  };

  const onWeightChange = (data) => {
    setWeight(data.weight);
    setWeightIsInLbs(data.selectedWeightMetric === 1);
  };

  const handleRadioChange = (value, checked) => {
    setSelectedValue(value + '');
  };

  const handleDatePickerChange = (date) => {
    setDueDate(formatDate(date, 'YYYY-MM-DD'));
  };

  function onSleepQualityChange(value) {
    setSleepQuality(value);
  }

  function onStressLevelChange(value) {
    setStressLevel(value);
  }

  function onExercisePerDayChange(value) {
    setExercisePerDay(value);
  }

  function onDietaryPreferencesChange(obj) {
    setDietaryPreferences({ ...overmindState.profileNutrition, ...obj });
  }

  const getWeightInKg = () => {
    return overmindState.metaData.user_metadata.selectedWeightMetric === 2
      ? overmindState.profile.weight
      : lbsToKg(overmindState.metaData.user_metadata.weightLbs);
  };

  function resetDataAndClose() {
    setName(
      overmindState.metaData.user_metadata.firstName &&
        overmindState.metaData.user_metadata.firstName != null
        ? overmindState.metaData.user_metadata.firstName
        : ''
    );
    setUserEthnicity(
      overmindState.metaData.user_metadata &&
        overmindState.metaData.user_metadata.selectedEthnicity
        ? overmindState.metaData.user_metadata.selectedEthnicity
        : null
    );
    setAge(
      overmindState.profile.year_of_birth != null
        ? today.getFullYear() - overmindState.profile.year_of_birth
        : null
    );
    setWeight(getSavedWeight());
    setHeight(
      overmindState.profile.height
        ? Math.floor(overmindState.profile.height)
        : null
    );

    setHeightIn(
      overmindState.metaData.user_metadata.heightIn
        ? overmindState.metaData.user_metadata.heightIn
        : null
    );

    setHeightFt(
      overmindState.metaData.user_metadata.heightFt
        ? overmindState.metaData.user_metadata.heightFt
        : null
    );

    setWeightIsInLbs(
      overmindState.metaData.user_metadata.selectedWeightMetric === 2
        ? false
        : true
    );
    setHeightCheckedInFeet(
      overmindState.metaData.user_metadata.selectedHeightMetric === 1
        ? true
        : false
    );
    setDueDate(
      overmindState.profilePregnancy
        ? overmindState.profilePregnancy.due_date
        : formatDate(new Date(), 'YYYY-MM-DD')
    );
    setSleepQuality(
      overmindState.profilePregnancy
        ? overmindState.profilePregnancy.sleep_quality
        : 0
    );
    setStressLevel(
      overmindState.profilePregnancy
        ? overmindState.profilePregnancy.stress_level
        : 0
    );
    setExercisePerDay(
      overmindState.profilePregnancy
        ? overmindState.profilePregnancy.exercise_level
        : 0
    );
    setDietaryPreferences(
      overmindState.profileNutrition ? overmindState.profileNutrition : null
    );
    props.onClose();
  }

  function updateAndClose() {
    actions.saveChangesToState({
      firstName: name,
      yearOfBirth: yearOfBirth,
      ethnicity: userEthnicity,
      country:
        overmindState.metaData.user_metadata &&
        overmindState.metaData.user_metadata.country
          ? overmindState.metaData.user_metadata.country
          : 'United States of America',
      gender: 'female',
      body_type: 'average',
      weight: weight,
      height: height,
      heightFt: heightFt,
      heightIn: heightIn,
      selectedWeightMetric: weightIsInLbs ? 1 : 2,
      selectedHeightMetric: heightCheckedInFeet ? 1 : 2,
      dueDate: dueDate,
      lastPeriodDate: subtractDays(dueDate, 280),
      sleep_quality: sleepQuality,
      stress_level: stressLevel,
      exercise_level: exercisePerDay,
      profileNutrition: { ...dietaryPreferences },
    });
    if (
      overmindState.componentForModalWindow === 'name' ||
      overmindState.componentForModalWindow === 'ethnicity'
    ) {
      actions.saveChanges('userMetadata');
    } else if (overmindState.componentForModalWindow === 'age') {
      actions.saveChanges('profile');
    } else if (
      overmindState.componentForModalWindow === 'height' ||
      overmindState.componentForModalWindow === 'weight'
    ) {
      actions.saveChanges('profile');
      actions.saveChanges('userMetadata');
    } else if (
      overmindState.componentForModalWindow === 'dueDate' ||
      overmindState.componentForModalWindow === 'sleep' ||
      overmindState.componentForModalWindow === 'stress' ||
      overmindState.componentForModalWindow === 'exercise'
    ) {
      actions.saveChanges('pregnancy');
    } else if (
      overmindState.componentForModalWindow === 'dietaryPreferences' ||
      overmindState.componentForModalWindow === 'allergiesRestrictions'
    ) {
      actions.saveChanges('nutrition');
    } else if (overmindState.componentForModalWindow === 'dnaKitNumber') {
      if (dnaKitNumber != null) {
        actions.submitKitNumber(dnaKitNumber);
      }
    }
    props.onClose();
  }

  return (
    <div
      style={{
        width: '100%',
        margin: '0px',
        maxWidth: '400px',
      }}
    >
      <Dialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{
          width: '100%',
          margin: '0px',
        }}
        fullWidth={true}
        className={classes.root}
      >
        <DialogContent
          style={{
            margin: '0',
            padding: '60px 20px',
          }}
        >
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'name' ? (
            <NameQuestion
              title={t('onboarding.generalSection.step1.nameTitle')}
              name={name}
              onChange={setNameValue}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'ethnicity' ? (
            <EthnicityQuestion
              title={t('onboarding.generalSection.step2.ethnicityTitle')}
              data={ethnicities}
              userEthnicity={userEthnicity}
              onChange={setEthnicity}
              colorUnchecked={'secondary'}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'age' ? (
            <AgeQuestion
              title={t('onboarding.generalSection.step1.ageTitle')}
              age={age}
              onChange={setAgeValue}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'height' ? (
            <HeightQuestion
              title={t('onboarding.generalSection.step3.height')}
              data={radioButtonsHeight}
              height={overmindState.profile.height}
              heightFt={overmindState.metaData.user_metadata.heightFt}
              heightIn={overmindState.metaData.user_metadata.heightIn}
              selectedHeightMetric={
                overmindState.metaData.user_metadata.selectedHeightMetric
              }
              onChange={onHeightChange}
              colorUnchecked={'secondary'}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'weight' ? (
            <WeightQuestionObsolete
              title={t('onboarding.generalSection.step3.weight')}
              data={radioButtonsWeight}
              weight={weight}
              selectedWeightMetric={
                overmindState.metaData.user_metadata.selectedWeightMetric
              }
              onChange={onWeightChange}
              colorUnchecked={'secondary'}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'dueDate' ? (
            <div>
              <DueDateQuestion
                title={t('onboarding.pregnancySection.step1.title')}
                data={[
                  {
                    label: t('common.answerYes'),
                    value: 1,
                    checked: selectedValue === '1',
                  },
                  {
                    label: t('common.answerNo'),
                    value: 2,
                    checked: selectedValue === '2',
                  },
                ]}
                onChange={(val, checked) => {
                  handleRadioChange(val, checked);
                }}
                colorUnchecked={'secondary'}
              />

              {selectedValue === '1' && (
                <DueDateDatePickerQuestion
                  dueDate={dueDate}
                  onChange={(e) => {
                    handleDatePickerChange(e);
                  }}
                />
              )}
            </div>
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'sleep' ? (
            <SleepQuestion
              title={t('onboarding.lifestyleSection.step1.question1.title')}
              data={getSleep()}
              selectedIllustrationIndex={sleepQuality}
              onChange={(index) => onSleepQualityChange(index)}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'stress' ? (
            <StressQuestion
              title={t('onboarding.lifestyleSection.step1.question2.title')}
              data={getStress()}
              selectedIllustrationIndex={stressLevel}
              onChange={(index) => onStressLevelChange(index)}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'exercise' ? (
            <ExercisePerDayQuestion
              title={t('onboarding.exerciseSection.step1.title')}
              exerciseLevel={exercisePerDay}
              onChange={(index) => onExercisePerDayChange(index)}
              data={getExercise()}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'dietaryPreferences' ? (
            <DietaryPreferencesQuestion
              title={t('onboarding.foodSection.step1.title')}
              caption={t('onboarding.foodSection.step1.subtitle')}
              items={getNutritionData(dietaryPreferences)}
              onAnswered={(values) => {
                const obj = getNutritionObject(values);
                onDietaryPreferencesChange(obj);
              }}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
          overmindState.componentForModalWindow === 'allergiesRestrictions' ? (
            <FoodAllergiesQuestion
              title={t('onboarding.foodSection.step2.title')}
              caption={t('onboarding.foodSection.step2.subtitle')}
              items={getFoodAllergiesData(dietaryPreferences)}
              onAnswered={(values) => {
                const obj = getFoodAllergiesObject(values);
                onDietaryPreferencesChange(obj);
              }}
            />
          ) : null}
          {overmindState.componentForModalWindow &&
            overmindState.componentForModalWindow === 'dnaKitNumber' && (
              <KitNumberInputQuestion
                onChange={(value) => setDnaKitNumber(value)}
                hideButton={true}
              />
            )}
        </DialogContent>
        <DialogActions
          style={{
            margin: '0 auto',
            paddingBottom: '40px',
          }}
        >
          <Button
            onClick={resetDataAndClose}
            color="default"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={() => updateAndClose()}
            color="primary"
            variant="contained"
            disabled={saveDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
