import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MuiFormControl from '@material-ui/core/FormControl';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import { useRadioButtonStyles } from './radioButtonStyles';

import RadioWithLabel from 'components/molecules/Inputs/RadioInput/RadioWithLabel';

export default function RadioButtonGroup(props) {
  const { id, items, rowNumber, color, ...rest } = props;
  const classes = useRadioButtonStyles();
  const [value, setValue] = useState(null);

  const handleChange = (e) => {
    setValue(e.target.value.toString());
    props.onChange && props.onChange(e.target.value);
  };

  return (
    <MuiFormControl component="fieldset" classes={{ root: classes.root }}>
      <MuiRadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={(e) => handleChange(e)}
      >
        <GridContainer direction={props.direction ? props.direction : 'row'}>
          {items &&
            items.map((item, ix) => (
              <GridItem
                xs={12 / rowNumber}
                key={ix}
                style={{
                  marginBottom: props.spaceBetween ? props.spaceBetween : 0,
                }}
              >
                <RadioWithLabel
                  id={`${id}-${ix}`}
                  color={props.color}
                  colorUnchecked={props.colorUnchecked}
                  label={item.label}
                  value={item.value.toString()}
                  checked={item.checked}
                  size={props.size}
                  {...rest}
                />
              </GridItem>
            ))}
        </GridContainer>
      </MuiRadioGroup>
    </MuiFormControl>
  );
}

RadioButtonGroup.propTypes = {
  color: PropTypes.string,
  rowNumber: PropTypes.number,
  spaceBetween: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
