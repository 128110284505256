import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Typography/Typography';
import ContainerWithBackgroundImg from 'components/molecules/Common/ContainerWithBackgroundImage/ContainerWithBackgroundImg';

import { backgroundLanding } from 'assets/imagesList';
import {
  getDayDifferenceInWeeks,
  formatDate,
} from 'components/helpers/dateHelper';

export default function Congratulations(props) {
  const { t } = useTranslation();
  const [dueDate] = useState(props.dueDate);
  const [lastPeriod] = useState(props.lastPeriod);

  return (
    <ContainerWithBackgroundImg
      image={backgroundLanding}
      maxWidth={'957px'}
      maxHeight={'700px'}
      height={'95vh'}
      width={'100vw'}
      marginTop={'0vh'}
    >
      <Box
        color="#ffffff"
        position="relative"
        maxWidth="300px"
        ml={2}
        left={{ sm: '27%' }}
        top={{ sm: '20%', xs: '30%' }}
      >
        <Typography variant="h4" textSize={35} align="center">
          {t('onboarding.pregnancySection.step3.title1')}
        </Typography>
        <Typography variant="h4" textSize={35} align="center">
          {t('onboarding.pregnancySection.step3.title2', {
            due_date: formatDate(dueDate, 'MMMM Do YYYY'),
          })}
        </Typography>
        {lastPeriod && (
          <Typography variant="h5" style={{ marginTop: '20px' }} align="center">
            {t('onboarding.pregnancySection.step3.subTitle', {
              pregnancy_duration: getDayDifferenceInWeeks(
                new Date(lastPeriod),
                new Date()
              ),
              plural:
                getDayDifferenceInWeeks(new Date(lastPeriod), new Date()) <= 1
                  ? ''
                  : 's',
            })}
          </Typography>
        )}

        <div onClick={() => props.onCalculateAgain()}>
          <Typography
            variant="body1"
            style={{ marginTop: '20px' }}
            align="center"
          >
            {t('onboarding.pregnancySection.step3.caption')}
          </Typography>
        </div>
      </Box>
    </ContainerWithBackgroundImg>
  );
}
