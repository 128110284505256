export const kgToLbs = (value) => {
  return value * 2.20462;
};

export const lbsToKg = (value) => {
  return value / 2.20462;
};

export const cmToFt = (value) => {
  return value / 30.48;
};

export const ftToCm = (value) => {
  return value * 30.48;
};

export const ftToIn = (value) => {
  return value * 12;
};

export const cmToIn = (value) => {
  return value / 2.54;
};

export const inToCm = (value) => {
  return value * 2.54;
};

export const convertFtAndInToCm = (ft, inch) => {
  return ftToCm(ft) + inToCm(inch);
};

export const getUserWeight = (value, metric) => {
  if (metric === 0) {
    return Math.round(kgToLbs(value));
  } else {
    return value;
  }
};

export const getUserHeight = (value, metric) => {
  if (metric === 0) {
    return parseInt(cmToFt(value), 10);
  } else {
    return value;
  }
};

export const getUserHeight2 = (value, metric) => {
  if (metric === 0) {
    let decimal = cmToFt(value) % 1;
    return Math.round(ftToIn(decimal));
  } else {
    return 0;
  }
};
