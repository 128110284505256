import * as React from 'react';

function HomeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.107"
      height="23.093"
      viewBox="0 0 23.107 23.093"
    >
      <path
        d="M-423.867,360.257h-4.661a.775.775,0,0,1-.4-.772c.018-1.289.008-2.577.006-3.866,0-.144-.012-.288-.018-.429h-2.359v.467c0,1.276-.011,2.553.007,3.829a.773.773,0,0,1-.4.772h-4.661a.778.778,0,0,1-.452-.82c.01-2.078,0-4.156.011-6.234a.474.474,0,0,0-.289-.495c-.488-.253-.96-.536-1.437-.809-.589-.337-.615-.736-.078-1.156.581-.454,1.156-.918,1.748-1.359a.575.575,0,0,0,.264-.527c-.013-1.239-.009-2.479,0-3.718,0-.467.192-.657.664-.662.651-.007,1.3-.009,1.953,0,.437.005.632.212.636.654,0,.47,0,.941,0,1.47.112-.076.172-.112.226-.154l2.425-1.894c.49-.383.654-.381,1.146,0q3.605,2.821,7.21,5.643c.379.3.724.641,1.085.963v.3a3.416,3.416,0,0,1-.484.442c-.455.282-.923.545-1.4.794a.5.5,0,0,0-.31.518c.012,2.074,0,4.147.011,6.221A.786.786,0,0,1-423.867,360.257Z"
        transform="translate(439.474 -343.815)"
        fill="none"
        stroke={props.color}
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
}

export default HomeIcon;
