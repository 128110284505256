import React, { useEffect } from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import { useIntroPageStyles } from './introPageStyles';
import { useTranslation } from 'react-i18next';
import { useOvermindState, useActions } from 'overmind/index';
import { useHistory } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { introTheme } from 'themes';

import { ROUTE_ONBOARDING } from 'routes';
import IntroPageSlider from './IntroPageSlider';

import moment from 'moment';
import {
  trackEvent,
  trackFbPixelEvent,
} from 'components/helpers/userTrackingEventsHelper';

export default function IntroPage() {
  const classes = useIntroPageStyles();
  const { t } = useTranslation();
  const history = useHistory();

  let introTexts = t('onboarding.introSection.landing.introTexts', {
    returnObjects: true,
  });
  let lastStep = introTexts.length - 1;

  const overmindState = useOvermindState();
  const actions = useActions();

  useEffect(() => {
    actions.enableForwardButton();
    if (!overmindState.metaData.user_metadata.nine_moons_first_signing_date) {
      saveFirstSigningDate();
    }
  }, []);

  async function saveFirstSigningDate() {
    let currentDate = moment().format('YYYY-MM-DD');
    await actions.saveFirstSigningDate(currentDate);
    trackEvent('SignUp', 'Completed');
    trackFbPixelEvent('Lead');
  }

  const goToNextOnboardingPage = () => {
    if (
      overmindState.metaData &&
      overmindState.metaData.user_metadata &&
      overmindState.metaData.user_metadata.terms_9moons
    ) {
      actions.enableForwardButton();
    } else {
      actions.disableForwardButton();
    }

    history.push(ROUTE_ONBOARDING);
  };

  return (
    <Box className={classes.root}>
      <ThemeProvider theme={introTheme}>
        <Box flexDirection="column" alignItems="center">
          <IntroPageSlider />
        </Box>
      </ThemeProvider>
    </Box>
  );
}
