import React, { useEffect } from 'react';
import Congratulations from './Congratulations';

import { useActions, useOvermindState } from 'overmind/index';

export default function CongratulationsPage(props) {
  const actions = useActions();
  const overmindState = useOvermindState();

  useEffect(() => {
    actions.enableForwardButton();
  });

  return (
    <Congratulations
      lastPeriod={overmindState.profilePregnancy.last_period_date}
      dueDate={overmindState.profilePregnancy.due_date}
      onCalculateAgain={() => actions.goBack()}
    />
  );
}
