import { makeStyles } from '@material-ui/core';

export const useCalendarWeekStyles = makeStyles((theme) => ({
  dates: (props) => ({
    backgroundColor:
      props.type === 'week' ? theme.palette.secondary.main + '20' : '',
  }),
  item: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  text: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
  selectedDateText1: (props) => ({
    color:
      props.type === 'week'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  }),
}));
