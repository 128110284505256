import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: en,
    },
  },
  lng: process.env.REACT_APP_I18N_LOCALE,
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false,
  },

  // react i18next special options (optional)
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: [
      'q',
      'b',
      'br',
      'strong',
      'i',
      'p',
      'u',
      'ul',
      'li',
      'h1',
      'h2',
      'h3',
      'a',
    ],
  },

  localeSubpaths: 'all',
  nsSeparator: false,
});

export default i18n;
