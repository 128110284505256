import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

import { useAccordionSummaryStyles } from './accordionStyles';

export default function AccordionSummary(props) {
  const { ariaControls, id, children, ...rest } = props;
  const classes = useAccordionSummaryStyles(props);

  return (
    <MuiAccordionSummary
      aria-controls={ariaControls}
      id={id}
      classes={{ ...classes }}
      {...rest}
    >
      {children}
    </MuiAccordionSummary>
  );
}
