import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import Box from 'components/atoms/Layout/Box';
import Typography from 'components/atoms/Typography/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useActions, useOvermindState } from 'overmind/index';
import PaymentDialogHome from 'components/organisms/PaymentDialog/PaymentDialogHome';
import { ROUTE_PROFILE } from 'routes';

export default function LinkBox(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const [paymentDialogOpened, setPaymentDialogOpened] = useState(false);

  useEffect(() => {
    if (
      overmindState.subscription.status == null &&
      !overmindState.subscription.isBeingFetched
    ) {
      actions.fetchSubscriptionStatus();
    }
  }, [actions]);

  return (
    <GridContainer
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{
        paddingTop: '20px',
        paddingBottom: '20px',
        border: '1px solid #e5e5e5',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (props.checkPayment && !overmindState.subscription.status) {
          setPaymentDialogOpened(true);
        } else if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <Box style={{ paddingLeft: '23px' }}>
        <Typography variant="body2">{props.title}</Typography>
      </Box>
      <Box style={{ paddingRight: '23px' }}>
        <NavigateNextIcon />
      </Box>
      <PaymentDialogHome
        openDialog={paymentDialogOpened}
        onCloseDialog={() => setPaymentDialogOpened(false)}
        redirectState={{ initiator: ROUTE_PROFILE }}
      />
    </GridContainer>
  );
}

LinkBox.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};
