import {
  getStepIndex,
  getCalculatorStepIndex,
} from 'components/helpers/onboardingHelper';
import { subtractDays } from 'components/helpers/dateHelper';
import { lbsToKg } from 'components/helpers/converterHelper';
import { getBDD } from 'components/helpers/dueDateCalcHelper';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';

const { REACT_APP_GATING_ON } = process.env;

export const onInitialize = async ({ state, effects, actions }, options) => {
  state.errors.errorGettingUserMetadata = '';
  state.errors.errorGettingProfile = '';
  state.errors.errorGettingProfilePregnancy = '';
  state.errors.errorGettingNutritionPreferences = '';
  state.errors.errorGettingAssesment = '';
  state.errors.errorGettingRecipeActions = '';
  state.errors.errorGettingWeeklyCheckin = '';
  state.errors.errorSettingWeeklyCheckin = '';
  state.errors.errorDeletingGenotype = '';
  state.errors.errorDeletingProfile = '';
  state.errors.errorDeletingUserMetadata = '';
  state.errors.errorDeletingRecipeActions = '';
  state.errors.errorSavingUserMetadata = '';
  state.errors.errorSavingProfile = '';
  state.errors.errorSavingProfilePregnancy = '';
  state.errors.errorSavingNutritionPreferences = '';
  state.errors.errorSavingGenotype = '';
  state.errors.errorSavingRecipeActions = '';
  state.errors.errorFethcingSubscriptionStatus = '';
  state.errors.errorSettingSubscriptionStatus = '';
  await effects.api
    .getProfile()
    .then((response) => {
      state.profile = response;
      return effects.apiProfilePregnancy.getProfilePregnancy();
    })
    .then((response) => {
      state.profilePregnancy = response;
      return effects.apiNutrition.getNutritionPreferences();
    })
    .then((response) => {
      state.profileNutrition = response;
    });

  await effects.apiGenotype
    .getGenotype()
    .then((response) => {
      actions.setGenotype(response);
      actions.setErrorGettingGenotype('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingGenotype(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });

  if (options && options.fetchEmail) {
    await effects.apiAuthentication.getUserInfo().then((response) => {
      if (response.email) {
        actions.setUserEmail(response.email);
      }
    });
  }

  await effects.api.getCurrentUserMetadata().then((response) => {
    state.metaData = response;
    state.hasFetchedMetaData = true;
  });
  state.isLoadingData = false;
};

export const hideHeader = ({ state, effects, actions }, value) => {
  state.hideHeader = value;
};

export const hideFooter = ({ state, effects, actions }, value) => {
  state.hideFooter = value;
};

export const enableForwardButton = ({ state, effects, actions }) => {
  state.onboardingForwardEnabled = true;
};

export const disableForwardButton = ({ state, effects, actions }) => {
  state.onboardingForwardEnabled = false;
};

export const termsChecked = ({ state, effects, actions }, checked) => {
  state.metaData.user_metadata.terms_9moons = checked;
  state.onboardingForwardEnabled = checked;
};

export const researchAgreementChecked = (
  { state, effects, actions },
  value
) => {
  state.metaData.user_metadata.research_agreement_9moons = value === '1';
  state.onboardingForwardEnabled = true;
};

export const dueDateQuestionAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.due_date_question = value;
  if (value === '1') {
    if (state.profilePregnancy.due_date) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else if (value === '2') {
    state.onboardingForwardEnabled = true;
  }
};

export const dueDateSet = ({ state, effects, actions }, value) => {
  state.profilePregnancy.due_date = value.dueDate;
  state.profilePregnancy.last_period_date = value.lastPeriodDate;
  state.onboardingForwardEnabled = true;
};

export const lastPeriodDateSet = ({ state, effects, actions }, value) => {
  state.profilePregnancy.due_date = value.dueDate;
  state.profilePregnancy.last_period_date = value.lastPeriodDate;
  state.onboardingForwardEnabled = true;
  state.progressStep += 1;
};

export const childrenQuestionAnswered = (
  { state, effects, actions },
  value
) => {
  if (value === '1') {
    state.profilePregnancy.other_children = true;
    state.onboardingForwardEnabled = false;
  } else if (value === '2') {
    state.profilePregnancy.other_children = false;
    state.onboardingForwardEnabled = true;
  }
};

export const complicationsQuestionAnswered = (
  { state, effects, actions },
  value
) => {
  if (value === '1') {
    state.profilePregnancy.past_complications = true;
    state.onboardingForwardEnabled = true;
  } else if (value === '2') {
    state.profilePregnancy.past_complications = false;
    state.onboardingForwardEnabled = true;
  }
};

export const wantsToUseDnaAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.wantsToUseDna = value;
  state.onboardingForwardEnabled = true;
};

export const dnaOptionAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaUsageOption = value;
  state.onboardingForwardEnabled = true;
};

export const providersAnswered = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaProviders = value;
  state.onboardingForwardEnabled = value && value.length > 0;
};

export const nutritionPreferencesAnswered = (
  { state, effects, actions },
  value
) => {
  if (value.no_preference != null) {
    if (value.no_preference) {
      state.metaData.user_metadata.nutrition_no_preference = true;
    } else {
      state.metaData.user_metadata.nutrition_no_preference = false;
    }
  }
  if (value.no_allergies != null) {
    if (value.no_allergies) {
      state.metaData.user_metadata.nutrition_no_allergies = true;
    } else {
      state.metaData.user_metadata.nutrition_no_allergies = false;
    }
  }
  state.profileNutrition = { ...state.profileNutrition, ...value };
  if (state.metaData.user_metadata.nutrition_no_preference != null) {
    state.onboardingForwardEnabled = true;
  }
  if (state.metaData.user_metadata.nutrition_no_allergies != null) {
    state.onboardingForwardEnabled = true;
  }
};

export const nameAndAgeSubmitted = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.firstName = value.firstName;
  state.profile.year_of_birth = value.yearOfBirth;
  state.profile.gender = 'female';
  state.profile.body_type = 'average';
  state.onboardingForwardEnabled = value.enableForward;
};

export const nameChanged = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.firstName = value;
  state.profile.gender = 'female';
  state.profile.body_type = 'average';
};

export const ageChanged = ({ state, effects, actions }, value) => {
  state.profile.year_of_birth = value;
  state.profile.gender = 'female';
  state.profile.body_type = 'average';
};

export const setEnableForward = ({ state, effects, actions }, value) => {
  state.onboardingForwardEnabled = value;
};

export const BMISubmitted = ({ state, effects, actions }, value) => {
  state.profile.weight =
    value.selectedWeightMetric === 1 ? lbsToKg(value.weight) : value.weight;
  state.profile.height = value.height;

  state.metaData.user_metadata.selectedWeightMetric =
    value.selectedWeightMetric;
  state.metaData.user_metadata.selectedHeightMetric =
    value.selectedHeightMetric;

  if (value.selectedWeightMetric === 1) {
    state.metaData.user_metadata.weightLbs = value.weight;
  } else {
    state.metaData.user_metadata.weightLbs = null;
  }

  if (value.selectedHeightMetric === 1) {
    state.metaData.user_metadata.heightFt = value.heightFt;
    state.metaData.user_metadata.heightIn = value.heightIn;
  }

  if (value.selectedHeightMetric === 2) {
    state.metaData.user_metadata.heightFt = null;
    state.metaData.user_metadata.heightIn = null;
  }

  state.onboardingForwardEnabled = value.enableForward;
};

export const countryEthnicitySubmitted = (
  { state, effects, actions },
  value
) => {
  state.metaData.user_metadata.country = value.country;
  if (!Array.isArray(value.ethnicity) || value.ethnicity.length > 0) {
    state.metaData.user_metadata.selectedEthnicity = value.ethnicity;
  }
  state.onboardingForwardEnabled = value.enableForward;
};

export const dnaKitReceived = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.dnaKitReceived = value;
};

export const kitNumberSubmitCompleted = ({ state, effects, actions }) => {
  state.kitNumberSubmit.submitSuccessful = true;
  state.kitNumberSubmit.showSubmitStatus = true;
  state.onboardingForwardEnabled = true;
};

export const kitNumberSubmitFailed = ({ state, effects, actions }) => {
  state.kitNumberSubmit.submitSuccessful = false;
  state.kitNumberSubmit.showSubmitStatus = true;
  state.onboardingForwardEnabled = false;
};

export const setGenotype = ({ state, effects, actions }, value) => {
  state.genotype = value;
  state.isGenotypeFetched = true;
};

export const submitKitNumber = ({ state, effects, actions }, kitNumber) => {
  const params = {
    gxgimport: {
      gxg_kit_number: kitNumber,
    },
  };

  effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
      return effects.apiGenotype.postGenotype(params);
    })
    .then(() => {
      actions.setErrorSavingGenotype('');
      return effects.apiGenotype.getGenotype();
    })
    .then((genotype) => {
      actions.setGenotype(genotype);
      actions.setErrorSavingGenotype('');
      if (genotype.status != null) {
        actions.kitNumberSubmitCompleted();
      } else {
        actions.kitNumberSubmitFailed();
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.config.method === 'delete') {
          actions.setErrorDeletingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'post') {
          actions.setErrorSavingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'get') {
          actions.setErrorGettingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      }
    });
};

export const fileUploadProgressChanged = (
  { state, effects, actions },
  value
) => {
  state.upload.progress = value;
};

export const fileUploadCompleted = ({ state, effects, actions }) => {
  state.upload.showUploadStatus = true;
  state.upload.progress = 100;
  state.upload.successful = true;
  state.onboardingForwardEnabled = true;
};

export const fileUploadFailed = ({ state, effects, actions }) => {
  state.upload.showUploadStatus = true;
  state.upload.progress = 100;
  state.upload.successful = false;
};

export const uploadFile = ({ state, effects, actions }, file) => {
  const params = {
    fileupload: {
      content_type: 'application/octet-stream',
      method: 'PUT',
      original_filename: file.name,
    },
  };

  effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
      return effects.apiGenotype.postGenotype(params);
    })
    .then(() => {
      actions.setErrorSavingGenotype('');
      return effects.apiGenotype.getGenotype();
    })
    .then((genotype) => {
      actions.setErrorSavingGenotype('');
      const uploadUrl = genotype.source_details.upload_url;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', uploadUrl, true);

      xhr.upload.onprogress = (ev) => {
        actions.fileUploadProgressChanged((ev.loaded * 100) / ev.total);
      };

      xhr.onload = () => {
        const status = xhr.status;
        if (status === 200) {
          actions.fileUploadCompleted();
        }
      };

      xhr.onerror = (err) => {
        actions.fileUploadFailed();
      };
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.send(file);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.config.method === 'delete') {
          actions.setErrorDeletingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'post') {
          actions.setErrorSavingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        } else if (err.response.config.method === 'get') {
          actions.setErrorGettingGenotype(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      }
    });
};

export const changeExerciseLevel = ({ state, effects, actions }, index) => {
  state.profilePregnancy.exercise_level = index;
};

export const changeLifestyleStressLevelIndex = (
  { state, effects, actions },
  index
) => {
  state.profilePregnancy.stress_level = index;
};

export const changeLifestyleSleepLevel = (
  { state, effects, actions },
  index
) => {
  state.profilePregnancy.sleep_quality = index;
};

export const goNextIntroIndex = ({ state }) => {
  state.forwardNavigation = true;
  state.introPageIndex = state.introPageIndex + 1;
};

export const goBack = ({ state, effects, actions }) => {
  state.forwardNavigation = false;
  state.onboardingForwardEnabled = true;

  if (state.metaData.user_metadata.usedCalculator) {
    if (
      state.progressStep === getStepIndex('exercise_per_day') ||
      state.progressStep === getStepIndex('exercise_intro')
    ) {
      state.progressStep = getStepIndex('name_and_age_page');
    } else if (
      state.progressStep === getStepIndex('lifestyle_sleep_and_stress')
    ) {
      state.progressStep = getStepIndex('exercise_per_day');
    } else {
      state.progressStep = state.progressStep - 1;
    }

    return;
  }

  if (
    state.progressStep === getStepIndex('children_question_page') &&
    state.metaData.user_metadata.due_date_question === '1'
  ) {
    // if user answered that user knows due date, skip questions about period date
    state.progressStep = getStepIndex('due_data_page');
  } else if (
    state.progressStep === getStepIndex('kit_number_input_page') ||
    state.progressStep === getStepIndex('kit_not_received_page')
  ) {
    state.hideBackButton = false;
    state.progressStep = getStepIndex('has_dna_page');
  } else if (state.metaData.user_metadata.app_ready_to_use_page_seen) {
    state.progressStep = getStepIndex('food_allergies_page');
  } else {
    state.progressStep = state.progressStep - 1;

    if (state.progressStep < 0) {
      state.progressStep = 0;
    }
  }
};

export const setErrorGettingUserMetadata = (
  { state, effects, actions },
  value
) => {
  state.errors.errorGettingCurrentUserMetadata = value;
};
export const setErrorGettingProfile = ({ state, effects, actions }, value) => {
  state.errors.errorGettingProfile = value;
};
export const setErrorGettingGenotype = ({ state, effects, actions }, value) => {
  state.errors.errorGettingGenotype = value;
};
export const setErrorGettingProfilePregnancy = (
  { state, effects, actions },
  value
) => {
  state.errors.errorGettingNutritionPreferences = value;
};
export const setErrorGettingNutritionPreferences = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingUserMetadata = value;
};
export const setErrorGettingAssesment = (
  { state, effects, actions },
  value
) => {
  state.errors.errorGettingAssesment = value;
};
export const setErrorGettingWeeklyCheckin = (
  { state, effects, actions },
  value
) => {
  state.errors.errorGettingWeeklyCheckin = value;
};
export const setErrorSettingWeeklyCheckin = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSettingWeeklyCheckin = value;
};
export const setErrorGettingMealPlan = ({ state, effects, actions }, value) => {
  state.errors.errorGettingMealPlan = value;
};
export const setErrorGettingRecipeActions = (
  { state, effects, actions },
  value
) => {
  state.errors.errorGettingRecipeActions = value;
};
export const setErrorSavingRecipeActions = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingRecipeActions = value;
};
export const setErrorDeletingRecipeActions = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingRecipeActions = value;
};
export const setErrorSavingUserMetadata = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingUserMetadata = value;
};
export const setErrorSavingProfile = ({ state, effects, actions }, value) => {
  state.errors.errorSavingProfile = value;
};
export const setErrorSavingGenotype = ({ state, effects, actions }, value) => {
  state.errors.errorSavingGenotype = value;
};
export const setErrorSavingNutritionPreferences = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingNutritionPreferences = value;
};
export const setErrorSavingProfilePregnancy = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSavingProfilePregnancy = value;
};

export const setErrorDeletingGenotype = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingGenotype = value;
};

export const setErrorSettingMonitorPregnancy = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingGenotype = value;
};

export const setErrorDeletingProfile = ({ state, effects, actions }, value) => {
  state.errors.errorDeletingProfile = value;
};
export const setErrorDeletingUserMetadata = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingUserMetadata = value;
};
export const setErrorDeletingProfilePregnancy = (
  { state, effects, actions },
  value
) => {
  state.errors.errorDeletingProfilePregnancy = value;
};
export const setErrorSettingSubscriptionStatus = (
  { state, effects, actions },
  value
) => {
  state.errors.errorSettingSubscriptionStatus = value;
};
export const setErrorFethcingSubscriptionStatus = (
  { state, effects, actions },
  value
) => {
  state.errors.errorFethcingSubscriptionStatus = value;
};

export const goBackInCalculator = ({ state, effects, actions }) => {
  state.forwardNavigation = false;
  state.onboardingForwardEnabled = true;
  state.metaData.user_metadata.usedCalculator = false;

  const seenCalcDueDate =
    window.localStorage.getItem('seenCalcDueDate') === 'true' ? true : false;
  if (seenCalcDueDate) {
    window.localStorage.removeItem('seenCalcDueDate');
  }

  if (state.progressStep === getCalculatorStepIndex('first_name')) {
    if (state.conceptionExperience === '1') {
      if (
        state.lastPeriodDate === null &&
        state.lastPeriodDateStart === null &&
        state.lastPeriodDateEnd === null
      ) {
        state.progressStep = getCalculatorStepIndex(
          'do_you_know_when_you_have_conceived'
        );
      } else {
        state.progressStep = getCalculatorStepIndex('do_you_know_last_period');
      }
    } else if (state.conceptionExperience === '2') {
      state.progressStep = getCalculatorStepIndex('date_of_transfer');
    }
  } else if (
    state.progressStep === getCalculatorStepIndex('do_you_know_last_period') &&
    state.conceptionExperience === '1'
  ) {
    state.progressStep = getCalculatorStepIndex('conception_experience');
  } else if (
    state.progressStep === getCalculatorStepIndex('post_term_pregnancy_page')
  ) {
    if (state.calc_other_children === '1') {
      state.progressStep = getCalculatorStepIndex('your_first_baby_page');
    } else {
      state.progressStep = getCalculatorStepIndex('first_baby_week');
    }
  } else {
    state.progressStep = state.progressStep - 1;
    if (state.progressStep < 0) {
      state.progressStep = 0;
    }
  }
  state.onboardingForwardEnabled = true;
};

export const goNextInCalculator = ({ state, effects, actions }) => {
  state.forwardNavigation = true;
  state.onboardingForwardEnabled = false;
  if (state.progressStep === getCalculatorStepIndex('general_intro')) {
    state.onboardingForwardEnabled = state.calc_firstName != null;
    state.progressStep = getCalculatorStepIndex('age');
    state.lastSectionVisited = 1;
    trackEvent('PregnancyCalc', 'Intro Completed');
  } else if (state.progressStep === getCalculatorStepIndex('name')) {
    state.onboardingForwardEnabled = state.calc_year_of_birth != null;
    state.progressStep = state.progressStep + 1;
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getCalculatorStepIndex('age')) {
    state.onboardingForwardEnabled = state.calc_height != null;
    state.progressStep = state.progressStep + 1;
    state.lastSectionVisited = 1;
    state.lastSectionVisited = 1;
    trackEvent('PregnancyCalc', 'Age Answered');
  } else if (state.progressStep === getCalculatorStepIndex('height')) {
    state.onboardingForwardEnabled = state.calc_weight != null;
    state.progressStep = state.progressStep + 1;
    state.lastSectionVisited = 1;
    trackEvent('PregnancyCalc', 'Height Answered');
  } else if (state.progressStep === getCalculatorStepIndex('weight')) {
    state.onboardingForwardEnabled = state.calc_other_children != null;
    state.progressStep = state.progressStep + 1;
    state.lastSectionVisited = 1;
    trackEvent('PregnancyCalc', 'Weight Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('your_first_baby_page')
  ) {
    if (state.calc_other_children === '2') {
      state.progressStep = getCalculatorStepIndex('first_baby_week');
      state.onboardingForwardEnabled = state.firstBabyWeek != null;
    } else {
      state.progressStep = getCalculatorStepIndex('post_term_pregnancy_page');
      state.onboardingForwardEnabled = state.postTermPregnancy != null;
    }
    trackEvent('PregnancyCalc', 'Baby Answered');
  } else if (state.progressStep === getCalculatorStepIndex('first_baby_week')) {
    state.onboardingForwardEnabled = false;
    state.progressStep = getCalculatorStepIndex('post_term_pregnancy_page');
  } else if (
    state.progressStep === getCalculatorStepIndex('post_term_pregnancy_page')
  ) {
    state.onboardingForwardEnabled = true;
    state.progressStep = state.progressStep + 1;
    trackEvent('PregnancyCalc', 'PostTerm Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('have_you_been_diagnosed')
  ) {
    state.onboardingForwardEnabled = false;
    state.progressStep = state.progressStep + 1;
    trackEvent('PregnancyCalc', 'Diagnosed Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('how_often_do_you_smoke')
  ) {
    state.onboardingForwardEnabled = false;
    state.progressStep = state.progressStep + 1;
    trackEvent('PregnancyCalc', 'Smoke Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('how_stressful_is_your_file')
  ) {
    state.onboardingForwardEnabled = true;
    state.progressStep = state.progressStep + 1;
    trackEvent('PregnancyCalc', 'Stress Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('do_you_know_your_due_date')
  ) {
    if (state.knowsDueDate === '1') {
      // TODO: (change)
      actions.saveCalcStateToLocalStorage();
    } else {
      state.progressStep = state.progressStep + 1;
    }
  } else if (state.progressStep === getCalculatorStepIndex('cycle_length')) {
    state.onboardingForwardEnabled = false;
    state.progressStep = state.progressStep + 1;
    trackEvent('PregnancyCalc', 'Cycle Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('conception_experience')
  ) {
    state.onboardingForwardEnabled = false;
    if (state.conceptionExperience === '2') {
      state.progressStep = state.progressStep + 1;
    } else {
      state.progressStep = getCalculatorStepIndex('do_you_know_last_period');
    }
    trackEvent('PregnancyCalc', 'Conception Experience Answered');
  } else if (
    state.progressStep === getCalculatorStepIndex('date_of_transfer')
  ) {
    actions.saveCalcStateToLocalStorage();
    state.progressStep = getCalculatorStepIndex('first_name');
    //actions.changeRedirectToSignup(true);
  } else if (
    state.progressStep === getCalculatorStepIndex('do_you_know_last_period')
  ) {
    if (state.lastPeriod === '1' || state.lastPeriod === '2') {
      actions.saveCalcStateToLocalStorage();
      state.progressStep = getCalculatorStepIndex('first_name');
      //actions.changeRedirectToSignup(true);
    } else {
      //actions.changeRedirectToSignup(false);
      state.onboardingForwardEnabled = true;
      state.progressStep = getCalculatorStepIndex(
        'do_you_know_when_you_have_conceived'
      );
    }
  } else if (
    state.progressStep ===
    getCalculatorStepIndex('do_you_know_when_you_have_conceived')
  ) {
    if (state.doYouKnowWhenConceived === '1') {
      actions.saveCalcStateToLocalStorage();
      state.progressStep = getCalculatorStepIndex('first_name');
      //actions.changeRedirectToSignup(true);
    } else {
      //actions.changeRedirectToSignup(false);
      state.progressStep = getCalculatorStepIndex('do_you_know_last_period');
    }
  } else if (state.progressStep === getCalculatorStepIndex('first_name')) {
    actions.saveCalcStateToLocalStorage();
    state.progressStep = getCalculatorStepIndex('capture_email');
    trackEvent('PregnancyCalc', 'FirstName Answered');
    //actions.changeRedirectToSignup(true);
  } else if (state.progressStep === getCalculatorStepIndex('capture_email')) {
    state.progressStep = getCalculatorStepIndex('calculate_due_date');
  } else if (
    state.progressStep === getCalculatorStepIndex('calculate_due_date')
  ) {
    actions.saveCalcStateToLocalStorage();
    actions.changeRedirectToSignup(true);

    state.isLoadingData = false;
  }
};

export const saveCalcStateToLocalStorage = async ({
  state,
  effects,
  actions,
}) => {
  let calculatorData = {
    firstName: state.calc_firstName,
    year_of_birth: state.calc_year_of_birth,
    height: state.calc_height,
    heightFt: state.calc_heightFt,
    heightIn: state.calc_heightIn,
    weight: state.calc_weight,
    weightLbs: state.calc_weightLbs,
    selectedHeightMetric: state.calc_selectedHeightMetric,
    selectedWeightMetric: state.calc_selectedWeightMetric,
    other_children: state.calc_other_children,
    firstBabyWeek: state.firstBabyWeek,
    postTermPregnancy: state.postTermPregnancy,
    diagnosedWith: state.diagnosedWith,
    smokingHabits: state.smokingHabits,
    stressLevel: state.stressLevel,
    cycleLength: state.cycleLength,
    conceptionExperience: state.conceptionExperience,
    dateOfTransfer: state.dateOfTransfer,
    lastPeriodDate: state.lastPeriodDate,
    lastPeriod: state.lastPeriod,
    lastPeriodDateStart: state.lastPeriodDateStart,
    lastPeriodDateEnd: state.lastPeriodDateEnd,
    dueDate: state.dueDate,
    knowsDueDate: state.knowsDueDate,
    conceivedDateStart: state.conceivedDateStart,
    conceivedDateEnd: state.conceivedDateEnd,
    doYouKnowWhenConceived: state.doYouKnowWhenConceived,
  };
  window.localStorage.setItem(
    '9moons_calculator_data',
    JSON.stringify(calculatorData)
  );
};
export const changeRedirectToSignup = async (
  { state, effects, actions },
  data
) => {
  state.redirectToSignup = data;
};
export const saveSeenCalcDueDate = async (
  { state, effects, actions },
  data
) => {
  state.seenCalcDueDate = data;
};

export const saveProfile = async ({ state, effects, actions }) => {
  effects.api.putProfile(state.profile);
};
export const saveProfilePregnancy = async ({ state, effects, actions }) => {
  effects.apiProfilePregnancy.putProfilePregnancy(state.profilePregnancy);
};

export const fillCalculatorData = async ({ state, effects, actions }, data) => {
  state.isLoadingData = true;
  await effects.api.getProfile().then((response) => {
    state.profile = response;
    state.profile.year_of_birth = data.year_of_birth;
    state.profile.height = data.height;
    state.profile.weight = data.weight;
    state.profile.gender = 'female';
    actions.saveProfile();
  });

  state.lastPeriod = data.lastPeriod;
  state.lastPeriodDate = data.lastPeriodDate;
  state.lastPeriodDateStart = data.lastPeriodDateStart;
  state.lastPeriodDateEnd = data.lastPeriodDateEnd;
  state.dateOfTransfer = data.dateOfTransfer;
  state.conceivedDateStart = data.conceivedDateStart;
  state.conceivedDateEnd = data.conceivedDateEnd;

  state.metaData.user_metadata.usedCalculator = true;
  state.metaData.user_metadata.firstName = data.firstName;
  state.metaData.user_metadata.selectedHeightMetric = data.selectedHeightMetric;
  state.metaData.user_metadata.selectedWeightMetric =
    data.selectedWeightMetric == null
      ? 1
      : parseInt(data.selectedWeightMetric, 10);
  state.metaData.user_metadata.heightFt = data.heightFt;
  state.metaData.user_metadata.heightIn = data.heightIn;
  state.metaData.user_metadata.weightLbs = data.weightLbs;
  await effects.api.putCurrentUserMetadata(state.metaData);

  state.conceptionExperience = data.conceptionExperience;
  state.dateOfTransfer = data.dateOfTransfer;
  state.lastPeriod = data.lastPeriod;
  state.lastPeriodDate = data.lastPeriodDate;
  state.lastPeriodDateEnd = data.lastPeriodDateEnd;
  state.lastPeriodDateStart = data.lastPeriodDateStart;
  state.cycleLength = data.cycleLength;
  state.conceivedDateStart = data.conceivedDateState;
  state.conceivedDateEnd = data.conceivedDateEnd;
  state.cycleLength = data.cycleLength;
  state.firstBabyWeek = data.firstBabyWeek;
  state.postTermPregnancy = data.postTermPregnancy;
  state.diagnosedWith = data.diagnosedWith;
  state.smokingHabits = data.smokingHabits;
  state.stressLevel = data.stressLevel;
  const bdd = getBDD(state);

  state.profilePregnancy = {};
  state.profilePregnancy.other_children =
    data.other_children === '1' ? true : false;
  state.profilePregnancy.stress_level = parseInt(data.stressLevel, 10);
  state.profilePregnancy.smoking_level = parseInt(data.smokingHabits, 10);
  state.profilePregnancy.due_date = bdd.dueDate.format('YYYY-MM-DD');
  state.profilePregnancy.exercise_level = 1;
  state.profilePregnancy.past_complications = false;
  state.profilePregnancy.sleep_quality = 2;
  state.profilePregnancy.alcohol_consumption_level = 0;
  const last_period_date = subtractDays(bdd.dueDate, 280);

  state.profilePregnancy.last_period_date = last_period_date;
  actions.saveProfilePregnancy();

  window.localStorage.removeItem('9moons_calculator_data');
  state.isLoadingData = false;
  state.calc_data_saved = true;
};

export const goNext = ({ state, effects, actions }) => {
  let step = 1;

  state.forwardNavigation = true;
  state.onboardingForwardEnabled = false;

  if (state.progressStep === getStepIndex('landing_page')) {
    // submit intro screen seen
    state.metaData.user_metadata.seen_intro_9moons = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    if (state.metaData.user_metadata.terms_9moons === true) {
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('terms_of_service')) {
    // submit ToS accepted
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    if (state.metaData.user_metadata.research_agreement_9moons != null) {
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('research_agreement_page')) {
    // submit Research agreement answer
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    if (state.metaData.user_metadata.usedCalculator) {
      step = 2;
    }
  } else if (state.progressStep === getStepIndex('general_intro')) {
    // submit general splash screen seen
    state.metaData.user_metadata.seen_intro_general = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (state.progressStep === getStepIndex('name_and_age_page')) {
    // submit name and age screen seen
    state.metaData.user_metadata.seen_name_and_age_general = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 1;
    if (state.metaData.user_metadata.usedCalculator) {
      // skip weight and height and pregnancy section
      step = 8;
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('bmi_page')) {
    // submit weight and height screen seen and submit weight and height
    state.metaData.user_metadata.seen_bmi_general = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.lastSectionVisited = 1;
  } else if (state.progressStep === getStepIndex('pregnancy_intro_page')) {
    if (
      state.metaData.user_metadata.due_date_question === '2' ||
      (state.metaData.user_metadata.due_date_question === '1' &&
        state.profilePregnancy.due_date != null)
    ) {
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('due_data_page')) {
    // submit users answer if she knows due date
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    if (state.metaData.user_metadata.due_date_question === '1') {
      // if she knows due date
      effects.apiProfilePregnancy
        .putProfilePregnancy(state.profilePregnancy)
        .then(() => {
          actions.setErrorSavingProfilePregnancy('');
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingProfilePregnancy(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      step = 3;
      if (
        state.profilePregnancy.other_children === false ||
        (state.profilePregnancy.other_children === true &&
          state.profilePregnancy.past_complications != null)
      ) {
        state.onboardingForwardEnabled = true;
      }
    }
    state.lastSectionVisited = 2;
  } else if (state.progressStep === getStepIndex('last_period_page')) {
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 2;
  } else if (state.progressStep === getStepIndex('congratulations_page')) {
    // submit last period date
    effects.apiProfilePregnancy
      .putProfilePregnancy(state.profilePregnancy)
      .then(() => {
        actions.setErrorSavingProfilePregnancy('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfilePregnancy(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 2;
  } else if (state.progressStep === getStepIndex('children_question_page')) {
    //setting past complications to null if other children is false in case user previously selected true for both
    if (state.profilePregnancy.other_children === false) {
      state.profilePregnancy.past_complications = null;
    }
    // submit children and complications
    effects.apiProfilePregnancy
      .putProfilePregnancy(state.profilePregnancy)
      .then(() => {
        actions.setErrorSavingProfilePregnancy('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfilePregnancy(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 2;
  } else if (state.progressStep === getStepIndex('exercise_intro')) {
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('exercise_per_day')) {
    // submit exercise per day
    if (state.profilePregnancy.exercise_level === null) {
      state.profilePregnancy.exercise_level = 0;
    }
    effects.apiProfilePregnancy
      .putProfilePregnancy(state.profilePregnancy)
      .then(() => {
        actions.setErrorSavingProfilePregnancy('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfilePregnancy(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 3;
    if (state.metaData.user_metadata.usedCalculator) {
      step = 2;
      state.onboardingForwardEnabled = true;
    }
  } else if (state.progressStep === getStepIndex('lifestyle_intro')) {
    state.onboardingForwardEnabled = true;
  } else if (
    state.progressStep === getStepIndex('lifestyle_sleep_and_stress')
  ) {
    // submit sleep and stress
    if (state.profilePregnancy.sleep_quality === null) {
      state.profilePregnancy.sleep_quality = 0;
    }

    if (state.profilePregnancy.stress_level === null) {
      state.profilePregnancy.stress_level = 0;
    }

    effects.apiProfilePregnancy
      .putProfilePregnancy(state.profilePregnancy)
      .then(() => {
        actions.setErrorSavingProfilePregnancy('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfilePregnancy(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.onboardingForwardEnabled = true;
    state.lastSectionVisited = 4;
    if (state.metaData.user_metadata.usedCalculator) {
      step = 2;
    }
  } else if (state.progressStep === getStepIndex('food_intro')) {
    state.metaData.user_metadata.seen_intro_food = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    /* state.profileNutrition.pork_free != null ||
    state.profileNutrition.pescatarian != null ||
    state.profileNutrition.vegetarian != null ||
    state.profileNutrition.vegan != null ||
    state.profileNutrition.no_preference != null
      ? (state.onboardingForwardEnabled = true)
      : (state.onboardingForwardEnabled = false); */

    //Added always true because we are not saving anywhere if none is selected, so it is selected in case all other values are false
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('dietary_preferences_page')) {
    // submit nutrition preferences
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingNutritionPreferences(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.lastSectionVisited = 5;
    /* state.profileNutrition.dairy_free != null ||
    state.profileNutrition.egg_free != null ||
    state.profileNutrition.gluten_free != null ||
    state.profileNutrition.nut_free != null ||
    state.profileNutrition.peanut_free != null ||
    state.profileNutrition.sesame_free != null ||
    state.profileNutrition.shellfish_free != null ||
    state.profileNutrition.soy_free != null ||
    state.profileNutrition.sugar_free != null ||
    state.profileNutrition.no_allergies != null
      ? (state.onboardingForwardEnabled = true)
      : (state.onboardingForwardEnabled = false); */

    //Added always true because we are not saving anywhere if none is selected, so it is selected in case all other values are false
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('food_allergies_page')) {
    // submit food allergies
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });

    // skip DNA
    state.metaData.user_metadata.completed_onboarding_9moons = true;
    state.metaData.user_metadata.app_ready_to_use_page_seen = true;
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    /*if (
      state.genotype &&
      state.genotype.status != null &&
      state.genotype.status !== 'genotype-data-invalid' &&
      state.genotype.status !== 'datasource-invalid'
    ) {
      state.metaData.user_metadata.completed_onboarding_9moons = true;
      effects.api
        .putCurrentUserMetadata(state.metaData)
        .then(() => {
          actions.setErrorSavingUserMetadata('');
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingUserMetadata(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      state.previouslyHadDna = true;
      state.progressStep = 1000;
      step = 1000;
    } else if (state.metaData.user_metadata.app_ready_to_use_page_seen) {
      step = 2;

      state.metaData.user_metadata.wantsToUseDna != null
        ? (state.onboardingForwardEnabled = true)
        : (state.onboardingForwardEnabled = false);
    }*/
    effects.apiSaveEmail
      .postOnboardedEmail({
        email_address: state.email,
        first_name: state.metaData.user_metadata.firstName,
        due_date: state.profilePregnancy.due_date,
      })
      .then(() => {
        state.savedOnboardedEmailToMailchimp = true;
      })
      .catch(() => {});
    trackEvent('Onboarding', 'Finished');
    state.lastSectionVisited = 5;
    state.onboardingForwardEnabled = true;
  } else if (state.progressStep === getStepIndex('ready_to_use_the_app_page')) {
    state.metaData.user_metadata.app_ready_to_use_page_seen = true;
    state.metaData.user_metadata.completed_onboarding_9moons = true;
    if (state.metaData.user_metadata.dnaKitReceived != null) {
      step = 1000;
    }

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    state.progressStep = 1000;
    step = 1000;
  } else if (state.progressStep === getStepIndex('our_genomics_intro')) {
    state.metaData.user_metadata.wantsToUseDna != null
      ? (state.onboardingForwardEnabled = true)
      : (state.onboardingForwardEnabled = false);
  } else if (state.progressStep === getStepIndex('has_dna_page')) {
    if (state.metaData.user_metadata.wantsToUseDna === '2') {
      step = 2;
    }
    state.hideBackButton = true;
    if (state.metaData.user_metadata.wantsToUseDna === '2') {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else if (state.progressStep === getStepIndex('kit_number_input_page')) {
    state.hideBackButton = true;
    actions.dnaKitReceived(true);
    state.metaData.user_metadata.completed_kitregistration_9moons = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    return;
  } else if (state.progressStep === getStepIndex('kit_not_received_page')) {
    actions.dnaKitReceived(false);
    state.metaData.user_metadata.completed_kitregistration_9moons = true;

    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
    step = 2;
    if (state.metaData.user_metadata.terms_9moons) {
      window.location = '/home';
    } else {
      window.location = '/onboarding';
    }
    return;
  }

  if (
    state.errors.errorSavingUserMetadata === '' &&
    state.errors.errorSavingProfile === '' &&
    state.errors.errorSavingGenotype === '' &&
    state.errors.errorSavingProfilePregnancy === '' &&
    state.errors.errorSavingNutritionPreferences === ''
  ) {
    state.progressStep = state.progressStep + step;
  }
};

export const skipToKitInput = ({ state, effects, actions }) => {
  if (state.metaData.user_metadata == null) {
    state.metaData.user_metadata = {};
  }
  state.progressStep = getStepIndex('kit_number_input_page');
};

export const goToStep = ({ state, effects, actions }, value) => {
  state.progressStep < value
    ? (state.forwardNavigation = true)
    : (state.forwardNavigation = false);
  state.progressStep = value;
};

export const updateLastSectionVisited = (
  { state, effects, actions },
  value
) => {
  state.lastSectionVisited = value;
};

export const updateIsWeeklyPlan = ({ state, effects, actions }, value) => {
  state.isWeeklyPlan = value;
};

export const saveChangesToState = ({ state, effects, actions }, value) => {
  state.metaData.user_metadata.firstName = value.firstName;
  state.profile.year_of_birth = value.yearOfBirth;
  state.profile.gender = 'female';
  state.profile.body_type = 'average';
  state.metaData.user_metadata.selectedEthnicity = value.ethnicity;
  state.metaData.user_metadata.country = value.country;
  state.profile.weight =
    value.selectedWeightMetric === 2 ? value.weight : lbsToKg(value.weight);
  state.profile.height = value.height;
  state.metaData.user_metadata.selectedWeightMetric =
    value.selectedWeightMetric;
  state.metaData.user_metadata.selectedHeightMetric =
    value.selectedHeightMetric;
  state.onboardingForwardEnabled = value.enableForward;
  state.profilePregnancy.due_date = value.dueDate;
  state.profilePregnancy.last_period_date = value.lastPeriodDate;
  state.profilePregnancy.sleep_quality = value.sleep_quality;
  state.profilePregnancy.stress_level = value.stress_level;
  state.profilePregnancy.exercise_level = value.exercise_level;
  state.profileNutrition = value.profileNutrition;

  if (value.selectedHeightMetric === 1) {
    state.metaData.user_metadata.heightFt = value.heightFt;
    state.metaData.user_metadata.heightIn = value.heightIn;
  }

  if (value.selectedWeightMetric === 2) {
    state.metaData.user_metadata.weightLbs = null;
  } else {
    state.metaData.user_metadata.weightLbs = value.weight;
  }
};

export const saveChanges = ({ state, effects, actions }, value) => {
  if (value === 'userMetadata') {
    effects.api
      .putCurrentUserMetadata(state.metaData)
      .then(() => {
        actions.setErrorSavingUserMetadata('');
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingUserMetadata(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (value === 'profile') {
    effects.api
      .putProfile(state.profile)
      .then(() => {
        actions.setErrorSavingProfile('');
        actions.fetchMealPlan();
        actions.fetchNutrientsReport();
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfile(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (value === 'pregnancy') {
    effects.apiProfilePregnancy
      .putProfilePregnancy(state.profilePregnancy)
      .then(() => {
        actions.setErrorSavingProfilePregnancy('');
        actions.fetchMealPlan();
        actions.fetchNutrientsReport();
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingProfilePregnancy(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  } else if (value === 'nutrition') {
    effects.apiNutrition
      .patchNutritionPreferences(state.profileNutrition)
      .then(() => {
        actions.setErrorSavingNutritionPreferences('');
        actions.fetchMealPlan();
        actions.fetchNutrientsReport();
      })
      .catch((err) => {
        if (err.response) {
          actions.setErrorSavingNutritionPreferences(
            `${err.response.status}: ${err.response.data.detail}`
          );
        }
      });
  }
};

export const fetchMonitorPregnancyWeight = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckin
    .getWeeklyCheckinWeight()
    .then((response) => {
      state.monitorPregnancy.weight = response[0].value;
      state.isMonitorPregnancyWeightLoaded = true;
      actions.setErrorGettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchMonitorPregnancyStress = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckin
    .getWeeklyCheckinStressLevel()
    .then((response) => {
      state.monitorPregnancy.stress_level = response[0].value;
      state.stressWeeklyCheckinsByPregnancyWeek = response;
      actions.setErrorGettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchMonitorPregnancySleep = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckin
    .getWeeklyCheckinSleepQuality()
    .then((response) => {
      state.monitorPregnancy.sleep_quality = response[0].value;
      state.sleepWeeklyCheckinsByPregnancyWeek = response;
      actions.setErrorGettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchMonitorPregnancyActivityLevel = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckin
    .getWeeklyCheckinExerciseLevel()
    .then((response) => {
      state.monitorPregnancy.exercise_level = response[0].value;
      state.activityWeeklyCheckinsByPregnancyWeek = response;
      actions.setErrorGettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchMonitorPregnancyComplications = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckin
    .getWeeklyCheckinPregnancyComplications()
    .then((response) => {
      state.monitorPregnancy.complications = response[0];
      state.complicationsWeeklyCheckinsByPregnancyWeek = response;
      actions.setErrorGettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveMonitorPregnancyState = (
  { state, effects, actions },
  value
) => {
  state.monitorPregnancy.weight = value.weight;
  state.monitorPregnancy.sleep_quality = value.sleep_quality;
  state.monitorPregnancy.stress_level = value.stress_level;
  state.monitorPregnancy.exercise_level = value.exercise_level;
  state.monitorPregnancy.complications = {
    bleeding_from_vagina: value.bleeding_from_vagina,
    sudden_sweling: value.sudden_sweling,
    fever: value.fever,
    abdomen_pain: value.abdomen_pain,
    severe_headaches: value.severe_headaches,
  };
  state.monitorPregnancy.nausea_level = value.nausea_level;
  state.monitorPregnancy.tired_level = value.tired_level;
  state.monitorPregnancy.back_pain_level = value.back_pain_level;
  state.monitorPregnancy.mood_swings_level = value.mood_swings_level;
  state.monitorPregnancy.data = value.data;
};

export const saveMonitorPregnancyChanges = async (
  { state, effects, actions },
  value
) => {
  switch (state.monitorPregnancy.data) {
    case 'weight':
      await actions.saveMonitorPregnancyWeight({
        value: state.monitorPregnancy.weight,
        date: value.date,
      });
      break;
    case 'sleep':
      actions.saveMonitorPregnancySleep({
        value: state.monitorPregnancy.sleep_quality,
        date: value.date,
      });
      break;
    case 'stress':
      actions.saveMonitorPregnancyStress({
        value: state.monitorPregnancy.stress_level,
        date: value.date,
      });
      break;
    case 'activity':
      actions.saveMonitorPregnancyActivityLevel({
        value: state.monitorPregnancy.exercise_level,
        date: value.date,
      });
      break;
    case 'complications':
      actions.saveMonitorPregnancyComplications({
        bleeding_from_vagina:
          state.monitorPregnancy.complications.bleeding_from_vagina,
        sudden_sweling: state.monitorPregnancy.complications.sudden_sweling,
        fever: state.monitorPregnancy.complications.fever,
        abdomen_pain: state.monitorPregnancy.complications.abdomen_pain,
        severe_headaches: state.monitorPregnancy.complications.severe_headaches,
        date: value.date,
      });
      break;
    default:
      return null;
  }
};

export const saveMonitorPregnancyWeight = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinWeight(value)
    .then((response) => {
      actions.setErrorSettingMonitorPregnancy('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingMonitorPregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};
export const saveMonitorPregnancySleep = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinSleepQuality(value)
    .then((response) => {
      actions.setErrorSettingMonitorPregnancy('');
      state.sleepWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingMonitorPregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};
export const saveMonitorPregnancyStress = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinStressLevel(value)
    .then((response) => {
      actions.setErrorSettingMonitorPregnancy('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingMonitorPregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};
export const saveMonitorPregnancyActivityLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinExerciseLevel(value)
    .then((response) => {
      actions.setErrorSettingMonitorPregnancy('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingMonitorPregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveMonitorPregnancyComplications = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinPregnancyComplications(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinState = ({ state, effects, actions }, value) => {
  state.weeklyCheckin.sleep_quality = value.sleep_quality;
  state.weeklyCheckin.stress_level = value.stress_level;
  state.weeklyCheckin.exercise_level = value.exercise_level;
  state.weeklyCheckin.nausea_level = value.nausea_level;
  state.weeklyCheckin.tired_level = value.tired_level;
  state.weeklyCheckin.back_pain_level = value.back_pain_level;
  state.weeklyCheckin.mood_swings_level = value.mood_swings_level;
  state.weeklyCheckin.date = value.date;

  switch (value.data) {
    case 'sleep_quality':
      actions.saveWeeklyCheckinSleepQuality({
        value: state.weeklyCheckin.sleep_quality,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'stress_level':
      actions.saveWeeklyCheckinStressLevel({
        value: state.weeklyCheckin.stress_level,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'exercise_level':
      actions.saveWeeklyCheckinExerciseLevel({
        value: state.weeklyCheckin.exercise_level,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'nausea_level':
      actions.saveWeeklyCheckinNauseaLevel({
        value: state.weeklyCheckin.nausea_level,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'tired_level':
      actions.saveWeeklyCheckinTiredLevel({
        value: state.weeklyCheckin.tired_level,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'back_pain_level':
      actions.saveWeeklyCheckinBackPainLevel({
        value: state.weeklyCheckin.back_pain_level,
        date: state.weeklyCheckin.date,
      });
      break;
    case 'mood_swings_level':
      actions.saveWeeklyCheckinMoodSwingsLevel({
        value: state.weeklyCheckin.mood_swings_level,
        date: state.weeklyCheckin.date,
      });
      break;
    default:
      return null;
  }
};

export const saveWeeklyCheckinSleepQuality = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinSleepQuality(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinStressLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinStressLevel(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinExerciseLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinExerciseLevel(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinNauseaLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinNausea(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinTiredLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinFatigue(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinBackPainLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinAchingBack(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveWeeklyCheckinMoodSwingsLevel = async (
  { state, effects, actions },
  value
) => {
  await effects.apiWeeklyCheckin
    .postWeeklyCheckinMoodSwings(value)
    .then((response) => {
      actions.setErrorSettingWeeklyCheckin('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingWeeklyCheckin(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchProfilePregnancy = async ({ state, effects, actions }) => {
  state.isProfilePregnancyFetched = false;
  await effects.apiProfilePregnancy
    .getProfilePregnancy()
    .then((response) => {
      state.profilePregnancy = response;
      state.isProfilePregnancyFetched = true;
      actions.setErrorGettingProfilePregnancy('');
    })
    .catch((err) => {
      state.isProfilePregnancyFetched = false;
      if (err.response) {
        actions.setErrorGettingProfilePregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchUserMetadata = ({ state, effects, actions }) => {
  state.hasFetchedMetaData = false;
  effects.api.getCurrentUserMetadata().then((response) => {
    actions.setMetaData(response);
  });
};

export const fetchProfile = async ({ state, effects, actions }) => {
  effects.api
    .getProfile()
    .then((response) => {
      state.profile = response;
      actions.setErrorGettingProfile('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingProfile(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchWeightCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getWeightCheckins()
    .then((response) => {
      state.weightWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchActivityCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getActivityCheckins()
    .then((response) => {
      state.activityWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchSleepCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getSleepCheckins()
    .then((response) => {
      state.sleepWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchStressCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getStressCheckins()
    .then((response) => {
      state.stressWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchNauseaCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getNauseaCheckins()
    .then((response) => {
      state.nauseaWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchFatigueCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getFatigueCheckins()
    .then((response) => {
      state.fatigueWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchAchingBackCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getAchingBackCheckins()
    .then((response) => {
      state.achingBackWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchMoodSwingsCheckinsByPregnancyWeek = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiWeeklyCheckinsByPregnancyWeek
    .getMoodSwingsCheckins()
    .then((response) => {
      state.moodSwingsBackWeeklyCheckinsByPregnancyWeek = response;
    })
    .catch(() => {});
};

export const fetchInitialReport = async ({ state, effects, actions }) => {
  await effects.apiAssessment
    .getInitialReport()
    .then((response) => {
      state.initialReport = response;
      state.isInitialReportLoaded = true;
    })
    .catch(() => {
      state.initialReport = null;
      state.isInitialReportLoaded = true;
    });
};

export const fetchNutrientsReport = async ({ state, effects, actions }) => {
  await effects.apiAssessment
    .getNutrientsReport()
    .then((response) => {
      state.nutrientsReport = response;
      state.isNutrientsReportLoaded = true;
    })
    .catch(() => {
      state.nutrientsReport = {};
      state.isNutrientsReportLoaded = true;
    });
};

export const fetchRecipeActions = async ({ state, effects, actions }) => {
  await effects.apiRecipeFavorites
    .getRecipeFavorites()
    .then((response) => {
      let action = [];

      response.forEach((recipeAction) => {
        action.push({
          recipe_id: recipeAction.id,
          action: 'favorite',
        });
      });

      state.recipeFavorites = action;
      actions.setErrorGettingRecipeActions('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingRecipeActions(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });

  await effects.apiRecipeSaved
    .getRecipeSaved()
    .then((response) => {
      let action = [];

      response.forEach((recipeAction) => {
        action.push({
          recipe_id: recipeAction.id,
          action: 'save',
        });
      });

      state.recipeActions = action;
      actions.setErrorGettingRecipeActions('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingRecipeActions(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });

  await effects.apiRecipeLogged
    .getRecipeLogged()
    .then((response) => {
      let action = [];

      response.forEach((recipeAction) => {
        action.push({
          recipe_id: recipeAction.id,
          action: 'log',
        });
      });

      state.recipeActions = action;
      actions.setErrorGettingRecipeActions('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingRecipeActions(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  state.areRecipeActionsLoaded = true;
};

export const editRecipeActions = async ({ state, effects, actions }, value) => {
  const recipeAction = value;
  const date = Object.keys(state.mealPlan);
  switch (recipeAction.code) {
    case 'favorite':
      const recipe_db_id = { recipe_db_id: recipeAction.recipe_db_id };
      await effects.apiRecipeFavorites
        .postRecipeFavorites(recipe_db_id)
        .then(() => {
          let found = false;
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isUserFavorite = !meal.recipe.isUserFavorite;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                found = true;
                return;
              }
            });
          });
          if (found === false && state.similarRecipes) {
            for (let ix1 in state.similarRecipes) {
              for (let ix2 in state.similarRecipes[ix1]) {
                if (
                  state.similarRecipes[ix1][ix2].id === recipeAction.recipe_id
                ) {
                  state.similarRecipes[ix1][ix2].isUserFavorite = !state
                    .similarRecipes[ix1][ix2].isUserFavorite;
                }
              }
            }
          }
          return effects.apiRecipeActions.getFavoriteRecipes();
        })
        .then((response) => {
          state.favoriteRecipes = response;
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    case 'save':
      const recipe_id = { recipe_id: recipeAction.recipe_id };
      await effects.apiRecipeSaved
        .postRecipeSaved(recipe_id)
        .then(() => {
          let found = false;
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isSaved = !meal.recipe.isSaved;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                found = true;
                return;
              }
            });
          });
          if (found === false && state.similarRecipes) {
            for (let ix1 in state.similarRecipes) {
              for (let ix2 in state.similarRecipes[ix1]) {
                if (
                  state.similarRecipes[ix1][ix2].id === recipeAction.recipe_id
                ) {
                  state.similarRecipes[ix1][ix2].isSaved = !state
                    .similarRecipes[ix1][ix2].isSaved;
                }
              }
            }
          }
          return effects.apiRecipeActions.getSavedForLaterRecipes();
        })
        .then((response) => {
          state.savedForLaterRecipes = response;
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    case 'log':
      const data = {
        recipe_id: recipeAction.recipe_id,
        meal_datetime: recipeAction.meal_datetime,
        num_of_servings: recipeAction.num_of_servings,
        meal_type: recipeAction.meal_type,
        meal_id: recipeAction.meal_id,
      };
      await effects.apiRecipeLogged
        .postRecipeLogged(data)
        .then((response) => {
          date.forEach((day) => {
            state.mealPlan[day].meals.forEach((meal) => {
              if (recipeAction.recipe_id === meal.recipe.id) {
                meal.recipe.isLogged = !meal.recipe.isLogged;
                actions.setRecipeDetails(meal.recipe);
                actions.setRecipeMealType(meal);
                return;
              }
            });
          });
        })
        .catch((err) => {
          if (err.response) {
            actions.setErrorSavingRecipeActions(
              `${err.response.status}: ${err.response.data.detail}`
            );
          }
        });
      break;
    default:
      return null;
  }
};

export const swapRecipe = async ({ state, effects, actions }, value) => {
  const recipeId = value.recipeId;
  const mealId = value.mealId;
  await effects.apiSwap
    .postSwap({ meal_id: mealId, recipe_id: recipeId })
    .then((response) => {})
    .catch((err) => {});
};

export const saveEmail = async ({ state, effects, actions }, value) => {
  await effects.apiSaveEmail
    .postEmail({
      email_address: value.email,
      first_name: value.first_name,
      due_date: value.due_date,
    })
    .then(() => {
      state.savedEmailToMailchimp = true;
    })
    .catch(() => {});
};

export const saveOnboardedEmail = async (
  { state, effects, actions },
  value
) => {
  if (value.finishOnboarding) {
    state.metaData.user_metadata.app_ready_to_use_page_seen = true;
    state.metaData.user_metadata.completed_onboarding_9moons = true;
    await effects.api.putCurrentUserMetadata(state.metaData);
  }

  await effects.apiSaveEmail
    .postOnboardedEmail({
      email_address: value.email,
      first_name: value.first_name,
      due_date: value.due_date,
    })
    .then(() => {
      state.savedOnboardedEmailToMailchimp = true;
      if (value.finishOnboarding) {
        window.location = '/home';
      }
    })
    .catch(() => {
      if (value.finishOnboarding) {
        window.location = '/home';
      }
    });
};

export const activateSubscription = async (
  { state, effects, actions },
  value
) => {
  await effects.apiActivateSubscription
    .updateSubscription({ activation_code: value })
    .then(() => {
      state.subscription.status = true;
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSettingSubscriptionStatus(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchSubscriptionStatus = async ({ state, effects, actions }) => {
  if (!REACT_APP_GATING_ON) {
    state.subscription.status = true;
    return;
  }
  state.subscription.isBeingFetched = true;
  await effects.apiFetchActiveSubscriptionStatus
    .fetchActiveSubscriptionStatus()
    .then((response) => {
      state.subscription.isBeingFetched = false;
      if (response == null || response.length === 0) {
        state.subscription.status = false;
      }

      // find all 9moons subscriptions, there can be
      // - inactive subscriptions (expired or with incomplete payment flow)
      // - active subscriptions (with is_activated = true, and without expiry date or with current date within expiry date)
      const allAppSubscriptions = response.filter(
        (r) => r.subscription_code === '9moons-premium'
      );
      if (allAppSubscriptions == null || allAppSubscriptions.length === 0) {
        state.subscription.status = false;
      }

      const activeAppSubscriptions = allAppSubscriptions.filter(
        (s) => s.is_activated || s.is_valid
      );
      if (
        activeAppSubscriptions == null ||
        activeAppSubscriptions.length === 0
      ) {
        state.subscription.status = false;
      } else {
        // TODO: stop ignoring the fact that subscription can expire
        // Here we should find first subscription that has expires_at = null, or that has expires_at < current date
        state.subscription.status = true;
      }
    })
    .catch((err) => {
      state.subscription.isBeingFetched = false;
      if (err.response) {
        actions.setErrorFethcingSubscriptionStatus(
          `${err.response.status}: ${err.response.data.detail}`
        );
      } else {
        actions.setErrorFethcingSubscriptionStatus('error');
      }
    });
};

export const generateReport = async ({ state, effects, actions }) => {
  await effects.apiReport.generateReport({
    report_code: '9moons',
    language: 'en',
  });
};

export const getReport = async ({ state, effects, actions }) => {
  await effects.apiReport
    .getReport()
    .then((response) => {
      if (
        state.genotype &&
        state.genotype.status != null &&
        response &&
        (Object.keys(response).length === 0 ||
          response.find((r) => r.report_code === '9moons') == null)
      ) {
        actions.generateReport();
      } else {
        state.report = response.find((r) => r.report_code === '9moons');
      }
    })
    .catch(() => {});
};

export const fetchMealPlan = async ({ state, effects, actions }) => {
  if (state.isMealPlanFetching) {
    return;
  }
  state.isMealPlanLoaded = false;
  state.isMealPlanFetching = true;
  state.mealPlan = {};
  state.similarRecipes = [];

  await effects.apiAssessment
    .getMealPlan()
    .then((response) => {
      state.isMealPlanFetching = false;
      state.mealPlan = response;
      state.isMealPlanLoaded = true;
      actions.setErrorGettingMealPlan('');
    })
    .catch((err) => {
      state.isMealPlanFetching = false;
      if (err.response) {
        actions.setErrorGettingMealPlan(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchNutritionPreferences = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiNutrition
    .getNutritionPreferences()
    .then((response) => {
      state.profileNutrition = response;
      actions.setErrorGettingNutritionPreferences('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorGettingNutritionPreferences(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const fetchSimilarRecipe = async (
  { state, effects, actions },
  value
) => {
  await effects.apiRecipeActions
    .getSimilarRecipes(value.recipeId, value.servingSize)
    .then((response) => {
      state.similarRecipes[value.recipeId] = response;
    })
    .catch(() => {});
};

export const fetchFavoriteRecipes = async ({ state, effects, actions }) => {
  await effects.apiRecipeActions
    .getFavoriteRecipes()
    .then((response) => {
      state.favoriteRecipes = response;
    })
    .catch(() => {});
};

export const fetchSavedForLaterRecipes = async ({
  state,
  effects,
  actions,
}) => {
  await effects.apiRecipeActions
    .getSavedForLaterRecipes()
    .then((response) => {
      state.savedForLaterRecipes = response;
    })
    .catch(() => {});
};

export const setRecipeDetails = ({ state, effects, actions }, value) => {
  state.recipeDetails = { ...value };
};

export const setRecipeMealType = ({ state, effects, actions }, value) => {
  state.recipeMealType = { ...value };
};

export const fetchRecipeDetails = async (
  { state, effects, actions },
  recipeId
) => {
  await effects.apiRecipeActions
    .getRecipeDetails(recipeId)
    .then((response) => {
      state.recipeDetails = response;
    })
    .catch(() => {});
};

export const addToShoppingList = async ({ state, effects, actions }, ids) => {
  const params = {
    recipe_db_id: ids.databaseId,
    action: 'add',
  };

  await effects.apiRecipeActions.postShoppingList(params).then(() => {
    actions.fetchRecipeDetails(ids.id);
  });
};

export const removeFromShoppingList = async (
  { state, effects, actions },
  recipeId
) => {
  const params = {
    recipe_id: recipeId,
    action: 'remove',
  };

  await effects.apiRecipeActions.postShoppingList(params).then(() => {
    actions.fetchRecipeDetails(recipeId);
  });
};

export const fetchShoppingList = async (
  { state, effects, actions },
  viewType
) => {
  await effects.apiRecipeActions
    .getShoppingList(viewType)
    .then((response) => {
      state.shoppingList = response;
    })
    .catch(() => {});
};

export const toggleShoppingListItem = async (
  { state, effects, actions },
  value
) => {
  state.shoppingList.forEach((item) => {
    if (item.id === value.itemId) {
      item.items[value.index].isDone = !item.items[value.index].isDone;
    }
  });
};

export const updateToShoppingListItem = async (
  { state, effects, actions },
  data
) => {
  const params = {
    item_id: data.value.databaseId,
    is_aggregate: data.displayMode === 'aisles',
  };

  await effects.apiRecipeActions
    .postShoppingListItem(params)
    .then((response) => {
      actions.toggleShoppingListItem(data.value);
    });
};

export const deleteCheckedShoppingListItems = async (
  { state, effects, actions },
  viewType
) => {
  await effects.apiRecipeActions
    .deleteShoppingListItems()
    .then((response) => {
      actions.fetchShoppingList(viewType);
    })
    .catch((err) => {});
};

export const deleteUserData = async ({ state, effects, actions }) => {
  await effects.apiGenotype
    .deleteGenotype()
    .then(() => {
      actions.setErrorDeletingGenotype('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingGenotype(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  await effects.apiProfilePregnancy
    .deleteProfilePregnancy()
    .then(() => {
      actions.setErrorDeletingProfilePregnancy('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingProfilePregnancy(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  await effects.api
    .deleteProfile()
    .then(() => {
      actions.setErrorDeletingProfile('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingProfile(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
  await effects.api
    .deleteCurrentUserMetadata()
    .then(() => {
      actions.setErrorDeletingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorDeletingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};

export const saveComponentForModalWindow = (
  { state, effects, actions },
  value
) => {
  state.componentForModalWindow = value;
};

export const setProgressStep = ({ state, effects, actions }, value) => {
  state.progressStep = value;
};

export const firstBabyQuestionAnswered = (
  { state, effects, actions },
  value
) => {
  if (value === '1') {
    state.profilePregnancy.other_children = false;
    state.onboardingForwardEnabled = true;
  } else if (value === '2') {
    state.profilePregnancy.other_children = true;
    state.onboardingForwardEnabled = false;
  }
};

export const firstBabyWeekQuestionAnswered = (
  { state, effects, actions },
  value
) => {
  if (value != null) {
    state.onboardingForwardEnabled = true;
  }
  state.firstBabyWeek = value;
};

export const knowsDueDateAnswered = ({ state, effects, actions }, value) => {
  state.knowsDueDate = value;
  if (value === '1') {
    if (state.dueDate) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else if (value === '2') {
    state.onboardingForwardEnabled = true;
  }
};

export const updateStateProp = ({ state, effects, actions }, obj) => {
  state[obj.key] = obj.value;
  if (obj.nextEnabled != null) {
    state.onboardingForwardEnabled = obj.nextEnabled;
  }
};

export const doYouKnowLastPeriodAnswered = (
  { state, effects, actions },
  value
) => {
  state.lastPeriod = value;
  if (value === '1') {
    if (state.lastPeriodDate) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else if (value === '2') {
    if (state.lastPeriodDateStart && state.lastPeriodDateEnd) {
      state.onboardingForwardEnabled = true;
    } else {
      state.onboardingForwardEnabled = false;
    }
  } else {
    state.onboardingForwardEnabled = true;
  }
};

export const savePdfReportDownloaded = async (
  { state, effects, actions },
  value
) => {
  state.metaData.user_metadata.pdf_report_downloaded = value;
  await effects.api.putCurrentUserMetadata(state.metaData);
};

export const setPaymentIntent = async ({ state }, response) => {
  state.payment.intent = response;
};

export const createPaymentIntent = async ({ effects, actions }, data) => {
  effects.apiPayment.createPaymentIntent(data).then((response) => {
    actions.setPaymentIntent(response);
  });
};

export const setMetaData = async ({ state }, data) => {
  state.metaData = data;
  state.hasFetchedMetaData = true;
};

export const getAuthenticationTokensAndMetaData = async (
  { effects, state, actions },
  data
) => {
  state.hasFetchedMetaData = false;
  effects.apiAuthentication.checkAuthorizationCode(data).then((response) => {
    if (response) {
      window.localStorage.setItem('access_token', response.access_token);
      console.log('Pushing token in action');
      if (window && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(response.access_token);
      } else {
        actions.onInitialize({ fetchEmail: true });
      }
    }
  });
};

export const setUserEmail = async ({ state }, email) => {
  state.email = email;
};

export const getUserEmail = async ({ effects, actions }) => {
  await effects.apiAuthentication.getUserInfo().then((response) => {
    if (response.email) {
      actions.setUserEmail(response.email);
    }
  });
};

export const saveFirstSigningDate = async (
  { state, effects, actions },
  signInDate
) => {
  state.metaData.user_metadata.nine_moons_first_signing_date = signInDate;
  effects.api
    .putCurrentUserMetadata(state.metaData)
    .then(() => {
      actions.setErrorSavingUserMetadata('');
    })
    .catch((err) => {
      if (err.response) {
        actions.setErrorSavingUserMetadata(
          `${err.response.status}: ${err.response.data.detail}`
        );
      }
    });
};
