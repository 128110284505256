import React from 'react';
import PropTypes from 'prop-types';

import BaseTextInput from './BaseTextInput';

export default function TextInput(props) {
  return <BaseTextInput label={props.label} variant="outlined" {...props} />;
}

TextInput.propTypes = {
  label: PropTypes.string,
};
