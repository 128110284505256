import React from 'react';

import Box from 'components/atoms/Layout/Box';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import QuestionRefactored from 'components/organisms/Inputs/Question/QuestionRefactored';

export default function OtherChildrenQuestion(props) {
  const handleChange = (value, checked) => {
    props.onChange && props.onChange(value, checked);
  };

  return (
    <QuestionRefactored title={props.title}>
      <Box display="flex" justifyContent="center">
        <div style={{ maxWidth: '70vw' }}>
          <RadioButtonGroup
            id="children-radio-input"
            color="primary"
            rowNumber={1}
            onChange={(value, checked) => {
              handleChange(value, checked);
            }}
            items={props.data}
            direction={'column'}
          />
        </div>
      </Box>
    </QuestionRefactored>
  );
}
