import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Button from 'components/atoms/Inputs/Buttons/Button';
import Box from 'components/atoms/Layout/Box/Box';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ROUTE_HOME } from 'routes';
import { trackEvent } from 'components/helpers/userTrackingEventsHelper';
import { useActions } from 'overmind/index';
import { getQueryString } from 'components/helpers/utmParametersHelper';

export default function Checkout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const actions = useActions();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    trackEvent('Payment Form Initialized', '');
  }, []);

  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // client_secret in this response is equal to payment order id
    trackEvent('Payment Attempt', '');
    stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then((response) => {
        const { error, paymentIntent } = response;
        if (
          error &&
          (error.type === 'card_error' || error.type === 'validation_error')
        ) {
          setMessage(error.message);
          trackEvent('Payment Failed', '');
        } else if (error) {
          setMessage('An unexpected error occured.');
          trackEvent('Payment Failed', '');
        } else if (paymentIntent) {
          switch (paymentIntent.status) {
            case 'succeeded':
              setSubscribed(true);
              setMessage('Your 9moons subscription is now active!');
              trackEvent('Payment Succeeded', '');
              break;
            case 'processing':
              setMessage('Your 9moons subscription is being processed...');
              break;
            case 'requires_payment_method':
              setMessage('Your payment was not successful, please try again.');
              trackEvent('Payment Failed', '');
              break;
            default:
              setMessage('Something went wrong.');
              trackEvent('Payment Failed', '');
              break;
          }
        } else {
          setMessage('Unknown error occured.');
          trackEvent('Payment Failed', '');
        }

        setIsLoading(false);
      });
  };

  return (
    <>
      {!subscribed && (
        <>
          <PaymentElement id="payment-element" />
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                maxWidth: '200px',
                marginTop: '20px',
              }}
              disabled={isLoading || !stripe || !elements}
            >
              {isLoading ? 'Processing...' : 'Subscribe'}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">
              {message && <div id="payment-message">{message}</div>}
            </Typography>
          </Box>
        </>
      )}

      {subscribed && (
        <>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">
              {message && <div id="payment-message">{message}</div>}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => {
                actions.fetchSubscriptionStatus();
                if (props.initiator) {
                  history.push(props.initiator + getQueryString());
                } else {
                  history.push(ROUTE_HOME + getQueryString());
                }
              }}
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                maxWidth: '200px',
                marginTop: '20px',
              }}
            >
              {'Continue to 9moons'}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
