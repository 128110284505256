import Box from 'components/atoms/Layout/Box/Box';
import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import Typography from 'components/atoms/Typography/Typography';
import LineChart from 'components/molecules/Charts/LineChart';
import i18n from 'i18n';

export const prepareMonitorLineChartData = (data, currentWeek) => {
  let newData = new Array(data.length);
  let currentLevel = 3;

  if (data.filter((d) => d != null).length === 0) {
    return [];
  }

  for (let i = 0; i < data.length; i++) {
    if (i > currentWeek) {
      newData[i] = null;
    } else {
      if (!data[i]) {
        newData[i] = currentLevel;
      } else {
        newData[i] = data[i];
        currentLevel = data[i];
      }
    }
  }

  return newData;
};

export const getCategories = () => {
  const categories = [];
  for (let i = 1; i < 41; i++) {
    categories.push('W' + i);
  }

  return categories;
};

export const renderSymptomChartVertical = (
  title,
  chartData,
  color,
  categories,
  textCategory,
  min,
  max
) => {
  let chartColor = [color];
  return (
    <GridContainer
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginBottom: '15px' }}
    >
      <GridItem xs={12}>
        <Box display="flex" flexDirection="column" color={color}>
          <Typography style={{ textAlign: 'center' }}>{title}</Typography>
        </Box>
      </GridItem>
      <GridItem xs={12}>
        {chartData && chartData.length > 0 && (
          <LineChart
            sparkline={false}
            data={[
              {
                name: title,
                data: chartData,
              },
            ]}
            colors={chartColor}
            categories={categories}
            yFormatter={(val) => {
              return i18n.t(`${textCategory}.a${val + 1}`);
            }}
            min={min != null ? min : 0}
            max={max != null ? max : 4}
          />
        )}
        {(chartData == null || chartData.length === 0) && (
          <Typography style={{ textAlign: 'center' }}>NO DATA</Typography>
        )}
      </GridItem>
    </GridContainer>
  );
};

export const renderSymptomChartHorizontal = (title, chartData, color) => {
  let chartColor = [color];
  return (
    <GridContainer
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginBottom: '5px' }}
    >
      <GridItem xs={5}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="20px"
        >
          <Box color={color}>
            <Typography>{title}</Typography>
          </Box>
        </Box>
      </GridItem>
      <GridItem xs={7}>
        {chartData && chartData.length > 0 && (
          <LineChart
            height={20}
            sparkline={true}
            data={[
              {
                name: title,
                data: chartData,
              },
            ]}
            colors={chartColor}
          />
        )}
        {(chartData == null || chartData.length === 0) && (
          <Typography>NO DATA</Typography>
        )}
      </GridItem>
    </GridContainer>
  );
};
