import React, { useState, useEffect } from 'react';

import GridContainer from 'components/atoms/Layout/Grid/GridContainer';
import GridItem from 'components/atoms/Layout/Grid/GridItem';
import FormattedDate from 'components/atoms/Typography/FormattedDate';
import HorizontalLine from 'components/atoms/Layout/HorizontalLine/HorizontalLine';
import MealSwiper from 'components/organisms/MealSwiper/MealSwiper';

import { getCurrentWeekFromDate, getDate } from 'components/helpers/dateHelper';
import { getRecipe } from 'components/helpers/planHelper';
import { useOvermindState } from 'overmind/index';

export default function WeeklyPlan(props) {
  const overmindState = useOvermindState();
  const [daysInWeek, setDaysInWeek] = useState([]);
  const [dailyMeals, setDailyMeals] = useState([]);

  useEffect(() => {
    let initialWeek = [];
    const firstDay = getDate(
      overmindState.profilePregnancy.pregnancy_week_start
    );
    getCurrentWeekFromDate(firstDay).forEach((day) => {
      initialWeek.push({
        date: day,
      });
    });
    setDaysInWeek(initialWeek);
  }, []);

  const getDailyMeals = (dayOfTheWeek) => {
    const date = Object.keys(overmindState.mealPlan)[dayOfTheWeek];
    if (overmindState.mealPlan[date]) {
      if (
        overmindState.mealPlan[date].meals &&
        overmindState.mealPlan[date].meals.length > 3
      ) {
        const arr = [
          overmindState.mealPlan[date].meals[0],
          overmindState.mealPlan[date].meals[1],
          overmindState.mealPlan[date].meals[3],
          overmindState.mealPlan[date].meals[2],
        ];
        setDailyMeals(arr);
      } else {
        setDailyMeals(overmindState.mealPlan[date].meals);
      }
    }
    return date;
  };

  return (
    <GridContainer direction="column">
      <GridItem>
        {daysInWeek &&
          daysInWeek.map((item, ix) => (
            <GridContainer direction="column" style={{ marginBottom: '23px' }}>
              <GridItem>
                <FormattedDate
                  date={item.date}
                  format={'dddd, DD MMMM'}
                  variant="h5"
                />
              </GridItem>
              <GridItem>
                <GridContainer
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <GridItem xs={12} style={{ marginTop: '11px' }}>
                    <MealSwiper
                      uid={'navigation-swiper-' + item.date.unix()}
                      spaceBetween={10}
                      index={ix}
                      getRecipe={getRecipe}
                      getDailyMeals={getDailyMeals}
                      dailyMeals={dailyMeals}
                      onRecipeSelected={(id, mealType, ix) =>
                        props.onRecipeSelected(id, mealType, ix)
                      }
                      parentComponent="meal"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              {ix !== 6 ? (
                <HorizontalLine
                  margin={'10px 0'}
                  border={'1px dashed #f0cccb'}
                />
              ) : null}
            </GridContainer>
          ))}
      </GridItem>
    </GridContainer>
  );
}
