import React from 'react';

import Box from 'components/atoms/Layout/Box';
import RadioButtonGroup from 'components/organisms/Inputs/RadioButtonGroup/RadioButtonGroup';
import Question from 'components/organisms/Inputs/Question/Question';

export default function DueDateQuestion(props) {
  const handleRadioChange = (val, checked) => {
    props.onChange && props.onChange(val, checked);
  };

  return (
    <Question title={props.title}>
      <Box display="flex" justifyContent="center">
        <RadioButtonGroup
          id="due-date-radio-input"
          color="primary"
          rowNumber={1}
          onChange={(val, checked) => {
            handleRadioChange(val, checked);
          }}
          items={props.data}
          colorUnchecked={props.colorUnchecked}
        />
      </Box>
    </Question>
  );
}
